import { Component } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  public showloader: any;
  constructor(public loader: LoaderService) {

  }
  ngOnInit() {
    this.loader.isLoading$.subscribe(x => { this.showloader = x }
    );
  }


}
