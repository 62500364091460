import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import * as Highcharts from 'highcharts/highcharts';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { NumberPipe } from 'src/app/pipes/number.pipe';
import * as _ from 'lodash';

declare interface SequenceInfo {
  externalId: string,
  title: string
  timestamp: number
};

@Component({
  selector: 'app-pump-curve-chart',
  templateUrl: './pump-curve-chart.component.html',
  styleUrls: ['./pump-curve-chart.component.css'],
})
export class PumpCurveChartComponent implements OnChanges {

  @Input() well: string;
  @Input() startDate: any;
  @Input() endDate: any;

  @ViewChild('mainContainer') mainContainer: ElementRef;

  public Highcharts: typeof Highcharts = Highcharts;
  public loading: boolean = false;
  public pumpCurveBaseSequences: SequenceInfo[] = [];
  public pumpCurveBaseSequence: SequenceInfo | null = null;
  public dynamicCurveSequences: SequenceInfo[] = [];
  public dynamicCurveSequence: SequenceInfo | null = null;
  public dynamicSequenceDropdownvalue: string;
  public chartOptions: Highcharts.Options | null = null;

  constructor(private apiService: CognitApiService, private numberFormat: NumberPipe) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    const properties = ['well', 'startDate', 'endDate'];

    if (changes) {
      const propertiesChanged = [];

      Object.entries(changes).forEach((entry: any[]) => {
        const key = entry[0];
        const change = entry[1];

        if (properties.includes(key) && !change.firstChange && change.previousValue !== change.currentValue)
          propertiesChanged.push(change);
      });

      if (propertiesChanged.length > 0)
        this.loadData();
    }
  }

  public onPumpCurveBaseSelected(event: any) {
    this.pumpCurveBaseSequence = _.cloneDeep(this.pumpCurveBaseSequences.filter((sequence:any)=>sequence.externalId == event.value)[0]);
    this.getSequenceData();
  }

  public onDynamicCurveSelected(event: any) {
    this.dynamicCurveSequence = _.cloneDeep(this.dynamicCurveSequences.filter((sequence:any)=>sequence.externalId == event.value)[0]);
    this.getSequenceData();
  }

  private async loadData() {
    this.loading = true;

    this.pumpCurveBaseSequence = null;
    this.dynamicCurveSequence = null;
    this.chartOptions = null;

    this.pumpCurveBaseSequences = await this.loadSequences(`pumpCurveBase:${this.well}`, false, true);
    this.dynamicCurveSequences = await this.loadSequences(`dynamicCurve:${this.well}`, true, false);

    if (this.pumpCurveBaseSequences.length > 0)
      this.pumpCurveBaseSequence = _.cloneDeep(this.pumpCurveBaseSequences[0]);

    if (this.dynamicCurveSequences.length > 0)
      this.dynamicCurveSequence = _.cloneDeep(this.dynamicCurveSequences[0]);

    this.loading = false;

    this.getSequenceData();
  }

  private async getSequenceData() {
    this.loading = true;

    const extraOptsForFrequencySeries = {
      color: '#fff',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
            radius: 4,
          },
        },
      },
    };

    const freqSeries: any = await this.loadSeriesData(this.pumpCurveBaseSequence?.externalId, extraOptsForFrequencySeries, serie => !isNaN(serie.name));
    const dynamicSeries: any = await this.loadSeriesData(this.dynamicCurveSequence?.externalId);
    const operatingPointSeries: any = await this.loadOperatingPotins();

    this.setChartOpts([...freqSeries, ...dynamicSeries, ...operatingPointSeries]);

    this.loading = false;
  }

  private loadOperatingPotins(): Promise<any[]> {
    return new Promise(res => {
      const viewId = 'VfmOutput';
      const viewVersion = '1_4';

      const filter = {
        "and": [{
          "nested": {
            scope: [environment.cogniteSpace, `${viewId}/${viewVersion}`, 'well'],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": [this.well]
              }
            }
          }
        }, {
          "equals": {
            "property": [environment.cogniteSpace, `${viewId}/${viewVersion}`, "pumpType"],
            "value": "ESP"
          }
        }]
      }

      this.apiService.getInstancelist(viewId, filter, viewVersion).subscribe({
        next: async (data: any) => {
          if (data?.items) {
            const series: any = [];

            const viewId = 'VfmOutput';
            const viewVersion = '1_4';

            const properties = data?.items?.[0]?.properties[environment.cogniteSpace]?.[`${viewId}/${viewVersion}`];

            let pumpHeadGeneratedId = properties?.pumpHeadGenerated;
            let avgRateThroughPumpId = properties?.avgRateThroughPump;

            let dynamicDate = moment(this.dynamicCurveSequence?.title, 'DD-MM-YYYY HH:mm:ss').add(1, 'second').valueOf();
            const pumpHeadGeneratedData = await this.getTimeSeriesData([{ externalId: pumpHeadGeneratedId, before: dynamicDate, targetUnit: 'length:ft' }]);
            const avgRateThroughPumpData = await this.getTimeSeriesData([{ externalId: avgRateThroughPumpId, before: dynamicDate }]);

            if (pumpHeadGeneratedData.length > 0 && avgRateThroughPumpData.length > 0) {
              series.push({
                name: 'Operating Point',
                data: [[avgRateThroughPumpData[0].value, pumpHeadGeneratedData[0].value]],
              });
            }

            res(series);
          } else {
            res([]);
          }
        },
        error: err => {
          console.error(err);
          res([]);
        },
      });
    });
  }

  private async getTimeSeriesData(items: any[]): Promise<any[]> {
    return new Promise(async res => {
      try {
        const data = await this.apiService.getLatestTimeseriesData(items);
        let dataPoints: any[] = [];

        if (data?.length > 0) {
          for (const d of data) {
            dataPoints = dataPoints.concat(d.datapoints);
          }
        }

        res(dataPoints.sort((a, b) => a.timestamp >= b.timestamp ? -1 : 1));
      } catch (e) {
        console.error(e);
        res([]);
      }
    });
  }

  private async loadSequences(externalIdPrefix: string, applyDatefilter = true, enddatefilter: boolean) {
    const sequences = await this.apiService.getSequenceListAll({ filter: { externalIdPrefix: externalIdPrefix } });
    const data: SequenceInfo[] = [];

    const startDate = moment(this.startDate).startOf("day").valueOf();
    let completeEnddate = new Date(this.endDate);
    completeEnddate.setDate(completeEnddate.getDate());

    const endDate = completeEnddate.valueOf();

    let completeEndDateForPumpCurv = new Date(this.endDate);
    completeEndDateForPumpCurv.setDate(completeEndDateForPumpCurv.getDate() - 1);

    const endDateforPumpCurv = completeEndDateForPumpCurv.valueOf();
    if (sequences?.length > 0) {
      sequences.forEach(e => {
        if (e.externalId && e.metadata?.date) {
          const timestamp = Number(moment.utc(e.metadata.date, 'YYYY-MM-DD HH:mm:ss').local().valueOf());
          const title = moment.utc(e.metadata.date, 'YYYY-MM-DD HH:mm:ss').local().format('DD-MM-YYYY HH:mm:ss');

          if (applyDatefilter === true) {
            if (timestamp >= startDate && timestamp <= endDate)
              data.push({ externalId: e.externalId, title: title, timestamp: timestamp });
          } else if (enddatefilter) {

            if (timestamp <= endDateforPumpCurv)
              data.push({ externalId: e.externalId, title: title, timestamp: timestamp });
          }
          else {
            data.push({ externalId: e.externalId, title: title, timestamp: timestamp });
          }
        }
      });

      if (data.length > 0)
        data.sort((a, b) => a.timestamp >= b.timestamp ? -1 : 1);
    }
    return data;
  }

  private async loadSeriesData(externalId?: string, props?: any, applyExtraOpsIf?: (serie: any) => {}) {
    let data: any = [];

    if (externalId && this.startDate && this.endDate) {
      const rows = await this.apiService.getSequenceRowsAll({ externalId: externalId, limit: 1000 });
      const processedData = this.processSequenceData(rows);

      data = this.prepareSeriesData(processedData, props, applyExtraOpsIf);
    }

    return data;
  }

  private setChartOpts(series: any[]): void {
    const width = this.mainContainer?.nativeElement?.offsetWidth ?? 800;
    const _that = this;

    this.chartOptions = {
      lang: { noData: 'No Data' },
      chart: { backgroundColor: 'transparent', width: width, height: 300 },
      credits: { enabled: false },
      title: { text: '' },
      tooltip: {
        formatter: function () {
          return `Freq ${this.point.series.name} (HZ): Q: ${_that.numberFormat.transform(this.x)} (bbl/day), H: ${_that.numberFormat.transform(this.y)} (ft)`;
        },
      },
      plotOptions: {
        series: {
          marker: {
            symbol: 'circle',
          },
        },
      },
      xAxis: { type: 'linear', title: { text: 'Q (bbl/day)' } },
      yAxis: { type: 'linear', title: { text: 'H (ft)' } },
      series: series,
    };
  }

  private prepareSeriesData(data: any[], seriesOpts: any = null, applyExtraOptsIf?: (serie: any) => {}): any[] {
    const series: any[] = [];

    if (data?.length > 0) {
      for (const row of data) {
        const index = series.findIndex(e => e.name === row.freq);
        const point = [row.Q, row.H];

        if (index !== -1) {
          series[index].data.push(point);
        } else {
          let serie = { name: row.freq, data: [point] };

          if (seriesOpts && (!applyExtraOptsIf || applyExtraOptsIf(serie)))
            serie = { ...serie, ...seriesOpts };

          series.push(serie);
        }
      }
    }

    return series.sort((a, b) => a.name <= b.name ? -1 : 1);
  }

  private processSequenceData(data: any[]): any[] {
    const tmpData: any[] = []

    if (data?.length > 0) {
      for (const row of data) {
        const tmpRow: any = {};

        row.columns.forEach((column: any, i: number) => {
          tmpRow[column.externalId] = row.values[i];
        });

        tmpData.push(tmpRow);
      }
    }

    return tmpData;
  }

}
