<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [showDateRange]="false" [filterOnWellType]="false" (onFieldChange)="clearWellSelection()" (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)" (onclear)="clearWellSelection()" [showClearButton]="true"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card class="example-card" id="card" autoHeight>
    <mat-card-header style="display: flex; align-items: center;">
      <mat-card-title style="flex-grow: 1;">Well Deviation Summary</mat-card-title>
      <div *ngIf="selectedTab === 'survey'" style="display: flex; align-items: center; margin-left: 30px;">
        <label for="numberInput" class="interval-label">Resampling Interval:</label>
        <input id="numberInput" class="interval-input" type="number" [(ngModel)]="intervalValue" (input)="onNumberChange()" min="0" >
      </div>
      <mat-button-toggle-group class="submenu" (change)="onTabChange($event)" [(value)]="selectedTab">
        <mat-button-toggle [value]="'survey'">Deviation Survey</mat-button-toggle>
        <mat-button-toggle [value]="'calculate'">Calculate</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-card-header>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="selectedTab === 'survey'">
        <div>
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="summaryData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true"
            kendoGridSelectBy="" [filterable]="true" [sortable]="true" [sort]="sort" (sortChange)="onSortChange($event)" [rowClass]="rowClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card' }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <h4 class="col-sm-10">Deviation Summary</h4>
                <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid"
                    [exportColumnRules]="exportColumnRules" [data]="summaryData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                  <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility()">
                  <mat-icon *ngIf="displayGridRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                  <mat-icon *ngIf="!displayGridRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="MD" title="MD"></kendo-grid-column>
            <kendo-grid-column field="TVDRT" title="TVDRT"></kendo-grid-column>
            <kendo-grid-column field="TVDSS" title="TVDSS"></kendo-grid-column>
            <kendo-grid-column field="INC" title="INC"></kendo-grid-column>
            <kendo-grid-column field="AZI" title="AZI"></kendo-grid-column>
            <kendo-grid-column field="DLS" title="DLS"></kendo-grid-column>
            <kendo-grid-excel fileName="Deviation Summary.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Deviation Summary.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
        </div>
      </div>
      <div *ngIf="selectedTab === 'calculate'">
        <app-deviation-calculate [wellSelected]="wellSelected" [sequenceData]="rawSequenceData"></app-deviation-calculate>
      </div>
    </mat-card-content>
  </mat-card>
</div>
