import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validator, Validators, FormControl } from '@angular/forms';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { HierarchyService } from '../../services/hierarchy.service';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { LossAccountingModel } from '../lossaccountingmodel';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { EventChange, ExternalEvent } from '@cognite/sdk/dist/src';
import { Category1Dataset, Category2Dataset, Category3Dataset } from '../lossAccounting-catgorydataset';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';


@Component({
  selector: 'app-lossaccounting',
  templateUrl: './lossaccounting.component.html',
  styleUrls: ['./lossaccounting.component.css']
})
export class LossaccountingComponent {
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public gridData: LossAccountingModel[] = [];
  private subs: Subscription[] = [];
  public sdmPropertyList: any[] = [];
  public lossAccountingdata: LossAccountingModel;
  public selectedrows: any;
  public startdate: any;
  public enddate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public fieldList: any;

  public selectedField: string;
  public timeseriesdata: any;
  formgroup: FormGroup;
  timeseriesid: string | undefined;
  unit: string | undefined;
  public wellPadList: any;
  public filterWellList: any;
  public selectedwell: string;
  public selectedwellPad: string;
  public formPadList: any;
  public formwellList: any;
  public filterstartDate: any;
  public filterendDate: any;
  public range: DateRange<Moment>;
  public WellListToloadGrid: any[] = [];
  eventType: string = "well downtime";
  public category1List = Category1Dataset;
  public category2List: any[] = [];
  public category3List: any[] = [];
  public category1selectedoption: string;
  public isResultpage = false;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'End Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  constructor(private timeseriesHelper: TimeseriesHelper,
    private dateHelper: DateHelper,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private router: Router,
    private hierarchyService: HierarchyService,) {
    this.setRecordValue();
    this.setdateRangeDefaultDate();

  }
  ngOnInit() {
    this.setRecordValue()
    this.setformgroupData();
    console.log(this.router.url);
    let url = this.router.url;
    this.isResultpage = url.split("/")[2] == "results" ? true : false;

    this.todaysdate = moment(moment().toDate());

    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {

      this.fieldList = data;
    }));
  }
  setdateRangeDefaultDate() {
    this.filterstartDate = moment(moment()).add(-1, 'months');
    this.filterendDate = moment();
    this.range = ({
      startDate: this.filterstartDate,
      endDate: this.filterendDate
    });
  }
  setRecordValue(data: any = undefined) {
    if (data == undefined) {
      this.startdate = moment(moment().toDate());
      this.enddate = moment(moment().add(+1, 'day').toDate()); // moment(moment().toDate());
      this.lossAccountingdata = { field: this.selectedField, wellpad: this.selectedwellPad, well: this.selectedwell }
    }
    else {
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      this.enddate = this.dateHelper.convertStringToDate(data.enddate);
      let wellselected = this.WellListToloadGrid.filter((x: any) => x.Name == data.well)[0]
      let wellid = wellselected.externalId;
      let wellpadid = wellselected.WellPad;
      this.category1selectedoption = data.category1;
      this.category2List = Category2Dataset.filter(item => item.category1type == this.category1selectedoption)
      this.category3List = Category3Dataset.filter(item => item.category2type == data.category2 && item.category1type == this.category1selectedoption);
      this.lossAccountingdata = {
        id: data.id,
        startdate: data.startdate, enddate: data.enddate, category1: data.category1 == '-' ? undefined : data.category1,
        category2: data.category2 == '-' ? undefined : data.category2,
        category3: data.category3 == '-' ? undefined : data.category3, comment: data.comment == '-' ? undefined : data.comment,
        loss_oil: data.loss_oil == '-' ? undefined : data.loss_oil, down_time: data.down_time == '-' ? undefined :Number(data.down_time),
        field: this.selectedField, wellpad: this.selectedwellPad == undefined || this.selectedwellPad == "" ? wellpadid : this.selectedwellPad,
        well: wellid
      }
    }
  }
  public setformgroupData() {
    //FormBuilder avoids using new FormControl in FormGroup instance
    this.formgroup = this.fb.group({
      field: new FormControl({ value: this.lossAccountingdata.field, disabled: this.formMode == "Edit" }, Validators.required),
      wellpad: new FormControl({ value: this.lossAccountingdata.wellpad, disabled: this.formMode == "Edit" }, Validators.required),
      well: new FormControl({ value: this.lossAccountingdata.well, disabled: this.formMode == "Edit" }, Validators.required),
      category1: [this.lossAccountingdata.category1, Validators.required],
      category2: [this.lossAccountingdata.category2, Validators.required],
      category3: [this.lossAccountingdata.category3, Validators.required],
      comment: [this.lossAccountingdata.comment],
      //loss_oil: [this.lossAccountingdata.loss_oil, Validators.required],
      downtime: [this.lossAccountingdata.down_time, Validators.required],
      loss_oil: new FormControl({ value: this.lossAccountingdata.loss_oil, disabled: this.formMode == "Edit" }, Validators.required),
    });
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedwell = "";
    this.selectedwellPad = "";
    //this.getWelldata([event.event.value]);
    this.wellPadList = event.wellPadList;
    this.WellListToloadGrid = event.allFieldwells;
    this.loadGridData();
  }
  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
    this.loadGridData();
  }

  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    if (this.filterWellList == undefined)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((well: any) => well.externalId == event.event.value);
    this.loadGridData();
  }
  onformfieldChange(event: any) {
    this.loader.showLoader();
    this.formwellList = [];
    this.subs.push(this.hierarchyService.getWellpad(event.value).subscribe((data: any) => {
      this.loader.hideLoader();
      this.formPadList = data;
    }));
  }
  onformwellPadChange(event: any) {
    this.loader.showLoader();
    this.subs.push(this.hierarchyService.getWelldata(event.value).subscribe((data: any) => {
      this.loader.hideLoader();
      this.formwellList = data;
    }));
  }
  onwellChange(event: any) {

  }

  onDateSelected(event: any) {
    this.gridData = [];
    this.filterstartDate = moment(event.event.startDate);
    this.filterendDate = moment(event.event.endDate);
    if (event.event.startDate != undefined && event.event.endDate != undefined && this.selectedField != undefined)
      this.loadGridData();
  }
  loadGridData() {
    //this.gridData = [];
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) {
      let selectedwell = this.WellListToloadGrid.map((a: any) => a.externalId);
      this.loader.showLoader();
      let enddate = moment(this.filterendDate._d).add(1, 'days').toDate();
      this.apiService.getEventlist(this.filterstartDate._d, enddate, this.eventType, selectedwell).then(eventResponse => {
        this.gridData = [];

        if (eventResponse.length > 0) {
          let wellAssetId = [...new Set(eventResponse.map((asset: any) => asset.assetIds[0]))];
          wellAssetId = wellAssetId.map(id => ({ 'id': id }))
          this.apiService.getAssetsByID(wellAssetId).then((assetdetails: any) => {
            this.loader.hideLoader();
            this.getGriddata(eventResponse, assetdetails)
          });
        }
        else {
          this.gridData = [];
          this.loader.hideLoader();
        }
      });
    }
  }
  getGriddata(eventResponse: any, assetdetails: any) {
    this.gridData = [];
    this.gridData.splice(0, this.gridData.length);
    let sequence = 0;
    eventResponse.forEach((eventitem: any) => {
      let metadata = eventitem.metadata;
      let starttime = moment(eventitem.startTime).format("DD-MM-YYYY");
      let endtime = moment(eventitem.endTime).format("DD-MM-YYYY");
      if (this.isResultpage == true) {
        starttime = moment(eventitem.startTime).format("YYYY-MM-DD");
        endtime = moment(eventitem.endTime).format("YYYY-MM-DD");
      }
      let category1 = metadata?.category1 != undefined || metadata?.category1 == "" ? metadata?.category1 : "-";
      let category2 = metadata?.category2 != undefined || metadata?.category2 == "" ? metadata?.category2 : "-"
      let category3 = metadata?.category3 != undefined || metadata?.category3 == "" ? metadata?.category3 : "-";
      let eventcomment = metadata?.comment != undefined || metadata?.comment == "" ? metadata?.comment : "-";
      let pdmscomment = metadata?.pdms_comment != undefined || metadata?.pdms_comment == "" ? metadata?.pdms_comment : "-";



      let well: string = "";
      let welldata;
      if (eventitem.assetIds != undefined) {
        let assetid = eventitem.assetIds[0];
        well = assetdetails.filter((asset: any) => (asset.id == assetid))[0].name;
        welldata = this.WellListToloadGrid.filter(x => x.Name == well)[0];
      }
      let wellpadid = undefined;
      let fieldid = undefined;
      fieldid = (this.selectedwellPad ==undefined || this.selectedwellPad =="")? this.selectedField: undefined;//  welldata.Field != undefined ? welldata.Field : this.selectedField;
      wellpadid = (this.selectedwell == undefined || this.selectedwell =="")?  welldata.WellPad: undefined;
      let lossitem: LossAccountingModel = {
        sequence: sequence, well: well,
        id: eventitem.id, loss_oil: isNaN(metadata?.lost_oil_bbl) ? "-" : Number(metadata?.lost_oil_bbl),
        startdate: starttime, enddate: endtime,
        category1: category1, category2: category2, category3: category3, comment: eventcomment,
        down_time: isNaN(metadata?.down_hours) ? "-" : Number(metadata?.down_hours) ,
        field: fieldid, wellpad: wellpadid, pdmscomment:pdmscomment
      }
      sequence += 1;
      this.gridData.push(lossitem);
    });
    this.gridData = addSortableDates(this.gridData, this.kendoGrid);

  }

  clear() {
    this.gridData = [];
    this.selectedField = "";
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.WellListToloadGrid = [];

  }
  onEditecordClick(dataitem: any) {
    this.formMode = "Edit"
    this.formPadList = this.wellPadList;
    this.formwellList = this.WellListToloadGrid;// [{ "externalId": dataitem.well, Name: dataitem.well }]
    //this.formwellList = this.filterWellList;
    this.setRecordValue(dataitem);
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });

  }

  onAddrecordClick() {
    this.formMode = "Add";
    this.formPadList = this.wellPadList;
    this.formwellList = this.filterWellList;
    this.setRecordValue();
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public ondelete(item: any) {
    this.openDialog(item);
  }
  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete: any[] = [];
    if (selectedrows == undefined) {
      let data = this.gridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.id != undefined) {
          recortodelete.push({ id: record.id })
        }
      });
    }
    else
      recortodelete.push({ id: selectedrows.id })
    this.loader.showLoader();
    this.apiService.deleteEvent(recortodelete).then(x => {
      this.selectedrows = undefined;
      setTimeout(() => {
        this.loader.hideLoader();
        this.loadGridData();
      }, 1000);
    })

  }
  save(formgroup: any) {
    this.gridData = [];
    this.loader.showLoader();
    if (this.formgroup.valid) {
      let lost_oil = String(this.formgroup.get("loss_oil")?.value) == '' ? undefined : this.formgroup.get("loss_oil")?.value;
      let down_time = String(this.formgroup.get("downtime")?.value) == '' ? undefined : this.formgroup.get("downtime")?.value;
      let category1 = String(this.formgroup.get("category1")?.value) == '' ? "" : this.formgroup.get("category1")?.value;
      let category2 = String(this.formgroup.get("category2")?.value) == '' ? "" : this.formgroup.get("category2")?.value;
      let category3 = String(this.formgroup.get("category3")?.value) == '' ? "" : this.formgroup.get("category3")?.value;
      let comment = String(this.formgroup.get("comment")?.value) == '' || String(this.formgroup.get("comment")?.value) == 'null' ? "-" : this.formgroup.get("comment")?.value;

      let selectedwell = this.formgroup.get("well")?.value;
      const prmisedata: Promise<any>[] = [
        this.apiService.getAssetsByID([{ "externalId": selectedwell }]),
        this.apiService.getDatasetDetailsById([{ "externalId": "all" }])
      ]
      Promise.all(prmisedata).then(results => {

        let assetId = results[0][0].id;
        let datasetId = results[1][0].id
        let metadata = { 'lost_oil_bbl': lost_oil, 'category1': category1, 'category2': category2, 'category3': category3, 'comment': comment, 'down_hours': down_time }
        let startdate = Date.parse(this.startdate.format("YYYY-MM-DD"));
        let enddate = Date.parse(this.enddate.format("YYYY-MM-DD"));

        if (this.formMode == "Add") {
          const eventitem: ExternalEvent = {
            assetIds: [assetId], dataSetId: datasetId,
            description: 'created from dashboard', externalId: selectedwell + "_HoursOnline_DOWN_TIME_" + Math.floor(Math.random() * 1000000000),
            startTime: startdate, endTime: enddate, metadata: metadata, type: this.eventType
          }
          this.apiService.createEvent([eventitem]).then(response => {
            this.gridData = [];
            setTimeout(() => {
              this.loader.hideLoader();
              this.loadGridData();
            }, 1000);
          }, error => {
            this.loader.hideLoader();
          });
        }
        else {
          let id = this.lossAccountingdata.id;
          const eventitem: EventChange = {
            id: Number(id),
            update: {
              description: { set: 'New description' },
              metadata: { set: { 'lost_oil_bbl': lost_oil, 'category1': category1, 'category2': category2, 'category3': category3, 'comment': comment, 'down_hours': down_time } }
            }
          }
          this.apiService.updateEvent([eventitem]).then(response => {
            setTimeout(() => {
              this.loader.hideLoader();
              this.loadGridData();
            }, 1000);

          }, error => {
            this.loader.hideLoader();
          });
        }
      });
    }
  }
  onCategory1Change(event: any) {
    this.category3List = [];
    this.category2List = Category2Dataset.filter(item => item.category1type == event.value)
  }
  onCategory2Change(event: any) {
    this.category3List = Category3Dataset.filter(item => item.category2type == event.value && item.category1type == this.category1selectedoption);
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
