import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import moment from 'moment';
import { WellBookService } from '../well-book.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { NodeAndEdgeCollectionResponseV3Response } from '@cognite/sdk/dist/src';

@Component({
  selector: 'app-well-events',
  templateUrl: './well-events.component.html',
  styleUrls: ['./well-events.component.css']
})
export class WellEventsComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  public loading: boolean = false;
  public wellSelected: string | null;
  public events: any[] = [];

  private cdfDef: CdfSpaceView | null = null;

  constructor(private apiService: CognitApiService, private wbService: WellBookService) {
    this.cdfDef = this.wbService.getSpaceDefinition('wellEvents');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onfilterwellChange(this.wbService.getWell(), true);
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadWellEvents();
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.events = [];
  }

  private loadWellEvents() {
    if (this.wellSelected && this.cdfDef) {
      this.loading = true;

      const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

      this.apiService.getInstancelist(this.cdfDef?.id ?? '', filter, this.cdfDef?.version, this.cdfDef?.space).subscribe({
        next: (data: NodeAndEdgeCollectionResponseV3Response) => {
          this.loading = false;
          this.processData(data);
        },
        error: err => {
          this.loading = false;
          console.error(err);
        }
      });
    }
  }

  private processData(data: NodeAndEdgeCollectionResponseV3Response): void {
    this.events = [];

    if (data?.items?.length > 0) {
      for (const item of data.items) {
        const properties = item.properties?.[this.cdfDef?.space ?? '']?.[this.cdfDef?.fullName ?? ''];

        if (properties) {
          const event = {
            startDate: properties.startTime ?? '',
            endDate: properties.endTime ?? '',
            type: properties.eventType ?? '',
            description: properties.eventDescription ?? '',
            assetIds: '',
            eastDeparture: '',
            id: item.externalId,
            createdTime: moment(item.createdTime).utcOffset(330).format('YYYY-MM-DD HH:mm'),
            lastUpdated: moment(item.lastUpdatedTime).utcOffset(330).format('YYYY-MM-DD HH:mm')
          };

          this.events.push(event);
        }
      }
    }
  }

}
