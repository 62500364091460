<!-- <div class="card-container" >
    <mat-card>
        <div id="container"> </div>
    </mat-card>
    <mat-card class=" content-left-margin ">
        <div> <app-result-distributionchart [data]="data"></app-result-distributionchart> </div>
    </mat-card>
</div> -->

<div class="grid-container">
    <div class="grid-child">
        <mat-card>
            <div id="container"> </div>
        </mat-card>
    </div>

    <div class="grid-child">
        <mat-card>
            <div> <app-result-distributionchart [data]="data"></app-result-distributionchart> </div>
        </mat-card>
    </div>
  
</div>