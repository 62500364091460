<div class="grid">
  <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15">
  </app-auto-refresh>
  <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true">
    <ng-template kendoGridToolbarTemplate>
      <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
        <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
          <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
        </button>
        <button type="button" kendoGridPDFCommand>
          <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
        </button>
      </div>
    </ng-template>
    <kendo-grid-column field="well" title="Well Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="startdatetime_ts" title="Start" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdatetime }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="enddatetime_ts" title="End" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.enddatetime }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="window" title="Drift Window" [width]="110"></kendo-grid-column>
    <kendo-grid-column field="severity" title="Severity" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="driftbasis" title="Basis For Drift" [width]="200"></kendo-grid-column>
    <kendo-grid-excel [fileName]="title + '.xlsx'"></kendo-grid-excel>
    <kendo-grid-pdf [fileName]="title + '.pdf'" [allPages]="true"></kendo-grid-pdf>
  </kendo-grid>
</div>
