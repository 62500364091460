import { Component, Input } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { CognitApiService } from '../../services/cognit-api.service';
import { Subscription, forkJoin } from 'rxjs';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { HighchartsService } from 'src/app/services/highcharts.service';
import { Chart, Point, Series } from 'highcharts';
import StockChart from 'highcharts/modules/stock';
import { LoaderService } from '../../services/loader.service';
import * as Highcharts from 'highcharts';

StockChart(Highcharts);

@Component({
  selector: 'app-pressure-chart',
  templateUrl: './pressure-chart.component.html',
  styleUrls: ['./pressure-chart.component.css']
})


export class PressurechartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  @Input()
  well: string;

  @Input()
  Title: string;

  @Input()
  Type: string;

  @Input()
  chartId: string = 'chart';

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input()
  properties: any;


  public externalidlist: any[] = [];
  public externalId: any = {};
  public typeProperties: any = {};
  public unit: any = {};
  public wht: any[] = [];
  public whp: any[] = [];
  public dht: any[] = [];
  public dhp: any[] = [];
  public fbhp: any[] = [];
  public pip: any[] = [];
  public pir: any[] = [];
  public wir: any[] = [];
  public tir: any[] = [];
  public total: any = {};
  public iind: any[] = [];
  public pind: any[] = [];
  public mpt: any[] = [];

  public loading: boolean = false;
  public chartWidth: any;
  chartData: any[] = [];
  public options: Highcharts.Options;
  chart: any;
  constructor(private highchart: HighchartsService,
    private loader: LoaderService,
    private apiService: CognitApiService) {

  }

  async ngOnChanges() {
    if (this.Type) {
      if (this.Type == 'JP' && this.startDate != undefined && this.endDate != undefined) {
        this.chart = null;
        this.showEmptyChart();
        this.loading = true;
        this.typeProperties = this.properties;
        this.loadData();
      } else {
        this.chart = null;
        this.showEmptyChart();
      }
    }
    else {
      if (this.well != undefined && this.well != '' && this.startDate != undefined && this.endDate != undefined) {
        this.chart = null;
        this.showEmptyChart();
        this.loading = true;
        this.typeProperties = this.properties;
        this.loadData();
      } else {
        this.chart = null;
        this.showEmptyChart();
      }
    }
  }


  getexternalidlist(results: any, space: string, viewid: any, version: string, propertyConditions: any) {

    const properties = results?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      if (externaliddata) {
        const property = externaliddata['property'];
        if (propertyConditions[property] && (externaliddata['timeseriesExtId'] !== undefined || externaliddata['timeseries'] !== undefined)) {
          let seriesID = externaliddata['timeseriesExtId'] != undefined ? externaliddata['timeseriesExtId'] : externaliddata['timeseries'];
          this.externalidlist = [...this.externalidlist, { "externalId": seriesID, cursor: '' }];
          this.externalId[property] = seriesID;
        }
      }
    }
  }


  ngOnInit() {
    this.chartWidth = document.getElementById('divhfchart')?.offsetWidth.toString();
    console.log(this.chartWidth);
  }

  ngAfterViewInit() {
    this.showEmptyChart();
    this.ngOnChanges();
  }

  loadData() {
    this.externalidlist = [];
    this.externalId = {};
    this.unit = {};
    this.wht = [];
    this.whp = [];
    this.dht = [];
    this.dhp = [];
    this.fbhp = [];
    this.pip = [];
    this.pir = [];
    this.wir = [];
    this.total = {};
    this.iind = [];
    this.pind = [];
    this.tir = [];
    this.mpt = [];

    let space = environment.cogniteSpace;
    let version = '';
    let viewid = '';
    let filter: any;

    if (this.Type == 'JP' && this.Title == 'MPT Chart') {
      version = environment.version.FieldPfProperties;
      viewid = environment.view.FieldPfProperties;

      filter = undefined;
    } else {
      version = environment.version.injectorWellproperty;
      viewid = environment.view.injectorWellproperty;

      filter = {
        "equals": {
          "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
          "value": this.well
        }
      }
    }
    let sources = [
      this.apiService.getInstancelist(viewid, filter, version).toPromise(),
    ];

    forkJoin(sources).subscribe(async (instancedata: any) => {
      this.loading = true;
      const data = instancedata[0];
      let rawData = data['items'];
      if (rawData.length) {
        rawData.forEach((item: any) => {
          this.getexternalidlist(item, space, viewid, version, this.typeProperties);
        });
        if (this.externalidlist.length) {
          //this.loader.showLoader();
          let results: any[] = await this.apiService.getTimeseriesAvgRange(this.externalidlist, moment(this.startDate).valueOf(), moment(this.endDate).add(1, 'days').valueOf(), '1m');
          this.loading = false;
          let result = [];
          results.forEach((data: any) => {
            if (data.externalId == this.externalId['wellheadTemperature']) {
              this.unit['wht'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.wht.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['wellheadPressure']) {
              this.unit['whp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.whp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['downholeTemperature']) {
              this.unit['dht'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.dht.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['downholePressure']) {
              this.unit['dhp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.dhp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['calculatedFbhp']) {
              this.unit['fbhp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.fbhp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['PIP']) {
              this.unit['pip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pip.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['polymerInjectionRate']) {
              this.unit['pir'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pir.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)*150.96]);
                this.total[moment(datapoint.timestamp).valueOf()] = +datapoint.average.toFixed(3)*150.96;
              });
            }
            if (data.externalId == this.externalId['waterInjectionRate']) {
              this.unit['wir'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.wir.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)*150.96]);
                if (this.total[moment(datapoint.timestamp).valueOf()]) {
                  this.total[moment(datapoint.timestamp).valueOf()] = this.total[moment(datapoint.timestamp).valueOf()] + +datapoint.average.toFixed(3)*150.96;
                } else {
                  this.total[moment(datapoint.timestamp).valueOf()] = +datapoint.average.toFixed(3)*150.96;
                }
              });
            }
            if (data.externalId == this.externalId['injectivityIndex']) {
              this.unit['iind'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.iind.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['pseudoInjectivityIndex']) {
              this.unit['pind'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pind.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['MPT_PF_Pressure']) {
              this.unit['mpt'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.mpt.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
          });
          for (var i in this.total) {
            this.tir.push([+i, this.total[i]]);
          }
          this.showChart();
        } else {
          this.loading = false;
          this.showEmptyChart();
        }
      } else { this.loading = false; }
    });
  }

  showEmptyChart() {
    this.options = {
      chart: {
        width: this.chartWidth,
        backgroundColor: "transparent",
        marginTop: 0,
      },
      credits: {
        enabled: false
      },
      title: {
        text: this.Title
      },
    };

    this.highchart.createChart(`container-${this.chartId}`, this.options);
  }

  showChart() {
    this.options = {
      chart: {
        zooming: {
          type: 'x'
        },
        width: this.chartWidth,
        backgroundColor: "transparent"
      },
      time: {
        timezone: moment.tz.guess()
      },
      credits: {
        enabled: false
      },
      title: {
        text: this.Title,
        align: 'left'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
          style: {
            fontSize: '20px'
          }
        }, labels: {
          enabled: true,
          style: {
            color: '#FFFFFF'
          },
          format: '{value:%e %b %Y}'
        },
      },
      yAxis: [{
        alignTicks: false,
        title: {
          text: 'Temperature(Deg C)'
        },
        opposite: false,
      }, {
        alignTicks: false,
        gridLineWidth: 0,
        title: {
          text: 'Pressure(kpag)'
        },
        opposite: true,
      }], legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal'
      },
      series: [{
        type: 'line',
        name: this.unit['wht'] != '' && this.unit['wht'] != undefined ? 'Well Head Temperature(' + this.unit['wht'] + ')' : 'Well Head Temperature(Deg C)',
        data: this.wht,
        showInLegend: this.properties['wellheadTemperature'] == undefined ? false : true,
        yAxis: 0,
      }, {
        type: 'line',
        name: this.unit['whp'] != '' && this.unit['whp'] != undefined ? 'Well Head Pressure(' + this.unit['whp'] + ')' : 'Well Head Pressure(kpag)',
        data: this.whp,
        showInLegend: this.properties['wellheadPressure'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['dht'] != '' && this.unit['dht'] != undefined ? 'Down Hole Temperature(' + this.unit['dht'] + ')' : 'Down Hole Temperature(Deg C)',
        data: this.dht,
        showInLegend: this.properties['downholeTemperature'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['dhp'] != '' && this.unit['dhp'] != undefined ? 'Down Hole Pressure(' + this.unit['dhp'] + ')' : 'Down Hole Temperature(kpag)',
        data: this.dhp,
        showInLegend: this.properties['downholePressure'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['fbhp'] != '' && this.unit['fbhp'] != undefined ? 'FBHP(' + this.unit['fbhp'] + ')' : 'FBHP(kpag)',
        data: this.fbhp,
        showInLegend: this.properties['calculatedFbhp'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['pip'] != '' && this.unit['pip'] != undefined ? 'PIP(' + this.unit['pip'] + ')' : 'PIP(kpag)',
        data: this.pip,
        showInLegend: this.properties['PIP'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['pir'] != '' && this.unit['pir'] != undefined ? 'Polymer Injection Rate(BBL/D)' : 'Polymer Injection Rate(BBL/D)',
        data: this.pir,
        showInLegend: this.properties['polymerInjectionRate'] == undefined ? false : true,
        yAxis: 0
      },{
        type: 'line',
        name: this.unit['wir'] != '' && this.unit['wir'] != undefined ? 'Water Injection Rate(BBL/D)' : 'Water Injection Rate(BBL/D)',
        data: this.wir,
        showInLegend: this.properties['waterInjectionRate'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['tir'] != '' && this.unit['tir'] != undefined ? 'Total Injection Rate(BBL/D)' : 'Total Injection Rate(BBL/D)',
        data: this.tir,
        showInLegend: this.properties['waterInjectionRate'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['iind'] != '' && this.unit['iind'] != undefined ? 'Injectivity Index(' + this.unit['iind'] + ')' : 'Injectivity Index(bbl/d/psi)',
        data: this.iind,
        showInLegend: this.properties['injectivityIndex'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['pind'] != '' && this.unit['pind'] != undefined ? 'Pseudo Injectivity Index(' + this.unit['pind'] + ')' : 'Pseudo Injectivity Index(bbl/d/psi)',
        data: this.pind,
        showInLegend: this.properties['pseudoInjectivityIndex'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['mpt'] != ' ' && this.unit['mpt'] != undefined ? 'MPT PF Pressure(' + this.unit['mpt'] + ')' : 'MPT PF Pressure(Bar)',
        data: this.mpt,
        showInLegend: this.properties['MPT_PF_Pressure'] == undefined ? false : true,
        yAxis: 0
      }],
      rangeSelector: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          dataGrouping: {
            enabled: false
          }
        }
      },
      tooltip: {
        shared: true,
        pointFormat: "{series.name} : {point.y:.2f}"
      }
    }

    this.chart = Highcharts.stockChart(`container-${this.chartId}`, this.options);
    this.updateYaxis();
  }

  updateYaxis() {
    let yTitle1: string[] = [];
    let yTitle2: string[] = [];
    this.chart.series.forEach((element: any) => {
      if (element.options.yAxis == 0 && element.options.showInLegend) { yTitle1.push(element.options.name) };
      if (element.options.yAxis == 1 && element.options.showInLegend) { yTitle2.push(element.options.name) };
    })
    this.chart.update({
      yAxis: [{
        title: {
          text: yTitle1.join(',')
        }
      }, {
        title: {
          text: yTitle2.join(',')
        }
      }]
    });
    this.loading = false;
  }
}
