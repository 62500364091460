import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CdfSpaceDefinition, CdfSpaceView, CdfViewDefinition } from '../common-component/types/cdf-space-view';

@Injectable({
  providedIn: 'root',
})
export class WellBookService {

  private well: any;
  private cdfSpaceDefinition = environment.spaces.wellbook;
  private cdfSpaces: CdfSpaceDefinition = {};

  constructor() {
    this.setCdfDefinition();
  }

  public setWell(well: any = null) {
    this.well = well;
  }

  public getWell() {
    return this.well;
  }

  public getSpaceDefinition(entity: string): CdfSpaceView | null {
    const definition = this.cdfSpaces[entity] ?? null;

    if (!definition)
      console.error(`The space for WellBook ${entity} is not defined.`);

    return definition;
  }

  public getCdfFilter(cdfDef: CdfSpaceView, scope: string, prop: any, values: any, dateRange: { props: string[], startDate: number, endDate: number } | null = null): any {
    const filter: any = {
      nested: {
        scope: [cdfDef.space, cdfDef.fullName, scope],
        filter: {
          and: [{
            in: {
              property: prop,
              values: values,
            },
          }],
        },
      },
    };

    if (dateRange) {
      filter.nested.filter.and.push({ range: { property: dateRange.props, gte: dateRange.startDate } });
      filter.nested.filter.and.push({ range: { property: dateRange.props, lte: dateRange.endDate }});
    }

    return filter;
  }

  public getCdfDocsFilter(prop: any, values: string[], dateRange: { props: string[], startDate: number, endDate: number } | null = null): any {
    const filter: any = {
      filter: {
        and: [{
          in: {
            property: prop,
            values: values,
          },
        }],
      },
    };

    if (dateRange) {
      filter.filter.and.push({ range: { property: dateRange.props, gte: dateRange.startDate } });
      filter.filter.and.push({ range: { property: dateRange.props, lte: dateRange.endDate }});
    }

    return filter;
  }

  private setCdfDefinition() {
    if (this.cdfSpaceDefinition?.views) {
      for (const view of Object.keys(this.cdfSpaceDefinition.views)) {
        const details: CdfViewDefinition = (this.cdfSpaceDefinition.views as { [propName: string]: CdfViewDefinition })[view];

        this.cdfSpaces[view] = {
          space: this.cdfSpaceDefinition.id,
          id: details.id,
          version: details.version,
          fullName: `${details.id}/${details.version}`,
        };
      }
    }
  }

}
