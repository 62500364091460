import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { TabOptionsEnum } from './model';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription } from 'rxjs';
import { GroupDescriptor, GroupResult, groupBy } from '@progress/kendo-data-query';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-virtual-metering',
  templateUrl: './virtualmetering.component.html',
  styleUrls: ['./virtualmetering.component.css']
})
export class VirtualMeteringComponent {
  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.VFM;
  public VFMSummary_report_link: SafeResourceUrl;
  public VMFNonExecutionWellListlink: SafeResourceUrl;
  public VFMSummary_iframeSrc: SafeResourceUrl;
  public selectedField: string = "";
  public filterStartDate: any;
  public filterEndDate: any;
  report_link: any;

  constructor(private sanitizer: DomSanitizer) {
    this.VFMSummary_report_link = PowerBIReportLink.virtualMentoring_VFMSummary;
    this.VMFNonExecutionWellListlink = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.virtualMentoring_VMFNonExecutionWellList);
  }

  ngOnInit() {
    this.loadIframe();
  }

  onTabChange($event: any) {
    this.selectedtab = $event.value;
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.loadIframe();
  }
  clear() {
    this.selectedField = "";
  }
  onDateSelected(event: any) {
    this.filterStartDate = moment(event.event.startDate).toDate();
    this.filterEndDate = moment(event.event.endDate).toDate();
    //moment(event.event.endDate).add(1, 'days').toDate();
    if (event.event.startDate != null && event.event.endDate != null) {
      this.loadIframe();
    }
  }

  loadIframe() {
    let startdate = moment(this.filterStartDate).format("YYYY-MM-DD");
    let enddate = moment(this.filterEndDate).format("YYYY-MM-DD");
    let url = this.VFMSummary_report_link

    if (this.selectedField == "" || this.selectedField == undefined)
      this.report_link = url
    else if (this.selectedField == "All")
      this.report_link = url + "&$filter=CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    else
      this.report_link = url + "&$filter=FlatHierarchy/fieldName eq '" + this.selectedField + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    this.VFMSummary_iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.report_link);
  }


}
