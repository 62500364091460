import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlsMonitoringComponent} from './wellsSurveillance/als-monitoring/als-monitoring.component';
import { ProductionOverviewComponent} from './wellsSurveillance/production-overview/production-overview.component';
import { VirtualMeteringComponent} from './wellsSurveillance/virtual-metering/virtualmetering.component';
import { WellMonitoringComponent } from './wellsSurveillance/well-monitoring/wellmonitoring.component';

const routes: Routes = [
  {
    path: 'Wellsurveillance',
    data: {
      title: 'Well Surveillance',
   },
    loadChildren: () => import('./wellsSurveillance/wellsurveillance-routing.module').then(m => m.WellsSurveillanceRoutingModule),
  },
  {
    path: 'admin',
    data: {
      title: 'Admin',
   },
    loadChildren: () => import('./adminModule/adminmodule-routing.module').then(m => m.AdminModuleRoutingModule),
  },
  {
    path: 'alarms',
    data: {
      title: 'Alarms',
   },
    loadChildren: () => import('./alsModule/alsmodule-routing.module').then(m => m.AlsModuleRoutingModule),
  },{
    path: 'calibration',
    data: {
      title: 'Calibration',
   },
    loadChildren: () => import('./calibrationModule/calibrationmodule-routing.module').then(m => m.CalibrationModuleRoutingModule),
  },
  {
    path: 'lossaccounting',
    data: {
      title: 'Loss Accounting',
   },
    loadChildren: () => import('./loss-accounting/lossaccountingmodule-routing.module').then(m => m.LossaccountingModuleRoutingModule),
  },
  {
    path: 'Welltestvalidation',
    data: {
      title: 'Well Test Validation ',
   },
    loadChildren: () => import('./well-test-validation/well-test-validation.module').then(m => m.WellTestValidationModule),
  },
  {
    path: 'wellbook',
    data: {
      title: 'Well Book',
    },
    loadChildren: () => import('./wellbook/well-book-routing.module').then(m => m.WellBookRoutingModule),
  },
  {
    path: 'well-ranking',
    data: {
      title: 'Well Ranking',
    },
    loadChildren: () => import('./well-ranking/well-ranking.module').then(m => m.WellRankingModule),
  },
  {
    path: 'ProductionAllocation',
    data: {
      title: 'Production Allocation',
   },
    loadChildren: () => import('./production-allocation/production-allocation.module').then(m => m.ProductionAllocationModule),
  },
  {
    path: 'AutomatedReport',
    data: {
      title: 'Automated Report',
   },
    loadChildren: () => import('./automated-dashboard/automated-dashboard.module').then(m => m.AutomatedDashboardModule),
  },
  {
    path: '',
    redirectTo: 'Wellsurveillance/alsmonitoring',
    pathMatch: 'full',
    data: {
        title: 'Well Surveillance',
    },

  },
  {
    path: '',
    redirectTo: '/WellSurveillance',
    pathMatch: 'full',
  },
  {
    path: 'WellSurveillance',
    component: ProductionOverviewComponent,
    data: {
      title: 'WellSurveillance',
    },
    children: [
      {
        path:'alsmonitoring',
        pathMatch: 'full',
        component: AlsMonitoringComponent,
      },
    ],
  },
  {
    path: 'alsmonitoring',
    component: AlsMonitoringComponent,
    data: {
      title: 'Well Surveillance',
    },
  },
  {
    path: 'virtualmetering',
    component: VirtualMeteringComponent,
    data: {
      title: 'Well Surveillance',
    },
  },
  {
    path: 'wellmonitoring',
    component: WellMonitoringComponent,
    data: {
      title: 'Well Surveillance',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
