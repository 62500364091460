import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { MsalService } from "@azure/msal-angular";
import { PublicClientApplication } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn:'root'
})
export class PowerBiService {

    private accessToken: string='';
    private reportId:string = '';
    private dataSubject = new BehaviorSubject<SafeResourceUrl>('');
    private reportLink: string = '';
    data$ = this.dataSubject.asObservable();
    public loggedInUser:any;
    pca : PublicClientApplication;
    private tokenExpiry: Date;

    setData(newData: SafeResourceUrl): void{
        this.dataSubject.next(newData);
    }


    constructor(private http:HttpClient,private msalService: MsalService,private sanitizer: DomSanitizer){
        this.pca = msalService.instance as PublicClientApplication;
    }

    setAccessTokenPowerBI(token:string, expiry:Date){
        this.accessToken = token;
        this.tokenExpiry = expiry;
    }

    getReportId():string {
        return this.reportId;
    }

    getReportLink():string{
        return this.reportLink
    }

    getaccessTokenPowerBI = async () => {

        if(new Date() < this.tokenExpiry){
            return this.accessToken;
        }
        const scopes = [
          'https://analysis.windows.net/powerbi/api/.default',
        ];
        const account = this.pca.getAllAccounts()[0];
        if (!account) {
          throw new Error("no user found");
        }
        this.loggedInUser = account.name;
        console.log("active account", account.username);
        const token = await this.pca.acquireTokenSilent({
          account,
          scopes,
        });
        
        if (!token) {
            throw new Error("unable to acquire powerbi token");
        }

        const expiry = token.expiresOn ? new Date(token.expiresOn) : new Date(new Date().getTime()+ 60 * 60 * 1000);
        this.setAccessTokenPowerBI(token.accessToken, expiry);
        
        return token.accessToken;
      };

      setReportLinkData(reportLink:string, safeIFrameUrl:SafeResourceUrl){
        this.reportLink = reportLink
        const reportUrl = new URL(reportLink);
        const params = new URLSearchParams(reportUrl.search);
        const reportId = params.get('reportId')
        
        if(reportId && reportId != '' && reportLink && reportLink !=''){
            this.reportId = reportId
            this.reportLink = reportLink
            this.setData(safeIFrameUrl)
        }
      }

}

