<div class="content">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Well Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="box-content">
        <div class="box">
          <h1>Last workover</h1>
          <span [matTooltip]="wellDetails.workOverCompletion.tooltip" class="box-value">{{ wellDetails.workOverCompletion.description }}</span>
        </div>
        <div class="box">
          <h1>Last SLK operation</h1>
          <span [matTooltip]="wellDetails.lastSlkOperation.tooltip" class="box-value">{{ wellDetails.lastSlkOperation.description }}</span>
        </div>
        <div class="box">
          <h1>Last ELN operation</h1>
          <span [matTooltip]="wellDetails.lastElnOperation.tooltip" class="box-value">{{ wellDetails.lastElnOperation.description }}</span>
        </div>
        <div class="box">
          <h1>Last CTU operation</h1>
          <span [matTooltip]="wellDetails.lastCtuOperation.tooltip" class="box-value">{{ wellDetails.lastCtuOperation.description }}</span>
        </div>
        <div class="box">
          <h1>Last Tag HUD operation</h1>
          <span [matTooltip]="wellDetails.lastHud.tooltip" class="box-value">{{ wellDetails.lastHud.description }}</span>
        </div>
        <div class="box">
          <h1>Any Intervention Problem</h1>
          <span class="box-value">{{ wellDetails.interventionProblems }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
