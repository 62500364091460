export const Category1Dataset:any[]=[
    { "name":"Planned","value":"Planned"},
    { "name":"Unplanned","value":"Unplanned"}
]

export const Category2Dataset:any[]=[
    { "name":"Surface","value":"Surface","category1type":"Planned"},
    { "name":"Well","value":"Well","category1type":"Planned"},
    { "name":"Surface","value":"Surface","category1type":"Unplanned"},
    { "name":"Well","value":"Well","category1type":"Unplanned"},
    { "name":"Deferred","value":"Deferred","category1type":"Unplanned"}
]

export const Category3Dataset:any[]=[
    { "name":"WH Greasing","value":"WH Greasing","category1type":"Planned","category2type":"Surface"},
    { "name":"OHL Shutdown","value":"OHL Shutdown","category1type":"Planned","category2type":"Surface"},
    { "name":"Pipeline Rectification","value":"Pipeline Rectification","category1type":"Planned","category2type":"Surface"},
    { "name":"Equipment Maintainance","value":"Equipment Maintainance","category1type":"Planned","category2type":"Surface"},
    { "name":"Pigging","value":"Pigging","category1type":"Planned","category2type":"Surface"},
    { "name":"LSF related","value":"LSF related","category1type":"Planned","category2type":"Surface"},
    { "name":"HSP related","value":"HSP related","category1type":"Planned","category2type":"Surface"},
    { "name":"Others","value":"Others","category1type":"Planned","category2type":"Surface"},

    { "name":"Reservoir Management-High WC","value":"Reservoir Management-High WC","category1type":"Planned","category2type":"Well"},
    { "name":"Reservoir Management- depletion related","value":"Reservoir Management- depletion related","category1type":"Planned","category2type":"Well"},
    { "name":"Data Acquisition","value":"Data Acquisition","category1type":"Planned","category2type":"Well"},
    { "name":"Flow Assurance issues","value":"Flow Assurance issues","category1type":"Planned","category2type":"Well"},
    { "name":"Shut for Conversion","value":"Shut for Conversion","category1type":"Planned","category2type":"Well"},
    { "name":"Planned or pre-emptive intervention","value":"Planned or pre-emptive intervention","category1type":"Planned","category2type":"Well"},
    { "name":"Facility Limitation","value":"Facility Limitation","category1type":"Planned","category2type":"Well"},
    { "name":"Well Integrity","value":"Well Integrity","category1type":"Planned","category2type":"Well"},
    { "name":"Others","value":"Others","category1type":"Planned","category2type":"Well"},
   
    { "name":"Equipment unavailability","value":"Equipment unavailability","category1type":"Unplanned","category2type":"Surface"},
    { "name":"Well trips due to surface issues","value":"Well trips due to surface issues","category1type":"Unplanned","category2type":"Surface"},
    { "name":"OHL t/p","value":"OHL t/p","category1type":"Unplanned","category2type":"Surface"},
    { "name":"Transfer pump failure like MPP","value":"Transfer pump failure like MPP","category1type":"Unplanned","category2type":"Surface"},
    { "name":"Line Leaks","value":"Line Leaks","category1type":"Unplanned","category2type":"Surface"},
    { "name":"Equipment Failure","value":"Equipment Failure","category1type":"Unplanned","category2type":"Surface"},
    { "name":"LSF related","value":"LSF related","category1type":"Unplanned","category2type":"Surface"},
    { "name":"HSP related","value":"HSP related","category1type":"Unplanned","category2type":"Surface"},
    { "name":"Others","value":"Others","category1type":"Unplanned","category2type":"Surface"},


    { "name":"Reservoir Management-High WC","value":"Reservoir Management-High WC","category1type":"Unplanned","category2type":"Well"},
    { "name":"Reservoir Management- depletion related","value":"Reservoir Management- depletion related","category1type":"Unplanned","category2type":"Well"},
    { "name":"Well Interpretation","value":"Well Interpretation","category1type":"Unplanned","category2type":"Well"},
    { "name":"Flow Assurance issues","value":"Flow Assurance issues","category1type":"Unplanned","category2type":"Well"},
    { "name":"Well Related trips","value":"Well Related trips","category1type":"Unplanned","category2type":"Well"},
    { "name":"Facility Limitation","value":"Facility Limitation","category1type":"Unplanned","category2type":"Well"},
    { "name":"Well Integrity","value":"Well Integrity","category1type":"Unplanned","category2type":"Well"},
    { "name":"Others","value":"Others","category1type":"Unplanned","category2type":"Well"},

    { "name":"Awaiting Workover","value":"Awaiting Workover","category1type":"Unplanned","category2type":"Deferred"},
    { "name":"Awaiting Hookup","value":"Awaiting Hookup","category1type":"Unplanned","category2type":"Deferred"},
    { "name":"Others","value":"Others","category1type":"Unplanned","category2type":"Deferred"},








   
]