import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PropertyValueGroupV3 } from '@cognite/sdk/dist/src';

declare class WellDetails {
  workOverCompletion: { [propName: string]: string };
  lastSlkOperation: { [propName: string]: string };
  lastElnOperation: { [propName: string]: string };
  lastCtuOperation: { [propName: string]: string };
  lastHud: { [propName: string]: string };
  interventionProblems: string;
};

@Component({
  selector: 'app-well-details',
  templateUrl: './well-details.component.html',
  styleUrls: ['./well-details.component.css'],
})
export class WellDetailsComponent implements OnChanges {

  @Input() wellBookData: PropertyValueGroupV3 | null;

  public wellDetails: WellDetails;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.wellBookData?.previousValue !== changes?.wellBookData?.currentValue)
      this.parseData();
  }

  private parseData() {
    const data = this.wellBookData;

    this.wellDetails = {
      workOverCompletion: this.parsePropertyDetails(data, 'lastWorkover'),
      lastSlkOperation: this.parsePropertyDetails(data, 'lastSlickline'),
      lastElnOperation: this.parsePropertyDetails(data, 'lastEline'),
      lastCtuOperation: this.parsePropertyDetails(data, 'lastCTU'),
      lastHud: this.parsePropertyDetails(data, 'lastTagHUD', 'Remarks', 'Source', 'Date', 'Date'),
      interventionProblems: data?.interventionProblems?.toString() ?? '-',
    };
  }

  private parsePropertyDetails(data: PropertyValueGroupV3 | null, propertyName?: string, prop?: string, source?: string, start?: string, end?: string): { [propName: string]: any } {
    if (!data)
      return { description: '-', tooltip: '' };

    const startDate = data[`${propertyName}${start ?? 'StartDate'}`];
    const endDate = data[`${propertyName}${end ?? 'EndDate'}`];

    const property = { description: data[`${propertyName}${prop ?? 'Activity'}`], source: data[`${propertyName}${source ?? 'Source'}`], range: '', tooltip: '' };

    if (startDate && endDate) {
      property.range = `From: ${startDate} - To: ${endDate}`;
    } else if (startDate) {
      property.range = `Start: ${startDate}`;
    } else if (endDate) {
      property.range = `End: ${endDate}`
    }

    if (property.source && property.range !== '') {
      property.tooltip = `Source: ${property.source} - ${property.range}`;
    } else if (property.source) {
      property.tooltip = `Source: ${property.source}`;
    } else if (property.range !== '') {
      property.tooltip = property.range;
    }

    return property;
  }

  private getPropertyValue(data: PropertyValueGroupV3 | null, propertyName: string, properties?: string[]): string {
    if (!data)
      return '-';

    if (!properties)
      properties = ['Activity', 'Source', 'StartDate'];

    const values = [];

    for (const property of properties) {
      const value = data[`${propertyName}${property}`]?.toString() ?? null;

      if (value)
        values.push(value);
    }

    return values.length > 0 ? values.join(' ') : '-';
  }

}
