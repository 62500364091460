import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import moment, { Moment } from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { TabOptionsEnum, ESP, JP } from '../calibrationmodels';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription, forkJoin } from 'rxjs';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { CalibrationTriggerModel } from '../calibrationmodels'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InformationDialog } from '../../common-component/information-dialog/information-dialog.component';

@Component({
  selector: 'app-calibration-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.css'],
})
export class TriggerComponent {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(DataBindingDirective) directive: any;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP;
  public espStatus: any = {};
  public jpStatus: any = {};
  public espText: any = {};
  public jpText: any = {};
  public logText: any = {};
  public TempStatus: any = {};
  public unitobject: any = {};
  public failedCall: any = {};
  private subs: Subscription[] = [];
  public fieldList: any;
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any;
  public selectedwelllist: any;
  public selectedField: string;
  public selectedWell: string | undefined;
  public selectedWellPad: string;
  public setInterval: any;
  public startdate: any;
  public enddate: any;
  public filterStartDate: any;
  public filterEndDate: any;
  public range: DateRange<Moment>;
  public loading: boolean = false;
  public clearGrid: boolean = false;

  public externalId: any = {};
  public dateList: any[] = [];
  public timelist: any = {};
  public inputDatelist: any[] = [];
  public gridData: CalibrationTriggerModel[] = [];
  public itemToDisplay: string;
  public valuedate = '';

  public thp: any = {};
  public tht: any = {};
  public watercut: any = {};
  public gor: any = {};
  public liquidrate: any = {};
  public frequency: any = {};
  public pip: any = {};
  public pdp: any = {};
  public annuluspressure: any = {};
  public pfrate: any = {};
  public rpressurelow: any = {};
  public rpressurehigh: any = {};
  public pindexlow: any = {};
  public pindexhigh: any = {};
  public knlow: any = {};
  public knhigh: any = {};
  public knstepsize: any = {};
  public kshigh: any = {};
  public kslow: any = {};
  public ksstepsize: any = {};
  public pindexstepsize: any = {};
  public rpressurestepsize: any = {};

  public call1: boolean = false;
  public call2: boolean = false;
  public call3: boolean = false;
  public call4: boolean = false;
  public call5: boolean = false;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start date/time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
    { field: 'Action', hide: true },
  ];

  constructor(
    private dialog: MatDialog,
    private cogniteAuthService: CogniteAuthService,
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,) {
    this.setInterval = null;
    this.getStatus();
  }

  public showDetails: boolean = false;

  public openLog(dataItem: any) {
      this.itemToDisplay = '';
      this.itemToDisplay = dataItem.logtext;

      const dialogRef = this.dialog.open(InformationDialog, {
      data: {
        message: this.itemToDisplay,
      }
    });
  }

  ngOnInit() {
    this.range = ({
      startDate: moment(moment()).add(-1, 'days'),
      endDate: moment()
    });
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
  }
  ngOnDestroy() {
    clearTimeout(this.setInterval);
    this.setInterval = null;
  }

  clear() {
    this.wellPadList = [];
    this.filterWellList = [];
    this.selectedwelllist = [];
    this.gridData = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.clearGrid = false;
    if (this.setInterval !== null) {
      this.clearGrid = true;
      clearTimeout(this.setInterval);
      this.setInterval = null;
    }
    this.TempStatus = {};
    this.failedCall = {};
    this.gridData = [];
  }

  async getStatus() {
    try {
      let results: any[] = await this.apiService.getRawList('workflow:sdm:db', 'CalibrationProcessingData');
      let data = results;
      if (data.length > 0) {
        //filter out other rows
        if (this.selectedWell)
          data = data.filter(item => item['columns'].WellExternalId == this.selectedWell);
        this.loading = true;
        data.forEach((item: any) => {
          if (item['columns'].WellLiftType == 'ESP' && (item['columns'].SimulationStatus == 'Queued' ||
            item['columns'].SimulationStatus == 'InPorcess' || item['columns'].SimulationStatus == 'InProgress')) {
            let date = moment(item['columns'].WellTestStartDate).format("YYYY/MM/DD");
            let well = item['columns'].WellExternalId;
            if (this.espStatus[well] == undefined) {
              let data: any = {};
              let text: any = {};
              let logs: any = {};
              data[date] = true;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.espStatus[well] = data;
              this.espText[well] = text;
              this.logText[well] = logs;
            }
            else {
              let data = this.espStatus[well];
              let text = this.espText[well];
              let logs = this.logText[well];
              data[date] = true;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.espStatus[well] = data;
              this.espText[well] = text;
              this.logText[well] = logs;
            }
          } else if (item['columns'].WellLiftType == 'ESP' && (item['columns'].SimulationStatus != 'Queued' &&
            item['columns'].SimulationStatus != 'InPorcess' && item['columns'].SimulationStatus != 'InProgress')) {
            let date = moment(item['columns'].WellTestStartDate).format("YYYY/MM/DD");
            let well = item['columns'].WellExternalId;
            if (this.espStatus[well] == undefined) {
              let data: any = {};
              let text: any = {};
              let logs: any = {};
              data[date] = false;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.espStatus[well] = data;
              this.espText[well] = text;
              this.logText[well] = logs;
            }
            else {
              let data = this.espStatus[well];
              let text = this.espText[well];
              let logs = this.logText[well];
              data[date] = false;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.espStatus[well] = data;
              this.espText[well] = text;
              this.logText[well] = logs;
            }
          }
        });

        data.forEach((item: any) => {
          if ((item['columns'].WellLiftType == 'JP' || item['columns'].WellLiftType == 'Jet Pump') && (item['columns'].SimulationStatus == 'Queued' ||
            item['columns'].SimulationStatus == 'InPorcess' || item['columns'].SimulationStatus == 'InProgress')) {
            let date = moment(item['columns'].WellTestStartDate).format("YYYY/MM/DD");
            let well = item['columns'].WellExternalId;
            if (this.jpStatus[well] == undefined) {
              let data: any = {};
              let text: any = {};
              let logs: any = {};
              data[date] = true;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.jpStatus[well] = data;
              this.jpText[well] = text;
              this.logText[well] = logs;
            }
            else {
              let data = this.jpStatus[well];
              let text = this.jpText[well];
              let logs = this.logText[well];
              data[date] = true;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.jpStatus[well] = data;
              this.jpText[well] = text;
              this.logText[well] = logs;
            }
          } else if ((item['columns'].WellLiftType == 'JP' || item['columns'].WellLiftType == 'Jet Pump') && (item['columns'].SimulationStatus != 'Queued' &&
            item['columns'].SimulationStatus != 'InPorcess' && item['columns'].SimulationStatus != 'InProgress')) {
            let date = moment(item['columns'].WellTestStartDate).format("YYYY/MM/DD");
            let well = item['columns'].WellExternalId;
            if (this.jpStatus[well] == undefined) {
              let data: any = {};
              let text: any = {};
              let logs: any = {};
              data[date] = false;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.jpStatus[well] = data;
              this.jpText[well] = text;
              this.logText[well] = logs;
            }
            else {
              let data = this.jpStatus[well];
              let text = this.jpText[well];
              let logs = this.logText[well];
              data[date] = false;
              text[date] = item['columns'].SimulationStatus;
              logs[date] = item['columns'].DiagnosticMessage != undefined ? item['columns'].DiagnosticMessage : '' ;
              this.jpStatus[well] = data;
              this.jpText[well] = text;
              this.logText[well] = logs;
            }
          }
        });
        this.loading = false;
      } else { this.loading = false; }
    } catch {
      this.loading = false;
    }
  }
  public statusCode(code: boolean): SafeStyle {
    let color = 'inherit'
    if (code)
      color = "red"
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  refreshGrid(well: string) {
    setTimeout(() => {
      this.getStatus();
    }, 20000);
    setTimeout(() => {
      this.getGriddata(well);
    }, 21000);
  }

  editRow(dataItem: any, error?: boolean) {
    this.loading = true;
    let date = dataItem.date.split(' ')[0];
    if (error) {
      const updatedItem = { ...dataItem, disable: false, status: '' };
      const index = this.gridData.findIndex(item => item.id === dataItem.id);
      if (index !== -1) {
        this.gridData[index] = updatedItem;
      }
      this.gridData = this.gridData.slice(0);

    }
    else {
      let flag: boolean = false;
      let text: string = '';
      if (this.selectedtab == 'ESP') {
        if (this.TempStatus[dataItem.well] == undefined) {
          let state: any = {};
          state[date] = true;
          this.TempStatus[dataItem.well] = state;
        }
        else {
          let state = this.TempStatus[dataItem.well]
          state[date] = true;
          this.TempStatus[dataItem.well] = state;
        }
        flag = true;
        text = 'Queued';
        this.espText[dataItem.well][dataItem.date] = 'Queued';
      }
      if (this.selectedtab == 'JP') {
        if (this.TempStatus[dataItem.well] == undefined) {
          let state: any = {};
          state[date] = true;
          this.TempStatus[dataItem.well] = state;
        }
        else {
          let state = this.TempStatus[dataItem.well]
          state[date] = true;
          this.TempStatus[dataItem.well] = state;
        }
        flag = true;
        text = 'Queued';
        this.jpText[dataItem.well][dataItem.date] = 'Queued';
      }
      const updatedItem = { ...dataItem, disable: flag, status: text };
      const index = this.gridData.findIndex(item => item.id === dataItem.id);
      if (index !== -1) {
        this.gridData[index] = updatedItem;
      }
      this.gridData = this.gridData.slice(0);
      this.loading = false;
    }
  }

  async onCalibration(dataItem: any) {
    const updatedItem = { ...dataItem, disable: true, status: 'Request Initiated' };
    const index = this.gridData.findIndex(item => item.id === dataItem.id);
    if (index !== -1) {
      this.gridData[index] = updatedItem;
    }
    this.gridData = this.gridData.slice(0);

    this.loading = true;
    let date = dataItem.date.split(' ')[0];
    let time = this.timelist[date] != undefined ? this.timelist[date] : '00:00';
    await this.cogniteAuthService.getapiToken().then(res => {
      if (res) {
        let token = res;

        let url = `${environment.webApiUrl}/Cognite/ExecuteCogniteFunction`
        let wtstartdate = moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm');
        let wtutcDateTime = moment(wtstartdate).utc().format("YYYY-MM-DDTHH:mm");
        let enddate = moment(moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm')).add(1, 'days');
        let wtutcenddate = moment(enddate).utc().format("YYYY-MM-DDTHH:mm");
        let workflow = this.selectedtab == "ESP" ? "WellCalibrationEsp" : "WellCalibrationJp";
        let data = {
          "start_date": wtutcDateTime,
          "end_date": wtutcenddate,
          "run_mode": "prod",
          "workflow_name": workflow,
          "force_run": "True",
          "entity_ext_ids": [dataItem.well]
        }
        let DataObject = {
          "name": "cairn-workflow-calibration-preprocessing",
          "data": data
        }
        this.apiService.saveFunction(url, DataObject, token).subscribe((data: any) => {
          if (data.response.ExecutionStatus == "Success") {
            delete this.failedCall[dataItem.well + date];
            this.editRow(dataItem, false);
            this.loading = false;
          } else {
            this.failedCall[dataItem.well + date] = data.response.ExecutionStatus;
            const updatedItem = { ...dataItem, disable: false, status: data.response.ExecutionStatus };
            const index = this.gridData.findIndex(item => item.id === dataItem.id);
            if (index !== -1) {
              this.gridData[index] = updatedItem;
            }
            this.gridData = this.gridData.slice(0);
            this.loading = false;
          }
        },
          error => {
            this.editRow(dataItem, true);
            this.loading = false;
            console.log(error);
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Error,
              //summary: 'Summary',
              detail: "service call failed, kindly contact administrator",

            };
            this.messageService.add(alert);
          });
      }
    },
      (error) => {
        this.editRow(dataItem, true);
        if (error) {
          console.log('Error DCA for type well')
        }
      });
  }

  onDateSelected(event: any) {
    this.filterStartDate = event?.event.startDate?._d
    if (moment(this.filterStartDate).format("DD-MM-YYYY") != moment(this.filterStartDate).utc().format("DD-MM-YYYY")) {
      this.filterStartDate = moment(this.filterStartDate).add(1, 'days');
    }
    this.filterEndDate = event?.event.endDate?._d
    if (moment(this.filterEndDate).format("DD-MM-YYYY") != moment(this.filterEndDate).utc().format("DD-MM-YYYY")) {
      this.filterEndDate = moment(this.filterEndDate).add(1, 'days');
    }
    if (this.filterStartDate != undefined && this.filterEndDate != undefined && (this.selectedWell != undefined && this.selectedWell != '')) {
      if (this.setInterval !== null) {
        clearTimeout(this.setInterval);
        this.setInterval = null;
      }
      this.loadGridData(this.selectedWell);
    }
  }

  onfieldChange(event: any) {
      this.wellPadList = event.wellPadList;
      this.selectedWellPad = this.wellPadList[0]['externalId'];
      this.selectedWell = '';
      this.gridData = [];
      if (this.setInterval !== null) {
        clearTimeout(this.setInterval);
        this.setInterval = null;
      }
      this.getWellwithPumpType(this.selectedWellPad);
  }

  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.gridData = [];
    // this.getWellwithPumpType(event.value);
    // this.selectedWell='';
    // this.gridData = [];
    if (this.setInterval !== null) {
      clearTimeout(this.setInterval);
      this.setInterval = null;
    }
  }

  onFilterWellChange(event: any) {
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.well;
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;

    if (this.filterWellList == undefined || this.filterWellList.length == 0)
      this.filterWellList = event.allFieldwells;

    this.selectedwelllist = this.filterWellList.filter((x: any) => x.externalId == event.event.value);
    this.selectedWell = this.selectedwelllist[0].externalId;
    if (this.filterStartDate != undefined && this.filterEndDate != undefined && this.selectedWell != undefined) {
      if (this.setInterval !== null) {
        clearTimeout(this.setInterval);
        this.setInterval = null;
      }
      this.loadGridData(this.selectedWell);
    }
  }

  getWellwithPumpType(value: string) {
    let lifttypeTimeserieslist: any[] = [];
    let listofallwell: { externalId: string; Name: string; liftType: string }[] = []
    let lifttypedata: any[] = [];
    this.loading = true;
    this.subs.push(this.hierarchyService.getWelldataWithProperties(value).subscribe((data: any) => {
      let version = environment.cogniteSDMVersion
      let space = environment.cogniteSpace;
      data.forEach((item: any) => {
        let properties = item.properties;
        if (properties !== undefined) {
          let liftType = properties[space]["Well/" + version].liftType;
          listofallwell.push({ externalId: item.externalId, Name: properties[space]["Well/" + version].name.toString(), liftType: String(liftType) })
          if (liftType != undefined)
            lifttypeTimeserieslist.push({ "externalId": String(liftType) })
        }
      });
      this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist).then((timeseriesdatapoints: any) => {
        this.loading = false;
        timeseriesdatapoints.forEach((datapoint: any) => {
          if (datapoint.datapoints.length > 0) {
            datapoint.datapoints.forEach((data: any) => {
              if (data != undefined && String(data.value).toUpperCase() == this.selectedtab) {
                lifttypedata.push(datapoint.externalId)
              }
            })
          }
        })
        this.filterWellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
        this.selectedwelllist = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
      })
    }));
  }


  onTabChange($event: any) {
    this.selectedtab = $event.value;
    if (this.setInterval !== null) {
      clearTimeout(this.setInterval);
      this.setInterval = null;
    }
    this.clear();
    this.hierarchyComponent.clear();
  }

  getFilter(viewId: string, wellid: string[], version: string = environment.cogniteSDMVersion) {

    let filter = {
      "nested": {
        "scope": [environment.cogniteSpace, viewId + "/" + version, "well"],
        "filter": {
          "and": [{
            "in": {
              "property": ["node", "externalId"],
              values: wellid
            }
          },
          ]
        }
      }
    }

    return filter;
  }

  loadGridData(selectedWell: any) {

    this.dateList = [];
    this.gridData = [];
    this.clearGrid = false;
    this.externalId = {};
    this.unitobject = {};
    this.timelist = {};
    this.call1 = false;
    this.call2 = false;
    this.call3 = false;
    this.call4 = false;
    this.call5 = false;
    this.thp = {};
    this.tht = {};
    this.watercut = {};
    this.gor = {};
    this.liquidrate = {};
    this.frequency = {};
    this.pip = {};
    this.pdp = {};
    this.annuluspressure = {};
    this.pfrate = {};
    this.rpressurelow = {};
    this.rpressurehigh = {};
    this.pindexlow = {};
    this.pindexhigh = {};
    this.knlow = {};
    this.knhigh = {};
    this.knstepsize = {};
    this.kshigh = {};
    this.kslow = {};
    this.ksstepsize = {};
    this.pindexstepsize = {};
    this.rpressurestepsize = {};

    let version = environment.cogniteSDMVersion;

    let operationalviewid = 'WellOperationalInputsHf'
    let theoreticalviewid = 'WellTheoreticalData'
    let espinputviewid = 'ESPInputs'
    let jpinputviewid = 'JPInputs'
    let calinputviewid = 'WellCalibrationInputs'

    let well = selectedWell;
    let externalidlist: any[] = [];
    let item: any = {};

    let sources = [
      this.apiService.getInstancelist(theoreticalviewid, this.getFilter(theoreticalviewid, [well],"1_2"), "1_2"),
      this.apiService.getInstancelist(operationalviewid, this.getFilter(operationalviewid, [well]), version),
      this.apiService.getInstancelist(espinputviewid, this.getFilter(espinputviewid, [well]), version),
      this.apiService.getInstancelist(jpinputviewid, this.getFilter(jpinputviewid, [well]), version),
      this.apiService.getInstancelist(calinputviewid, this.getFilter(calinputviewid, [well]), version),
    ];

    this.loading = true;
    forkJoin(sources).subscribe((instancedata: any) => {

      item = instancedata[0]['items'][0]['properties'][environment.cogniteSpace][theoreticalviewid + "/1_2"];
      if (item.theoreticalGOR != undefined && item.theoreticalWaterCut != undefined && item.theoreticalLiquidRate != undefined) {
        externalidlist = [{ "externalId": item.theoreticalGOR }, { "externalId": item.theoreticalWaterCut }, { "externalId": item.theoreticalLiquidRate }];

        this.externalId['theoreticalGOR'] = item.theoreticalGOR;
        this.externalId['theoreticalWaterCut'] = item.theoreticalWaterCut;
        this.externalId['theoreticalLiquidRate'] = item.theoreticalLiquidRate;
        const promise1: Promise<any>[] = [
          this.apiService.getTimeseriesDataAvgRange(externalidlist, moment(this.filterStartDate).utc().valueOf(), moment(this.filterEndDate).utc().valueOf())
        ];

        Promise.all(promise1)
          .then((results: any[]) => {
            let result = [];
            result = results[0];
            result.forEach((data: any) => {
              if (data.externalId == this.externalId['theoreticalGOR']) {
                this.unitobject['gor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                data.datapoints.forEach((datapoint: any) => {
                  let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                  let time = moment(datapoint.timestamp).format("HH:mm");
                  if (!this.dateList.includes(date)) { this.dateList.push(date); this.timelist[date] = time; }
                  this.gor[date] = datapoint.average;
                });
              }
              if (data.externalId == this.externalId['theoreticalWaterCut']) {
                this.unitobject['watercut'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                data.datapoints.forEach((datapoint: any) => {
                  let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                  let time = moment(datapoint.timestamp).format("HH:mm");
                  if (!this.dateList.includes(date)) { this.dateList.push(date); this.timelist[date] = time; }
                  this.watercut[date] = datapoint.average;
                });
              }
              if (data.externalId == this.externalId['theoreticalLiquidRate']) {
                this.unitobject['liquidrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                data.datapoints.forEach((datapoint: any) => {
                  let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                  let time = moment(datapoint.timestamp).format("HH:mm");
                  if (!this.dateList.includes(date)) { this.dateList.push(date); this.timelist[date] = time; }
                  this.liquidrate[date] = datapoint.average;
                });
              }
            });
            this.call1 = true;
            if (this.call1 && this.call2 && this.call3 && this.call4) {
              this.getToleranceData(instancedata[4], well);
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error("At least one promise rejected call1:", error);
          });
      } else {
        this.call1 = true;
        if (this.call1 && this.call2 && this.call3 && this.call4) {
          this.getToleranceData(instancedata[4], well);
        }
      }

      item = {};
      externalidlist = [];
      item = instancedata[1]['items'][0]['properties'][environment.cogniteSpace][operationalviewid + "/" + version];
      if (!(item.tubingHeadPress == undefined && item.tubingHeadTemp == undefined)) {
        if (item.tubingHeadPress != undefined) {
          externalidlist.push({ "externalId": item.tubingHeadPress, "targetUnit": "pressure:psi" });
          this.externalId['tubingHeadPress'] = item.tubingHeadPress;
        }
        if (item.tubingHeadTemp != undefined) {
          externalidlist.push({ "externalId": item.tubingHeadTemp, "targetUnit": "temperature:deg_c" });
          this.externalId['tubingHeadTemp'] = item.tubingHeadTemp;
        }

        const promise2: Promise<any>[] = [
          this.apiService.getTimeseriesDataAvgRange(externalidlist, moment(this.filterStartDate).utc().valueOf(), moment(this.filterEndDate).utc().valueOf())
        ];

        Promise.all(promise2)
          .then((results: any[]) => {
            let result = [];
            result = results[0];
            result.forEach((data: any) => {
              if (data.externalId == this.externalId['tubingHeadPress']) {
                this.unitobject['thp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                data.datapoints.forEach((datapoint: any) => {
                  let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                  this.thp[date] = datapoint.average;
                });
              }
              if (data.externalId == this.externalId['tubingHeadTemp']) {
                this.unitobject['tht'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                data.datapoints.forEach((datapoint: any) => {
                  let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                  this.tht[date] = datapoint.average;
                });
              }
            });
            this.call2 = true;
            if (this.call1 && this.call2 && this.call3 && this.call4) {
              this.getToleranceData(instancedata[4], well);
            }
          })
          .catch((error) => {
            this.loading = false;
            console.error("At least one promise rejected call2:", error);
          });
      } else {
        this.call2 = true;
        if (this.call1 && this.call2 && this.call3 && this.call4) {
          this.getToleranceData(instancedata[4], well);
        }
      }


      item = {};
      externalidlist = [];
      if (instancedata[2]['items'][0] != undefined) {
        item = instancedata[2]['items'][0]['properties'][environment.cogniteSpace][espinputviewid + "/" + version];
        if (!(item.operatingFrequency == undefined && item.pumpIntakePress == undefined && item.pumpDischargePress == undefined)) {
          if (item.operatingFrequency != undefined) {
            externalidlist.push({ "externalId": item.operatingFrequency, "targetUnit": "frequency:hz" });
            this.externalId['operatingFrequency'] = item.operatingFrequency;
          }
          if (item.pumpIntakePress != undefined) {
            externalidlist.push({ "externalId": item.pumpIntakePress, "targetUnit": "pressure:psi" });
            this.externalId['pumpIntakePress'] = item.pumpIntakePress;
          }
          if (item.pumpDischargePress != undefined) {
            externalidlist.push({ "externalId": item.pumpDischargePress, "targetUnit": "pressure:psi" });
            this.externalId['pumpDischargePress'] = item.pumpDischargePress;
          }

          const promise3: Promise<any>[] = [
            this.apiService.getTimeseriesDataAvgRange(externalidlist, moment(this.filterStartDate).utc().valueOf(), moment(this.filterEndDate).utc().valueOf())
          ];

          Promise.all(promise3)
            .then((results: any[]) => {
              let result = [];
              result = results[0];
              result.forEach((data: any) => {
                if (data.externalId == this.externalId['operatingFrequency']) {
                  this.unitobject['frequency'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                  data.datapoints.forEach((datapoint: any) => {
                    let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                    this.frequency[date] = datapoint.average;
                  });
                }
                if (data.externalId == this.externalId['pumpIntakePress']) {
                  this.unitobject['pip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                  data.datapoints.forEach((datapoint: any) => {
                    let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                    this.pip[date] = datapoint.average;
                  });
                }
                if (data.externalId == this.externalId['pumpDischargePress']) {
                  this.unitobject['pdp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                  data.datapoints.forEach((datapoint: any) => {
                    let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                    this.pdp[date] = datapoint.average;
                  });
                }
              });
              this.call3 = true;
              if (this.call1 && this.call2 && this.call3 && this.call4) {
                this.getToleranceData(instancedata[4], well);
              }
            })
            .catch((error) => {
              this.loading = false;
              console.error("At least one promise rejected call3:", error);
            });
        } else {
          this.call3 = true;
          if (this.call1 && this.call2 && this.call3 && this.call4) {
            this.getToleranceData(instancedata[4], well);
          }
        }
      } else {
        this.call3 = true;
        if (this.call1 && this.call2 && this.call3 && this.call4) {
          this.getToleranceData(instancedata[4], well);
        }
      }

      item = {};
      externalidlist = [];

      if (instancedata[3]['items'][0] != undefined) {
        item = instancedata[3]['items'][0]['properties'][environment.cogniteSpace][jpinputviewid + "/" + version];
        if (!(item.aAnnulusPress == undefined && item.powerFluidRateCorrected == undefined)) {
          if (item.aAnnulusPress != undefined) {
            externalidlist.push({ "externalId": item.aAnnulusPress, "targetUnit": "pressure:psi" });
            this.externalId['aAnnulusPress'] = item.aAnnulusPress;
          }
          if (item.powerFluidRateCorrected != undefined) {
            externalidlist.push({ "externalId": item.powerFluidRateCorrected });
            this.externalId['powerFluidRateCorrected'] = item.powerFluidRateCorrected;
          }

          const promise4: Promise<any>[] = [
            this.apiService.getTimeseriesDataAvgRange(externalidlist, moment(this.filterStartDate).utc().valueOf(), moment(this.filterEndDate).utc().valueOf())
          ];

          Promise.all(promise4)
            .then((results: any[]) => {
              let result = [];
              result = results[0];
              result.forEach((data: any) => {
                if (data.externalId == this.externalId['aAnnulusPress']) {
                  this.unitobject['annuluspressure'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                  data.datapoints.forEach((datapoint: any) => {
                    let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                    this.annuluspressure[date] = datapoint.average;
                  });
                }
                if (data.externalId == this.externalId['powerFluidRateCorrected']) {
                  this.unitobject['pfrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
                  data.datapoints.forEach((datapoint: any) => {
                    let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                    this.pfrate[date] = datapoint.average;
                  });
                }
              });
              this.call4 = true;
              if (this.call1 && this.call2 && this.call3 && this.call4) {
                this.getToleranceData(instancedata[4], well);
              }
            })
            .catch((error) => {
              this.loading = false;
              console.error("At least one promise rejected call4:", error);
            });
        } else {
          this.call4 = true;
          if (this.call1 && this.call2 && this.call3 && this.call4) {
            this.getToleranceData(instancedata[4], well);
          }
        }
      } else {
        this.call4 = true;
        if (this.call1 && this.call2 && this.call3 && this.call4) {
          this.getToleranceData(instancedata[4], well);
        }
      }
    });
  }

  getToleranceData(instancedata: any, well: string) {
    this.valuedate = '';
    this.dateList = this.dateList
      .map(date => moment(date))
      .sort((a, b) => b.valueOf() - a.valueOf())
      .map(date => date.format("YYYY/MM/DD"));

    this.inputDatelist = [];
    let version = environment.cogniteSDMVersion;
    let calinputviewid = 'WellCalibrationInputs'

    let externalidlist = [];
    let item = instancedata['items'][0]['properties'][environment.cogniteSpace][calinputviewid + "/" + version];
    if (!(item.reservoirPressureLow == undefined && item.reservoirPressureHigh == undefined && item.productivityIndexLow == undefined && item.productivityIndexHigh == undefined && item.knLow == undefined
      && item.knHigh == undefined && item.knStepSize == undefined && item.ksHigh == undefined && item.ksLow == undefined && item.ksStepSize == undefined && item.productivityIndexStepSize == undefined && item.reservoirPressureStepSize == undefined)) {
      if (item.reservoirPressureLow != undefined) {
        externalidlist.push({ "externalId": item.reservoirPressureLow, "targetUnit": "pressure:psi" });
        this.externalId['reservoirPressureLow'] = item.reservoirPressureLow;
      }
      if (item.reservoirPressureHigh != undefined) {
        externalidlist.push({ "externalId": item.reservoirPressureHigh, "targetUnit": "pressure:psi" });
        this.externalId['reservoirPressureHigh'] = item.reservoirPressureHigh;
      }
      if (item.reservoirPressureStepSize != undefined) {
        externalidlist.push({ "externalId": item.reservoirPressureStepSize });
        this.externalId['reservoirPressureStepSize'] = item.reservoirPressureStepSize;
      }
      if (item.productivityIndexLow != undefined) {
        externalidlist.push({ "externalId": item.productivityIndexLow });
        this.externalId['productivityIndexLow'] = item.productivityIndexLow;
      }
      if (item.productivityIndexHigh != undefined) {
        externalidlist.push({ "externalId": item.productivityIndexHigh });
        this.externalId['productivityIndexHigh'] = item.productivityIndexHigh;
      }
      if (item.productivityIndexStepSize != undefined) {
        externalidlist.push({ "externalId": item.productivityIndexStepSize });
        this.externalId['productivityIndexStepSize'] = item.productivityIndexStepSize;
      }
      if (item.knLow != undefined) {
        externalidlist.push({ "externalId": item.knLow });
        this.externalId['knLow'] = item.knLow;
      }
      if (item.knHigh != undefined) {
        externalidlist.push({ "externalId": item.knHigh });
        this.externalId['knHigh'] = item.knHigh;
      }
      if (item.knStepSize != undefined) {
        externalidlist.push({ "externalId": item.knStepSize });
        this.externalId['knStepSize'] = item.knStepSize;
      }
      if (item.ksHigh != undefined) {
        externalidlist.push({ "externalId": item.ksHigh });
        this.externalId['ksHigh'] = item.ksHigh;
      }
      if (item.ksLow != undefined) {
        externalidlist.push({ "externalId": item.ksLow });
        this.externalId['ksLow'] = item.ksLow;
      }
      if (item.ksStepSize != undefined) {
        externalidlist.push({ "externalId": item.ksStepSize });
        this.externalId['ksStepSize'] = item.ksStepSize;
      }
      const promise5: Promise<any>[] = [
        this.apiService.getTimeseriesData(externalidlist)
      ];

      Promise.all(promise5)
        .then((results: any[]) => {
          let result = [];
          result = results[0];
          result.forEach((data: any) => {
            if (data.externalId == this.externalId['reservoirPressureLow']) {
              this.unitobject['rpressurelow'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.rpressurelow[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['reservoirPressureHigh']) {
              this.unitobject['rpressurehigh'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.rpressurehigh[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['reservoirPressureStepSize']) {
              this.unitobject['rpressurestepsize'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.rpressurestepsize[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['productivityIndexLow']) {
              this.unitobject['pindexlow'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.pindexlow[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['productivityIndexHigh']) {
              this.unitobject['pindexhigh'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.pindexhigh[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['productivityIndexStepSize']) {
              this.unitobject['pindexstepsize'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.pindexstepsize[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['knLow']) {
              this.unitobject['knlow'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.knlow[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['knHigh']) {
              this.unitobject['knhigh'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.knhigh[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['knStepSize']) {
              this.unitobject['knstepsize'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.knstepsize[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['ksHigh']) {
              this.unitobject['kshigh'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.kshigh[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['ksLow']) {
              this.unitobject['kslow'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.kslow[date] = datapoint.value;
              });
            }
            if (data.externalId == this.externalId['ksStepSize']) {
              this.unitobject['ksstepsize'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                if (!this.inputDatelist.includes(date)) { this.inputDatelist.push(date); }
                this.ksstepsize[date] = datapoint.value;
              });
            }
          });

          if (this.inputDatelist.length > 0) {

            this.inputDatelist = this.inputDatelist.map(date => moment(date))
              .sort((a, b) => b.valueOf() - a.valueOf())
              .map(date => date.format("YYYY/MM/DD"));

            let fisrstdate = moment(this.dateList[0]);

            const index = this.inputDatelist.map(date => moment(date)).findIndex(date => date.valueOf() - fisrstdate.valueOf() <= 0);

            this.valuedate = this.inputDatelist[index];

            this.dateList.forEach((date) => {
              if (!this.inputDatelist.includes(date)) {
                this.rpressurelow[date] = this.rpressurelow[this.valuedate];
                this.rpressurehigh[date] = this.rpressurehigh[this.valuedate];
                this.rpressurestepsize[date] = this.rpressurestepsize[this.valuedate];
                this.pindexlow[date] = this.pindexlow[this.valuedate];
                this.pindexhigh[date] = this.pindexhigh[this.valuedate];
                this.pindexstepsize[date] = this.pindexstepsize[this.valuedate];
                this.knlow[date] = this.knlow[this.valuedate];
                this.knhigh[date] = this.knhigh[this.valuedate];
                this.knstepsize[date] = this.knstepsize[this.valuedate];
                this.kshigh[date] = this.kshigh[this.valuedate];
                this.kslow[date] = this.kslow[this.valuedate];
                this.ksstepsize[date] = this.ksstepsize[this.valuedate];
              }
              else {
                this.valuedate = date;
                this.rpressurelow[date] = this.rpressurelow[this.valuedate];
                this.rpressurehigh[date] = this.rpressurehigh[this.valuedate];
                this.rpressurestepsize[date] = this.rpressurestepsize[this.valuedate];
                this.pindexlow[date] = this.pindexlow[this.valuedate];
                this.pindexhigh[date] = this.pindexhigh[this.valuedate];
                this.pindexstepsize[date] = this.pindexstepsize[this.valuedate];
                this.knlow[date] = this.knlow[this.valuedate];
                this.knhigh[date] = this.knhigh[this.valuedate];
                this.knstepsize[date] = this.knstepsize[this.valuedate];
                this.kshigh[date] = this.kshigh[this.valuedate];
                this.kslow[date] = this.kslow[this.valuedate];
                this.ksstepsize[date] = this.ksstepsize[this.valuedate];
              }
            });
          }

          this.call5 = true;
          if (this.call1 && this.call2 && this.call3 && this.call4 && this.call5) {
            this.getGriddata(well);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("At least one promise rejected call5:", error);
        });
    } else {
      this.call5 = true;
      if (this.call1 && this.call2 && this.call3 && this.call4 && this.call5) {
        this.getGriddata(well);
      }
    }

  }

  getGriddata(well: string) {
    this.loading = true;
    let tempitem: CalibrationTriggerModel = {};
    let tempgrid: any[] = [];
    this.gridData = [];
    let id = 0;
    if (this.setInterval !== null) {
      clearTimeout(this.setInterval);
      this.setInterval = null;
    }
    this.dateList.forEach((date) => {

      tempitem = {};
      tempitem.id = id;
      tempitem.date = moment(date).format('DD-MM-YYYY') + ' ' + (this.timelist[date] != undefined ? this.timelist[date] : '00:00');
      tempitem.well = well;
      tempitem.gor = this.gor[date] != undefined ? this.gor[date].toFixed(2) : '-';;
      tempitem.watercut = this.watercut[date] != undefined ? this.watercut[date].toFixed(2) : '-';
      tempitem.liquidrate = this.liquidrate[date] != undefined ? (this.liquidrate[date]).toFixed(2) : '-';
      tempitem.tht = this.tht[date] != undefined ? this.tht[date].toFixed(2) : '-';
      tempitem.thp = this.thp[date] != undefined ? this.thp[date].toFixed(2) : '-';
      tempitem.frequency = this.frequency[date] != undefined ? this.frequency[date].toFixed(2) : '-';
      tempitem.pip = this.pip[date] != undefined ? this.pip[date].toFixed(2) : '-';
      tempitem.pdp = this.pdp[date] != undefined ? this.pdp[date].toFixed(2) : '-';
      tempitem.annuluspressure = this.annuluspressure[date] != undefined ? this.annuluspressure[date].toFixed(2) : '-';
      tempitem.pfrate = this.pfrate[date] != undefined ? this.pfrate[date].toFixed(2) : '-';
      tempitem.rpressurelow = this.rpressurelow[date] != undefined ? this.rpressurelow[date].toFixed(2) : '-';
      tempitem.rpressurehigh = this.rpressurehigh[date] != undefined ? this.rpressurehigh[date].toFixed(2) : '-';
      tempitem.rpressurestepsize = this.rpressurestepsize[date] != undefined ? Math.round(this.rpressurestepsize[date])  : '-';
      tempitem.pindexlow = this.pindexlow[date] != undefined ? this.pindexlow[date].toFixed(2) : '-';
      tempitem.pindexhigh = this.pindexhigh[date] != undefined ? this.pindexhigh[date].toFixed(2) : '-';
      tempitem.pindexstepsize = this.pindexstepsize[date] != undefined ? Math.round(this.pindexstepsize[date]) : '-';
      tempitem.knlow = this.knlow[date] != undefined ? this.knlow[date].toFixed(2) : '-';
      tempitem.knhigh = this.knhigh[date] != undefined ? this.knhigh[date].toFixed(2) : '-';
      tempitem.knstepsize = this.knstepsize[date] != undefined ? this.knstepsize[date].toFixed(2) : '-';
      tempitem.kshigh = this.kshigh[date] != undefined ? this.kshigh[date].toFixed(2) : '-';
      tempitem.kslow = this.kslow[date] != undefined ? this.kslow[date].toFixed(2) : '-';
      tempitem.ksstepsize = this.ksstepsize[date] != undefined ? this.ksstepsize[date].toFixed(2) : '-';

      if (this.selectedtab == "ESP") {
        if (this.espStatus[well]?.[date] == undefined && this.TempStatus[well]?.[date]) {
          tempitem.disable = true;
          tempitem.status = 'Queued'
          tempitem.logtext = ''
        } else {
          tempitem.logtext = this.logText[well]?.[date] != undefined ? this.logText[well]?.[date] : '';
          tempitem.disable = this.espStatus[well]?.[date] != undefined ? this.espStatus[well]?.[date] : false;
          if (this.failedCall[well + date]) {
            tempitem.status = this.failedCall[well + date];
          } else {
            tempitem.status = this.espText[well]?.[date] != undefined ? this.espText[well]?.[date] : '';
          }
          if (this.TempStatus[well]?.[date] != undefined) {
            this.TempStatus[well][date] = false;
          }
        }
        tempgrid.push(tempitem);
      }

      if (this.selectedtab == "JP") {
        if (this.jpStatus[well]?.[date] == undefined && this.TempStatus[well]?.[date]) {
          tempitem.disable = true;
          tempitem.status = 'Queued'
          tempitem.logtext = ''
        } else {
          tempitem.logtext = this.logText[well]?.[date] != undefined ? this.logText[well]?.[date] : '';
          tempitem.disable = this.jpStatus[well]?.[date] != undefined ? this.jpStatus[well]?.[date] : false;
          if (this.failedCall[well + date]) {
            tempitem.status = this.failedCall[well + date];
          } else {
            tempitem.status = this.jpText[well]?.[date] != undefined ? this.jpText[well]?.[date] : '';
          }
          if (this.TempStatus[well]?.[date] != undefined) {
            this.TempStatus[well][date] = false;
          }
        }
        tempgrid.push(tempitem);
      }

      id += 1;
    });
    if (this.clearGrid) {
      this.gridData = [];
      this.clearGrid = false;
    } else {
      this.directive.skip = 0;
      this.gridData = addSortableDates(tempgrid, this.kendoGrid);
      if (this.setInterval === null) {
        this.setInterval = setTimeout(() => { this.refreshGrid(well) }, 10000);
      }
    }
    this.loading = false;
  }

}
