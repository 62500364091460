<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [retainHierarchy]="true" [selectDefaultFieldValue]="true" (onFieldChange)="onfieldChange($event)" (onWellPadChange)="onfilterwellPadChange($event)" (onWellchange)="onFilterWellChange($event)" (ondatechange)="onDateSelected($event)" (onclear)="clear()" [showDateRange]="showDateRange" [showClearButton]="showClearButton"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <div>
    <mat-card class="alrmgrid">
        <mat-card-header>
          <mat-card-title>Drift Alarm</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-event-alarm [WellListToloadGrid]="this.WellListToloadGrid" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate"></app-event-alarm>
        </mat-card-content>
      </mat-card>
  </div>
</div>
