import { Injectable, Inject } from '@angular/core';

import * as Highcharts from 'highcharts';
import Sunburst from 'highcharts/modules/sunburst';
import SunsetTheme from 'highcharts/themes/high-contrast-dark'; // load 

declare var require: any;

const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

const More = require('highcharts/highcharts-more');
More(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);
SunsetTheme(Highcharts);
const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);

 const drilldown = require('highcharts/modules/drilldown');
 drilldown(Highcharts);

 const nodata = require('highcharts/modules/no-data-to-display');
 nodata(Highcharts);

 const Draggable = require("highcharts/modules/draggable-points.js");
  Draggable(Highcharts);
 
Sunburst(Highcharts);


@Injectable({
  providedIn: 'root'
})
export class HighchartsService {

  constructor() { }

  createChart(chartcontainer: string, options: any) {
  options.chart.backgroundColor = "transparent";
  options.credits = false;

    return new Highcharts.Chart(chartcontainer, options);
  }

 

}