import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { ListResponse, NodeAndEdgeCollectionResponseV3Response, RawPropertyValueV3, SequenceRow, SequenceRowsRetrieve } from '@cognite/sdk/dist/src';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { GridComponent } from '@progress/kendo-angular-grid';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SortDescriptor } from '@progress/kendo-data-query';

type ResponseType = SequenceRow | ListResponse<SequenceRow[]> | null;

@Component({
  selector: 'app-deviation-summary',
  templateUrl: './deviation-summary.component.html',
  styleUrls: ['./deviation-summary.component.css'],
})
export class DeviationSummaryComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public loading: boolean = false;
  public wellSelected: string | null;
  public summaryData: any[] = [];
  public summaryDataAll: any[] = [];
  public selectedTab: string = 'survey';
  public displayGridRows: boolean = true;
  public intervalValue: number = 0;
  public exportColumnRules: ExportColumnRule[] = [];
  public rawSequenceData: SequenceRow[] = [];
  public sort: SortDescriptor[] = [{ field: 'MD', dir: 'asc' }];

  private readonly cdfDef: CdfSpaceView | null = null;
  private tvdssSet: boolean = false;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService ,  private cd : ChangeDetectorRef) {
    this.cdfDef = this.wbService.getSpaceDefinition('deviation');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);
    this.cd.detectChanges();
  }

  onNumberChange() {
    if (this.intervalValue) {
      if (this.intervalValue < 0) {
        this.intervalValue = 0;
      }
      if (this.summaryDataAll.length) {
        this.reampleData(this.summaryDataAll);
      }
    }
  }

  public onSortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.summaryData = this.summaryData.sort((a, b) => {
      const dir = sort[0].dir;
      const field = sort[0].field;

      return dir === 'asc' ? a[field] - b[field] : b[field] - a[field];
    });
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadDeviationSummary();
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.wbService.setWell(null);
    this.summaryData = [];
    this.intervalValue = 0;
  }

  public toggleRowsVisibility() {
    this.displayGridRows = !this.displayGridRows;
  }

  public rowClass = () => {
    return { 'hide-row': !this.displayGridRows };
  }

  private loadDeviationSummary() {
    this.summaryData = [];
    this.tvdssSet = false;

    if (!this.cdfDef)
      return;

    this.loading = true;

    const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

    this.apiService.getInstancelist(this.cdfDef?.id, filter, this.cdfDef.version, this.cdfDef.space).subscribe({
      next: (data: NodeAndEdgeCollectionResponseV3Response) => {
        this.loading = false;
        this.processData(data);
      },
      error: err => {
        this.loading = false;
        console.error(err);
      },
    });
  }

  private async processData(data: NodeAndEdgeCollectionResponseV3Response) {
    if (data?.items?.length > 0 && this.cdfDef) {
      for (const item of data.items) {
        const properties = item.properties?.[this.cdfDef?.space]?.[this.cdfDef.fullName];

        const wellTrajectory = properties?.wellTrajectory;
        const elevation = properties?.elevation ?? 0;

        if (wellTrajectory) {
          const deviationData = await this.getDeviationData((wellTrajectory as string));
          this.checkDeviationData(elevation, deviationData);
        }
      }
    }
  }

  private checkDeviationData(elevation: RawPropertyValueV3, deviationData: ResponseType) {
    let allDeviationData: SequenceRow[] = [];

    if (deviationData) {
      if (this.apiService.isListResponse(deviationData)) {
        allDeviationData = deviationData.items;
      } else {
        allDeviationData.push(deviationData);
      }

      this.rawSequenceData = allDeviationData;
      this.summaryDataAll = this.processDeviationData(allDeviationData, (elevation as string));
      this.reampleData(this.summaryDataAll);
    }
  }

  private getDeviationData(externalId: string): Promise<SequenceRow | ListResponse<SequenceRow[]> | null> {
    return new Promise((res: (val: SequenceRow | ListResponse<SequenceRow[]> | null) => void) => {
      if (externalId?.trim() !== '' && this.cdfDef) {
        const filter: any = this.getSequenceRequest(externalId);

        this.apiService.getSequenceRows(filter).subscribe({
          next: (data: SequenceRow | ListResponse<SequenceRow[]>) => {
            res(data);
          },
          error: err => {
            console.error(err);
            res(null);
          },
        });
      }
    });
  }

  private processDeviationData(data: SequenceRow[], elevation: string): any[] {
    if (!data?.length)
      return [];

    data = data.map(row => {
      const columns = row.columns;

      if (!columns)
        return {};

      const rows = columns.reduce((acc: any, column, i) => {
        if (column.externalId)
          acc[column.externalId] = row.values[i];

        return acc;
      }, {});

      rows['elevation'] = elevation;

      return rows;
    });
    return data;
  }

  reampleData(data: any[]) {
    if (this.intervalValue == 0) {
      const samplerecords = Math.round(data.length * 0.2);
      const resample = Math.round(data.length / samplerecords);
      this.intervalValue = resample;
    }
    const firstElement = data[0];
    const lastElement = data[data.length - 1];
    const middleData = data.slice(1, data.length - 1);
    const resampledMiddleData = middleData.filter((item, index) => (index + 1) % this.intervalValue === 0);
    const rows: any[] = [firstElement, ...resampledMiddleData, lastElement];

    if (rows.length > 0 && !this.tvdssSet) {
      rows.forEach(row => {
        if (!isNaN(row.TVDSS)) {
          row.TVDSS *= -1;
        }
      });

      this.tvdssSet = true;
    }

    this.summaryData = rows;
    this.onSortChange(this.sort);
  }

  private getSequenceRequest(externalId: string, cursor: string | undefined = undefined): SequenceRowsRetrieve {
    return {
      start: 0,
      end: undefined,
      limit: 10000,
      cursor: cursor,
      columns: undefined,
      externalId: externalId,
    };
  }

  public onTabChange(tab: MatButtonToggleChange) {
    this.selectedTab = tab.value;
  }

}
