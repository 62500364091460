<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.OVR">Overview</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.WSS">Well Status Summary</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<ng-container *ngIf="selectedtab === 'OVR'">
  <div class="tabcontrolpanel">
    <mat-button-toggle-group (change)="onChange($event)" [(value)]="Liftselectedtab">
      <mat-button-toggle [value]="liftTypetabOptions.ESP">ESP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.HRP">HRP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.Injector">Injector</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.NF">NF</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.JP">JP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.PCP">PCP</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.ESP">
    <app-i-frame title="Cairn ASP Surveillance" [src]="ESPSummary_report_link"></app-i-frame>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.HRP">
    <app-i-frame title="Cairn ASP Surveillance" [src]="HRPSummary_report_link"></app-i-frame>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.Injector">
    <app-i-frame title="Cairn ASP Surveillance" [src]="InjectorSummary_report_link"></app-i-frame>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.JP">
    <app-i-frame title="Cairn ASP Surveillance" [src]="JPSummary_report_link"></app-i-frame>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.NF">
    <app-i-frame title="Cairn ASP Surveillance" [src]="NFSummary_report_link"></app-i-frame>
  </div>
  <div class="page-content" *ngIf="Liftselectedtab === liftTypetabOptions.PCP">
    <app-i-frame title="Cairn ASP Surveillance" [src]="PCPSummary_report_link"></app-i-frame>
  </div>
</ng-container>
<ng-container *ngIf="selectedtab === 'WSS'">
  <app-well-summary></app-well-summary>
</ng-container>
