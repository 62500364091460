<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [timerangeDifference]="'365'" [filterOnWellType]="false" [showDateRange]="true" (onFieldChange)="clearWellSelection()" (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)" (ondatechange)="handleDateRangeChange()" (onclear)="clearWellSelection()" [showClearButton]="true"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card class="example-card" id="card" autoHeight>
    <mat-card-header>
      <mat-card-title *ngIf="!wellSelected">Event Viewer (please select a well)</mat-card-title>
      <mat-card-title *ngIf="wellSelected">Event Viewer</mat-card-title>
      <div class="submenu" [hidden]="globalLoading || loadingEvents || eventTypes.length === 0">
        <mat-form-field slbFormField class="form-field" appearance="outline">
          <mat-select placeholder="Event Type" #input [panelClass]="'dls-panel'" [(ngModel)]="defaultEventType" (selectionChange)="onEventTypeChanged($event)" [hideSingleSelectionIndicator]="true">
            <mat-option [value]="null">Clear</mat-option>
            <mat-option *ngFor="let eventType of eventTypes" [value]="eventType.value">{{ eventType.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-header>
    <mat-card-content [autoHeight]="false">
      <mat-card class="exmaple-card card-child" *ngIf="!globalLoading">
        <mat-card-content>
          <kendo-grid #eventsGrid [kendoGridBinding]="events" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [selectable]="true" (cellClick)="onEventSelected($event)" [rowClass]="rowEventsClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 4 }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <h4 class="col-sm-10">Events <mat-spinner class="custom-loading" [diameter]="20" [strokeWidth]="3" *ngIf="loadingEvents"></mat-spinner></h4>
                <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="eventsGrid" [exportColumnRules]="exportColumnRules" [data]="events">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('events')">
                    <mat-icon *ngIf="displayEventsGridRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayEventsGridRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="startDate_ts" title="Start Date" [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="wellName" title="Well Name"></kendo-grid-column>
            <kendo-grid-column field="eventObjective" title="Event Objective"></kendo-grid-column>
            <kendo-grid-column field="unitName" title="Unit Name"></kendo-grid-column>
            <kendo-grid-column field="jobType" title="Job Type"></kendo-grid-column>
            <kendo-grid-excel fileName="Events.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Events.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
          <kendo-grid #perforationDetailsGrid [kendoGridBinding]="perforationDetails" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" kendoTooltip [rowClass]="rowPerforationDetailsClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 4 }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <h4 class="col-sm-10">Perforation Details <mat-spinner class="custom-loading" [diameter]="20" [strokeWidth]="3" *ngIf="loadingDailyEvents"></mat-spinner></h4>
                <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="perforationDetailsGrid" [exportColumnRules]="exportColumnRules" [data]="perforationDetails">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('perforationDetails')">
                    <mat-icon *ngIf="displayPerforationDetailsGridRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayPerforationDetailsGridRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="startTime_ts" title="Date" [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="perforationDetails" title="Details"></kendo-grid-column>
            <kendo-grid-excel fileName="Perforation Details.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Perforation Details.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
          <kendo-grid #dailyEventsGrid [kendoGridBinding]="dailyEvents" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" (cellClick)="onDailyEventSelected($event)" [rowClass]="rowDailyOperationClass" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 4 }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <h4 class="col-sm-10">Daily Operation <mat-spinner class="custom-loading" [diameter]="20" [strokeWidth]="3" *ngIf="loadingDailyEvents"></mat-spinner></h4>
                <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="dailyEventsGrid" [exportColumnRules]="exportColumnRules" [data]="dailyEvents">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('dailyOperation')">
                    <mat-icon *ngIf="displayDailyOperationGridRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayDailyOperationGridRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="startDate_ts" title="Date" [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="status" title="Status" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="forecast" title="24 Hour Forecast" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="summary" title="Daily Summary"></kendo-grid-column>
            <kendo-grid-excel fileName="Daily Operation.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Daily Operation.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
          <kendo-grid #activityEventsGrid [kendoGridBinding]="activityEvents" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [rowClass]="rowDailyActivityClass">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <h4 class="col-sm-10">Daily Activity <mat-spinner class="custom-loading" [diameter]="20" [strokeWidth]="3" *ngIf="loadingActivityEvents"></mat-spinner></h4>
                <div class="col-sm-2" style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="activityEventsGrid" [exportColumnRules]="exportColumnRules" [data]="activityEvents" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card', heightFragment: 4 }">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleRowsVisibility('dailyActivity')">
                    <mat-icon *ngIf="displayDailyActivityGridRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
                    <mat-icon *ngIf="!displayDailyActivityGridRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="startDate_ts" title="Start Date & Time" [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="duration" title="Duration (hrs)" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="details" title="Activity Details"></kendo-grid-column>
            <kendo-grid-excel fileName="Daily Activity.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Daily Activity.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
