import { ConfigurableFocusTrapConfig } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { CogniteClient } from '@cognite/sdk';
import { debug } from 'console';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CogniteAuthService {
  public token:string;
  public loggedInUser:any;
  pca : PublicClientApplication;
  constructor(private msalService: MsalService) {
    this.pca = msalService.instance as PublicClientApplication;
  }

  getapiToken = async () => {

    const account = this.pca.getAllAccounts()[0];

    const token = await this.pca.acquireTokenSilent({
      account,
       scopes:environment.Scopes
    }).catch(e => {

      console.log("Failed to acquire token for congiteclient");
      console.log(e);
      return ;
    });
    if (token){
      console.log("recieved token for cognite client is "+token.accessToken);
      return token.accessToken;
    }
    return "";

  };

  getToken = async () => {

    const account = this.pca.getAllAccounts()[0];

    const token = await this.pca.acquireTokenSilent({
      account,
       scopes:[
        `${environment.baseUrl}DATA.VIEW`
      ]
    }).catch(e => {
      console.log("Failed to acquire token for congiteclient");
      console.log(e);
      return ;
    });
    if (token){
      console.log("recieved token for cognite client is "+token.accessToken);
      return token.accessToken;
    }
    return "";

  };

  getaccessToken = async () => {
    const scopes = [
      `${environment.baseUrl}DATA.VIEW`
    ];
    const account = this.pca.getAllAccounts()[0];
    if (!account) {
      throw new Error("no user found");
    }
    this.loggedInUser = account.name;
    console.log("active account", account.username);
    const token = await this.pca.acquireTokenSilent({
      account,
      scopes,
    });
    return token.accessToken;
  };

   sdk: CogniteClient = new CogniteClient({
    appId: environment.clientId,
    project:environment.project,
    baseUrl:environment.baseUrl,
    getToken:  this.getaccessToken//this.getToken1
  });

  getSdk() {
    return this.sdk;
  }
}
