<slb-navigation-framework [applicationLogo]="'/assets/vedantalogo.svg'"
  [applicationName]="'Vedanta | Data Driven Reservoir Management Dashboard '" [hasSidenav]="true" [notifications]="true"
  [pageTitle]="pageTitle" [secondaryNavigationItems]="sideMenuList" [slbLogo]="'assets/logos/slb-white.png'"
  [displayViewModeButtons]="false" (primaryNavItemSelected)="onSelectPrimaryNavItem($event)"
  (secondaryNavigationSelected)="onSelectSecondaryNavItem($event)">
  <slb-framework-toolbar>
    <ng-content *ngTemplateOutlet="toolbar"></ng-content>
  </slb-framework-toolbar>

  <slb-framework-sidenav>
    <ng-content *ngTemplateOutlet="sidenav"></ng-content>
  </slb-framework-sidenav>

  <slb-framework-content>
    <ng-content *ngTemplateOutlet="content"></ng-content>
  </slb-framework-content>
  <slb-framework-page-header *ngIf="showHeader" [showBackButton]="true" [pageTitle]="pageTitle"
    [items]="secondaryLinks">

  </slb-framework-page-header>
</slb-navigation-framework>
<ng-template #toolbar>

  <slb-logout [userName]="loggedInUser" (logout)="Logout()">

  </slb-logout>
</ng-template>

<ng-template #sidenav>
  <mat-nav-list class="app-sidenav">
    <a class="dls-navigation-item" matTooltip="Home" [matTooltipPosition]="'right'" [routerLink]="['home']">
      <mat-icon svgIcon="home"></mat-icon>
    </a>
    <a class="dls-navigation-item" matTooltip="Notifications" [matTooltipPosition]="'right'"
      [routerLink]="['notifications']">
      <mat-icon svgIcon="layers"></mat-icon>
    </a>
    <span class="dls-navigation-item" matTooltip="More items" [matTooltipPosition]="'right'">
      <mat-icon svgIcon="more"></mat-icon>
    </span>
    <a class="dls-navigation-item" matTooltip="Themes" [matTooltipPosition]="'right'" [routerLink]="['themes']">
      <mat-icon svgIcon="color-picker"></mat-icon>
    </a>
  </mat-nav-list>
</ng-template>
<ng-template #content>
  <router-outlet>
    <app-spinner></app-spinner>
  </router-outlet>
</ng-template>
