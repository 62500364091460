export enum TabOptionsEnum {
    VFM = "VFM",
    CMP = "CMP",
    VFMNE = "VFMNE",
}


export interface comparisonModel
{
    'well':string;
    'starttype':string;
    'endtype':string;
    'ovd':string;
    'lvd':string;
    'ovdml':string;
    'lvdml':string;
}
