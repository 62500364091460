import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CognitApiService } from '../../../services/cognit-api.service'
import { CognitDataFormatter } from '../../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { CogniteAuthService } from '../../../services/auth.service';
import { environment } from '../../../../../src/environments/environment';
import { LoaderService } from '../../../services/loader.service';
import { ConfirmationDialog } from '../../../common-component/confirmation-dialog/confirmation-dialog.component';
import { TabOptionsEnum, WellSummaryModel } from '../../model';
import { HierarchyService } from '../../../services/hierarchy.service';
import { Subscription } from 'rxjs';
import { GroupDescriptor, GroupResult, groupBy } from '@progress/kendo-data-query';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

@Component({
  selector: 'app-well-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})

export class SummaryComponent {

  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.WSS;
  private subs: Subscription[] = [];

  public startdate: any;
  public enddate: any;
  public gridData: any[] = [];
  public rawData: any[] = [];
  public WellListToloadGrid: any[] = [];
  public filterStartDate: any;
  public filterEndDate: any;
  public range: DateRange<Moment>;

  public fieldList: any;
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any;
  public selectedField: string;
  public selectedWell: string | undefined;
  public Lifttype: string | undefined;
  public Welltype: string | undefined;
  public selectedWellPad: string | undefined;
  pumpparametergranuality = '10m';


  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'End Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
  ];

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService,
    private cognitDataFormatter: CognitDataFormatter,
    private hierarchyService: HierarchyService) {
  }

  ngOnInit() {
    this.startdate = moment(moment().toDate());
    this.enddate = moment(moment().toDate());
    this.range = ({
      startDate: moment(moment()).add(-1, 'months'),
      endDate: moment()
    });
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
  }

  clear() {
    this.filterWellList = [];
    this.WellListToloadGrid = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.Lifttype = '';
    this.Welltype = '';
    this.selectedWellPad = '';
    this.gridData = [];
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.WellListToloadGrid = event.allFieldwells;
    this.loadGridData();
  }

  onfilterwellPadChange(event: any) {
    this.loader.showLoader();
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
    this.loadGridData();

  }

  onFilterWellChange(event: any) {
    if (this.filterWellList == undefined || this.filterWellList.length == 0)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadGridData();
  }


  onDateSelected(event: any) {
    this.filterStartDate = event.event?.startDate?._d;
    this.filterEndDate = event.event?.endDate?._d;
    if (this.filterStartDate != undefined && this.filterEndDate != undefined) {
    let datediff = moment(event.event.endDate).diff(moment(event.event.startDate), 'days');
    this.pumpparametergranuality = datediff > 1 ? "10m" : "1m"
    this.loadGridData();
  }
}

loadGridData() {
  this.Lifttype = '';
  this.Welltype = '';
  if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) {
    this.loader.showLoader();
    this.gridData = [];
    let selectedWell = this.WellListToloadGrid.map((a: any) => a.externalId);
    if (selectedWell.length == 1) {
      this.selectedWell = selectedWell[0];
      this.Welltype = this.WellListToloadGrid[0].WellType;
      if (this.WellListToloadGrid[0].LiftType == undefined && this.WellListToloadGrid[0].WellType.includes('injector')) { this.Lifttype = this.WellListToloadGrid[0].WellType } else { this.Lifttype = this.WellListToloadGrid[0].LiftType; }
    } else { this.selectedWell = '' };
    this.apiService.getEventlist(this.filterStartDate, this.filterEndDate, 'well status', selectedWell).then(eventResponse => {
      this.gridData = [];
      if (eventResponse.length > 0) {
        const data: any = [];

        eventResponse.forEach(item => {
          let metadata: any = item.metadata;
          var duration: any = moment.duration(moment(item.endTime).diff(moment(item.startTime)));

          let summaryitem: WellSummaryModel = {
            'well': metadata?.well_external_id != undefined || metadata?.well_external_id == "" ? metadata?.well_external_id : "-",
            'wellstatus': item.subtype,
            'startdate': moment(item.startTime).format("DD-MM-YYYY"),
            'starttime': moment(item.startTime).format("HH:mm"),
            'enddate': moment(item.endTime).format("DD-MM-YYYY"),
            'endtime': moment(item.endTime).format("HH:mm"),
            'duration': Math.trunc(duration.asHours()) + ":" + duration.minutes()
          }

          data.push(summaryitem);
        });

        this.gridData = addSortableDates(data, this.kendoGrid);
        this.loader.hideLoader();
      } else { this.loader.hideLoader(); }
    });
  }
}

ngOnDestroy() {
  this.subs.forEach(s => s.unsubscribe())
}

}
