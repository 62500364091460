import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-auto-refresh',
  templateUrl: './auto-refresh.component.html',
  styleUrls: ['./auto-refresh.component.css']
})
export class AutoRefreshComponent {
  private subscription: Subscription;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();

  @Input() SearchDate: moment.Moment = moment();

  @Input()
  RefreshTime: number = 15;
  ElapsTime: number;

  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;

  everySecond: Observable<number> = timer(0, 1000);
  constructor(private ref: ChangeDetectorRef) {
    this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");
  }
  ngOnChanges() {
    //this.ElapsTime = this.RefreshTime;
  }
  ngOnInit() {
    this.ElapsTime = this.RefreshTime;
    this.subscription = this.everySecond.subscribe((seconds) => {
      var currentTime: moment.Moment = moment();
      this.remainingTime = this.searchEndDate.diff(currentTime)
      this.remainingTime = this.remainingTime / 1000;

      if (this.remainingTime <= 0) {
        this.SearchDate = moment();
        this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");

        this.TimerExpired.emit();
      }
      else {
        this.minutes = Math.floor(this.remainingTime / 60);
        this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      }
      this.ref.markForCheck()
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
