import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitApiService } from './cognit-api.service';
import { LoaderService } from './loader.service';
import moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class DateHelper {
    DateHelper() {

    }
    public convertStringToDate(date: string, stringformat = "DD-MM-YYYY") {
        return moment(date, stringformat);
    }

}