import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validator, Validators, FormControl } from '@angular/forms';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { HierarchyService } from '../../services/hierarchy.service'
import { CognitApiService } from '../../services/cognit-api.service';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';


import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { PumpCurvVariables } from '../model';
import { MessageService } from '@slb-dls/angular-material/notification';
import { Subscription, forkJoin } from 'rxjs';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { WellvariablesComponent } from './wellvariables/wellvariables.component';
import { forEachRight } from 'cypress/types/lodash';
import { start } from 'repl';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

export enum TabOptionsEnum {
  Field = "Field",
  Well = "Well",
}
@Component({
  selector: 'app-pumpcurvvariables',
  templateUrl: './pumpcurvvariables.component.html',
  styleUrls: ['./pumpcurvvariables.component.css']
})
export class PumpcurvvariablesComponent {
  public PumpCurvVariablesData: PumpCurvVariables[] = [];
  private subs: Subscription[] = [];
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild('addWllRecordDialog') addWllRecordDialog: TemplateRef<any>;
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild('wellvariablesComponent') wellvariablesComponent: WellvariablesComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;
  formationlistData: any[] = [{ "value": "1", "Name": "Fategarh" }, { "value": "2", "Name": "Lower Fategarh" }]
  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.Well
  public pumpCurvevariable: PumpCurvVariables;
  public selectedrows: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public fieldList: any;
  public wellList: any;
  public filterWellList: any;
  public selectedField: string;
  public selectedWell: string;
  public timeseriesdata: any;
  formgroup: FormGroup;
  wellformgroup: FormGroup;
  timeseriesid: string | undefined;
  public welllisttoLoadGridData: any
  unit: string | undefined;
  selectedWellPad: string;
  wellPadList: any;
  formwellPadList: any;
  filterStartDate: any;
  filterEndDate: any;
  sdmPropertyList: any[] = [];
  viewid: string = "PumpCurveFieldInputs";

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date Time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private hierarchyService: HierarchyService,
    private timeseriesHelper: TimeseriesHelper,
    private dateHelper:DateHelper,) {

  }

  ngOnInit() {
    this.setRecordValue();
    this.setformgroupData();
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());

    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));

  }
  onChange($event: any) {
    this.selectedtab = $event.value;
    this.clear();
    this.hierarchyComponent.clear();
  }
  public setformgroupData() {
    //FormBuilder avoids using new FormControl in FormGroup instance
    this.formgroup = this.fb.group({
      apiofoil: [this.pumpCurvevariable.apiofoil],
      gasgravity: [this.pumpCurvevariable.gasgravity],
      viscosity: [this.pumpCurvevariable.viscosity],
      formation: [this.pumpCurvevariable.formation].toString(),
      pw: [this.pumpCurvevariable.pw],
      field: new FormControl({ value: this.pumpCurvevariable.field, disabled: this.formMode == "Edit" }, Validators.required),
    });
  }

  setRecordValue(data: any = undefined) {
    if (data == undefined) {

      this.pumpCurvevariable = { field: [this.selectedField], }
      if (this.selectedField != undefined || this.selectedField != "")
        this.pumpCurvevariable = { gasgravity: 1, apiofoil: 30, pw: 1000, formation: "1", field: [this.selectedField] }
    }
    else {
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      let formation = this.formationlistData.filter((x: any) => x.Name == data.formation)[0].value;
      this.pumpCurvevariable = {
        startdate: data.startdate, apiofoil: data.apiofoil, formation: formation, gasgravity: data.gasgravity,
        viscosity: data.viscosity, pw: data.pw, field: [this.selectedField], timestamp: data.timestamp
      }
    }
  }

  // filter hierarchy
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedWell = "";
    this.selectedWellPad = "";
    this.filterStartDate = this.hierarchyComponent.filterStartDate;
    this.filterEndDate = this.hierarchyComponent.filterEndDate;
    this.wellPadList = event.wellPadList;
    if (this.selectedtab == this.tabOptions.Field) {
      this.loadGridData();
    }
    else {
      this.wellvariablesComponent.onfilterfieldChange(event);
    }
  }
  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.welllisttoLoadGridData = event.well;
    if (this.selectedtab == this.tabOptions.Field) {
      this.loadGridData();
    }
    else {
      this.wellvariablesComponent.onfilterwellPadChange(event);
    }

  }

  onFilterWellChange(event: any) {
    this.selectedWell = event.event.value;
    if (this.selectedtab == this.tabOptions.Field) {
      this.loadGridData();
    }
    else {
      this.wellvariablesComponent.onFilterWellChange(event);
    }
  }
  // fiel level hierarchy
  onformfieldChange(event: any) {
    this.pumpCurvevariable = { gasgravity: 1, apiofoil: 30, pw: 1000, formation: "1" }
    this.pumpCurvevariable.field = event.value;
    this.setformgroupData();
    //this.getWelldata(event.value);
  }


  public onAddrecordClick() {
    //this.grid.expandRow(0);
    this.formMode = "Add";
    this.setRecordValue();
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',


    });
  }

  public onEditecordClick(dataItem: any) {
    this.formMode = "Edit"
    this.wellList = this.selectedWell != undefined ? this.filterWellList : undefined;
    this.setRecordValue(dataItem);
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',


    });
  }
  public ondelete(item: any) {
    this.openDialog(item);
  }
  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete: any[] = [];
    let gravityexternaID = this.selectedField + ":Pump_Curve:Gas_Gravity";
    let ApiexternaID = this.selectedField + ":Pump_Curve:Oil_API_Gravity";
    let pwexternaID = this.selectedField + ":Pump_Curve:Formation_Water_Density";
    let formationexternaID = this.selectedField + ":Pump_Curve:Formation";
    let viscocityexternaID = this.selectedWell + ":Pump_Curve:Viscosity";

    if (selectedrows == undefined) {
      let data = this.PumpCurvVariablesData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          recortodelete.push({ externalId: gravityexternaID, inclusiveBegin: Date.parse(String(record.timestamp)) })
          recortodelete.push({ externalId: ApiexternaID, inclusiveBegin: Date.parse(String(record.timestamp)) })
          recortodelete.push({ externalId: pwexternaID, inclusiveBegin: Date.parse(String(record.timestamp)) })
          recortodelete.push({ externalId: formationexternaID, inclusiveBegin: Date.parse(String(record.timestamp)) })
        }
      });
    }
    else {
      recortodelete.push({ externalId: gravityexternaID, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
      recortodelete.push({ externalId: ApiexternaID, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
      recortodelete.push({ externalId: pwexternaID, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
      recortodelete.push({ externalId: formationexternaID, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })

    }
    this.loader.showLoader();
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.loader.hideLoader();
      this.selectedWell = "";
      this.loadGridData();
    })

  }

  save(form: any) {
    if (this.formgroup.valid) {
      let gas_gravity_value = this.formgroup.get("gasgravity")?.value;
      let api_value = this.formgroup.get("apiofoil")?.value;
      let pw_value = this.formgroup.get("pw")?.value;
      let formation_value = Number(this.formgroup.get("formation")?.value);
      let fieldlist = this.formgroup.get("field")?.value;

      this.loader.showLoader();
      this.timeseriesHelper.getExternalId(fieldlist, this.viewid, "field").subscribe((list: any) => {
        let externalid = list.externalidlist;
        this.sdmPropertyList = list.sdmPropertyList;
        if (externalid.length > 0) {
          let datapoints: any[] = []
          let externalidlist = [...new Set(externalid.map((asset: any) => ({ "externalId": asset.externalId })))];
          let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
          if (this.formMode == "Edit")
            timestamp = Number(this.pumpCurvevariable.timestamp);
          this.apiService.getbulkTsId(externalidlist).then((idresponse: any) => {
            fieldlist.forEach((field: any) => {
              let gasgavityid = this.gettsidfromlist(this.getexternalIdfromSDMList(field, "gasgravity"), idresponse);
              let formationid = this.gettsidfromlist(this.getexternalIdfromSDMList(field, "formation"), idresponse)
              let oilapigravityid = this.gettsidfromlist(this.getexternalIdfromSDMList(field, "oilapigravity"), idresponse)
              let formationwaterdensityid = this.gettsidfromlist(this.getexternalIdfromSDMList(field, "formationwaterdensity"), idresponse);

              datapoints.push({ id: gasgavityid, datapoints: [{ timestamp: timestamp, value: gas_gravity_value }] });
              datapoints.push({ id: oilapigravityid, datapoints: [{ timestamp: timestamp, value: api_value }] });
              datapoints.push({ id: formationwaterdensityid, datapoints: [{ timestamp: timestamp, value: pw_value }] });
              datapoints.push({ id: formationid, datapoints: [{ timestamp: timestamp, value: formation_value }] });
            })

            this.apiService.insertbulkTimeSeriesData(datapoints).then(response => {
              this.loader.hideLoader();
              this.loadGridData();
            })

          })
        }
      });

      // fieldlist.forEach((field: any) => {
      //   let gravityexternaID = field + ":Pump_Curve:Gas_Gravity"
      //   let ApiexternaID = field + ":Pump_Curve:Oil_API_Gravity"
      //   let pwexternaID = field + ":Pump_Curve:Formation_Water_Density"
      //   let formationexternaID = field + ":Pump_Curve:Formation"
      //   const promises: Promise<any>[] = [
      //     this.apiService.getTsId(gravityexternaID),
      //     this.apiService.getTsId(ApiexternaID),
      //     this.apiService.getTsId(pwexternaID),
      //     this.apiService.getTsId(formationexternaID)
      //   ];
      //   Promise.all(promises)
      //     .then((results: any[]) => {
      //       let timestamp = Date.parse(this.startdate._d.toDateString());
      //       let datapoints: any[] = [];
      //       datapoints.push({ id: results[0]?.items[0].id, datapoints: [{ timestamp: timestamp, value: gas_gravity_value }] });
      //       datapoints.push({ id: results[1]?.items[0].id, datapoints: [{ timestamp: timestamp, value: api_value }] });
      //       datapoints.push({ id: results[2]?.items[0].id, datapoints: [{ timestamp: timestamp, value: pw_value }] });
      //       datapoints.push({ id: results[3]?.items[0].id, datapoints: [{ timestamp: timestamp, value: formation_value }] });
      //       this.apiService.insertbulkTimeSeriesData(datapoints).then(response => {
      //         this.loader.hideLoader();
      //         this.loadGridData();
      //         console.log(response)
      //       })
      //     })
      //     .catch((error) => {
      //       this.loader.hideLoader();
      //       console.error("Error in saving field data:", error);
      //     });
      // });
    }
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
  getexternalIdfromSDMList(field: string, propertyinSdm: string): string {
    let filteredlist = this.sdmPropertyList.filter((property: any) => property.FilterPropertyId == field && property.keyvalue == propertyinSdm)
    let externalid = "";
    if (filteredlist.length > 0) {
      externalid = filteredlist[0].value;
    }
    return externalid;
  }
  gettsidfromlist(externalId: string, list: any[]): string {
    let idlist = list.filter((item: any) => item.externalId == externalId);
    let id = "";
    if (idlist.length > 0) {
      id = idlist[0].id;
    }
    return id;
  }
  loadGridData(iswellfilter = false) {
    if (this.selectedField != undefined && this.selectedField != "") {
      let filtervalueList = [this.selectedField]
      this.PumpCurvVariablesData = [];
      this.loader.showLoader();
      this.subs.push(this.timeseriesHelper.getExternalId(filtervalueList, this.viewid, "field").subscribe(data => {
        let externalidlist = data.externalidlist;
        this.sdmPropertyList = data.sdmPropertyList;
        console.log(this.sdmPropertyList);
        if (externalidlist !== undefined && externalidlist.length > 0) {
          const promises: Promise<any>[] = [
            this.apiService.getTimeseriesDataRange(externalidlist, this.filterStartDate._d, this.filterEndDate._d),
          ];
          Promise.all(promises)
            .then((results: any[]) => {
              this.getGridData(results, iswellfilter);
              this.loader.hideLoader();
            })
            .catch((error) => {
              console.error("At least one promise rejected:", error);
            });

        }
        else
          this.loader.hideLoader();
      }));
    }
  }

  public getGridData(results: any[], iswellfilter = false) {
    let timeseriesdatapoints = results[0];
    // this.timeseriesdata = results[1];
    if (!iswellfilter)
      this.PumpCurvVariablesData = [];
    let sequence = 0;
    timeseriesdatapoints.forEach((datapoint: any) => {
      const key1 = 'gasgravity';
      const key2 = "oilapigravity";
      const key3 = "formation";
      const key4 = "formationwaterdensity";
      const key5 = 'viscosity';
      if (datapoint.datapoints.length > 0) {
        let timeseriesproperty = this.sdmPropertyList.filter((X: any) => X.value == datapoint.externalId)[0].keyvalue;
        datapoint.datapoints.forEach((data: any) => {
          if (data != undefined) {
            let startdate =moment(data.timestamp).format("DD-MM-YYYY")
            let filtergriddata = this.PumpCurvVariablesData.filter((rowdata: any) => rowdata.startdate == startdate);
            let index = filtergriddata != undefined ? this.PumpCurvVariablesData.indexOf(filtergriddata[0]) : -1;
            let formation = ""
            let parameter = timeseriesproperty;
            if (parameter == key3)
              formation = this.formationlistData.filter((x: any) => x.value == data.value)[0].Name;
            if (index <= -1) {
              let item: PumpCurvVariables = { sequence: sequence, id: datapoint.id, startdate:startdate, timestamp: data.timestamp }
              item.field = this.selectedField;
              item.well = this.selectedWell;
              item.gasgravity = parameter == key1 ? data.value : undefined;
              item.apiofoil = parameter == key2 ? data.value : undefined;
              item.formation = parameter == key3 ? formation : undefined;
              item.pw = parameter == key4 ? data.value : undefined;
              item.viscosity = parameter == key5 ? data.value : undefined;
              this.PumpCurvVariablesData.push(item);
            }
            else {
              this.PumpCurvVariablesData[index].field = this.selectedField;
              this.PumpCurvVariablesData[index].well = this.selectedWell;
              this.PumpCurvVariablesData[index].gasgravity = parameter == key1 ? data.value : this.PumpCurvVariablesData[index].gasgravity;
              this.PumpCurvVariablesData[index].apiofoil = parameter == key2 ? data.value : this.PumpCurvVariablesData[index].apiofoil;
              this.PumpCurvVariablesData[index].formation = parameter == key3 ? formation : this.PumpCurvVariablesData[index].formation;
              this.PumpCurvVariablesData[index].pw = parameter == key4 ? data.value : this.PumpCurvVariablesData[index].pw;
              this.PumpCurvVariablesData[index].viscosity = parameter == key5 ? data.value : this.PumpCurvVariablesData[index].viscosity;

            }
            sequence += 1;
          }

        });
      }
    });

    this.PumpCurvVariablesData = addSortableDates(this.PumpCurvVariablesData, this.kendoGrid);
  }

  clear() {
    if (this.selectedtab == this.tabOptions.Field) {
      this.selectedField = '';
      this.selectedWell = '';
      this.welllisttoLoadGridData = [];
      this.PumpCurvVariablesData = [];
    }
    else if (this.wellvariablesComponent != undefined)
      this.wellvariablesComponent.clear();

  }
  public onDateRangeChange(event: any) {
    this.filterStartDate = moment(event.event.startDate)
    this.filterEndDate = moment(event.event.endDate)
    if (this.selectedtab == this.tabOptions.Field && event.event.startDate != undefined && event.event.startDate != undefined && this.selectedField != undefined )
      this.loadGridData();
    else if (this.wellvariablesComponent != undefined)
      this.wellvariablesComponent.onDateRangeChange(event)
  }


}
