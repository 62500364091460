import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { File } from 'src/app/common-component/types/file';
import { Document, NodeAndEdgeCollectionResponseV3Response, PropertyValueGroupV3 } from '@cognite/sdk/dist/src';
import moment from 'moment';

import { ToggleFullscreenDirective } from 'src/app/directives/toggle-fullscreen.directive';

declare interface MapData {
  externalId: string,
  date: string,
  epochTime: number,
};

declare interface FilePreview {
  selected?: File,
  url?: string,
};

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css'],
})
export class MapViewComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  public loading: boolean = false;
  public previewFiles: FilePreview[] = [];
  public files: File[] = [];
  public fullScreenImage: { url?: string, opts: any } | undefined;
  public showPdfFullScreen: boolean = false;

  private readonly cdfDef: CdfSpaceView | null = null;
  private wellSelected: string | null;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService) {
    this.cdfDef = this.wbService.getSpaceDefinition('maps');

    this.previewFiles = [{}, {}, {}];
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onfilterwellChange(this.wbService.getWell(), true);
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadMapFiles();
  }

  public openFullScreenImage(event: any, url?: string) {
    this.fullScreenImage = { url: url, opts: event };
  }

  public togglePdfViewerSize(isFullscreen: boolean) {
    this.showPdfFullScreen = isFullscreen;
  }

  public clearMapFiles(clearWellSelection = true) {
    if (clearWellSelection) {
      this.wellSelected = null;
      this.wbService.setWell(null);
    }

    this.files = [];
    this.previewFiles = [{}, {}, {}];
  }

  public setPreviewFile(event: any, index: number) {
    const file = this.files.find(e => e.id === event?.value);

    if ((file?.type === 'PDF' || file?.type === 'Image') && file?.id) {
      this.getDocumentUrls(Number(file?.id), (urls: string[]) => {
        if (urls?.length > 0) {
          this.previewFiles[index].selected = file;
          this.previewFiles[index].url = urls[0];
        }
      });
    }
  }

  private getDocumentUrls(id: number, handleDocumentUrls: (urls: string[]) => void) {
    this.loading = true;

    this.apiService.getDocumentDownloadUrl(id).subscribe({
      next: (data: any) => {
        this.loading = false;
        let urls: string[] = [];

        if (data?.items?.length > 0)
          urls = data.items.map((e: any) => e.downloadUrl);

        handleDocumentUrls(urls);
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private loadMapFiles() {
    this.clearMapFiles(false);

    if (!this.cdfDef || !this.wellSelected)
      return;

    this.loading = true;

    const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

    this.apiService.getInstancelist(this.cdfDef.id, filter, this.cdfDef.version, this.cdfDef.space).subscribe({
      next: (data: NodeAndEdgeCollectionResponseV3Response) => {
        this.loading = false;

        if (data?.items?.length > 0 && this.cdfDef) {
          const wellData = data.items[0].properties?.[this.cdfDef.space]?.[this.cdfDef.fullName];
          this.processMapData(wellData);
        }
      },
      error: err => {
        this.loading = false;
        console.error(err);
      },
    });
  }

  private processMapData(data: PropertyValueGroupV3 | undefined) {
    const mapsData: MapData[] = [];

    if (data?.maps && data?.mapsFileDate) {
      for (const i in (data.maps as string[])) {
        const date: string = (data.mapsFileDate as string[])[i];
        const epochTime = moment(date).valueOf();

        mapsData.push({
          externalId: (data.maps as string[])[i],
          date: date,
          epochTime: epochTime,
        });
      }
    }

    this.loadMapsMetadata(mapsData);
  }

  private loadMapsMetadata(mapData: MapData[]) {
    this.files = [];

    if (mapData?.length > 0) {
      this.loading = true;

      const filter: any = this.wbService.getCdfDocsFilter(['externalId'], mapData.map(e => e.externalId));

      this.apiService.getDocumentList(filter).subscribe({
        next: (data: any) => {
          this.loading = false;
          let docs: any = [];

          if (this.apiService.isListResponse(data)) {
            docs = data.items ?? [];
          } else {
            docs = [data];
          }

          this.processFiles(docs, mapData);
        },
        error: err => {
          console.error(err);
          this.loading = false;
        },
      });
    }
  }

  private processFiles(documents: Document[], mapData: MapData[]) {
    if (documents?.length > 0) {
      this.files = documents.map(document => {
        let fileDate = mapData.find(e => e.externalId === document.externalId)?.date;

        if (!fileDate)
          fileDate = moment(document.createdTime).utcOffset(330).format('YYYY-MM-DD HH:mm');

        return {
          id: document.id,
          externalId: document.externalId ?? '',
          name: document.sourceFile?.name ?? '-',
          date: fileDate,
          type: document.type ?? '',
          extension: document.extension ?? '',
        };
      });
    }
  }

}
