export function calculateRowsPerPage(container: any, heightFragment: number = 1, rowHeight: number = 21, discardHeight: number = 170): number {
  let numberOfRows = 10;

  if (container) {
    try {
      let height = getRawHeight(container);

      if (discardHeight)
        height -= discardHeight;

      if (heightFragment)
        height /= heightFragment;

      numberOfRows = Math.floor(height / rowHeight);
    } catch (e) {
      console.error('Invalid element: ', e);
    }
  }

  return numberOfRows;
}

function getRawHeight(element: any): number {
  let height = 0;

  if (element) {
    const style = getComputedStyle(element);
    height = element.clientHeight;

    height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
  }

  return height;
}
