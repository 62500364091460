<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [retainHierarchy]="true" (onWellPadChange)="onfilterwellPadChange($event)" (onWellchange)="onFilterWellChange($event)" (ondatechange)="onDateSelected($event)" (onclear)="clear()" [showDateRange]="showDateRange" [showClearButton]="showClearButton"></app-hierarchy>
</div>
<div class="page-content">
  <div>
    <div>
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Calibration Alarm</mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="alarmgridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" appGridTransform>
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="alarmgridData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </ng-template>
            <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="workflowName" title="Workflow  Name" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="startDateTime_ts" title="Start Date" [width]="140">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="startDateTime">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="alarmName" title="Alarm Name" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="alarmMessage" title="Alarm Message" [width]="250"></kendo-grid-column>
            <kendo-grid-column field="alarmType" title="Alarm Type" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="alarmLevel" title="Alarm Level" [width]="100"></kendo-grid-column>
            <kendo-grid-excel fileName="Calibration Alarms.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Calibration Alarms.pdf" [allPages]="true">
              <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="workflowName" title="Workflow  Name" [width]="140"></kendo-grid-column>
              <kendo-grid-column field="startDateTime" title="Start Date" [width]="140"></kendo-grid-column>
              <kendo-grid-column field="alarmName" title="Alarm Name" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="alarmMessage" title="Alarm Message" [width]="250"></kendo-grid-column>
              <kendo-grid-column field="alarmType" title="Alarm Type" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="alarmLevel" title="Alarm Level" [width]="100"></kendo-grid-column>
            </kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
