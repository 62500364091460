<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.ESP">ESP Tolerance</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.JP">JP Tolerance</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.PCP">PCP Tolerance</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="false" [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [screeId]="'AlsWellTol'" [getListofAllWellsUnderField]="true" (onWellchange)="onfilterwellChange($event)" (onclear)="clear()" [LiftTypeToloadWells]="this.selectedtab"></app-hierarchy>
  <span>
    <div matError>
      Note:If tolerances are not defined at the well level, the default field tolerance will be applied. If tolerances are defined at the well level, then field level tolerances will be ignored. ALS will run considering only the tolerances defined at the well level.
    </div>
  </span>
</div>
<div class="page-content">
  <div>
    <div>
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>ALS Well Level Tolerance</mat-card-title>
          <div class="header-right-tools">
            <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="onAddrecordClick()">
              <mat-icon svgIcon="add"></mat-icon>
              <span>Add</span>
            </button>
            <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base delete-button" type="button" (click)="ondelete(undefined)" [disabled]="selectedrows === undefined || selectedrows?.length === 0">
              <mat-icon svgIcon="delete"></mat-icon>
              <span>Delete</span>
            </button>
          </div>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="tolerancegridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="sequence" [(selectedKeys)]="selectedrows" (selectionChange)="selectionchange($event)" [filterable]="true" [sortable]="true">
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="tolerancegridData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </ng-template>
            <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="sequence" title="sequence" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="unit" title="unit" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="startdate_ts" title="Start Date Time">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="condition" title="Condition"></kendo-grid-column>
            <kendo-grid-column field="parameterType" title="Parameter Type"></kendo-grid-column>
            <kendo-grid-column field="operationType" title="Operation Type"></kendo-grid-column>
            <kendo-grid-column field="valueunit" title="Value" [filterable]="false"></kendo-grid-column>
            <kendo-grid-column title="Status" field="status">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span [style.color]="statusCode(dataItem.status)">{{ dataItem.status }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Action" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItem)">
                  <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
                </a>
                <a slb-icon-button routerLink="." title="delete" (click)="ondelete(dataItem)">
                  <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
                </a>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="ALS Well Level Tolerance.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="ALS Well Level Tolerance.pdf" [allPages]="true">
              <kendo-grid-column field="startdate" title="Start Date Time"></kendo-grid-column>
              <kendo-grid-column field="condition" title="Condition"></kendo-grid-column>
              <kendo-grid-column field="parameterType" title="Parameter Type"></kendo-grid-column>
              <kendo-grid-column field="operationType" title="Operation Type"></kendo-grid-column>
              <kendo-grid-column field="valueunit" title="Value"></kendo-grid-column>
              <kendo-grid-column title="Status" field="status">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span [style.color]="statusCode(dataItem.status)">{{ dataItem.status }}</span>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
      <ng-template #addRecordDialog>
        <mat-dialog-content style="height: auto; width: 700px;" class="update-range-dialog-content">
          <mat-card-title class="headerLabel"> {{ formMode === 'Add'?'Add New ':'Update' }} Record</mat-card-title>
          <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
          <div class="form-container" style="margin-top: 30px;">
            <form (ngSubmit)='save(formgroup)' [formGroup]='formgroup'>
              <slb-date-picker hint="" [disabled]="formMode === 'Edit'" [(value)]="startdate" label="Start Date Time" [maxValue]="todaysdate"></slb-date-picker>
              <br>
              <mat-form-field slbFormField class="form-field" appearance="outline">
                <mat-label>Field</mat-label>
                <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field" (selectionChange)="onformfieldChange($event)" [hideSingleSelectionIndicator]="true">
                  <mat-option *ngFor="let field of fieldList" [value]="field.externalId">{{ field.Name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
                <mat-label>Well Pad</mat-label>
                <mat-select placeholder="Well Pad" #input [panelClass]="'dls-panel'" formControlName='wellpad' (selectionChange)="onformwellPadChange($event)" [hideSingleSelectionIndicator]="true">
                  <mat-option *ngFor="let wellPad of formPadList" [value]="wellPad.externalId">{{ wellPad.Name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
                <mat-label>Well</mat-label>
                <mat-select placeholder="Select" formControlName='well' #input [panelClass]="'dls-panel'" (selectionChange)="onformwellChange($event)" [hideSingleSelectionIndicator]="true">
                  <mat-option *ngFor="let well of formwellList" [value]="well.externalId">{{ well.Name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field slbFormField class="form-field" appearance="outline" #field>
                <mat-label>Condition</mat-label>
                <mat-select #input [panelClass]="'dls-panel'" formControlName='condition' [hideSingleSelectionIndicator]="true" placeholder="Select" (selectionChange)="onConditionchange($event)">
                  <mat-option *ngFor="let condition of conditionList" [value]="condition.value">{{ condition.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline" #field>
                <mat-label> Parameter Type </mat-label>
                <mat-select #input [panelClass]="'dls-panel'" formControlName='parameterType' placeholder="Select" [hideSingleSelectionIndicator]="true" (selectionChange)="onparameterchange($event)">
                  <mat-option *ngFor="let parameter of parameterListOfselectedCondition" [value]="parameter.value">{{ parameter.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline" #field>
                <mat-label>Value</mat-label>
                <input type="number" matInput formControlName="value"><span>{{ unit }}</span>
              </mat-form-field>
              <div class="space-div"></div>
              <mat-form-field slbFormField class="form-field" appearance="outline" #field>
                <mat-label>Operation Type</mat-label>
                <mat-select #input [panelClass]="'dls-panel'" formControlName='operationType' [hideSingleSelectionIndicator]="true" placeholder="Select">
                  <mat-option *ngFor="let operation of filteredoperationTypeList" [value]="operation.value">{{ operation.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-checkbox class="mat-mdc-checkbox mat-primary" formControlName='status'>Active</mat-checkbox>
            </form>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end" class="update-range-dialog-actions">
          <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
          <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="onsave(formgroup)" matDialogClose="yes">{{ formMode === 'Add' ? 'Create' : 'Update' }}</button>
        </mat-dialog-actions>
      </ng-template>
    </div>
  </div>
</div>
