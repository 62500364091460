import { Component, ViewChild } from '@angular/core';
import { FileInfo } from '@cognite/sdk/dist/src';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import moment from 'moment';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-file-search',
  templateUrl: './file-search.component.html',
  styleUrls: ['./file-search.component.css'],
})
export class FileSearchComponent {

  @ViewChild(GridComponent) public grid: GridComponent;

  public loading: boolean = false;
  public searchValue: string;
  public files: any[] = [];

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
  ];

  constructor(private readonly apiService: CognitApiService) {

  }

  public searchFiles() {
    this.files = [];

    if (!this.searchValue || this.searchValue?.trim() === '')
      return;

    this.loading = true;

    const filter: any = { search: { name: this.searchValue }};

    this.apiService.searchFiles(filter).subscribe({
      next: data => {
        this.loading = false;
        this.processFiles(data);
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private processFiles(files: FileInfo[]) {
    if (files?.length > 0) {
      const allFiles = [];

      for (const file of files) {
        allFiles.push({
          id: file.id,
          externalId: file.externalId,
          name: file.name,
          type: this.getFileType(file),
          date: this.getDate(file),
        });
      }

      this.files = addSortableDates(allFiles, this.grid, null, 'DD-MM-YYYY');
    }
  }

  private getFileType(file: FileInfo) {
    let type = 'Other';

    if (file?.mimeType)
      type = this.getTypeFromMimeType(file.mimeType);

    return type;
  }

  private getTypeFromMimeType(mimeType: string) {
    if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      return 'Excel';

    if (mimeType === 'image/jpeg')
      return 'Image';

    if (mimeType === 'application/pdf')
      return 'PDF';

    return 'Other';
  }

  private getDate(file: FileInfo) {
    let date: string | null = null;

    if (file?.metadata?.['Date'])
      date = file.metadata.Date;

    if (date === null && file.createdTime)
      date = moment(file.createdTime).format('DD-MM-YYYY');

    return date;
  }

}
