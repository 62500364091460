import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import moment from 'moment';
import { WellBookService } from '../well-book.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { File, FileInstance } from 'src/app/common-component/types/file';
import { Document, NodeAndEdgeCollectionResponseV3Response } from '@cognite/sdk/dist/src';
import { CogniteAuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { MessageService, SlbSeverity } from '@slb-dls/angular-material/notification';

declare interface DynacardInstance {
  externalId: string;
  date: string;
  type: string;
}

@Component({
  selector: 'app-log-data',
  templateUrl: './log-data.component.html',
  styleUrls: ['./log-data.component.css'],
})
export class LogDataComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  public loading: boolean = false;
  public selectedFileType: string = 'all';
  public files: File[] = [];
  public pdfUrl: string | undefined;
  public image: { name: string, url: string } | null = null;
  public lasData: { [propName: string]: number[] } | null = null;
  public showPdfFullScreen: boolean = false;
  public selectedRows: number[] = [];
  public filesData: { fileType: string, files: FileInstance[], label: string }[] = [];

  private readonly cdfDef: CdfSpaceView | null = null;
  private readonly dynaCdfDef: CdfSpaceView | null = null;
  private readonly echoCdfDef: CdfSpaceView | null = null;
  private wellSelected: string | null;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService, private readonly cdfAuthService: CogniteAuthService, private readonly msgService: MessageService,
    private cd : ChangeDetectorRef
  ) {
    this.setFileDataTypes();

    this.cdfDef = this.wbService.getSpaceDefinition('logs');
    this.dynaCdfDef = this.wbService.getSpaceDefinition('dynacard');
    this.echoCdfDef = this.wbService.getSpaceDefinition('echometer');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);
    this.cd.detectChanges();
  }

  public togglePdfViewerSize(isFullscreen: boolean) {
    this.showPdfFullScreen = isFullscreen;
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.getAllFiles();
  }

  public clearWellFiles(cleanWellSelection = true) {
    if (cleanWellSelection) {
      this.wellSelected = null;
      this.wbService.setWell(null);
    }

    this.setFileDataTypes();
    this.selectFileType();
    this.clearViewerSection();
  }

  public selectFileType() {
    this.loadFiles(this.getFileList());
  }

  public downloadFile(file: File) {
    return new Promise((res: any) => {
      if (file?.id) {
        this.getDocumentUrls(Number(file?.id), (urls: string[]) => {
          if (urls?.length > 0) {
            for (const url of urls) {
              this.triggerDownloadFile(url, file.name);
            }
          }

          setTimeout(() => res(), 500);
        });
      } else {
        res();
      }
    });
  }

  public previewFile(file: File) {
    this.clearViewerSection();

    if ((file.type === 'PDF' || file.type === 'Image') && file?.id) {
      this.getDocumentUrls(Number(file?.id), (urls: string[]) => {
        if (urls?.length > 0) {
          if (file.type === 'PDF') {
            this.pdfUrl = urls[0];
          } else if (file.type === 'Image') {
            this.image = { name: file.name, url: urls[0] };
          }
        }
      });
    }
  }

  public previewLasFile(file: File) {
    this.clearViewerSection();

    if (file.extension === 'las' && file?.id) {
      this.loading = true;

      this.cdfAuthService.getapiToken().then(res => {
        if (res) {
          const token = res;
          const url = `${environment.webApiUrl}/Cognite/GetLasFileToJson?documentId=${file.id}`;

          this.apiService.saveFunction(url, null, token).subscribe({
            next: data => {
              this.loading = false;

              if (data)
                this.lasData = data;
            },
            error: err => {
              console.error(err);
              this.loading = false;
              this.msgService.add({
                target: 'modal',
                severity: SlbSeverity.Error,
                detail: 'Failed to get the LAS file data.',
              });
            },
          });
        }
      });
    }
  }

  public async downloadAllFiles() {
    if (this.selectedRows.length > 0 && this.files.length > 0) {
      for (const row of this.selectedRows) {
        const file = this.files.find(e => e.id === row);

        if (file)
          await this.downloadFile(file);
      }
    }
  }

  private clearViewerSection() {
    this.pdfUrl = undefined;
    this.image = null;
    this.lasData = null;
  }

  private getFileList() {
    if (this.selectedFileType !== 'all')
      return this.filesData.find(e => e.fileType === this.selectedFileType)?.files ?? [];

    let allFiles: FileInstance[] = [];

    for (const data of this.filesData)
      allFiles = allFiles.concat(data?.files ?? []);

    return allFiles;
  }

  private getDocumentUrls(id: number, handleDocumentUrls: (urls: string[]) => void) {
    this.loading = true;

    this.apiService.getDocumentDownloadUrl(id).subscribe({
      next: (data: any) => {
        this.loading = false;
        let urls: string[] = [];

        if (data?.items?.length > 0)
          urls = data.items.map((e: any) => e.downloadUrl);

        handleDocumentUrls(urls);
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private async triggerDownloadFile(url: string, name: string) {
    if (url) {
      const outsideRes = await fetch(url);
      const blob = await outsideRes.blob();
      const internalUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = internalUrl;

      if (name)
        link.download = name;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  }

  private setFileDataTypes() {
    this.filesData = [
      { fileType: 'productionLoggingTool', files: [], label: 'PLT' },
      { fileType: 'reservoirSaturationTool', files: [], label: 'RST' },
      { fileType: 'openHoleLogs', files: [], label: 'OH' },
      { fileType: 'other', files: [], label: 'Other' },
      { fileType: 'dynacard', files: [], label: 'Dynacard' },
      { fileType: 'echometer', files: [], label: 'Echometer' },
    ];
  }

  private async getAllFiles() {
    this.clearWellFiles(false);

    if (!this.cdfDef || !this.wellSelected)
      return;

    this.loading = true;

    const fileRequests = [
      this.loadWellFiles(this.cdfDef),
      this.fetchData(this.dynaCdfDef, 'dynacardReport', 'dynacardReportFileDate'),
      this.fetchData(this.echoCdfDef, 'echometerReport', 'echometerReportFileDate'),
    ];

    Promise.all(fileRequests).then(data => {
      const dynacardFiles: any = data[1];
      const echometerFiles: any = data[2];

      if (dynacardFiles?.length > 0)
        this.filesData.find(e => e.fileType === 'dynacard')!.files = dynacardFiles;

      if (echometerFiles?.length > 0)
        this.filesData.find(e => e.fileType === 'echometer')!.files = echometerFiles;

      this.selectFileType();
    });
  }

  private loadWellFiles(cdfDef: CdfSpaceView): Promise<void> {
    return new Promise((res: any) => {
      const filter: any = this.wbService.getCdfFilter(cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

      this.apiService.getInstancelist(cdfDef.id, filter, cdfDef.version, cdfDef.space).subscribe({
        next: (data: NodeAndEdgeCollectionResponseV3Response) => {
          if (data?.items?.length > 0 && cdfDef) {
            const wellData = data.items[0].properties?.[cdfDef.space]?.[cdfDef.fullName];
            this.setFileData(wellData);

            res();
          }
        },
        error: err => {
          console.error(err);
          res();
        },
      });
    });
  }

  private fetchData(cdfSpace: CdfSpaceView | null, propertyName: string, propertyDate: string): Promise<any[]> {
    return new Promise(res => {
      if (!cdfSpace) {
        res([]);
        return;
      }

      const filter: any = this.wbService.getCdfFilter(cdfSpace, 'well', ['node', 'externalId'], [this.wellSelected]);

      this.apiService.getInstancelist(cdfSpace.id, filter, cdfSpace.version, cdfSpace.space).subscribe({
        next: (data: NodeAndEdgeCollectionResponseV3Response) => {
          let dynaCardData: any = [];

          if (data?.items?.length > 0 && cdfSpace) {
            dynaCardData = data.items[0].properties?.[cdfSpace.space]?.[cdfSpace.fullName];
            dynaCardData = this.setDynaCardData(dynaCardData, cdfSpace.id, propertyName, propertyDate);
          }

          res(dynaCardData);
        },
        error: err => {
          console.error(err);
          res([]);
        },
      });
    });
  }

  private loadFiles(fileList: FileInstance[]) {
    this.files = [];

    this.clearViewerSection();

    if (fileList.length > 0) {
      this.loading = true;

      const filter: any = this.wbService.getCdfDocsFilter(['externalId'], fileList.map(e => e.externalId));

      this.apiService.getDocumentList(filter).subscribe({
        next: (data: any) => {
          this.loading = false;
          let docs: any = [];

          if (this.apiService.isListResponse(data)) {
            docs = data.items ?? [];
          } else {
            docs = [data];
          }

          this.processFiles(docs);
        },
        error: err => {
          console.error(err);
          this.loading = false;
        },
      });
    }
  }

  private processFiles(documents: Document[]) {
    if (documents?.length > 0) {
      const fileList = this.getFileList();

      this.files = documents.map(document => {
        const file = fileList.find(e => e.externalId === document.externalId);
        let fileDate = file?.date;

        if (!fileDate)
          fileDate = moment(document.createdTime).utcOffset(330).format('YYYY-MM-DD HH:mm');

        return {
          id: document.id,
          category: file?.type ?? 'Other',
          externalId: document.externalId ?? '',
          name: document.sourceFile?.name ?? '-',
          date: fileDate,
          type: document.type ?? '',
          extension: document.extension ?? '',
        };
      });
    }
  }

  private setFileData(data: any) {
    if (data) {
      for (const files of this.filesData) {
        if (data.hasOwnProperty(files.fileType)) {
          for (const i in data[files.fileType]) {
            files.files.push({
              externalId: data[files.fileType][i],
              date: data[`${files.fileType}FileDate`][i],
              type: files.label,
            });
          }
        }
      }
    }
  }

  private setDynaCardData(data: any, type: string, filePropertyName: string, filePropertyDate: string): DynacardInstance[] {
    const convertedData: DynacardInstance[] = [];

    if (data && filePropertyName && filePropertyDate) {
      const files = data[filePropertyName];
      const dates = data[filePropertyDate];

      for (const i in files) {
        convertedData.push({ type: type, externalId: files[i], date: dates[i] });
      }
    }

    return convertedData;
  }

}
