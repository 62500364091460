export const PCPCondition: any[] = [
    { "name": "Rod Failure", "value": "RodFailure" ,"propertyname":"Rod Failure" },
    { "name": "Well Trip", "value": "WellTrip","propertyname":"Well Trip"  },
    { "name": "Tubing Failure", "value": "TubingFailure","propertyname":"Tubing Failure"  },


]
export const ESPCondition: any[] = [
    { "name": "Shaft Broken", "value": "ShaftBroken","propertyname":"Shaft Broken" },
    { "name":"DHG Failure","value":"DhgFailure","propertyname":"DHG Failure"},
    { "name": "ESP Trip", "value": "EspTrip" ,"propertyname":"ESP Trip" },
    { "name": "High MWT", "value": "HighMWT","propertyname":"High MWT"  },
    { "name": "ESP Vibration", "value": "Vibration","propertyname":"Vibration"  },
]

export const JPCondition: any[] = [
    { "name": "JP Choking", "value": "PumpChoking","propertyname":"Pump Choking" },
    { "name": "Nozzle Broken", "value": "NozzleBroken" ,"propertyname":"Nozzle Broken"},
    { "name": "Casing/Packer Leak", "value": "JpUnset","propertyname":"JP Unset" },
    { "name": "PF Bull Head", "value": "PFBullHead","propertyname":"PF Bullhead" },
    { "name": "Tubing Leak", "value": "TubingLeak","propertyname":"Tubing Leak" },
]