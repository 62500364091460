import { Component, Input } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';
import { CognitApiService } from '../../../services/cognit-api.service';
import { Subscription, forkJoin } from 'rxjs';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { HighchartsService } from 'src/app/services/highcharts.service';
import { Chart, Point, Series } from 'highcharts';
import StockChart from 'highcharts/modules/stock';
import { LoaderService } from '../../../services/loader.service';
import * as Highcharts from 'highcharts';

StockChart(Highcharts);

@Component({
  selector: 'app-summary-pump-chart',
  templateUrl: './summary-pump-chart.component.html',
  styleUrls: ['./summary-pump-chart.component.css']
})


export class SummaryPumpchartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  @Input()
  well: string;

  @Input()
  Welltype: string;

  @Input()
  Lifttype: string;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  public externalidlist: any[] = [];
  public externalId: any = {};
  public unit: any = {};
  public pdp: any[] = [];
  public pip: any[] = [];
  public rpm: any[] = [];
  public torque: any[] = [];
  public mf: any[] = [];
  public mc: any[] = [];
  public apressure: any[] = [];
  public mwt: any[] = [];
  public sibhp: any[] = [];
  public shutin: any[] = [];
  public steady: any[] = [];
  public restart: any[] = [];
  public rampup: any[] = [];
  public rampdown: any[] = [];
  public pfrate: any[] = [];
  public spm: any[] = [];
  public waterrate: any[] = [];
  public tht: any[] = [];

  public Type: string = '';

  public options: Highcharts.Options;
  chart: any;
  constructor(private highchart: HighchartsService,
    private loader: LoaderService,
    private apiService: CognitApiService) {

  }

  async ngOnChanges() {
    if (this.well != undefined && this.well != '' && this.startDate != undefined && this.endDate != undefined) {
      this.chart = null;
      this.showEmptyChart();
      this.Type = '';
      const datapoints = await this.apiService.getLatestTimeseriesData([{ "externalId": this.Welltype }]);
      datapoints.forEach((datapoint: any) => {
        if (datapoint.datapoints.length > 0) {
          datapoint.datapoints.forEach((data: any) => {
            if (data != undefined) {
              this.Type = String(data.value).toUpperCase();
            }
          })
        }
      })

      if (this.Type == 'PRODUCER') {
        const typepoints = await this.apiService.getLatestTimeseriesData([{ "externalId": this.Lifttype }]);
        typepoints.forEach((datapoint: any) => {
          if (datapoint.datapoints.length > 0) {
            datapoint.datapoints.forEach((data: any) => {
              if (data != undefined) {
                this.Type = String(data.value).toUpperCase();
              }
            })
          }
        });
      }
      this.loadData();
    } else {
      this.chart = null;
      this.showEmptyChart();
    }
  }

  getexternalidlist(results: any, space: string, viewid: any, version: string) {
    if (viewid != undefined) {
      let properties = results?.properties;
      if (properties !== undefined) {
        let externaliddata = properties[space][viewid + "/" + version];
        if ((externaliddata['property'] == 'motorFrequency' || externaliddata['property'] == 'motorCurrent' ||
          externaliddata['property'] == 'RPM' || externaliddata['property'] == 'Torque' || externaliddata['property'] == 'waterInjectionRate'
          || externaliddata['property'] == 'SPM' || (externaliddata['property'] == 'THT' && this.Type == 'NATURAL')
          || externaliddata['property'] == 'powerFluidRate' || externaliddata['property'] == 'A Pressure'
          || externaliddata['property'] == 'operatingFrequency' || externaliddata['property'] == 'Current')
          && (externaliddata['timeseriesExtId'] != undefined || externaliddata['timeseries'] !== undefined)) {
          let seriesID = externaliddata['timeseriesExtId'] != undefined ? externaliddata['timeseriesExtId'] : externaliddata['timeseries'];
          this.externalidlist = this.externalidlist.concat({ "externalId": seriesID, cursor: '' });
          this.externalId[externaliddata['property']] = seriesID;
        }
      }
    }
    if (viewid == undefined) {
      if (results?.externalId.includes('SHUT_IN_status') || results?.externalId.includes('RAMP_DOWN_status')
        || results?.externalId.includes('STEADY_STATE_status') || results?.externalId.includes('RAMP_UP_status') || results?.externalId.includes('RESTART_status')) {
        this.externalidlist = this.externalidlist.concat({ "externalId": results?.externalId, cursor: '' });
        let key = results?.name.split(' ')[1];
        this.externalId[key] = results?.externalId;
      }
    }
  }

  ngOnInit() {

  }


  loadData() {
    this.externalidlist = [];
    this.externalId = {};
    this.unit = {};
    this.pdp = [];
    this.pip = [];
    this.rpm = [];
    this.torque = [];
    this.mf = [];
    this.mc = [];
    this.apressure = [];
    this.mwt = [];
    this.sibhp = [];
    this.shutin = [];
    this.steady = [];
    this.restart = [];
    this.rampup = [];
    this.rampdown = [];
    this.pfrate = [];
    this.spm = [];
    this.waterrate = [];
    this.tht = [];

    let space = environment.cogniteSpace;
    let version = '';
    let viewid = '';

    switch (this.Type) {
      case 'ESP':
        version = environment.version.espParameter;
        viewid = environment.view.espParameter;
        break;
      case 'PCP':
        version = environment.version.pcpParameter;
        viewid = environment.view.pcpParameter;
        break;
      case 'JP':
        version = environment.version.jpParameter;
        viewid = environment.view.jpParameter;
        break;
      case 'HRP':
        version = environment.version.hrpParameter;
        viewid = environment.view.hrpParameter;
        break;
      case 'INJECTOR':
        version = environment.version.injectorWellproperty;
        viewid = environment.view.injectorWellproperty;
        break;
      case 'NATURAL':
        version = environment.version.nf;
        viewid = environment.view.nf;
        break;
    }


    let filter = {};

    if (this.Type == 'NATURAL') {
      filter = {
        "nested": {
          "scope": [environment.cogniteSpace, viewid + '/' + version, "well"],
          "filter": {
            "equals": {
              "property": ["node", "externalId"],
              "value": this.well
            }
          }
        }
      }
    } else {
      filter = {
        "equals": {
          "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
          "value": this.well
        }
      }
    }
    let sources = [
      this.apiService.getInstancelist(viewid, filter, version).toPromise(),
      this.apiService.getTimeseriesList([this.well], { "workflow": "SIBHP" }),
    ];

    forkJoin(sources).subscribe(async (instancedata: any) => {
      const data = instancedata[0];
      const wellseries = instancedata[1];
      let rawData = data['items'];
      if ((rawData.length || wellseries.length)) {
        if (rawData.length) {
          rawData.forEach((item: any) => {
            this.getexternalidlist(item, space, viewid, version);
          });
        }
        if (wellseries.length) {
          wellseries.forEach((item: any) => {
            this.getexternalidlist(item, '', undefined, '');
          });
        }

        if (this.externalidlist.length) {
          this.loader.showLoader();
          let results: any[] = await this.apiService.getTimeseriesAvgRange(this.externalidlist, moment(this.startDate).valueOf(), moment(this.endDate).add(1, 'days').valueOf(), '1m');
          this.loader.hideLoader();
          let result = [];
          results.forEach((data: any) => {
            if ((data.externalId == this.externalId['motorFrequency'] || data.externalId == this.externalId['operatingFrequency'])) {
              this.unit['mf'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.mf.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if ((data.externalId == this.externalId['motorCurrent'] || data.externalId == this.externalId['Current'])) {
              this.unit['mc'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.mc.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['RPM']) {
              this.unit['rpm'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.rpm.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['Torque']) {
              this.unit['torque'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.torque.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['A Pressure']) {
              this.unit['apressure'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.apressure.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['PDP']) {
              this.unit['pdp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pdp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['PIP']) {
              this.unit['pip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pip.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['MWT']) {
              this.unit['mwt'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.mwt.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['SIBHP']) {
              this.unit['sibhp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.sibhp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['SPM']) {
              this.unit['spm'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.spm.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['THT']) {
              this.unit['tht'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.tht.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['powerFluidRate']) {
              this.unit['pfrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pfrate.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['waterInjectionRate']) {
              this.unit['waterrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.waterrate.push([moment(datapoint.timestamp).valueOf(), +(datapoint.average * 6.29).toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['SHUT_IN_status']) {
              data.datapoints.forEach((datapoint: any) => {
                this.shutin.push([moment(datapoint.timestamp).valueOf(), datapoint.average]);
              });
            }
            if (data.externalId == this.externalId['STEADY_STATE_status']) {
              data.datapoints.forEach((datapoint: any) => {
                this.steady.push([moment(datapoint.timestamp).valueOf(), datapoint.average]);
              });
            }
            if (data.externalId == this.externalId['RAMP_DOWN_status']) {
              data.datapoints.forEach((datapoint: any) => {
                this.rampdown.push([moment(datapoint.timestamp).valueOf(), datapoint.average]);
              });
            }
            if (data.externalId == this.externalId['RAMP_UP_status']) {
              data.datapoints.forEach((datapoint: any) => {
                this.rampup.push([moment(datapoint.timestamp).valueOf(), datapoint.average]);
              });
            }
            if (data.externalId == this.externalId['RESTART_status']) {
              data.datapoints.forEach((datapoint: any) => {
                this.restart.push([moment(datapoint.timestamp).valueOf(), datapoint.average]);
              });
            }
          });
          this.showChart();
        } else {
          this.showEmptyChart();
        }
      }
    });
  }

  showEmptyChart() {
    this.options = {
      chart: {
        height: '30%',
        marginTop: 0,
        backgroundColor: "transparent"
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Select Well To Show Pump Parameters'
      },
    };

    this.highchart.createChart("linecontainer", this.options);
  }

  showChart() {
    this.options = {
      chart: {
        zooming: {
          type: 'x'
        },
        height: '35%',
        backgroundColor: "transparent"
      },
      time: {
        timezone: moment.tz.guess()
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Pump Parameter',
        align: 'left'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
          style: {
            fontSize: '20px'
          }
        }
      },
      yAxis: [{
        alignTicks: false,
        title: {
          text: 'current(A),Frequency(Hz),MWT(Deg C)'
        },
        opposite: false,
      }, {
        alignTicks: false,
        gridLineWidth: 0,
        title: {
          text: 'SIBHP,PDP(psi),PIP(psi)'
        },
        opposite: true,
      }, {
        alignTicks: false,
        allowDecimals: false,
        gridLineWidth: 0,
        title: {
          text: 'Operational State Indicator.'
        },
        max: 2,
        min: 0,
        opposite: false,
      }], legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal'
      },
      series: [{
        type: 'line',
        name: this.unit['pdp'] != '' && this.unit['pdp'] != undefined ? 'PDP(' + this.unit['pdp'] + ')' : 'PDP(psi)',
        data: this.pdp,
        showInLegend: this.unit['pdp'] == undefined ? false : true,
        yAxis: 1,
      }, {
        type: 'line',
        name: this.unit['pip'] != '' && this.unit['pip'] != undefined ? 'PIP(' + this.unit['pip'] + ')' : 'PIP(psi)',
        data: this.pip,
        showInLegend: (this.unit['pip'] == undefined || this.Type == 'INJECTOR') ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['mf'] != '' && this.unit['mf'] != undefined ? 'Frequency(' + this.unit['mf'] + ')' : 'Frequency(Hz)',
        data: this.mf,
        showInLegend: this.Type == 'ESP' || this.Type == 'PCP',
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['mc'] != '' && this.unit['mc'] != undefined ? 'Current(' + this.unit['mc'] + ')' : 'Current(A)',
        data: this.mc,
        showInLegend: this.Type == 'ESP' || this.Type == 'PCP',
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['mwt'] != '' && this.unit['mwt'] != undefined ? 'MWT(' + this.unit['mwt'] + ')' : 'MWT(Deg C)',
        data: this.mwt,
        showInLegend: this.unit['mwt'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['sibhp'] != '' && this.unit['sibhp'] != undefined ? 'SIBHP(' + this.unit['sibhp'] + ')' : 'SIBHP(psi)',
        data: this.sibhp,
        showInLegend: this.unit['sibhp'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['spm'] != '' && this.unit['spm'] != undefined ? 'SPM(' + this.unit['spm'] + ')' : 'SPM',
        data: this.spm,
        showInLegend: this.Type == 'HRP',
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['waterrate'] != '' && this.unit['waterrate'] != undefined ? 'Water Injection Rate(BBL/hr)' : 'Water Injection Rate',
        data: this.waterrate,
        showInLegend: this.Type == 'INJECTOR',
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['pfrate'] != '' && this.unit['pfrate'] != undefined ? 'PF Rate(' + this.unit['pfrate'] + ')' : 'PF Rate(m3/hr)',
        data: this.pfrate,
        showInLegend: this.Type == 'JP',
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['apressure'] != '' && this.unit['apressure'] != undefined ? 'A Pressure(' + this.unit['apressure'] + ')' : 'A Pressure(psi)',
        data: this.apressure,
        showInLegend: this.Type == 'JP',
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['rpm'] != '' && this.unit['rpm'] != undefined ? 'RPM(' + this.unit['rpm'] + ')' : 'RPM',
        data: this.rpm,
        showInLegend: this.Type == 'PCP',
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['torque'] != '' && this.unit['torque'] != undefined ? 'Torque(' + this.unit['torque'] + ')' : 'Torque(Ft-lbs)',
        data: this.torque,
        showInLegend: this.Type == 'PCP',
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['tht'] != '' && this.unit['tht'] != undefined ? 'THT(' + this.unit['tht'] + ')' : 'THT(Deg C)',
        data: this.tht,
        showInLegend: this.Type == 'NATURAL',
        yAxis: 0
      }, {
        type: 'area',
        tooltip: {
          pointFormat: "{series.name} : {point.y:.0f}"
        },
        marker: {
          enabled: false
        },
        name: 'Ramp-Up',
        data: this.rampup,
        yAxis: 2,
        opacity: 0.3,
        color: 'cyan'
      }, {
        type: 'area', 
        tooltip: {
          pointFormat: "{series.name} : {point.y:.0f}"
        },
        marker: {
          enabled: false
        },
        name: 'Restart',
        data: this.restart,
        yAxis: 2,
        opacity: 0.3,
        color: 'blue'
      }, {
        type: 'area', 
        tooltip: {
          pointFormat: "{series.name} : {point.y:.0f}"
        },
        name: 'Ramp-Down',
        marker: {
          enabled: false
        },
        data: this.rampdown,
        yAxis: 2,
        opacity: 0.3,
        color: 'red'
      }, {
        type: 'area', 
        tooltip: {
          pointFormat: "{series.name} : {point.y:.0f}"
        },
        name: 'Steady-State',
        marker: {
          enabled: false
        },
        data: this.steady,
        yAxis: 2,
        opacity: 0.3,
        color: 'yellow'
      }, {
        type: 'area', 
        tooltip: {
          pointFormat: "{series.name} : {point.y:.0f}"
        },
        name: 'Shut-in',
        marker: {
          enabled: false
        },
        data: this.shutin,
        yAxis: 2,
        opacity: 0.3,
        color: 'green'
      }],
      rangeSelector: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          }
        }
      },
      tooltip: {
        shared: true,
        pointFormat: "{series.name} : {point.y:.2f}"
      }
    }

    this.chart = Highcharts.stockChart("linecontainer", this.options);
    this.updateYaxis();
  }

  updateYaxis() {
    let yTitle1: string[] = [];
    let yTitle2: string[] = [];
    this.chart.series.forEach((element: any) => {
      if (element.options.yAxis == 0 && element.options.showInLegend) { yTitle1.push(element.options.name) };
      if (element.options.yAxis == 1 && element.options.showInLegend) { yTitle2.push(element.options.name) };
    })
    this.chart.update({
      yAxis: [{
        title: {
          text: yTitle1.join(',')
        }
      }, {
        title: {
          text: yTitle2.join(',')
        }
      }]
    });
  }
}
