import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import SolidGaugeModule from 'highcharts/modules/solid-gauge';


// Initialize the SolidGauge module
SolidGaugeModule(Highcharts);
@Component({
  selector: 'app-production-chart-card',
  templateUrl: './production-chart-card.component.html',
  styleUrls: ['./production-chart-card.component.css']
})
export class ProductionChartCardComponent implements OnInit, OnChanges {


  @Input() cardTitle: string = '';
  @Input() allocated: number = 0;
  @Input() theoreticalToday: number = 0;
  @Input() theoreticalYesterday: number = 0;
  @Input() asOfDate: string = '';
  @Input() unit: string = '';
  @Input() loading: boolean = false;
  // @Input() percentage: string = '';


  gaugeChartConstructor: string = 'chart';
  gaugeHighcharts: typeof Highcharts = Highcharts;
  gaugeChartOptions: Highcharts.Options;


  public percentage: number;
  public diffrenceToShow: string;
  public isNanPercentage: boolean;
  public dummyChartOptions: Highcharts.Options;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const percentageChange = ((this.theoreticalToday - this.theoreticalYesterday) / this.theoreticalToday) * 100;
      this.percentage = Math.round(percentageChange * 100) / 100;
      this.diffrenceToShow = this.formatNumber(Math.abs(this.theoreticalToday - this.theoreticalYesterday))
      this.isNanPercentage = isNaN(Math.round(percentageChange * 100) / 100);
      if(!this.isNanPercentage){
        const maxValue = Math.max(this.theoreticalYesterday, this.theoreticalToday);
        let calculatedMax = Math.ceil(maxValue / 100) * 100; 
        if(((this.theoreticalToday/calculatedMax)*100 > 95) || ((this.theoreticalYesterday/calculatedMax)*100 > 95)){
          calculatedMax = calculatedMax + (calculatedMax * 0.05);
        }
        let yesterdayTheoreticalPrecent = (this.theoreticalYesterday/calculatedMax)*100;
        let theoreticalPercent = (this.theoreticalToday/calculatedMax)*100;
        const theoreticalValue = this.theoreticalToday;
        const formatValue = this.formatNumber;
        const yesterdayTheoreticalValue = this.theoreticalYesterday;
        // console.log("calculatedMax", {calculatedMax, yesterdayTheoreticalPrecent, theoreticalPercent, theoreticalValue, yesterdayTheoreticalValue});
        this.gaugeChartOptions = {
          chart: {
            backgroundColor: '',
            type: 'solidgauge',
            margin: [0, 0, 0, 0],
            height: 120
          },
          title: {
            text: '',
          },
          exporting: {
            enabled: false // Disable the exporting module to remove the hamburger menu
          },
    
          credits: {
            enabled: false // Disable the Highcharts credits
          },
          pane: {
            startAngle: 0,
            center: ['50%', '50%'],
            size: '90%',
            endAngle: 360,
            background: [{
              backgroundColor: '#222',
              innerRadius: '60%',
              outerRadius: '100%',
              borderWidth: 0
            }]
          },
          tooltip: { enabled: true, // Enable tooltip globally
            // useHTML: true,        
            // positioner: function (labelWidth, labelHeight, point) {
            //   return { x: point.plotX + 10, y: point.plotY - labelHeight / 2 };
            // },
            formatter: function () {
              const tolerance = 2;
            //   if (this.point &&
            //     this.point.y !== undefined &&
            //     Math.abs(this.point.y - yesterdayTheoreticalPrecent) <= tolerance) {
            //     console.log("point allo", this.point)
            //     return `<b>Allocated:</b> ${formatValue(yesterdayTheoreticalValue)}`;
            // } else 
            if (this.point) {
              // console.log("point the ", this.point, this.y)
              return `<b>Theoretical:</b> ${formatValue(theoreticalValue)}`;
            }else{
              console.log("point none", this)
              return ''
            }
            } },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
            plotLines: [
              {
                value: yesterdayTheoreticalPrecent,
                width: 3,
                color: '#ff0000', // Red for the dotted line
                dashStyle: 'ShortDot', // Dotted style
                zIndex: 5, // Ensure it appears on top
                // label: {
                //   text: `Allocated ${this.formatNumber(this.allocated)}`, // Tooltip-like label
                //   align: 'center',
                //   style: {
                //     color: '#000000',
                //     fontSize: '10px',
                //     fontWeight: 'bold'
                //   }
                // }
              }
            ]
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                enabled: false
              },
              linecap: 'round',
              rounded: true,
              stickyTracking: false
            }
          },
          series: [
            {
              name: 'Theoretical',
              type: 'solidgauge',
              data: [{
                radius: '90%',
                innerRadius: '70%',
                y: theoreticalPercent,
                color: this.percentage >= 0 ? '#80ff80' : '#ff6666'
              }],
            },
            // {
            //   name: "Allocated",
            //   type: 'solidgauge',
            //   data: [{
            //     z: 1,
            //     radius: '90%',
            //     innerRadius: '70%',
            //     y: yesterdayTheoreticalPrecent,
            //     color: this.percentage >= 0 ? '#008000' : '#ff0000'
            //   }],
            // }
          ]
        };
      }
    }
  }
  ngOnInit() {
    const percentageChange = ((this.theoreticalToday - this.theoreticalYesterday) / this.theoreticalToday) * 100;
    this.percentage = Math.round(percentageChange * 100) / 100;
    this.diffrenceToShow = this.formatNumber(Math.abs(this.theoreticalToday - this.theoreticalYesterday))
    this.isNanPercentage = isNaN(Math.round(percentageChange * 100) / 100)
    // const progressPercent = (this.actualValue / this.potentialValue) * 100; // Calculate progress
    if(!this.isNanPercentage){
    this.gaugeChartOptions = {
      chart: {
        backgroundColor: '',
        type: 'solidgauge',
        margin: [0, 0, 0, 0],
        height: 120
      },
      title: {
        text: '',
      },
      exporting: {
        enabled: false // Disable the exporting module to remove the hamburger menu
      },

      credits: {
        enabled: false // Disable the Highcharts credits
      },
      pane: {
        startAngle: 0,
        center: ['50%', '50%'],
        size: '90%',
        endAngle: 360,
        background: [{
          backgroundColor: '#222',
          innerRadius: '60%',
          outerRadius: '100%',
          borderWidth: 0
        }]
      },
      tooltip: { enabled: false },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          rounded: true,
          stickyTracking: false
        }
      },
      series: [
        // {
        //   name: "Allocated",
        //   type: 'solidgauge',
        //   data: [{
        //     radius: '90%',
        //     innerRadius: '70%',
        //     y: 80,
        //     color: '#33HG8d'
        //   }],
        // },
        {
          name: 'Theoretical',
          type: 'solidgauge',
          data: [{
            radius: '90%',
            innerRadius: '70%',
            y: 60,
            color: this.percentage >= 0 ? 'green' : 'red'
          }],
        }
      ]
    };
  }else{
      this.dummyChartOptions = {
        chart: {
          backgroundColor: '',
          type: 'solidgauge',
          margin: [0, 0, 0, 0],
          height: 120
        },
        title: {
          text: '',
        },
        exporting: {
          enabled: false // Disable the exporting module to remove the hamburger menu
        },
  
        credits: {
          enabled: false // Disable the Highcharts credits
        },
        pane: {
          startAngle: 0,
          center: ['50%', '50%'],
          size: '90%',
          endAngle: 360,
          background: [{
            backgroundColor: '#222',
            innerRadius: '60%',
            outerRadius: '100%',
            borderWidth: 0
          }]
        },
        tooltip: { enabled: false },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false
            },
            linecap: 'round',
            rounded: true,
            stickyTracking: false
          }
        },
        series: [
          {
            name: 'No Value',
            type: 'solidgauge',
            data: [{
              radius: '90%',
              innerRadius: '70%',
              y: 100,
              color: '#4d4d4d'
            }],
          }
        ]
      };
    
  }
  }
  getPercentageColor() {
    return this.percentage >= 0 ? 'green' : 'red'
  }

formatNumber(value: number): string {
  return value.toLocaleString('en-US');
}
}
