import { Interpolator1D } from "./Interpolator1D";

export class Interp1d extends Interpolator1D {

  private x: number[];
  private y: number[];
  private kind: string;
  private fillValue: number | [number, number];
  private boundsError: boolean;
  private extrapolate: boolean;
  private _fillValueBelow: number;
  private _fillValueAbove: number;

  constructor( x: number[], y: number[], kind: string = 'linear', axis: number = -1, boundsError: boolean = true, fillValue: number | [number, number] = NaN) {
    super(x, y, axis);

    this.x = [...x];
    this.y = [...y];
    this.kind = kind;
    this.boundsError = boundsError;
    this.fillValue = fillValue;
    this._fillValueBelow = NaN;
    this._fillValueAbove = NaN;

    if (this.x.length !== this.y.length)
      throw new Error("x and y arrays must have the same length.");

    const sortedIndices = this.x
      .map((val, index) => ({ val, index }))
      .sort((a, b) => a.val - b.val)
      .map(item => item.index);

    this.x = this.x.sort((a, b) => a - b);
    this.y = sortedIndices.map(index => this.y[index]);

    this._initializeInterpolationMethod();
  }

  private _initializeInterpolationMethod() {
    if (this.kind === 'linear') {
      this.call = this.callLinear;
    } else {
      throw new Error(`${this.kind} interpolation is not supported`);
    }
  }

  private callLinear(xNew: number[]): number[] {
    const result: number[] = [];

    for (let i = 0; i < xNew.length; i++) {
      let xVal = xNew[i];

      const idx = this._findInterval(xVal);
      const lo = this.x[idx];
      const hi = this.x[idx + 1];
      const yLo = this.y[idx];
      const yHi = this.y[idx + 1];

      const slope = (yHi - yLo) / (hi - lo);
      const interpolatedVal = yLo + slope * (xVal - lo);

      result.push(interpolatedVal);
    }

    return result;
  }

  private _findInterval(xVal: number): number {
    let idx = this.x.findIndex(val => val >= xVal);

    if (idx === -1)
      idx = this.x.length - 2;

    return idx;
  }

  protected override evaluate(xNew: number[]): number[] {
    let yNew = this.call(xNew);

    if (!this.extrapolate) {
      const belowBounds = xNew.map(xVal => xVal < this.x[0]);
      const aboveBounds = xNew.map(xVal => xVal > this.x[this.x.length - 1]);

      if (belowBounds.some(val => val))
        yNew = yNew.map((value, index) => belowBounds[index] ? this._fillValueBelow : value);

      if (aboveBounds.some(val => val))
        yNew = yNew.map((value, index) => aboveBounds[index] ? this._fillValueAbove : value);
    }

    return yNew;
  }

  get fill_value(): number | [number, number] {
    return this.fillValue;
  }

  set fill_value(value: number | [number, number]) {
    if (Array.isArray(value) && value.length === 2) {
      this._fillValueBelow = value[0];
      this._fillValueAbove = value[1];
    } else {
      this._fillValueBelow = value as number;
      this._fillValueAbove = value as number;
    }
    this.fillValue = value;
  }

  interpolate(xNew: number[]): number[] {
    return this.evaluate(xNew);
  }
}
