<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [timerangeDifference]="365" [filterOnWellType]="false" (onFieldChange)="clearWellSelection()" (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)" (onclear)="clearWellSelection()" [showClearButton]="true"  ></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card class="example-card" autoHeight>
    <mat-card-content [autoHeight]="false">
      <div *ngIf="loading" class="spinner__loading loading-container">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div class="viewer-block">
        <div>
          <kendo-grid #grid appGridAutoTooltip [kendoGridBinding]="completionSchematics" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="false" [filterable]="true" [sortable]="true" [rowClass]="rowCallback" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card' }">
            <ng-template kendoGridToolbarTemplate>
              <div class="row">
                <div class="col-sm-10">
                  <h4>Well Completion Schematic</h4>
                </div>
                <div class="col-sm-2">
                  <div style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                    <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="grid" [exportColumnRules]="exportColumnRules" [data]="completionSchematics">
                      <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                    </button>
                    <button type="button" kendoGridPDFCommand>
                      <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="id" title="ID" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="name" title="File"></kendo-grid-column>
            <kendo-grid-column field="date_ts" title="Date">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.date }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="action" title="Action" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                <a slb-icon-button routerLink="." title="Download" (click)="previewFile(dataItem, true)" *ngIf="dataItem.type === 'Image' || dataItem.type === 'Spreadsheet'">
                  <mat-icon style="color: #6BA7FF;" svgIcon="download"></mat-icon>
                </a>
                <a slb-icon-button routerLink="." title="Preview" (click)="previewFile(dataItem)" *ngIf="dataItem.type === 'Image'">
                  <mat-icon style="color: #6BA7FF;" svgIcon="preview"></mat-icon>
                </a>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="Well Completion Schematic.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Well Completion Schematic.pdf" [allPages]="true">
              <kendo-grid-column field="name" title="File"></kendo-grid-column>
              <kendo-grid-column field="date" title="Date"></kendo-grid-column>
            </kendo-grid-pdf>
          </kendo-grid>
        </div>
        <div *ngIf="!loading && !imageUrl" class="no-preview">
          <p>No file to view</p>
        </div>
        <div *ngIf="!loading && imageUrl" class="viewer-container">
          <app-image-viewer [imageUrl]="imageUrl" [enableAnnotations]="true"></app-image-viewer>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
