import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitApiService } from './cognit-api.service';
import { LoaderService } from './loader.service';
import moment from 'moment';



@Injectable({
    providedIn: 'root'
})
export class TimeseriesHelper {
    public sdmPropertyList: any[] = [];
    constructor(private apiService: CognitApiService,
        private loader: LoaderService,
    ) {

    }
    /**
     *
     * @param filtervalue Array of filter values []
     * @param viewid Name of view
     * * @param referencePropertytoFilter Name of property in view on which filter needs to be applied
     * @returns
     */
    public getExternalId(filtervalueList: any[], viewid: string, referencePropertytoFilter: string): Observable<any> {
        let version = environment.cogniteSDMVersion
        let space = environment.cogniteSpace;
        let filter = {
            "nested": {
                "scope": [environment.cogniteSpace, viewid + "/" + version, referencePropertytoFilter],
                "filter": {
                    "in": {
                        "property": ["node", "externalId"],
                        "values": filtervalueList
                    }
                }
            }
        };
        this.loader.showLoader();
        return this.apiService.getInstancelist(viewid, filter, version).pipe(map((instancedata: any) => {
            this.loader.hideLoader();
            let externalidlist: any[] = [];
            this.sdmPropertyList = [];
            let inputresult = instancedata;
            inputresult.items.forEach((item: any) => {
                externalidlist = externalidlist.concat(this.getexternalidlist(item, space, viewid, environment.cogniteSDMVersion, referencePropertytoFilter));
            });
            let propertyList = this.sdmPropertyList;
            this.sdmPropertyList = [];
            let returnobject = { "externalidlist": externalidlist, "sdmPropertyList": propertyList };
            return returnobject;
        }, (error: any) => {
            this.loader.hideLoader();
            console.log(error)
        }));

    }
    public getexternalidlist(results: any, space: string, viewid: string, version: string, property: string): any {
        let externalidlist: any[] = [];
        let properties = results?.properties;
        if (properties !== undefined) {
            let externaliddata = properties[space][viewid + "/" + version];
            let propertyid = externaliddata[property].externalId;
            delete externaliddata[property]
            this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map(function (key) {
                return { "keyvalue": key.toLowerCase(), "value": externaliddata[key], "FilterPropertyId": propertyid };
            }));
            //console.log(this.sdmPropertyList);
            let values = Object.values(externaliddata);
            externalidlist = values.map(value => ({ "externalId": value }));
        }
        return externalidlist;
    }


    /**
  *
  * @param filtervalue Array of filter values []
  * @param viewid Name of view
  * * @param referencePropertytoFilter Name of property in view on which filter needs to be applied
  * @returns
  */
    public getExternalIdsusingexternalId(filtervalueList: any[], viewid: string, propertiestoshow: any[], version = environment.cogniteSDMVersion, space = environment.cogniteSpace): Observable<any> {
        let filter = {
            "in": {
                "property": ["externalId"],
                "values": filtervalueList
            }
        }

        this.loader.showLoader();
        return this.apiService.getInstance(viewid, filter, version, space, 1000).pipe(map((instancedata: any) => {
            let externalidlist: any[] = [];
            this.sdmPropertyList = [];
            let inputresult = instancedata;
            inputresult.items.forEach((item: any) => {
                externalidlist = externalidlist.concat(this.getSpecificexternalidlist(item, space, viewid, version, propertiestoshow));
            });
            let propertyList = this.sdmPropertyList;
            this.sdmPropertyList = [];
            let returnobject = { "externalidlist": externalidlist, "sdmPropertyList": propertyList };
            return returnobject;
        }, (error: any) => {
            this.loader.hideLoader();
            console.log(error)
        }));

    }
    private getSpecificexternalidlist(results: any, space: string, viewid: string, version: string, propertiestoinclude: string[]): any {
        let externalidlist: any[] = [];
        let properties = results?.properties;
        if (properties !== undefined) {
            let propertielist = properties[space][viewid + "/" + version];

            let items = Object.keys(propertielist).map(function (key) {
                if (propertiestoinclude.includes(key))
                    return { "keyvalue": key.toLowerCase(), "value": propertielist[key], "FilterPropertyId": results.externalId };
                else
                    return null
            })
            let filteredArray = items.filter((element) => element !== null);
            this.sdmPropertyList = this.sdmPropertyList.concat(filteredArray);
            externalidlist = filteredArray.map(value => ({ "externalId": value?.value }));
        }
        return externalidlist;
    }
/**
 * 
 * @param filterStartdate startdate
 * @param filterEnddate enddate
 * @param alarmeventType event Type name for alarm
 * @param selectedwell lisr of well [{externalId:""}]
 * @returns 
 */
    getAlsEventAlarm(filterStartdate: any, filterEnddate: any, alarmeventType: string, selectedwell: any[]): Promise<any> {
        return this.apiService.getEventlist(filterStartdate, filterEnddate, alarmeventType, selectedwell).then((eventResponse: any) => {
            return this.getEventAlarm(eventResponse);
        })
    }
    getEventAlarm(eventResponse: any): any[] {
        let rawGrid: any[] = []
        eventResponse.forEach((eventitem: any) => {
            let metadata = eventitem.metadata;
            let driftWindow = metadata['window'] == undefined ? '-' : metadata['window'];
            let allowed_deviation = metadata["allowed_deviation"] == undefined ? '-' : metadata["allowed_deviation"];
            let max_deviation = metadata["max_deviation"] == undefined ? '-' : metadata["max_deviation"];
            let starttime = moment(eventitem.startTime).format("DD-MM-YYYY HH:mm");
            let dataRow = {
                well: metadata['Well'],
                workflowName: metadata['Workflow'],
                startDateTime: starttime,
                endDateTime: starttime,
                alarmName: metadata['Alarm Name'],
                alarmFlags: metadata['Alarm Flags'] == undefined ? '-' : metadata['Alarm Flags'],
                alarmMessage: metadata['Alarm Message'] == undefined ? '-' : metadata['Alarm Message'],
                alarmType: eventitem.subtype,
                alarmLevel: metadata['Alarm Level'],
                driftWindow: driftWindow,
                maxDeviation: max_deviation,
                allowedDeviation: allowed_deviation,
                startdatetimestamp: eventitem.startTime
            };
            rawGrid.push(dataRow);
        })
        //this.alarmgridData = this.alarmgridData.concat(this.rawgridData);
        let data = this.sortGridData(rawGrid);
        return data;
        // this.alarmgridData = this.rawgridData;
    }

    sortGridData(rawgridData: any): any[] {
        if (rawgridData.every((obj: any) => 'startdatetimestamp' in obj)) {
            rawgridData.sort((a: any, b: any) => b.startdatetimestamp - a.startdatetimestamp);
            return rawgridData;
        } else {
            console.error("Some objects lack the 'startdatetimestamp' key. Sorting is not feasible.");
            return rawgridData;
        }
    }
}
