import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import * as powerbi from 'powerbi-client'
import { CogniteAuthService } from 'src/app/services/auth.service';
import { PowerBiService } from 'src/app/services/powerbi.service';

@Component({
  selector: 'app-i-frame',
  templateUrl: './i-frame.component.html',
  styleUrls: ['./i-frame.component.css'],
})
export class IFrameComponent implements AfterViewInit, OnChanges {

  @Input() title: string;
  @Input() src: SafeResourceUrl;
  srcSafe: SafeHtml;
  srcSafeUrl: SafeResourceUrl;

  @ViewChild('iframeContainer') iframeContainer: ElementRef;

  public isViewReadyForIframe: boolean = false;

  constructor(private renderer: Renderer2,private cdfAuthService: CogniteAuthService,private powerBiService: PowerBiService,private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void{
    this.powerBiService.data$.subscribe(
      newData => {
        if(newData !== this.srcSafeUrl){
          this.srcSafeUrl = newData;
          this.setIFrameHeight();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.setIFrameHeight();
  }

  get safeUrl(): SafeHtml{
    this.fetchAndSanitize(this.srcSafeUrl);
    return this.srcSafe;
  }

  fetchAndSanitize(safeUrl: SafeResourceUrl): void{

    const url = this.sanitizer.sanitize(SecurityContext.URL, safeUrl);
    if(url)
      this.srcSafe = this.sanitizer.bypassSecurityTrustHtml(url);
  }


  embedPowerBIReport(): void {

    this.cdfAuthService.getaccessToken().then(token =>{

      this.powerBiService.getaccessTokenPowerBI().then(powerBiToken => {

      const reportId  =this.powerBiService.getReportId();
      const reportLink = this.powerBiService.getReportLink();

      if(reportId && reportId != '' && reportLink && reportLink != ''){
        const embedConfig = {
        type: 'report',
        tokenType: powerbi.models.TokenType.Aad,
        accessToken: powerBiToken,
        embedUrl: reportLink,
        id: reportId,
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
        },
        visualName: this.title,
       };

      const reportContainer = this.iframeContainer.nativeElement;
      const powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
       if(reportContainer){
        powerbiService.reset(reportContainer)
        powerbiService.embed(reportContainer, embedConfig);
       }
      }
      else{
        console.error('unable to embed the powerbi report')
      }
      })
    })
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.srcSafeUrl && changes.srcSafeUrl.previousValue !== changes.srcSafeUrl.currentValue) {
      this.srcSafeUrl = changes.srcSafeUrl.currentValue;
      this.setIFrameHeight();
    }
  }

  private setIFrameHeight() {
    
    if (this.iframeContainer) {
      const height = this.calculateHeight(this.iframeContainer.nativeElement);
      this.renderer.setStyle(this.iframeContainer.nativeElement, 'height', `${height}px`);
      if (this.srcSafe)
        setTimeout(() => {
        this.embedPowerBIReport();
      }, 250);
    }
  }

  private calculateHeight(element: HTMLElement): number {
    let containerElement: HTMLElement = element;

    while (!containerElement.classList.contains('mat-card') && containerElement.parentElement) {
      containerElement = containerElement.parentElement ?? containerElement;
    }

    const viewportHeight = window.innerHeight;
    const elementTop = containerElement.getBoundingClientRect().top;
    const heightDifference = viewportHeight - elementTop;
    return heightDifference;
  }


}
