import { LoaderService } from './../../services/loader.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { WellBookService } from '../well-book.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { NodeAndEdgeCollectionResponseV3Response, PropertyValueGroupV3 } from '@cognite/sdk/dist/src';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { Subscription, timestamp } from 'rxjs';

declare class WellbookSummary {
  wellDrilledOnDate: string;
  wellDrilledOnDateRemarks: string;
  wellDrilledOnDateSource: string;
  wellCompletedOnDate: string;
  wellCompletedOnDateRemarks: string;
  wellCompletedOnDateSource: string;
  startProductionDate: string;
  wellType: string;
  liftType: string;
  liftTypeInitialDate: string;
};

@Component({
  selector: 'app-wellbook-summary',
  templateUrl: './wellbook-summary.component.html',
  styleUrls: ['./wellbook-summary.component.css'],
})
export class WellbookSummaryComponent implements AfterViewInit, OnDestroy {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  public globalLoading: boolean = false;
  public loading: boolean = false;
  public wellSelected: string | null = null;
  public summaryData: WellbookSummary | null = null;
  public selectedTab: string = 'wellDetails';
  public wellBookData: PropertyValueGroupV3 | null = null;

  private cdfDef: CdfSpaceView | null = null;
  private loaderSubscriber: Subscription;

  constructor(private apiService: CognitApiService, private wbService: WellBookService, private loaderService: LoaderService, private cd: ChangeDetectorRef) {
    this.cdfDef = this.wbService.getSpaceDefinition('wellbook');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);

    this.loaderSubscriber = this.loaderService.isLoading$.subscribe({
      next: (loading: boolean) => this.globalLoading = loading,
      error: () => this.globalLoading = false,
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.loaderSubscriber)
      this.loaderSubscriber.unsubscribe();
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadData();
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.wbService.setWell(null);
    this.summaryData = null;
  }

  public onTabChange(tab: MatButtonToggleChange) {
    this.selectedTab = tab.value;
  }

  private async loadData() {
    this.summaryData = null;

    if (!this.cdfDef || !this.wellSelected)
      return;

    this.loading = true;

    try {
      const [wellbookData, ofmData, wellData] = await Promise.all([this.loadWellbookData(), this.loadOfmData(), this.loadWellData()]);

      let wellType: any = null;
      let liftType: any = null;
      let liftTypeInitialDate: any = null;

      if (wellData) {
        const requests = [
          this.loadLatestTimeseriesValue((wellData as any).wellType?.toString() ?? ''),
          this.loadLatestTimeseriesValue((wellData as any).liftType?.toString() ?? ''),
          this.getLiftTypeDate((wellData as any).liftType?.toString() ?? ''),
        ];

        [wellType, liftType, liftTypeInitialDate] = await Promise.all(requests);
      }

      this.processData(wellbookData, ofmData, wellType?.value, liftType?.value, liftTypeInitialDate);
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  private loadWellbookData(): Promise<PropertyValueGroupV3 | null> {
    return new Promise(res => {
      if (this.cdfDef && this.wellSelected) {
        const filter: any = this.wbService.getCdfDocsFilter(['externalId'], [this.wellSelected]);

        this.apiService.getInstance(this.cdfDef.id, filter.filter, this.cdfDef.version, this.cdfDef.space).subscribe({
          next: (data: NodeAndEdgeCollectionResponseV3Response) => {
            let properties: PropertyValueGroupV3 | null = null;

            if (this.cdfDef)
              properties = data?.items?.[0]?.properties?.[this.cdfDef.space]?.[this.cdfDef.fullName] ?? null;

            res(properties);
          },
          error: err => {
            console.error(err);
            res(null);
          },
        });
      } else {
        res(null);
      }
    });
  }

  private loadOfmData(): Promise<PropertyValueGroupV3 | null> {
    return new Promise(res => {
      if (this.wellSelected) {
        const view = environment.spaces.wellbook.views.ofm;

        const cdfSpaceView: CdfSpaceView = {
          space: environment.spaces.wellbook.id,
          id: view.id,
          version: view.version,
          fullName: `${view.id}/${view.version}`,
        };

        const filter: any = this.wbService.getCdfFilter(cdfSpaceView, 'well', ['node', 'externalId'], [this.wellSelected]);

        this.apiService.getInstancelist(view.id, filter, view.version, environment.spaces.wellbook.id).subscribe({
          next: (data: NodeAndEdgeCollectionResponseV3Response) => {
            const properties = data?.items?.[0]?.properties?.[cdfSpaceView.space]?.[cdfSpaceView.fullName] ?? null;
            res(properties);
          },
          error: err => {
            console.error(err);
            res(null);
          },
        });
      } else {
        res(null);
      }
    });
  }

  private loadWellData(): Promise<PropertyValueGroupV3 | null> {
    return new Promise(res => {
      if (this.wellSelected) {
        const filter: any = this.wbService.getCdfDocsFilter(['externalId'], [this.wellSelected]);
        const view = environment.spaces.workflow.views.well;

        this.apiService.getInstance(view.id, filter.filter, view.version, environment.spaces.workflow.id).subscribe({
          next: (data: NodeAndEdgeCollectionResponseV3Response) => {
            const properties = data?.items?.[0]?.properties?.[environment.spaces.workflow.id]?.[`${view.id}/${view.version}`] ?? null;
            res(properties);
          },
          error: err => {
            console.error(err);
            res(null);
          },
        });
      } else {
        res(null);
      }
    });
  }

  private loadLatestTimeseriesValue(externalId: string): Promise<{ [propName: string]: any } | null> {
    return new Promise(res => {
      if (externalId?.trim() !== '') {
        let value: { [propName: string]: any } | null = null;

        this.apiService.getLatestTimeseriesData([{ externalId: externalId }]).then((data: any) => {
          value = data?.[0]?.datapoints?.[0] ?? null;
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          res(value);
        });
      } else {
        res(null);
      }
    });
  }

  private processData(wellbookData: PropertyValueGroupV3 | null, ofmData: PropertyValueGroupV3 | null, wellType: string | null, liftType: string | null, liftTypeInitialDate: number | null) {
    this.summaryData = {
      wellDrilledOnDate: wellbookData?.wellDrilledOnDate?.toString() ?? '-',
      wellDrilledOnDateRemarks: wellbookData?.wellDrilledOnDateRemarks?.toString() ?? '-',
      wellDrilledOnDateSource: wellbookData?.wellDrilledOnDateSource?.toString() ?? '-',
      wellCompletedOnDate: wellbookData?.wellCompletedOnDate?.toString() ?? '-',
      wellCompletedOnDateRemarks: wellbookData?.wellCompletedOnDateRemarks?.toString() ?? '-',
      wellCompletedOnDateSource: wellbookData?.wellCompletedOnDateSource?.toString() ?? '-',
      startProductionDate: ofmData?.startProductionDate?.toString() ?? '-',
      wellType: wellType ?? '-',
      liftType: liftType ?? '-',
      liftTypeInitialDate: liftTypeInitialDate ? moment(liftTypeInitialDate).format('YYYY-MM-DD') : '-',
    };

    this.wellBookData = wellbookData;
  }
  extractPrefix(input: any) {
    const parts = input.split('-');
    return `${parts[0]}-${parts[1]}`;
  }

  private async getLiftTypeDate(liftType: string | null) {
    let liftTypeChange = this.extractPrefix(this.wellSelected) + "_LiftTypeChange";
    const lastPoint = await this.loadLatestTimeseriesValue(liftTypeChange ?? '').finally();
    if (!lastPoint)
      return null;
    let liftTypeDate: number | null = Number(lastPoint?.timestamp) ?? null;
    return liftTypeDate;
  }

}
