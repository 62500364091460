import { Component } from '@angular/core';
import { TabOptionsEnum, LiftTabOptionsEnum } from '../model';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-well-monitoring',
  templateUrl: './wellmonitoring.component.html',
  styleUrls: ['./wellmonitoring.component.css']
})
export class WellMonitoringComponent {
  public tabOptions = TabOptionsEnum;
  public liftTypetabOptions = LiftTabOptionsEnum;
  public Liftselectedtab = this.liftTypetabOptions.ESP;
  public selectedtab = this.tabOptions.OVR;
  public ESPSummary_report_link: SafeResourceUrl;
  public HRPSummary_report_link: SafeResourceUrl;
  public InjectorSummary_report_link: SafeResourceUrl;
  public JPSummary_report_link: SafeResourceUrl;
  public NFSummary_report_link: SafeResourceUrl;
  public PCPSummary_report_link: SafeResourceUrl;


  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.Liftselectedtab = this.liftTypetabOptions.ESP;
    this.ESPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.ESP);
  }

  onTabChange($event: any) {
    this.selectedtab = $event.value;
    if(this.selectedtab == this.tabOptions.OVR)
      this.Liftselectedtab = this.liftTypetabOptions.ESP;
  }
  onChange($event: any) {
    this.Liftselectedtab = $event.value;
    switch (this.Liftselectedtab) {
      case this.liftTypetabOptions.ESP:
        this.ESPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.ESP);
        break;
      case this.liftTypetabOptions.HRP:
        this.HRPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.HRP);
        break;
      case this.liftTypetabOptions.Injector:
        this.InjectorSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.Injector);
        break;
      case this.liftTypetabOptions.JP:
        this.JPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.JP);
        break;
      case this.liftTypetabOptions.NF:
        this.NFSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.NF);
        break;
      case this.liftTypetabOptions.PCP:
        this.PCPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellmonitoring.PCP);
        break;
    }
  }
}