import { TabOptionsEnum, LiftTabOptionsEnum } from '../../model';
import { Component, ViewChild } from '@angular/core';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { wellModel } from 'src/app/alsModule/model';
import moment from 'moment';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { ActivatedRoute } from '@angular/router';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { PowerBiService } from 'src/app/services/powerbi.service';


@Component({
  selector: 'app-wellmonitoringnew',
  templateUrl: './wellmonitoringnew.component.html',
  styleUrls: ['./wellmonitoringnew.component.css']
})
export class WellmonitoringnewComponent {
  public tabOptions = TabOptionsEnum;
  public liftTypetabOptions = LiftTabOptionsEnum;
  public Liftselectedtab = this.liftTypetabOptions.ESP;
  public selectedtab = this.tabOptions.OVR;
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public filterStartDate: any;
  public filterEndDate: any;
  public ChartEnddate: any;
  public hide: boolean;
  public loading: boolean = false;
  public selectedField: string = "";
  private subs: Subscription[] = [];
  public alarmgridData: wellModel[] = [];
  public rawgridData: wellModel[] = [];
  public WellListToloadGrid: any[] = [];
  public filterWellList: any;
  public selectedWell: string = "";
  public selectedWellPad: string = "";
  public iframeurl: SafeResourceUrl;
  public propertyConditions: any;
  public pressureConditions: any;
  public injectorConditions: any;
  public comparisonConditions: any;
  public surfacedpConditions: any;
  public rndpConditions: any;
  public flpConditions: any;
  public Lifttype: string | undefined;
  public retainWellName: boolean = false;
  ESPSummary_report_link: any;
  AlarmDefination: any[] = []
  viewID: string = "AlsWellAlarms";
  maxvalidity: number;
  alarmeventType: string = "PCP_alarms";
  public hight: number;
  pumpparametergranuality = '10m';

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
    { field: 'End Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
    private loader: LoaderService,
    private timeseriesHelper: TimeseriesHelper,
    private powerBiService: PowerBiService
  ) {
    this.propertyConditions = {
      'ESP': { 'motorFrequency': true, 'motorCurrent': true, 'MWT': true, 'PDP': true, 'PIP': true, 'SIBHP': true },
      'PCP': { 'Current': true, 'operatingFrequency': true, 'RPM': true, 'Torque': true, 'PIP': true, 'SIBHP': true, 'pumpDischargePressure': true },//check PIP name
      'JP': { 'A Pressure': true, 'powerFluidRate': true, 'THT': true, 'THP': true },
      'HRP': { 'SPM': true, 'FAT': true, 'AP': true, 'THP': true },
    };
    this.comparisonConditions = {
      'PCP': { 'PIP - Calculated from ML': true, 'PIP - Calculated from Curve': true, 'productivityIndex': true ,'PIP': true }
    };
    this.surfacedpConditions = {
      'ESP': { 'surfaceDp': true },
      'PCP': { 'surfaceDp': true },
      'HRP': { 'surfaceDp': true }
    };
    this.rndpConditions = {
      'ESP': { 'RNDP': true },
    };
    this.flpConditions = {
      'NATURAL': { 'flowlinePressure': true },
    };
    this.pressureConditions = { 'wellheadTemperature': true, 'wellheadPressure': true, 'calculatedFbhp': true };
    this.injectorConditions = { 'polymerInjectionRate': true, 'waterInjectionRate': true, 'injectivityIndex': true, 'pseudoInjectivityIndex': true };
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const wellName = params['wellName'];
      const wellType = params?.['wellType'];
      const liftType = params['liftType']; // Get the lift type from query params 
      if (!!wellType){
        this.hierarchyService.setWell(wellName);
        this.retainWellName = true;
        this.onChange({ value: this.liftTypetabOptions.Injector });
      } else if (!!liftType) {
        this.hierarchyService.setWell(wellName);
        this.retainWellName = true;
        this.onChange({ value: liftType });
      } else {
        this.Liftselectedtab = this.liftTypetabOptions.ESP;
      }
    })

    this.hide = false;
    this.loadIframe();
  }

  onTabChange($event: any) {
    this.selectedtab = $event.value;
    this.selectedWell = "";
    if (this.selectedtab == this.tabOptions.OVR)
      this.Liftselectedtab = this.liftTypetabOptions.ESP;
  }

  onChange($event: any) {
    this.Liftselectedtab = $event.value;
    this.hide = this.Liftselectedtab == this.liftTypetabOptions.NF || this.Liftselectedtab == this.liftTypetabOptions.Injector;
    //if (this.selectedField != undefined && this.selectedField != "")
    this.hierarchyComponent.clear(false);
    //this.hierarchyComponent.loadAllWells();
    this.loadIframe();
    //this.loadGridData();
    this.clear();
  }
  onDateSelected(event: any) {
    this.filterStartDate = moment(event.event.startDate).toDate();
    this.filterEndDate = moment(event.event.endDate).add(1, 'days').toDate();
    this.ChartEnddate = moment(event.event.endDate).toDate();

    // 1
    if (event.event.startDate != null && event.event.endDate != null) {
      let datediff = moment(event.event.endDate).diff(moment(event.event.startDate), 'days');
      this.pumpparametergranuality = datediff > 1 ? "10m" : "1m"
      this.WellListToloadGrid = event.allFieldwells;
      // this.loadIframe();
      // this.loadGridData();
    }
  }
  refresh() {
    this.loadGridData();
  }
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.WellListToloadGrid = event.allFieldwells;
    this.loadGridData();
    //this.loadIframe();
  }

  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
    this.loadGridData();
    //this.loadIframe();
  }

  onFilterWellChange(event: any) {
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;
    if (this.filterWellList == undefined)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadGridData();
    this.loadIframe();
  }

  loadIframe() {
    let startdate = moment(this.filterStartDate).format("YYYY-MM-DD");
    let enddate = moment(this.ChartEnddate).format("YYYY-MM-DD");
    let url = ""
    switch (this.Liftselectedtab) {
      case this.liftTypetabOptions.ESP:
        url = PowerBIReportLink.well_monitoring.ESP;
        this.hight = 2420;
        break;
      case this.liftTypetabOptions.JP:
        url = PowerBIReportLink.well_monitoring.JP;
        this.hight = 1800;
        break;
      case this.liftTypetabOptions.PCP:
        url = PowerBIReportLink.well_monitoring.PCP;
        this.hight = 2400;
        break;
      case this.liftTypetabOptions.HRP:
        url = PowerBIReportLink.well_monitoring.HRP;
        this.hight = 1400;
        break;
      case this.liftTypetabOptions.NF:
        url = PowerBIReportLink.well_monitoring.NF;
        this.hight = 1400;
        break;
      case this.liftTypetabOptions.Injector:
        url = PowerBIReportLink.well_monitoring.Injector;
        this.hight = 1800;
        break;
    }
    if (this.selectedWell == "" || this.selectedWell == undefined)
      this.ESPSummary_report_link = url
    else
      //this.ESPSummary_report_link = url + "&$filter=FlatHierarchy/wellName eq '" + this.selectedWell + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
      this.ESPSummary_report_link = url + "&$filter=FlatHierarchy/fieldName eq '" + this.selectedField + "' and FlatHierarchy/wellPadName eq '" + this.selectedWellPad + "' and FlatHierarchy/wellName eq '" + this.selectedWell + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
      
      this.powerBiService.setReportLinkData(this.ESPSummary_report_link,this.sanitizer.bypassSecurityTrustResourceUrl(this.ESPSummary_report_link))
  }

  loadGridData() {
    this.alarmgridData = [];
    this.rawgridData = [];
    let version = "1_2";
    let viewid = this.viewID;
    let space = environment.cogniteSpace;
    let filter: any = undefined;
    if ((this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) && this.filterStartDate != undefined && this.filterStartDate != null && this.filterEndDate != undefined && this.filterEndDate != null) {
      let selectedWell = this.WellListToloadGrid.map((a: any) => a.externalId);
      if (selectedWell.length === 1) {
        this.selectedWell = selectedWell[0];
        if (this.WellListToloadGrid[0].LiftType == undefined && this.WellListToloadGrid[0].WellType.includes('injector')) { this.Lifttype = this.WellListToloadGrid[0].WellType } else { this.Lifttype = this.WellListToloadGrid[0].LiftType; }
      } else { this.selectedWell = '' };
      filter = {
        and: [{
          "nested": {
            "scope": [environment.cogniteSpace, 'AlsWellAlarms/1_2', "well"],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": selectedWell
              }
            }
          }
        }, {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "startDateTime"],
                gt: moment(this.filterStartDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "endDateTime"],
                lte: moment(this.filterEndDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }
        ]
      }
      this.loading = true;
      this.loader.showLoader();
      this.subs.push(this.apiService.getInstancelistWithcursorData(viewid, filter, version).subscribe(data => {
        this.loader.hideLoader();
        let rawData = data['items'];
        if (rawData.length > 0) {
          rawData.forEach((item: any) => {
            let property = item.properties;
            let record = property[space][viewid + "/" + version];
            if (record['well'] != undefined) {
              let starttime = moment(record['startDateTime']).format("DD-MM-YYYY HH:mm");
              let endtime = moment(record['endDateTime']).format("DD-MM-YYYY HH:mm");
              let dataRow = {
                well: record['well']['externalId'],
                workflowName: record['workflowName'],
                startDateTime: starttime,
                endDateTime: endtime,
                alarmName: record['alarmName'],
                alarmFlags: record['alarmFlags'] == undefined ? '-' : record['alarmFlags'],
                alarmMessage: record['alarmMessage'] == 'null' ? '-' : record['alarmMessage'],
                alarmType: record['alarmType'],
                alarmLevel: record['alarmLevel'],
                driftWindow: '-',
                maxDeviation: '-',
                allowedDeviation: '-',
                startdatetimestamp: Date.parse(record['startDateTime'])
              };
              this.rawgridData.push(dataRow);
            }

          });
        }
        if (this.liftTypetabOptions.PCP == this.Liftselectedtab) {
          this.loader.showLoader();
          this.timeseriesHelper.getAlsEventAlarm(this.filterStartDate, this.filterEndDate, this.alarmeventType, selectedWell).then(data => {
            this.loader.hideLoader();
            this.rawgridData = this.rawgridData.concat(data);
            //this.alarmgridData = this.timeseriesHelper.sortGridData(this.rawgridData)
            this.alarmgridData = addSortableDates(this.rawgridData, this.kendoGrid);
          })
        }
        else {
          //this.rawgridData = this.timeseriesHelper.sortGridData(this.rawgridData)
          this.alarmgridData = addSortableDates(this.rawgridData, this.kendoGrid);
        }
      }));

    } else {
      this.alarmgridData = [];
      this.loading = false;
    }

  }
  clear() {
    this.alarmgridData = [];
    this.rawgridData = [];
    this.selectedField = "";
    this.selectedWell = "";
    this.WellListToloadGrid = [];
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
