<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.ESP">ESP</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.JP">JP</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [retainHierarchy]="true"
    [showDateRange]="true" [timerangeDifference]="7" [showClearButton]="true"
    (onWellPadChange)="onfilterwellPadChange($event)" [LiftTypeToloadWells]="selectedtab"
    [getListofAllWellsUnderField]="true" (onWellchange)="onFilterWellChange($event)" (onclear)="clear()"
    (ondatechange)="onDateSelected($event)" [showErrorFromComponentToHierarchyMessage]="showErrorFromComponentToHierarchyMessage"
    [errorMessage]="errorToHierarchyMessage"></app-hierarchy>
</div>
<ng-container>
  <div class="page-content">
    <div *ngIf="(loading == true)" style="display: flex;justify-content: center;align-items: center;">
      <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
    </div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Data For Calibration</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true"
          [pageSize]="10" [filterable]="true" [sortable]="true">
          <ng-template kendoGridToolbarTemplate>
            <div
              style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button"
                class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid"
                [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column title="Action" [width]="110">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button slb-button [disabled]="dataItem.disable" (click)="onCalibration(dataItem);">Calibrate</button>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Status" field="status" [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a [ngClass]="{'hyperlink':dataItem.status === 'Failed' || dataItem.status === 'CompletedWithError' || dataItem.status === 'Processed' || dataItem.status ==='Completed'}" (click)="OpenAlarmPage(dataItem)">
                {{ dataItem.status }} 
              </a>
              <!-- <span *ngIf="dataItem.logtext !== '' &&  (dataItem.status != 'Queued' && dataItem.status != 'Request Initiated') ">
                <a slb-icon-button routerLink="." title="log" (click)="openLog(dataItem);">
                  <mat-icon [ngStyle]="{'color':'#42f560'}" svgIcon="info"></mat-icon>
                </a>
              </span> -->
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column-group title="Well Test Data">
            <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="date_ts" title="Start Date" [width]="130">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.date }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="date">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="liquidrate" title="Liquid Rate (bbl/day)" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="gor" title="GOR (scf/stb)" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="watercut" title="Water Cut (%)" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="thp"
              title="{{ !!(this.unitobject['thp']) ? 'THP ('+(this.unitobject['thp'])+')' : 'THP (psia)'}}"
              [width]="100"></kendo-grid-column> <kendo-grid-column field="tht"
              title="{{ !!(this.unitobject['tht']) ? 'THT ('+(this.unitobject['tht'])+')' : 'THT (°C)'}}"
              [width]="100"></kendo-grid-column>
            <kendo-grid-column field="frequency"
              title="{{ !!(this.unitobject['frequency']) ? 'PMM Frequency ('+(this.unitobject['frequency'])+')' : 'PMM Frequency (Hz)'}}"
              [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pip"
              title="{{ !!(this.unitobject['pip']) ? 'PIP ('+(this.unitobject['pip'])+')' : 'PIP (psia)'}}"
              [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pdp"
              title="{{ !!(this.unitobject['pdp']) ? 'PDP ('+(this.unitobject['PDP'])+')' : 'PDP (psia)'}}"
              [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>

            <kendo-grid-column field="annuluspressure"
              title="{{ !!(this.unitobject['annuluspressure']) ? 'Annulus Pressure ('+(this.unitobject['annuluspressure'])+')' : 'Annulus Pressure (psia)'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pfrate"
              title="{{ !!(this.unitobject['pfrate']) ? 'PF Rate ('+(this.unitobject['pfrate'])+')' : 'PF Rate'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Calibration Input">
            <kendo-grid-column field="rpressurelow"
              title="{{ !!(this.unitobject['rpressurelow']) ? 'Reservoir Pressure Low ('+(this.unitobject['rpressurelow'])+')' : 'Reservoir Pressure Low (psia)'}}"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="rpressurehigh"
              title="{{ !!(this.unitobject['rpressurehigh']) ? 'Reservoir Pressure High ('+(this.unitobject['rpressurehigh'])+')' : 'Reservoir Pressure High (psia)'}}"
              [width]="120"></kendo-grid-column>
            <kendo-grid-column field="rpressurestepsize"
              title="{{ !!(this.unitobject['rpressurestepsize']) ? 'Step Size Count ('+(this.unitobject['rpressurestepsize'])+')' : 'Step Size Count'}}"
              [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="rpressurestepsize"
              title="{{ !!(this.unitobject['rpressurestepsize']) ? 'Range Divison Count' : 'Range Divison Count'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pindexlow"
              title="{{ !!(this.unitobject['pindexlow']) ? 'Productivity index low ('+(this.unitobject['pindexlow'])+')' : 'Productivity index low'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pindexhigh"
              title="{{ !!(this.unitobject['pindexhigh']) ? 'Productivity index High ('+(this.unitobject['pindexhigh'])+')' : 'Productivity index High'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pindexstepsize"
              title="{{ !!(this.unitobject['pindexstepsize']) ? 'Range Divison Count' : 'Range Divison Count'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="140"></kendo-grid-column>
            <kendo-grid-column field="knlow"
              title="{{ !!(this.unitobject['knlow']) ? 'knLow ('+(this.unitobject['knlow'])+')' : 'knLow'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="knhigh"
              title="{{ !!(this.unitobject['knhigh']) ? 'KnHigh ('+(this.unitobject['knhigh'])+')' : 'KnHigh'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="knstepsize"
              title="{{ !!(this.unitobject['knstepsize']) ? 'Step Size Count ('+(this.unitobject['knstepsize'])+')' : 'Step Size Count'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="kslow"
              title="{{ !!(this.unitobject['kslow']) ? 'ksLow ('+(this.unitobject['kslow'])+')' : 'ksLow'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="kshigh"
              title="{{ !!(this.unitobject['kshigh']) ? 'KsHigh ('+(this.unitobject['kshigh'])+')' : 'KsHigh'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="ksstepsize"
              title="{{ !!(this.unitobject['ksstepsize']) ? 'Step Size Count ('+(this.unitobject['ksstepsize'])+')' : 'Step Size Count'}}"
              [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-excel fileName="Data For Calibration.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Data For Calibration.pdf" [allPages]="true">
            <kendo-grid-column title="Status" field="status" [width]="100"></kendo-grid-column>
            <kendo-grid-column-group title="Well Test Data">
              <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="date" title="Start Date" [width]="130"></kendo-grid-column>
              <kendo-grid-column field="liquidrate" title="Liquid Rate (bbl/day)" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="gor" title="GOR (scf/stb)" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="watercut" title="Water Cut (%)" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="thp"
                title="{{ !!(this.unitobject['thp']) ? 'THP ('+(this.unitobject['thp'])+')' : 'THP (psia)'}}"
                [width]="100"></kendo-grid-column> <kendo-grid-column field="tht"
                title="{{ !!(this.unitobject['tht']) ? 'THT ('+(this.unitobject['tht'])+')' : 'THT (°C)'}}"
                [width]="100"></kendo-grid-column>
              <kendo-grid-column field="frequency"
                title="{{ !!(this.unitobject['frequency']) ? 'Frequency ('+(this.unitobject['frequency'])+')' : 'Frequency (Hz)'}}"
                [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pip"
                title="{{ !!(this.unitobject['pip']) ? 'PIP ('+(this.unitobject['pip'])+')' : 'PIP (psia)'}}"
                [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pdp"
                title="{{ !!(this.unitobject['pdp']) ? 'PDP ('+(this.unitobject['PDP'])+')' : 'PDP (psia)'}}"
                [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="annuluspressure"
                title="{{ !!(this.unitobject['annuluspressure']) ? 'Annulus Pressure ('+(this.unitobject['annuluspressure'])+')' : 'Annulus Pressure (psia)'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pfrate"
                title="{{ !!(this.unitobject['pfrate']) ? 'PF Rate ('+(this.unitobject['pfrate'])+')' : 'PF Rate'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="Calibration Input">
              <kendo-grid-column field="rpressurelow"
                title="{{ !!(this.unitobject['rpressurelow']) ? 'Reservoir Pressure Low ('+(this.unitobject['rpressurelow'])+')' : 'Reservoir Pressure Low (psia)'}}"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="rpressurehigh"
                title="{{ !!(this.unitobject['rpressurehigh']) ? 'Reservoir Pressure High ('+(this.unitobject['rpressurehigh'])+')' : 'Reservoir Pressure High (psia)'}}"
                [width]="120"></kendo-grid-column>
              <kendo-grid-column field="rpressurestepsize"
                title="{{ !!(this.unitobject['rpressurestepsize']) ? 'Step Size Count ('+(this.unitobject['rpressurestepsize'])+')' : 'Step Size Count'}}"
                [hidden]="this.selectedtab === 'JP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="rpressurestepsize"
                title="{{ !!(this.unitobject['rpressurestepsize']) ? 'Range Divison Count' : 'Range Divison Count'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pindexlow"
                title="{{ !!(this.unitobject['pindexlow']) ? 'Productivity index low ('+(this.unitobject['pindexlow'])+')' : 'Productivity index low'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pindexhigh"
                title="{{ !!(this.unitobject['pindexhigh']) ? 'Productivity index High ('+(this.unitobject['pindexhigh'])+')' : 'Productivity index High'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="pindexstepsize"
                title="{{ !!(this.unitobject['pindexstepsize']) ? 'Range Divison Count' : 'Range Divison Count'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="140"></kendo-grid-column>
              <kendo-grid-column field="knlow"
                title="{{ !!(this.unitobject['knlow']) ? 'knLow ('+(this.unitobject['knlow'])+')' : 'knLow'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="knhigh"
                title="{{ !!(this.unitobject['knhigh']) ? 'KnHigh ('+(this.unitobject['knhigh'])+')' : 'KnHigh'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="knstepsize"
                title="{{ !!(this.unitobject['knstepsize']) ? 'Step Size Count ('+(this.unitobject['knstepsize'])+')' : 'Step Size Count'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="kslow"
                title="{{ !!(this.unitobject['kslow']) ? 'ksLow ('+(this.unitobject['kslow'])+')' : 'ksLow'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="kshigh"
                title="{{ !!(this.unitobject['kshigh']) ? 'KsHigh ('+(this.unitobject['kshigh'])+')' : 'KsHigh'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
              <kendo-grid-column field="ksstepsize"
                title="{{ !!(this.unitobject['ksstepsize']) ? 'Step Size Count ('+(this.unitobject['ksstepsize'])+')' : 'Step Size Count'}}"
                [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
            </kendo-grid-column-group>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>