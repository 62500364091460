import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { SlbNavigationFrameworkModule } from '@slb-dls/angular-material/navigation-framework';
import { SlbSharedModule } from '@slb-dls/angular-material/shared';
import { SlbNotificationModule, MessageService } from '@slb-dls/angular-material/notification';
import { SlbNotificationsPanelModule } from '@slb-dls/angular-material/notifications-panel';
import { SlbLogoutModule } from '@slb-dls/angular-material/logout';
import { SlbPopoverModule } from '@slb-dls/angular-material/popover';
import { SlbButtonModule } from '@slb-dls/angular-material/button';
import { SlbBreadcrumbsModule } from '@slb-dls/angular-material/breadcrumbs';
import { SlbFormFieldModule } from '@slb-dls/angular-material/form-field';
import { SlbDatePickerRangeModule } from '@slb-dls/angular-material/date-range-picker';
import { SlbDateTimePickerModule } from '@slb-dls/angular-material/date-time-picker';
import {SlbModalModule} from '@slb-dls/angular-material/modal';
import { SlbDatePickerModule } from '@slb-dls/angular-material/date-picker';
import { SlbCardGroupModule } from '@slb-dls/angular-material/card-group';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AlsMonitoringComponent } from './wellsSurveillance/als-monitoring/als-monitoring.component';
import { ProductionOverviewComponent } from './wellsSurveillance/production-overview/production-overview.component';
import { VirtualMeteringComponent } from './wellsSurveillance/virtual-metering/virtualmetering.component';
import { ComparisonComponent } from './wellsSurveillance/virtual-metering/comparison/comparison.component';
import { AlstoleranceComponent } from './adminModule/alstolerance/alstolerance.component';
import { AlsalarmComponent } from './alsModule/alsalarm/alsalarm.component';
import { DriftalarmComponent } from './alsModule/driftalarm/driftalarm.component';
import { OutputComponent } from './calibrationModule/output/output.component';
import { TriggerComponent } from './calibrationModule/trigger/trigger.component';
import { CalibrationSummaryComponent } from './calibrationModule/summary/summary.component';
import { CalibrationalarmComponent } from './calibrationModule/calibrationalarm/calibrationalarm.component';
import { WellMonitoringComponent } from './wellsSurveillance/well-monitoring/wellmonitoring.component';
import { SummaryComponent } from './wellsSurveillance/well-monitoring/summary/summary.component';
import { SummaryPumpchartComponent } from './wellsSurveillance/well-monitoring/summary-pump-chart/summary-pump-chart.component';
import { ExcelModule, GridModule, PDFModule, SharedModule } from '@progress/kendo-angular-grid';
import { ConfirmationDialog } from './common-component/confirmation-dialog/confirmation-dialog.component';
import { InformationDialog } from './common-component/information-dialog/information-dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';

import { AppLoginComponent } from './app-login/app-login.component';
import { MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
import { MSALInstanceFactory, MSALInterceptorConfigFactory } from './utils/msal.factory';
import { CognitApiService } from './services/cognit-api.service';
import { CognitDataFormatter } from './services/cognit-data-formatter';

import { CogniteAuthService } from './services/auth.service';
import { SpinnerComponent } from './common-component/spinner/spinner.component';
import { EventAlarmComponent } from './common-component/event-alarm/event-alarm.component';
import { EventConcentrationAlarmComponent } from './common-component/event-concentration-alarm/event-concentration-alarm.component';
import { EventViscosityAlarmComponent } from './common-component/event-viscosity-alarm/event-viscosity-alarm.component';
import { ThresholdComponent } from './adminModule/threshold/threshold.component';
import { PumpcurvvariablesComponent } from './adminModule/pumpcurvvariables/pumpcurvvariables.component';
import { InputComponent } from './calibrationModule/input/input.component';
import { AlswelltoleranceComponent } from './adminModule/alswelltolerance/alswelltolerance.component';
import { LossaccountingComponent } from './loss-accounting/lossaccounting/lossaccounting.component';
//import { HierarchyComponent } from './common-component/hierarchy/hierarchy.component';
import { ResultComponent } from './loss-accounting/result/result.component';
import { ResultDistributionchartComponent } from './loss-accounting/result-distributionchart/result-distributionchart.component';
import { WellvariablesComponent } from './adminModule/pumpcurvvariables/wellvariables/wellvariables.component';
import { ConcentrationviscosityMonitoringComponent } from './adminModule/concviscosity-monitoring/concviscosity-monitoring.component';
//import { SearchDropdownComponent } from './common-component/search-dropdown/search-dropdown.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ShareModule } from './shared/share/share.module';
import { HfalarmComponent } from './alsModule/hfalarm/hfalarm.component';
import { AutoRefreshComponent } from './common-component/auto-refresh/auto-refresh.component';
import { HfchartComponent } from './alsModule/hfchart/hfchart.component';
import { AlsmonitoringComponent } from './wellsSurveillance/alsmonitoring/alsmonitoring.component';
import { WellmonitoringnewComponent } from './wellsSurveillance/well-monitoring/wellmonitoringnew/wellmonitoringnew.component';
import { IFrameComponent } from './shared/i-frame/i-frame.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { LossvolumechartComponent } from './loss-accounting/lossvolumechart/lossvolumechart.component';
import { PumpCurveChartComponent } from './wellsSurveillance/well-monitoring/wellmonitoringnew/pump-curve-chart/pump-curve-chart.component';
import { NumberPipe } from './pipes/number.pipe';
import { LogDataComponent } from './wellbook/log-data/log-data.component';
import { WellEventsComponent } from './wellbook/well-events/well-events.component';
import { PDFViewerModule } from '@progress/kendo-angular-pdfviewer';
import { CompletionSchematicComponent } from './wellbook/completion-schematic/completion-schematic.component';
import { ImageViewerComponent } from './common-component/image-viewer/image-viewer.component';
import { DeviationSummaryComponent } from './wellbook/deviation-summary/deviation-summary.component';
import { LasViewerComponent } from './shared/las-viewer/las-viewer.component';
import { WellbookSummaryComponent } from './wellbook/wellbook-summary/wellbook-summary.component';
import { WellDetailsComponent } from './wellbook/wellbook-summary/well-details/well-details.component';
import { WellDataComponent } from './wellbook/wellbook-summary/well-data/well-data.component';
import { ProductionDataComponent } from './wellbook/wellbook-summary/production-data/production-data.component';
import { EventViewerComponent } from './wellbook/event-viewer/event-viewer.component';
import { PressurechartComponent } from './common-component/pressure-chart/pressure-chart.component';
import { DeviationCalculateComponent } from './wellbook/deviation-summary/calculate/deviation-calculate.component';
import { DynaCardComponent } from './wellbook/dyna-card/dyna-card.component';
import { MapViewComponent } from './wellbook/map-view/map-view.component';
import { AutoFitPdfDirective } from './directives/auto-fit-pdf.directive';
import { MatSliderModule } from '@angular/material/slider';
import { FileSearchComponent } from './wellbook/file-search/file-search.component';
import { TagCatalogueComponent } from './wellbook/tag-catalogue/tag-catalogue.component';
import { AuthInterceptor } from './services/authinterceptor.service';
import { ErrorInterceptor } from './services/ErrorInterceptor.service';
import { InjectionDataComponent } from './wellbook/wellbook-summary/injection-data/injection-data.component';
import { VfmalarmComponent } from './wellsSurveillance/virtual-metering/vfmalarms/vfmalarm.component';

var cors = require('cors');
const CUSTOME_DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MM-YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD-MM-YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlsMonitoringComponent,
    ProductionOverviewComponent,
    VirtualMeteringComponent,
    ComparisonComponent,
    VfmalarmComponent,
    WellMonitoringComponent,
    SummaryComponent,
    SummaryPumpchartComponent,
    PressurechartComponent,
    AlstoleranceComponent,
    AlsalarmComponent,
    DriftalarmComponent,
    OutputComponent,
    TriggerComponent,
    CalibrationSummaryComponent,
    CalibrationalarmComponent,
    ConfirmationDialog,
    InformationDialog,
    AppLoginComponent,
    SpinnerComponent,
    EventAlarmComponent,
    EventConcentrationAlarmComponent,
    EventViscosityAlarmComponent,
    ThresholdComponent,
    PumpcurvvariablesComponent,
    InputComponent,
    AlswelltoleranceComponent,
    LossaccountingComponent,
    //HierarchyComponent,
    ResultComponent,
    ResultDistributionchartComponent,
    WellvariablesComponent,
    ConcentrationviscosityMonitoringComponent,
    //SearchDropdownComponent,
    HfalarmComponent,
    AutoRefreshComponent,
    HfchartComponent,
    AlsmonitoringComponent,
    WellmonitoringnewComponent,
    LogDataComponent,
    WellEventsComponent,
    CompletionSchematicComponent,
    ImageViewerComponent,
    DeviationSummaryComponent,
    LasViewerComponent,
    IFrameComponent,
    LossvolumechartComponent,
    PumpCurveChartComponent,
    NumberPipe,
    WellbookSummaryComponent,
    WellDetailsComponent,
    WellDataComponent,
    ProductionDataComponent,
    InjectionDataComponent,
    EventViewerComponent,
    DeviationCalculateComponent,
    DynaCardComponent,
    MapViewComponent,
    AutoFitPdfDirective,
    FileSearchComponent,
    TagCatalogueComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    SlbSharedModule,
    SlbButtonModule,
    SlbFormFieldModule,
    SlbPopoverModule,
    SlbNotificationModule,
    SlbNotificationsPanelModule,
    SlbNavigationFrameworkModule,
    SlbBreadcrumbsModule,
    SlbLogoutModule,
    SlbDatePickerRangeModule,
    SlbModalModule,
    GridModule,
    ExcelModule,
    PDFModule,
    SlbDatePickerModule,
    SlbDateTimePickerModule,
    SlbCardGroupModule,

    HighchartsChartModule,
    DropDownsModule,
    ShareModule,
    PDFViewerModule,
    MatSliderModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOME_DATE_FORMAT },
    { provide: MessageService, useClass: MessageService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useFactory: MSALGuardConfigFactory
    // },
     {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    CognitApiService,
    CogniteAuthService,
    CognitDataFormatter,
    NumberPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
