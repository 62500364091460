<div class="form-container">
  <form [formGroup]='formgroup'>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>ElevationUnit</mat-label>
      <input type="text" matInput formControlName="eunit">
    </mat-form-field>
    <div class="space-div"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Elevation</mat-label>
      <input type="text" matInput formControlName="elevation">
    </mat-form-field>
    <div class="line-break"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Convert Depth from</mat-label>
      <mat-select #input [panelClass]="'dls-panel'" formControlName='coverter' placeholder="Select" [hideSingleSelectionIndicator]="true" (selectionChange)="clearData()">
        <mat-option [value]="'MD to TVDRT/TVDSS'">MD to TVDRT/TVDSS</mat-option>
        <mat-option [value]="'TVDRT to MD'">TVDRT to MD</mat-option>
        <mat-option [value]="'TVDSS to MD'">TVDSS to MD</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="space-div"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Enter Depth</mat-label>
      <input type="number" step="any" matInput formControlName="depth" ><span></span>
    </mat-form-field>
    <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="onLocalCalculate()" style="margin-left: 10px; top: -2px;">Calculate</button>
    <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="onCalculate()" style="margin-left: 10px; top: -2px; display: none;">Calculate</button>
  </form>
</div>
<div *ngIf="errorMessage" class="error-message">
  {{ errorMessage }}
</div>
<div *ngIf="successMessage">
  <kendo-grid #kendoGrid [kendoGridBinding]="data" [resizable]="true" [pageable]="true" [pageSize]="10" kendoGridSelectBy="id" [filterable]="true" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card' }">
    <ng-template kendoGridToolbarTemplate>
      <div class="row">
        <div class="col-sm-12">
          <div style="display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
            <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [data]="data">
              <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
            </button>
            <button type="button" kendoGridPDFCommand>
              <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <kendo-grid-column field="md" title="MD"></kendo-grid-column>
    <kendo-grid-column field="tvdrt" title="TVDRT"></kendo-grid-column>
    <kendo-grid-column field="tvdss" title="TVDSS"></kendo-grid-column>
    <kendo-grid-excel fileName="Deviation Survey Calculate.xlsx"></kendo-grid-excel>
    <kendo-grid-pdf fileName="Deviation Survey Calculate.pdf" [allPages]="true"></kendo-grid-pdf>
  </kendo-grid>
</div>
<div *ngIf="loading;" style="display: flex; justify-content: center;align-items: center;">
  <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
</div>
