import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.css']
})
export class SearchDropdownComponent {
  @Input()
  optionList: any[] = [];
  @Input()
  placeholder: string = "Well";

  selectedvalue: string;
  searchTxt: any;
  @Input()
  value: string = '';

  //public AllBranchoption = [];
  public FilterData: any[] = [];
  public selectedbranchlist: any[] = [];
  @Output()
  onValueChange = new EventEmitter<any>();
  searchPlaceholder = "Seach"

  ngOnInit() {
    this.searchPlaceholder = "Search " + this.placeholder;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.optionList != undefined)
      this.FilterData = changes.optionList.currentValue;
    this.selectedvalue = this.value;
    if (this.value == '')
      this.searchTxt = ''
  }

  onSearchChange(event: any, matSelect: MatSelect): void {
    let searchtext = event.target.value;
    // if (searchtext.length == 0) {
    //   let selectAlloption = matSelect.options.filter((item: MatOption) => item.value === 0)[0]
    //   if (this.selectedbranchlist != this.AllBranchoption && selectAlloption.selected) {
    //     selectAlloption.deselect();
    //   }
    // }

    this.FilterData = this.optionList.filter((data: any) => JSON.stringify(data.Name).toLowerCase().indexOf(searchtext.toLowerCase()) !== -1);
  }

  onSelectionChange(event: any) {
    this.onValueChange.emit(event);
  }
}
