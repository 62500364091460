import { Pipe, PipeTransform } from '@angular/core';

export interface NumberFormatOpts {
  maxDecimals?: number;
  minDecimals?: number;
  useDelimiter?: boolean;
  delimiter?: string;
};

@Pipe({
  name: 'number',
})
export class NumberPipe implements PipeTransform {

  transform(value: any, options?: NumberFormatOpts): unknown {
    try {
      value = Number(value).toString();
      options = this.setOptions(options);

      const units: string = this.getUnits(value, options);
      const decimals: string = this.getDecimals(value, options);

      value = units + (decimals !== '' ? `.${decimals}` : '');
    } catch (e) {
      console.error('Invalid number transformation.', e);
    }

    return value;
  }

  private setOptions(options?: NumberFormatOpts): NumberFormatOpts {
    if (!options) options = {};
    if (!options.maxDecimals) options.maxDecimals = 2;
    if (!options.minDecimals || options.minDecimals < 0) options.minDecimals = 0;
    if (!options.useDelimiter) options.useDelimiter = true;
    if (!options.delimiter) options.delimiter = ',';

    return options;
  }

  private getDecimals(num: string, options?: NumberFormatOpts): string {
    let decimals: string = '';

    if (num.includes('.'))
      decimals = num.split('.')[1];

    const deicmalsCount = decimals.length;

    if (options?.minDecimals && options?.minDecimals > 0 && deicmalsCount < options?.minDecimals) {
      const missingDecimals = options?.minDecimals - deicmalsCount;
      decimals += [...Array(missingDecimals + 1)].join('0');
    }

    if (options?.maxDecimals && options?.maxDecimals < deicmalsCount)
      decimals = decimals.substring(0, options?.maxDecimals);

    return decimals;
  }

  private getUnits(num: string, options?: NumberFormatOpts): string {
    let units: string = '';

    if (num.includes('.')) {
      units = num.split('.')[0];
    } else {
      units = `${num}`;
    }

    if (options?.useDelimiter && options?.delimiter) {
      units = Number(units).toLocaleString();

      if (units.length > 3 && !units.includes(options?.delimiter))
        units = units.replace(units.charAt(units.length - 4), options?.delimiter);
    }

    return units;
  }

}
