import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { TabOptionsEnum, ESP, JP } from '../calibrationmodels';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription } from 'rxjs';
import { GroupDescriptor, GroupResult, SortDescriptor, State, groupBy, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import * as _ from 'lodash';
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CalibrationService } from '../calibration-service.service';
// import { Convert } from '@slb-psdc/uom_converter';


@Component({
  selector: 'app-calibration',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css']
})
export class OutputComponent {
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild('espKendoGrid') public espKendoGrid: GridComponent;
  @ViewChild('jpKendoGrid') public jpKendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab: any;
  ishierarchySelected: any;
  public functionId = 'cairn-workflow-calibration-postprocessing';
  private subs: Subscription[] = [];
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any = [];
  public selectedField: string;
  public selectedWell: string | undefined;
  public selectedWellPad: string | undefined;
  public espData: ESP[] = [];
  public espFilterData: ESP[] = [];
  public jpData: JP[] = [];
  public jpFilterData: JP[] = [];
  public espgridData: any = [];
  public jpgridData: any = [];
  public jpUnitobject: any = {};
  public unitobject: any = {};
  public wellCount: number = 0;
  public groups: GroupDescriptor[] = [{ field: 'wellnumber' }];
  public sort: SortDescriptor[] = [{ field: "executedate_ts", dir: "desc" }]
  loading = false;

  calibrationprocessingviewid = environment.spaces.callibrationModule.views.CalibrationOutputData.id;
  calibrationprocessingversion = environment.spaces.callibrationModule.views.CalibrationOutputData.version;
 
  public state: State = {
    skip: 0,
    take: 20,
    group: this.groups,
    sort: [
      { field: "executedate_ts", dir: "desc" }  // Sort by latest executedate first
    ]
    //sort:this.sort
  };
  filterEndDate: any;
  filterStartDate: any;
  simulationData: any;
  espgridexportData: any;
  jpgridexportData: any;

  constructor(
    private dialog: MatDialog,
    private cogniteAuthService: CogniteAuthService,
    private apiService: CognitApiService,
    private loader: LoaderService,
    private messageService: MessageService,
    private calService: CalibrationService,
    private hierarchyService: HierarchyService,) {
    this.selectedtab = this.calService.selectedTab;
  }

  onRadioClick(dataItem: any) {
    this.openDialog(dataItem);
  }
  onDateSelected(event: any) {
    this.filterStartDate = event?.event.startDate?._d
    this.filterStartDate = moment(this.filterStartDate).startOf('day');
    this.filterEndDate = event?.event.endDate?._d
    this.filterEndDate = moment(this.filterEndDate).endOf('day');
    this.ishierarchySelected = ((this.hierarchyService.getWell() != undefined && this.hierarchyService.getWell() != '') || (this.hierarchyService.getfield() != undefined && this.hierarchyService.getfield() != ''))
    if (!this.ishierarchySelected && this.filterStartDate != undefined && this.filterEndDate != undefined)
      this.loadGridData();
    //this.loadGridData();
  }

  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Want To Save?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.saveRow(selectedrows);
      }
    });

  }

  private async saveRow(selectedrows: any) {
    this.loader.showLoader();
    await this.cogniteAuthService.getapiToken().then(res => {
      if (res) {
        let token = res;

        let url = `${environment.webApiUrl}/Cognite/ExecuteCogniteFunction`

        let data = {
          "name": "cairn-workflow-calibration-postprocessing",
          "data": {
            "key": selectedrows.key,
            "casenumber": selectedrows.casenumber
          }
        }

        this.apiService.saveFunction(url, data, token).subscribe((data: any) => {
          this.loader.hideLoader();
          if (this.selectedtab == this.tabOptions.ESP)
            this.espData = this.espData.filter((x: any) => !(x.wellnumber == selectedrows.wellnumber && x.executedate == selectedrows.executedate));
          else
            this.jpData = this.jpData.filter((x: any) => !(x.wellnumber == selectedrows.wellnumber && x.executedate == selectedrows.executedate));
          this.processdata();
        },
          error => {
            this.loader.hideLoader();
            console.log(error);
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Error,
              detail: "service call failed, kindly contact administrator",
            };
            this.messageService.add(alert);
          });


      }
    },
      (error) => {

        if (error) {
          console.log('Error DCA for type well')
        }
      });
  }

  ngOnInit() {
    //this.loadGridData();
  }

  getCalibrationProcessingData() {
    //this.loader.showLoader();
    this.simulationData = [];
    this.loading = true;
    let wellid = this.filterWellList.map((x: any) => x.externalId);
    let filter = this.getFilter(this.calibrationprocessingviewid, wellid, this.calibrationprocessingversion);
    this.subs.push(this.apiService.getInstancelistWithcursorData(this.calibrationprocessingviewid, filter, this.calibrationprocessingversion)
      .subscribe((instancedata: any) => {
        this.getStatusFromCallibrationProcessing(instancedata, this.calibrationprocessingviewid, this.calibrationprocessingversion);
      }));
  }
  getFilter(viewId: string, wellid: string[], version: string = environment.cogniteSDMVersion) {
    let filter: any;
    if (this.filterWellList.length == 0) {
      filter = {
        and: [{
          "equals": {
            property: [environment.cogniteSpace, viewId + "/" + version, "wellLiftType"],
            "value": this.selectedtab
          }
        },
        {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                gt: moment(this.filterStartDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                lte: moment(this.filterEndDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }]
      }
    }
    else {
      filter = {
        and: [{
          "in": {
            "property": [environment.cogniteSpace, viewId + "/" + version, "wellExternalId"],
            "values": wellid
          }
        },
        {
          "equals": {
            property: [environment.cogniteSpace, viewId + "/" + version, "wellLiftType"],
            "value": this.selectedtab
          }
        },
        {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                gt: moment(this.filterStartDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                lte: moment(this.filterEndDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }
        ]
      }
    }
    return filter;
  }
  getStatusFromCallibrationProcessing(instanceData: any, calibrationprocessingviewid: string, calibrationprocessingversion: string) {
    let latestRecords = instanceData.items.reduce((acc: any, item: any) => {
      const properties = item['properties'][environment.cogniteSpace][calibrationprocessingviewid + "/" + calibrationprocessingversion];
      const caseNumber = properties.caseNumber;
      const wellTestStartDateTime = moment(properties.wellTestStartDateTime).format("YYYY/MM/DD");
      const wellExternalId = properties.wellExternalId;
      const key = `${caseNumber}_${wellTestStartDateTime}_${wellExternalId}`;
      if (!acc[key] || moment(properties.createdDateTime).isAfter(acc[key].createdDateTime)) {
        acc[key] = { ...properties, wellTestStartDateTime };
      }
      return acc;
    }, {})
    latestRecords = Object.values(latestRecords).map((properties: any) => ({
      properties
    }));
    let data = latestRecords;
    this.simulationData = data?.map((simulationItem: any) => {
      let properties = simulationItem.properties;
      let outputJson = properties.outputJson;
      let result: any = {
        key: properties.calibrationProcessingData?.externalId,
        wellnumber: properties.wellExternalId,
        executedate: moment(properties.wellTestStartDateTime).format('DD-MM-YYYY'),
        executedate_ts: moment(properties.wellTestStartDateTime).valueOf(),
        casenumber: properties.caseNumber,
      };
      const sensitivityVariables = outputJson.SensitivityVariables || [];
      const resPressureProp = sensitivityVariables.find((senvar: any) => senvar.sensitivityType === 'CaseReservoirPressure');
      if (resPressureProp) {
        // result.respressure = this.roundValue(Convert('psig','psia',resPressureProp.sensitivityVariableValue)); //this.roundValue(resPressureProp.sensitivityVariableValue)
        result.respressure = this.roundValue(Number(resPressureProp.sensitivityVariableValue) + 14.7)

        this.unitobject['respressure'] = resPressureProp.sensitivityVariableUom;
      }
      const knProp = sensitivityVariables.find((senvar: any) => senvar.sensitivityType === 'CaseKn');
      if (knProp) {
        result.kn = this.roundValue(knProp.sensitivityVariableValue);
        this.unitobject['kn'] = knProp.sensitivityVariableUom;
      }
      const piProp = sensitivityVariables.find((senvar: any) => senvar.sensitivityType === 'CasePI');
      if (piProp) {
        result.pi = this.roundValue(piProp.sensitivityVariableValue);
        this.unitobject['pi'] = piProp.sensitivityVariableUom;
      }
      outputJson.data.map((item: any) => {
        let element = item;
        switch (element.propertyName) {
          case 'ReservoirPressure':
            //result.respressure =this.roundValue(Convert('psig','psia',element.propertyValue));
            result.respressure = this.roundValue(Number(element.propertyValue) + 14.7); //converted psig to psia

            this.unitobject['respressure'] = 'psia';
            break;
          case 'EstLiquidRate':
          case 'Estimated Liquid Rate':
            result.liquidrate = this.roundValue(element.propertyValue);
            this.unitobject['liquidrate'] = element.simulationUnit;
            break;
          case 'EstOilRate':
          case 'Estimated Oil Rate':
            result.oilrate = this.roundValue(element.propertyValue);
            this.unitobject['oilrate'] = element.simulationUnit;
            break;
          case 'EstWaterRate':
          case 'Estimated Water Rate':
            result.waterrate = this.roundValue(element.propertyValue);
            this.unitobject['waterrate'] = element.simulationUnit;
            break;
          case 'EstGasRate':
          case 'Estimated Gas Rate':
            result.gasrate = this.roundValue(element.propertyValue);
            this.unitobject['gasrate'] = element.simulationUnit;
            break;
          case 'FrictionFactor':
          case 'Friction Factor':
            result.frictionfactor = this.roundValue(element.propertyValue);
            this.unitobject['frictionfactor'] = element.simulationUnit;
            break;
          case 'HoldupFactor':
          case 'Holdup Factor':
            result.holdupfactor = this.roundValue(element.propertyValue);
            this.unitobject['holdupfactor'] = element.simulationUnit;
            break;
          case 'PumpWearFactor':
            result.pumpwearfactor = this.roundValue(element.propertyValue);
            this.unitobject['pumpwearfactor'] = element.simulationUnit;
            break;
          case 'EstDpFriction':
            result.dpfriction = this.roundValue(element.propertyValue);
            this.unitobject['dpfriction'] = element.simulationUnit;
            break;
          case 'EstDpGravity':
            result.dpgravity = this.roundValue(element.propertyValue);
            this.unitobject['dpgravity'] = element.simulationUnit;
            break;
          case 'PI':
            result.pi = this.roundValue(element.propertyValue);
            this.unitobject['pi'] = element.simulationUnit;
            break;
          case 'PIP':
            result.pip = this.roundValue(element.propertyValue);
            this.unitobject['pip'] = element.simulationUnit;
            break;
          case 'EstimatedKs':
            result.suctionloss = this.roundValue(element.propertyValue);
            this.unitobject['suctionloss'] = element.simulationUnit;
            break;
          case 'EstimatedKd':
            result.diffuserloss = this.roundValue(element.propertyValue);
            this.unitobject['diffuserloss'] = element.simulationUnit;
            break;
        }
      });
      return result;
    })
    if (this.selectedtab == this.tabOptions.ESP)
      this.espData = addSortableDates(this.simulationData, this.espKendoGrid, 'executedate');
    else
      this.jpData = addSortableDates(this.simulationData, this.jpKendoGrid);
    this.processdata();
    //this.loader.hideLoader();
  }

  roundValue(value: number): string {
    if (value == null || value == undefined) {
      return '-';
    }
    return value.toFixed(2);
  }

  loadGridData() {
    //this.loader.showLoader();
    this.loading = true;
    this.espData = [];
    this.jpData = [];
    this.espgridData = [];
    this.jpgridData = [];
    this.unitobject = {};
    this.jpUnitobject = {};
    this.getCalibrationProcessingData();
  }
  protected espdataStateChange(state: DataStateChangeEvent): void {
    state.group?.map(
      group => group.field = this.groups[0].field);
    this.state = state;
    this.espgridData = process(this.espFilterData, this.state);

  }
  protected jpdataStateChange(state: DataStateChangeEvent): void {
    state.group?.map(
      group => group.field = this.groups[0].field);
    this.state = state;
    this.jpgridData = process(this.jpFilterData, this.state);
  }
  public getFielTextWithUnit(paramName: string, unit: string, defaultUnit: string = '') {
    const unitTxt = this.getUnit(unit, this.selectedtab);
    if (!!unitTxt)
      return `${paramName} (${unitTxt})`;

    return defaultUnit ? `${paramName} (${defaultUnit})` : `${paramName}`;
  }

  private getUnit(field: string, type: string): string {
    const typeObject = this.unitobject;
    return typeObject[field] || '';
  }

  clear() {
    this.filterWellList = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
    //this.loadGridData();
    this.unitobject = {};
    this.jpUnitobject = {};
    this.simulationData = []
  }


  onfieldChange(event: any) {
    this.selectedWell = ""
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.allFieldwells;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.loadGridData();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }

  }
  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.loadGridData();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }
  }
  onFilterWellChange(event: any) {
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.allFieldwells;
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.loadGridData();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }
  }


  onTabChange($event: any) {
    this.selectedtab = $event.value;
    this.calService.selectedTab = this.selectedtab;
    this.filterWellList = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.espgridData = [];
    this.jpgridData = [];
    this.subs.forEach(s => s.unsubscribe())
    this.hierarchyComponent.clear();
    //this.loadGridData();
    //this.processdata();
  }

  processdata() {
    this.state = {
      skip: 0,
      take: 20,
      group: this.groups,
      sort: [{ field: "executedate_ts", dir: "desc" }]
    };
    let externalid = this.filterWellList.map((x: any) => x.externalId);
    if (this.selectedtab == this.tabOptions.ESP) {
      let filtereddata = _.cloneDeep(this.espData.filter((x: any) => externalid.includes(x.wellnumber)));
      this.espFilterData = externalid.length > 0 ? addSortableDates(filtereddata, this.espKendoGrid, "executedate") : this.espData;
      this.espgridData = process(this.espFilterData, this.state);
      this.espgridexportData = process(this.espFilterData, { group: this.groups});
      this.loader.hideLoader();
      this.loading = false;
      //this.espgridData =  this.sortgroupdata(this.espFilterData, this.state);
      const uniqueGroupNames = new Set(this.espFilterData.map((item: any) => item.wellnumber));
      this.wellCount = uniqueGroupNames.size;
    }
    else if (this.selectedtab == this.tabOptions.JP) {
      let filtereddata = _.cloneDeep(this.jpData.filter((x: any) => externalid.includes(x.wellnumber)));
      this.jpFilterData = externalid.length > 0 ? addSortableDates(filtereddata, this.jpKendoGrid, "executedate") : this.jpData;
      this.jpgridData = process(this.jpFilterData, this.state);
      this.jpgridexportData = process(this.jpFilterData, { group: this.groups});
      this.loader.hideLoader();
      this.loading = false;
      const uniqueGroupNames = new Set(this.jpFilterData.map((item: any) => item.wellnumber));
      this.wellCount = uniqueGroupNames.size;
    }
  }
  sortgroupdata(filtereddata: any, state: any) {
    let groupData = process(filtereddata, state);
    groupData.data = groupData.data.sort((a: any, b: any) => {
      const latestA = Math.max(...a.items.map((item: any) => item.executedate_ts));
      const latestB = Math.max(...b.items.map((item: any) => item.executedate_ts));
      return latestB - latestA;
    });
    return groupData;
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
