import { Component, Input } from '@angular/core';
import { LossAccountingModel, chartdata } from '../lossaccountingmodel';
import { HighchartsService } from 'src/app/services/highcharts.service';
import { Chart, Point, Series } from 'highcharts/';
import * as Highcharts from 'highcharts';


interface extendedpoint extends Highcharts.Point {
  value: number;
  Unplanned: number;
}
@Component({
  selector: 'app-result-distributionchart',
  templateUrl: './result-distributionchart.component.html',
  styleUrls: ['./result-distributionchart.component.css']
})


export class ResultDistributionchartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  @Input()
  data: LossAccountingModel[] = [];

  chartData: any[] = [];
  public options: Highcharts.Options;
  chart: any;
  constructor(private highchart: HighchartsService) { }

  ngOnChanges() {
    this.showShortfallDistributionChart();
  }

  ngOnInit() {

  }
  showEmptyChart() {
    this.options = {
      chart: {
        height: '100%',
        marginTop: 0,
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Planned vs Unplanned ShortFall'
      },
    };

    this.chart = this.highchart.createChart("sunburncontainer", this.options);
  }

  showShortfallDistributionChart() {
    this.chartData = [];

    let fielddata = this.rollupdata('field');
    let wellpaddata = this.rollupdata('wellpad');
    let welldata = this.rollupdata('well');
    this.chartData = this.chartData.concat(fielddata).concat(wellpaddata).concat(welldata);
    console.log(this.chartData);
    this.options = {
      chart: {
        type: 'sunburst',
        height: '80%',

      },
      title: {
        text: 'Planned vs Unplanned ShortFall'
      },
      series: [
        {
          type: 'sunburst',
          allowTraversingTree: true,
          cursor: 'pointer',
          name:'Shortfall',
          data: this.chartData,
          colors: ['#D47500', '#EE5E64', '#F6BCBC', '#DA44AA', '#404040', '#939393', '#94DD90', '#2196F3', '#D47500', '#FEE58A', '#A488D8', '#57B2C0',
            '#87d5e4', '#4999d4', '#ffce92', '#f39180', '#eb7a9b', '#8a84e2', '#07c1db',
            '#26b8bd', '#2380be', '#ffaa34', '#fd6d51', '#de3a6a', '#5f59b8', '#009ebe', '#009297', '#f2f2f2',
            '#fcecda', '#ffd2ca', '#febfc5'],
          dataLabels: {
            color: '#3b3b3b',
            format: '{point.name}',
            filter: {
              property: 'innerArcLength',
              operator: '>',
              value: 16
            }
          },
          levels: [{
            level: 1,
            dataLabels: {
              rotationMode: 'parallel',
              filter: {
                property: 'outerArcLength',
                operator: '>',
                value: 64
              }
            }
          },
          {
            level: 2,
            colorByPoint: true
          }, {
            level: 3,
            colorByPoint: true
          }],

        },
      ],

      tooltip: {
        headerFormat: '',
        valueDecimals: 2,

        pointFormat: '<b>{point.name}</b>  <br> <b>Planned:</b> {point.planned} bbl <br> <b>Unplanned:</b> {point.Unplanned} bbl',

      }
    };
    this.chart = this.highchart.createChart("sunburncontainer", this.options)
  }

  rollupdata(category = ''): any[] {
    let result: any[] = [];
    if (this.data.length > 0) {
      var array = this.data;
      let resultarray: any[] = [];
      let parent: any = null;
      const rollup = array.reduce(function (res: any, value: any) {
        let resvalue= res;
        if (value[category] == undefined)
          return;
        if (!res[value[category]]) {
          //let sdate = (new Date(value.startdate)).getTime();
          if (category != "field")
            parent = category == "well" ? value["wellpad"] : value["field"];
          res[value[category]] = { name: value[category], parent: parent, value: 0, id: value[category], planned: 0, Unplanned: 0 };
          result.push(res[value[category]])
        }
        if (value.loss_oil != "-" && value.category1 == "Planned") {
          res[value[category]].value += Number(value.loss_oil);
          res[value[category]].planned += Number(value.loss_oil);
        }
        else if (value.loss_oil != "-" && value.category1 == "Unplanned") {
          res[value[category]].Unplanned += Number(value.loss_oil);
          res[value[category]].value += Number(value.loss_oil);
        }
        return res;
      }, {}); 
    }
    return result;


  }
}
