<div class="grid">
  <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15">
  </app-auto-refresh>
  <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" appGridTransform>
    <ng-template kendoGridToolbarTemplate>
      <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
        <button type="button" class="k-button k-grid-pdf k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
          <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
        </button>
        <button type="button" kendoGridPDFCommand>
          <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
        </button>
      </div>
    </ng-template>
    <kendo-grid-column field="welllinkedasset" title="Linked Asset" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="startdatetime_ts" title="Start" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdatetime }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="enddatetime_ts" title="End" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.enddatetime }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="type" title="Type" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="mothersvc" title="Mother Solution Viscosity Checkup" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="dissolvedocc" title="Dissolved oxygen concentration Checkup" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="vdeviation" title="Viscosity Deviation" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="vthreshold" title="Viscosity Threshold" [width]="80"></kendo-grid-column>
    <kendo-grid-column field="fratioc" title="Filter Ratio Checkup" [width]="80"></kendo-grid-column>
    <kendo-grid-excel [fileName]="title + '.xlsx'"></kendo-grid-excel>
    <kendo-grid-pdf [fileName]="title + '.pdf'" [allPages]="true"></kendo-grid-pdf>
  </kendo-grid>
</div>
