<div class="content">
  <mat-card class="example-card" *ngIf="data.length > 0">
    <mat-card-header>
      <mat-card-title>Well Data</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="box-content">
        <div class="box" *ngFor="let item of data">
          <div>
            <h1>{{ item.title }}</h1>
            <span class="box-value">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="no-data" *ngIf="data.length === 0">
    <p>No data available</p>
  </div>
</div>
