<div class="form-container">
  <form [formGroup]='formgroup'>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>ElevationUnit</mat-label>
      <input type="text" matInput formControlName="eunit">
    </mat-form-field>
    <div class="space-div"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Elevation</mat-label>
      <input type="text" matInput formControlName="elevation">
    </mat-form-field>
    <div class="line-break"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Convert Depth from</mat-label>
      <mat-select #input [panelClass]="'dls-panel'" formControlName='coverter' placeholder="Select" [hideSingleSelectionIndicator]="true">
        <mat-option [value]="'MD to TVDRT/TVDSS'">MD to TVDRT/TVDSS</mat-option>
        <mat-option [value]="'TVDRT to MD'">TVDRT to MD</mat-option>
        <mat-option [value]="'TVDSS to MD'">TVDSS to MD</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="space-div"></div>
    <mat-form-field slbFormField class="form-field" appearance="outline" #field>
      <mat-label>Enter Depth in MD</mat-label>
      <input type="number" step="any" matInput formControlName="depth" ><span></span>
    </mat-form-field>
    <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="onCalculate()" style="margin-left: 10px; top: -2px;">Calculate</button>
  </form>
</div>
<div *ngIf="successMessage || errorMessage" [ngClass]="{'success-message': successMessage, 'error-message': !successMessage}">
  {{ successMessage || errorMessage }}
</div>
<div *ngIf="loading;" style="display: flex; justify-content: center;align-items: center;">
  <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
</div>
