<span>
    <mat-form-field *ngIf="showoWellTypeFilter" class="form-field" appearance="outline" #field>
        <mat-select placeholder="Well Type" #input [panelClass]="'dls-panel'"
            (selectionChange)="onWellTypeChange($event)" [(value)]="selectedWellType"
            [hideSingleSelectionIndicator]="true">
            <mat-option value="Producer">Producer </mat-option>
            <mat-option value="Injector">Injector </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field slbFormField style="margin-left: 10px;width: 10%;" class="form-field" appearance="outline"
        *ngIf="showMonthYearDateFilter">
        <input matInput [matDatepicker]="dp" [formControl]="monthYearDate" [max]="CurrentmonthYearDate.value">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, dp)" panelClass="month-year-picker">
        </mat-datepicker>
    </mat-form-field>
    <mat-form-field slbFormField style="margin-left: 10px;" class="form-field" appearance="outline" #field>
        <mat-select placeholder="Field" #input [panelClass]="'dls-panel'" (selectionChange)="onfieldChange($event)"
            [(value)]="selectedField" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let field of fieldList" [value]="field.externalId"> {{field.Name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!showonlyFieldControl" style="margin-left: 10px;" slbFormField class="form-field"
        appearance="outline" #field>
        <mat-select placeholder="Well Pad" #input [panelClass]="'dls-panel'"
            (selectionChange)="onFilterWellPadChange($event)" [hideSingleSelectionIndicator]="true"
            [(value)]="selectedWellPad">
            <mat-option *ngFor="let wellPad of wellPadList" [value]="wellPad.externalId">
                {{wellPad.Name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <app-search-dropdown *ngIf="!showonlyFieldControl" [value]="selectedWell" [optionList]="filterWellList"
        (onValueChange)="onFilterWellChange($event)"></app-search-dropdown>
    <slb-date-picker-range *ngIf="showDateRange" class="calendar" placeholder="Select Date Range"
        style="margin-left: 10px;" (rangeSelected)="onDateSelected($event)" [dateRange]="range"
        [maxValue]="curDate"></slb-date-picker-range>
    <slb-date-picker hint="" *ngIf="showDatePicker" class="calendar" placeholder="Select Date"
      style="margin-left: 10px;" (dateChange)="onDateSelected($event)" [(value)]="startDate" [maxValue]="getCurDate()"></slb-date-picker>
    <button style="margin-left: 10px;" slb-secondary-button *ngIf="showClearButton"
        class="ddls-medium dls-secondary-button dls-button-base" type="button" (click)="clear()" [disabled]="false">
        <span>Clear</span>
    </button>
    <!-- Error message section -->
     <div *ngIf="showErrorFromComponentToHierarchyMessage" style="color: red; margin-top: 10px;  margin-left: 10px;">
        <p>{{ errorMessage }}</p>
    </div>
</span>
