<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [filterOnWellType]="false" (onFieldChange)="clearMapFiles()" (onWellPadChange)="clearMapFiles()" (onWellchange)="handleWellChange($event)"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <mat-card id="card" class="example-card" autoHeight>
    <mat-card-header>
      <mat-card-title>Well Map View</mat-card-title>
    </mat-card-header>
    <mat-card-content [autoHeight]="false">
      <div class="spinner__loading loading-container" *ngIf="loading">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
      </div>
      <div class="map-view-content">
        <div *ngFor="let previewFile of previewFiles; index as imageIndex" [id]="'preview_' + imageIndex">
          <mat-form-field class="form-field" id="file-type-selection" appearance="outline" #field>
            <mat-select placeholder="Select a map" #input [panelClass]="'dls-panel'" (selectionChange)="setPreviewFile($event, imageIndex)">
              <mat-option *ngFor="let file of files" [value]="file.id">{{ file.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="preview">
            <p *ngIf="!previewFile.selected">No file selected to view</p>
            <div *ngIf="previewFile.selected?.type === 'Image'">
              <app-image-viewer [imageUrl]="previewFile.url" [enableAnnotations]="true"></app-image-viewer>
            </div>
            <div *ngIf="previewFile.selected?.type === 'PDF'" class="pdf-viewer" #pdfContainer>
              <button type="button" class="pdf-fullscreen k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [toggleFullscreen]="pdfContainer" (onFullscreenChanged)="togglePdfViewerSize($event)">
                <mat-icon *ngIf="!showPdfFullScreen" svgIcon="expand" aria-hidden="false" aria-label="Expand" title="View full screen"></mat-icon>
                <mat-icon *ngIf="showPdfFullScreen" svgIcon="close" aria-hidden="false" aria-label="Close" title="Close full screen"></mat-icon>
              </button>
              <kendo-pdfviewer #pdfViewer [url]="previewFile.url" autoFitPdf [parentId]="'preview_' + imageIndex"></kendo-pdfviewer>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
