<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [showDateRange]="false" [filterOnWellType]="false" (onFieldChange)="clearData()" (onWellPadChange)="clearData()" (onWellchange)="handleWellChange($event)" (onclear)="clearData()" [showClearButton]="true"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <div>
    <mat-card id="card" class="example-card">
      <mat-card-content>
        <div *ngIf="loading" class="spinner__loading loading-container">
          <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
        </div>
        <div class="viewer-block">
          <div>
            <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="{ checkboxOnly: true }" kendoGridSelectBy="id" [(selectedKeys)]="selectedRows" [filterable]="true" [appGridTransform]="{ calculateRowsPerPage: true, mainGridContainerId: 'card' }">
              <ng-template kendoGridToolbarTemplate>
                <h4>Dyna Card and Echometric Survey</h4>
                <div style="width: 49%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button *ngIf="selectedRows.length > 0" type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="downloadAllFiles()">
                    <span class="k-button-text">Download</span>
                  </button>
                </div>
              </ng-template>
              <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
              <kendo-grid-column field="id" title="ID" [hidden]="true"></kendo-grid-column>
              <kendo-grid-column field="source" title="Type"></kendo-grid-column>
              <kendo-grid-column field="name" title="File Name"></kendo-grid-column>
              <kendo-grid-column field="date" title="Date Uploaded" [width]="160"></kendo-grid-column>
              <kendo-grid-column field="type" title="File Type" [width]="120"></kendo-grid-column>
              <kendo-grid-column field="action" title="Action" [width]="120">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <a slb-icon-button routerLink="." title="Download" (click)="downloadFile(dataItem)">
                    <mat-icon style="color: #6BA7FF;" svgIcon="download"></mat-icon>
                  </a>
                  <a slb-icon-button routerLink="." title="Preview" (click)="previewFile(dataItem)" *ngIf="dataItem.type === 'PDF' || dataItem.type === 'Image'">
                    <mat-icon style="color: #6BA7FF;" svgIcon="preview"></mat-icon>
                  </a>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
          <div *ngIf="!pdfUrl && !image" class="no-preview">
            <p>No file to view</p>
          </div>
          <div *ngIf="pdfUrl !== undefined && !showPdfFullScreen" class="pdf-viewer">
            <button class="pdf-fullscreen k-button k-toggle-button k-icon-button k-button-md k-rounded-md k-button-solid-base k-button-solid k-group-start" (click)="togglePdfViewerSize()">
              <mat-icon svgIcon="expand" aria-hidden="false" aria-label="Expand" title="View full screen"></mat-icon>
            </button>
            <kendo-pdfviewer #pdfViewer [url]="pdfUrl" autoFitPdf></kendo-pdfviewer>
          </div>
          <div *ngIf="image !== null" class="image-viewer">
            <app-image-viewer [imageUrl]="image.url"></app-image-viewer>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div *ngIf="pdfUrl !== undefined && showPdfFullScreen" class="pdf-viewer fullscreen">
  <button class="pdf-fullscreen k-button k-toggle-button k-icon-button k-button-md k-rounded-md k-button-solid-base k-button-solid k-group-start" (click)="togglePdfViewerSize()">
    <mat-icon svgIcon="close" aria-hidden="false" aria-label="Close" title="Close full screen"></mat-icon>
  </button>
  <kendo-pdfviewer #pdfViewer [url]="pdfUrl" autoFitPdf></kendo-pdfviewer>
</div>
