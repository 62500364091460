import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TabOptionsEnum } from '../model';
import { ESPCondition, JPCondition, PCPCondition } from '../Alsthresholdconditiondata'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validator, Validators, FormControl } from '@angular/forms';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { HierarchyService } from '../../services/hierarchy.service';
import { CognitDataFormatter } from '../../services/cognit-data-formatter';
import { CognitApiService } from '../../services/cognit-api.service'

import moment from 'moment';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { ThresholdModel } from '../model';
import { Subscription } from 'rxjs';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';


@Component({
  selector: 'app-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.css']
})
export class ThresholdComponent {
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  private subs: Subscription[] = [];

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP;
  private fieldViewId = "Field"
  public fieldList: any;
  public selectedField: string;
  public conditionList = ESPCondition;
  public selectedrows: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public gridData: ThresholdModel[] = [];
  public thresholditemdata: ThresholdModel;
  public timeseriesdata: any;
  formgroup: FormGroup;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date Time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private hierarchyService: HierarchyService,
    private dateHelper:DateHelper) {
  }

  ngOnInit() {
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());
    this.setRecordValue();
    this.setformgroupData();
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
  }

  onChange($event: any) {
    //console.log($event.value);
    this.selectedtab = $event.value;
    switch (this.selectedtab) {
      case this.tabOptions.ESP:
        this.conditionList = ESPCondition;
        break;
      case this.tabOptions.PCP:
        this.conditionList = PCPCondition;
        break;
      case this.tabOptions.JP:
        this.conditionList = JPCondition;
        break;
    }
    this.selectedField = '';
    this.gridData = [];
  }

  public setformgroupData() {
    if (this.selectedtab == this.tabOptions.ESP) {
      this.conditionList = ESPCondition;
    }
    else if (this.selectedtab == this.tabOptions.JP) {
      this.conditionList = JPCondition;
    }
    else if (this.selectedtab == this.tabOptions.PCP) {
      this.conditionList = PCPCondition;
    }

    //FormBuilder avoids using new FormControl in FormGroup instance
    this.formgroup = this.fb.group({
      field: new FormControl({ value: this.thresholditemdata.field, disabled: this.formMode == "Edit" }, Validators.required),
      condition: new FormControl({ value: this.thresholditemdata.condition, disabled: this.formMode == "Edit" }, Validators.required),
      value: [this.thresholditemdata.value, Validators.required],
    });
  }
  onfieldChange(event: any) {
    this.loadGridData();
  }
  onAddrecordClick() {
    this.formMode = "Add";
    this.setRecordValue();
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
      disableClose: true


    });
  }
  ondelete(data: any) {
    this.openDialog(data);
  }
  public onsave(item: any) {
    if (this.formMode == "Edit") {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        panelClass: 'confirm-secondary-dialog',
        data: {
          message: 'Previous values would be lost. Do you want to proceed with the changes ?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.save(item);
        }
        else {
          const dialogRef = this.dialog.open(this.addRecordDialog, {
            panelClass: 'update-range-dialog',
            disableClose: true
          });
        }
      });
    }else{
      this.save(item);
    }
  }
  getTimeseriesexternaID(field: string): string {
    return field + ":" + this.getconditionvalue() + ":fieldFailureThreshold";
  }
  getthresholdviewID(): string {
    return "ALSFieldConfiguration";
  }
  getconditionvalue(): string {
    let conditionvalue = this.conditionList.filter((x: any) => x.value == this.formgroup.get("condition")?.value)[0].propertyname;
    return conditionvalue;
  }
  save(form: any) {
    this.loader.showLoader();
    if (this.formgroup.valid) {
      let condition = this.formgroup.get("condition")?.value;
      let value = this.formgroup.get("value")?.value;
      let field = this.formgroup.get("field")?.value;

      let timeseriesexternaID = this.getTimeseriesexternaID(field);
      this.apiService.getTsId(timeseriesexternaID).then(data => {
        if (data.items.length > 0) {
          this.loader.hideLoader();
          let response = data?.items[0];
          let timeseriesid = response?.id;
          let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
          if (this.formMode == "Edit")
            timestamp = Date.parse(String(this.thresholditemdata.timestamp));
          let datapoint = { id: timeseriesid, datapoints: [{ timestamp: timestamp, value: value }] }

          const promises: Promise<any>[] = [
            this.apiService.insertTimeSeriesData(datapoint),
          ];
          Promise.all(promises)
            .then((results: any[]) => {
              this.loader.hideLoader();
              this.loadGridData();
              this.startdate = this.todaysdate;
              if (this.formMode == 'Edit') {
                const alert: SlbMessage = {
                  target: 'modal',
                  severity: SlbSeverity.Success,
                  summary: 'Updated',
                  detail: 'Record Updated Successfully.',
                };
                this.messageService.add(alert);
              }
            })
            .catch((error) => {
              this.loader.hideLoader();
              console.error("At least one promise rejected:", error);
            });

        }
        else {
          this.loader.hideLoader();
          const alert: SlbMessage = {
            target: 'modal',
            severity: SlbSeverity.Error,
            //summary: 'Summary',
            detail: '(' + timeseriesexternaID + ') Timeseries not found. ',

          };
          this.messageService.add(alert);
        }
      });
    }
  }
  loadGridData() {
    this.gridData = [];
    let version = environment.cogniteSDMVersion
    let viewid = this.getthresholdviewID()
    let space = environment.cogniteSpace;
    let conditionnamelist = this.conditionList.map((x: any) => x.propertyname);
    let filter = {
      "and": [{
        "in": {
          "property": ["failureMode"],
          "values": conditionnamelist
        }
      },
      {
        "equals": {
          "property": ["field"],
          "value": { "space": space, "externalId": this.selectedField }
        }
      }]
    }
    this.loader.showLoader();
    this.subs.push(this.apiService.getInstance(viewid, filter, version).subscribe(data => {
      let properties = data.items[0]?.properties;
      if (properties !== undefined) {
        let externalidlist = data.items.map(item => ({ "externalId": item.externalId + ":fieldFailureThreshold" }));
        this.apiService.getLatestTimeseriesData(externalidlist).then((results: any) => {
          this.loader.hideLoader();
          this.getGridData(results);
        })
          .catch((error) => {
            console.error("Error in getting data:", error);
          });

      }
      else
        this.loader.hideLoader();
    }));

  }
  setRecordValue(data: any = undefined) {
    if (data == undefined) {
      this.thresholditemdata = { condition: '', value: 0, field: this.selectedField }
    }
    else {
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      let condition = this.conditionList.filter((x: any) => x.name == data.condition)[0]?.value;
      this.thresholditemdata = {
        startdate: data.startdate, condition: condition, value: data.value,
        field: this.selectedField, timestamp: data.timestamp
      }
    }
  }
  onEditecordClick(dataitem: any) {
    this.formMode = "Edit"
    this.setRecordValue(dataitem);
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
      disableClose: true


    });
  }
  public getGridData(results: any) {
    let timeseriesdatapoints = results;
    this.gridData = [];
    let sequence = 0;
    timeseriesdatapoints.forEach((datapoint: any) => {
      if (datapoint.datapoints.length > 0) {
        let idparams = datapoint.externalId.split(":");
        let condition = this.conditionList.filter((list:any)=>list.propertyname ==idparams[1] )[0].name;
        datapoint.datapoints.forEach((data: any) => {
          let item = {
            sequence: sequence, id: datapoint.id, condition: condition, startdate: moment(data.timestamp).format("DD-MM-YYYY"),
            value: data.value, timestamp: data.timestamp
          };
          sequence += 1;
          this.gridData.push(item)
        });
      }
    });

    this.gridData = addSortableDates(this.gridData, this.kendoGrid);
  }

  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete = [];
    if (selectedrows == undefined) {
      let data = this.gridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          recortodelete.push({ id: record.id, inclusiveBegin: Date.parse(String(record.timestamp)) })
        }
      });
    }
    else
      recortodelete.push({ id: selectedrows.id, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
    this.loader.showLoader();
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.selectedrows = undefined;
      this.loadGridData();
    })

  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
