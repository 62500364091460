import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { NodeAndEdgeCollectionResponseV3Response, NodeOrEdge } from '@cognite/sdk/dist/src';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

declare interface CompletionSchematicFile {
  externalId: string;
  date: string;
  name?: string;
  tz?: number;
  id?: number;
  type?: string;
}

@Component({
  selector: 'app-completion-schematic',
  templateUrl: './completion-schematic.component.html',
  styleUrls: ['./completion-schematic.component.css'],
})
export class CompletionSchematicComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild('grid') public grid: GridComponent;

  public loading: boolean = false;
  public wellSelected: string | null;
  public imageUrl: string | undefined = undefined;
  public completionSchematics: CompletionSchematicFile[] = [];
  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Action', hide: true },
    { field: 'Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
  ];

  private readonly cdfDef: CdfSpaceView | null = null;
  private selectedFile: string;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService,  private cd : ChangeDetectorRef) {
    this.cdfDef = this.wbService.getSpaceDefinition('completionSchematic');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);
    this.cd.detectChanges();
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadCompletionSchematics();
  }

  public previewFile(wellSchematic: CompletionSchematicFile, download: boolean = false) {
    if (!download && wellSchematic.type === 'Image')
      this.loadImagePreview(wellSchematic);

    if (download || wellSchematic.type === 'Spreadsheet')
      this.openExcel(wellSchematic);
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.completionSchematics = [];
    this.imageUrl = undefined;
  }

  public rowCallback = (context: RowClassArgs) => {
    const rowSelected = this.selectedFile === context.dataItem?.externalId;
    return { rowSelected: rowSelected };
  };

  private loadCompletionSchematics() {
    this.completionSchematics = [];
    this.imageUrl = undefined;

    if (this.wellSelected && this.cdfDef) {
      this.loading = true;

      const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

      this.apiService.getInstancelist(this.cdfDef?.id ?? '', filter, this.cdfDef?.version, this.cdfDef?.space).subscribe({
        next: (data: NodeAndEdgeCollectionResponseV3Response) => {
          this.loading = false;
          this.processData(data);
        },
        error: err => {
          console.error(err);
          this.loading = false;
        },
      });
    }
  }

  private processData(data: NodeAndEdgeCollectionResponseV3Response): void {
    let files: CompletionSchematicFile[] = [];

    if (this.cdfDef && data?.items?.length > 0) {
      for (const item of data.items) {
        files = files.concat(this.processItem(item));
      }
    }

    files.forEach(e => e.tz = new Date(e.date).valueOf());
    files.sort((a, b) => (a.tz ?? 0) <= (b.tz ?? 0) ? -1 : 1);

    this.getFileMetadata(files);
  }

  private processItem(item: NodeOrEdge): CompletionSchematicFile[] {
    let files: CompletionSchematicFile[] = [];

    if (this.cdfDef?.space) {
      const properties = item.properties?.[this.cdfDef?.space]?.[this.cdfDef?.fullName];
      const completionSchematic = properties?.completionSchematic;
      const completionSchematicDate = properties?.completionSchematicFileDate;

      if (completionSchematic && completionSchematicDate)
        files = files.concat(this.getFileFromItem(completionSchematic, completionSchematicDate));
    }

    return files;
  }

  private getFileFromItem(completionSchematic: any, completionSchematicDate: any): CompletionSchematicFile[] {
    const files: CompletionSchematicFile[] = [];

    if (Array.isArray(completionSchematic) && Array.isArray(completionSchematicDate)) {
      for (let i in completionSchematic) {
        files.push({
          externalId: completionSchematic[i],
          date: completionSchematicDate[i],
        });
      }
    } else {
      files.push(({ externalId: completionSchematic as string, date: completionSchematicDate as string }));
    }

    return files;
  }

  private getFileMetadata(files: CompletionSchematicFile[]) {
    this.loading = true;

    const filter = this.wbService.getCdfDocsFilter(['externalId'], files.map(e => e.externalId));

    this.apiService.getDocumentList(filter).subscribe({
      next: (data: any) => {
        this.loading = false;
        if (data) {
          const items: any = this.apiService.isListResponse(data) ? data.items : [data];

          for (const file of files) {
            const item = items.find((e: any) => e.externalId === file.externalId);

            if (item) {
              file.type = item.type;
              file.name = item.sourceFile.name;
              file.id = item.id;
            }
          }

          this.completionSchematics = addSortableDates(files.filter(e => e.id), this.grid, null, 'YYYY-MM-DD');
          this.loadImagePreview(this.completionSchematics.filter(e => e.type === 'Image')?.[0]);
        }
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private async loadImagePreview(file: CompletionSchematicFile) {
    if (file?.id && file?.type === 'Image') {
      const imageUrl = await this.getDocumentUrl(file);

      if (imageUrl)
        this.imageUrl = imageUrl;
    }
  }

  private async openExcel(file: CompletionSchematicFile) {
    if (file.id) {
      const docUrl = await this.getDocumentUrl(file);

      if (docUrl) {
        const outsideRes = await fetch(docUrl);
        const blob = await outsideRes.blob();
        const internalUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = internalUrl;

        if (file.name)
          link.download = file.name;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      }
    }
  }

  private getDocumentUrl(file: CompletionSchematicFile): Promise<string | null> {
    return new Promise(res => {
      if (file.id) {
        this.loading = true;
        this.selectedFile = file.externalId;

        this.apiService.getDocumentDownloadUrl(file.id).subscribe({
          next: (data: any) => {
            this.loading = false;
            let urls: string[] = [];

            if (data?.items?.length > 0)
              urls = data.items.map((e: any) => e.downloadUrl);

            res(urls[0]);
          },
        });
      } else {
        res(null);
      }
    });
  }

}
