import { Component, Input } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import * as moment from 'moment-timezone';
import { Subscription, forkJoin, map, timestamp } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';
import * as Highcharts from 'highcharts/highstock';
import SunsetTheme from 'highcharts/themes/high-contrast-dark'; // load 
import { HighchartsService } from 'src/app/services/highcharts.service';
//import 'moment-timezone';
SunsetTheme(Highcharts);
//import { LoaderService } from 'ser'

@Component({
  selector: 'app-hfchart',
  templateUrl: './hfchart.component.html',
  styleUrls: ['./hfchart.component.css']
})
export class HfchartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chart: any;
  public options: Highcharts.Options;
  private subs: Subscription[] = [];
  @Input()
  wellid: string = "";
  @Input()
  startDate: any;
  @Input()
  endDate: any;
  viewid = "WellOperationalInputsHf";
  chartData: any[] = [];
  externalId: string;
  chartWidth:any;

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService,
    private TimeseriesHelper: TimeseriesHelper,
    private highchart: HighchartsService) {
  }

  ngOnInit() {
    this.chartWidth = document.getElementById('divhfchart')?.offsetWidth.toString();
    this.emptychart();
  }
  ngOnChanges() {
    this.loadchart();
  }
  clear()
  {
    this.chartData = [];
    this.emptychart();
  }

  loadchart() {
    if (this.wellid != "" && this.startDate != undefined && this.endDate != undefined) {
      this.loader.showLoader();
      this.TimeseriesHelper.getExternalId([this.wellid], this.viewid, "well").subscribe(data => {
        let externalid = data.sdmPropertyList.filter((x: any) => x.keyvalue == "tubingheadpress")[0].value;
        this.externalId = externalid;
        this.getTimeseriesdata();
      })

    }
  }
  getTimeseriesdata() {
    this.loader.showLoader();
    this.apiService.getTimeseriesDataRange([{ "externalId": this.externalId }], this.startDate, this.endDate, 10000).then((resultsdata: any) => {
      //console.log(resultsdata);
      this.loader.hideLoader();
      let data = [...new Set(resultsdata[0].datapoints.map((datapoint: any) => ([Date.parse(datapoint.timestamp), datapoint.value])))];
      this.chartData = data;
      //console.log(this.chartData);
      this.preaparechart();
    })
  }
  refresh() {
    this.chartData = [];
    this.getTimeseriesdata();
  }
  emptychart() {
    //options.chart.backgroundColor = "transparent";
    this.options = {
      lang: {
        noData: 'no data'
      },
      chart: {
        backgroundColor: "transparent",
        width:this.chartWidth
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Tubing Head Pressure HF Data',
        align: 'left'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date time'
        }
      },
      yAxis: [{
        alignTicks: false,
        title: {
          text: 'Tubing Head Pressure (kPag)'
        },
        opposite: false,
      },],
      rangeSelector: {
        inputEnabled: false,
        // selected: 2,
        buttons: [
          {
            type: 'minute',
            count: 5,
            text: '5min'
          },
          {
            type: 'minute',
            count: 60,
            text: '1h'
          }, {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            count: 1,
            text: '1m'
          }, {
            type: 'year',
            count: 1,
            text: '1y'
          }, {
            type: 'all',
            text: 'All'
          }]
      },
      series: [
        {
          type: 'line',
          pointInterval: 24 * 3600 * 1000,
          data: this.chartData,
          name: "Pressure"
        }
      ]


    };
  }
  preaparechart() {
    this.options = {
      time: {
        timezone: moment.tz.guess()
      },
      credits: {
        enabled: false
      },
      rangeSelector: {
        inputEnabled: false,
        // selected: 2,
        buttons: [
          {
            type: 'minute',
            count: 5,
            text: '5min'
          },
          {
            type: 'minute',
            count: 60,
            text: '1h'
          }, {
            type: 'day',
            count: 1,
            text: '1d'
          }, {
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            count: 1,
            text: '1m'
          }, {
            type: 'year',
            count: 1,
            text: '1y'
          }, {
            type: 'all',
            text: 'All'
          }]
      },
      series: [
        {
          type: 'line',
          pointInterval: 24 * 3600 * 1000,
          data: this.chartData,
          name: "Pressure"
        }
      ]
    };
  }
}
