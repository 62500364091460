export class Interpolator1D {

  private yAxis: number | null = null;
  private yExtraShape: number[] = [];
  private dtype: 'float' | 'complex' | null = null;

  constructor(xi?: number[], yi?: number[], axis?: number) {
    if (axis !== undefined)
      this.yAxis = axis;

    if (yi)
      this.setYi(yi, xi, axis);
  }

  public call(x: number[]): number[] {
    const { preparedX, xShape } = this.prepareX(x);
    const y = this.evaluate(preparedX);
    return this.finishY(y, xShape);
  }

  protected evaluate(x: number[]): number[] {
    throw new Error('Not implemented');
  }

  private prepareX(x: number[]): { preparedX: number[]; xShape: number[] } {
    const xShape = Array.isArray(x) ? [x.length] : [];
    const preparedX = x.flat();
    return { preparedX, xShape };
  }

  private finishY(y: number[], xShape: number[]): number[] {
    const reshapedY = this.reshape(y, [...xShape, ...this.yExtraShape]);

    if (this.yAxis !== 0 && xShape.length > 0) {
      const nx = xShape.length;
      const ny = this.yExtraShape.length;

      const order = [
        ...Array.from({ length: this.yAxis! }, (_, i) => nx + i),
        ...Array.from({ length: nx }, (_, i) => i),
        ...Array.from({ length: ny - this.yAxis! }, (_, i) => nx + this.yAxis! + i),
      ];

      return this.transpose(reshapedY, order);
    }

    return reshapedY;
  }

  private reshape(arr: number[], newShape: number[]): number[] {
    return arr;
  }

  private transpose(arr: number[], order: number[]): number[] {
    return arr;
  }

  private reshapeYi(yi: number[], check = false): number[] {
    const reshapedYi = this.moveAxis(yi, this.yAxis!);

    if (check && reshapedYi.length !== this.yExtraShape.reduce((a, b) => a * b, 1)) {
      const okShape = `${this.yExtraShape.slice(-this.yAxis!).toString()} + (N,) + ${this.yExtraShape.slice(0, -this.yAxis!).toString()}`;
      throw new Error(`Data must be of shape ${okShape}`);
    }

    return reshapedYi.flat();
  }

  private moveAxis(arr: number[], axis: number): number[] {
    return arr;
  }

  private setYi(yi: number[], xi?: number[], axis?: number): void {
    const resolvedAxis = axis ?? this.yAxis;

    if (resolvedAxis === null)
      throw new Error('No interpolation axis specified');

    const shape = Array.isArray(yi) ? [yi.length] : [];

    if (xi && shape[resolvedAxis] !== xi.length)
      throw new Error('x and y arrays must be equal in length along interpolation axis.');

    this.yAxis = resolvedAxis % shape.length;
    this.yExtraShape = [...shape.slice(0, this.yAxis), ...shape.slice(this.yAxis + 1)];
    this.dtype = null;
    this.setDtype('float');
  }

  private setDtype(dtype: 'float' | 'complex', union = false): void {
    if (dtype === 'complex' || this.dtype === 'complex') {
      this.dtype = 'complex';
    } else if (!union) {
      this.dtype = 'float';
    }
  }

}
