import { Component, ElementRef, ViewChild } from '@angular/core';
import { LiftTabOptionsEnum } from '../model';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { wellModel } from 'src/app/alsModule/model';
import moment from 'moment';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

@Component({
  selector: 'app-alsmonitoring-new',
  templateUrl: './alsmonitoring.component.html',
  styleUrls: ['./alsmonitoring.component.css']
})
export class AlsmonitoringComponent {
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  @ViewChild('kendoGrid') public kendoGrid: GridComponent;

  public filterStartdate: any;
  public filterEnddate: any;
  public loading: boolean = false;
  public selectedField: string = "";
  private subs: Subscription[] = [];
  public alarmgridData: wellModel[] = [];
  public rawgridData: wellModel[] = [];
  public WellListToloadGrid: any[] = [];
  public iframeurl: SafeResourceUrl;
  public liftTypetabOptions = LiftTabOptionsEnum;
  public Liftselectedtab = this.liftTypetabOptions.ESP;
  ESPSummary_report_link: any;
  AlarmDefination: any[] = []
  viewID: string = "AlsWellAlarms";
  definationViewId: string = "AlarmDefinition";
  maxvalidity: number;
  alarmeventType: string = "PCP_alarms";
  PCPValidity: number = 24;
  public hight:number;
  public chartConditions: any;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private loader: LoaderService,
  ) {
    this.chartConditions = {'MPT_PF_Pressure': true};
  }

  ngOnInit() {
    this.getAlarmDefination();
    this.Liftselectedtab = this.liftTypetabOptions.ESP;
    this.loadIframe();
    //this.ESPSummary_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.alsmonitoring.ESP);
  }
  getAlarmDefination() {
    this.apiService.getInstance("AlarmDefinition", undefined).subscribe((defination: any) => {
      defination.items.forEach((data: any) => {
        let propertieslist = data.properties[environment.cogniteSpace][this.definationViewId + "/" + environment.cogniteSDMVersion];
        if (propertieslist.validity != undefined && propertieslist.validity != "") {
          let item = { "externalId": data.externalId, "validity": propertieslist.validity, "alarmName": propertieslist.alarmName }
          this.AlarmDefination.push(item);
        }
      });
      let highestvalidity = this.AlarmDefination.map(x => x.validity);
      this.maxvalidity = Math.max(...highestvalidity);
    })
  }
  onChange($event: any) {
    this.Liftselectedtab = $event.value;
    //this.hierarchyComponent.loadAllWells();
    //this.loadIframe();
    //this.loadGridData();
    //this.clear();
    //

  }
  onDateSelected(event: any) {
    this.filterStartdate = moment(event.event.startDate).toDate();
    this.filterEnddate = moment(event.event.endDate).toDate();
    //moment(event.event.endDate).add(1, 'days').toDate();
    if (event.event.startDate != null && event.event.endDate != null) {
      this.WellListToloadGrid = event.allFieldwells;
      this.loadIframe();
      this.loadGridData();
    }
  }
  refresh() {
    this.loadGridData();
  }
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.WellListToloadGrid = event.allFieldwells;
    this.loadGridData();
    this.loadIframe();
  }

  loadIframe() {
    let startdate = moment(this.filterStartdate).format("YYYY-MM-DD");
    let enddate = moment(this.filterEnddate).format("YYYY-MM-DD");
    let url = ""
    switch (this.Liftselectedtab) {
      case this.liftTypetabOptions.ESP:
        url = PowerBIReportLink.alsmonitoring.ESP;
        this.hight = 2120;
        break;
      case this.liftTypetabOptions.JP:
        url = PowerBIReportLink.alsmonitoring.JP;
        this.hight = 1810;
        break;
      case this.liftTypetabOptions.PCP:
        url = PowerBIReportLink.alsmonitoring.PCP;
        this.hight = 2090;
        break;
      case this.liftTypetabOptions.HRP:
        url = PowerBIReportLink.alsmonitoring.HRP;
        this.hight = 1410;
        break;
      case this.liftTypetabOptions.NF:
        url = PowerBIReportLink.alsmonitoring.NF;
        this.hight = 1450;
        break;
    }
    if (this.selectedField == "" || this.selectedField == undefined)
      this.ESPSummary_report_link = url
    else if (this.selectedField == "All")
      this.ESPSummary_report_link = url + "&$filter=CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    else
      this.ESPSummary_report_link = url + "&$filter=FlatHierarchy/fieldName eq '" + this.selectedField + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ESPSummary_report_link);
  }

  loadGridData() {
    this.alarmgridData = [];
    this.rawgridData = [];
    let version = "1_2";
    let viewid = this.viewID;
    let space = environment.cogniteSpace;
    let filter: any = undefined;
    let startdate = moment(moment().add(-this.maxvalidity, 'hours')).utc().format("YYYY-MM-DDTHH:mm:ss");
    let enddate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    let eventstartdate = moment(moment().add(-this.PCPValidity, 'hours')).toDate();
    let eventenddate = moment().toDate();

    if ((this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) && this.filterStartdate != undefined && this.filterStartdate != null && this.filterEnddate != undefined && this.filterEnddate != null) {
      let selectedwell = this.WellListToloadGrid.map((a: any) => a.externalId);
      filter = {
        and: [{
          "nested": {
            "scope": [environment.cogniteSpace, 'AlsWellAlarms/1_2', "well"],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": selectedwell
              }
            }
          }
        }, {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "startDateTime"],
                gt: startdate,
              }
            }, {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "endDateTime"],
                lte: enddate,
              }
            }
          ]
        }
        ]
      }
      this.loading = true;
      this.loader.showLoader();
      this.subs.push(this.apiService.getInstancelistWithcursorData(viewid, filter, version).subscribe(data => {
        this.loader.hideLoader();
        let rawData = data['items'];
        if (rawData.length > 0) {
          rawData.forEach((item: any) => {
            let property = item.properties;
            let record = property[space][viewid + "/" + version];
            let alarmName = record['alarmName'];
            let alarmvalidity;
            let alarmdefination = this.AlarmDefination.filter(def => def.alarmName == alarmName)[0];
            alarmvalidity = (alarmdefination != undefined && alarmdefination.validity != null) ? alarmdefination.validity : 24;
            if (record['well'] != undefined) {
              let starttime = moment(record['startDateTime']).format("DD-MM-YYYY HH:mm");
              let endtime = moment(record['endDateTime']).format("DD-MM-YYYY HH:mm");
              let datetodisplayrecord = moment(moment().add(-alarmvalidity, 'hours'));
              let alarmdatetime = moment(record['startDateTime']);
              let isvalid = alarmdatetime.isSameOrAfter(datetodisplayrecord);
              if (isvalid == true) {
                let dataRow = {
                  well: record['well']['externalId'],
                  workflowName: record['workflowName'],
                  startDateTime: starttime,
                  endDateTime: endtime,
                  alarmName: record['alarmName'],
                  alarmFlags: record['alarmFlags'] == undefined ? '-' : record['alarmFlags'],
                  alarmMessage: record['alarmMessage'] == undefined ? '-' : record['alarmMessage'],
                  alarmType: record['alarmType'],
                  alarmLevel: record['alarmLevel'],
                  driftWindow: '-',
                  maxDeviation: '-',
                  allowedDeviation: '-',
                  startdatetimestamp: Date.parse(record['startDateTime'])

                };
                this.rawgridData.push(dataRow);
              }
            }
          });
        }
        if (this.liftTypetabOptions.PCP == this.Liftselectedtab) {
          this.loader.showLoader();
          this.apiService.getEventlist(eventstartdate, eventenddate, this.alarmeventType, selectedwell).then(eventResponse => {
            //this.apiService.getEventlist(this.filterStartdate._d, this.filterEnddate._d, this.alarmeventType, selectedwell).then(eventResponse => {
            this.loader.hideLoader();
            this.getEventAlarm(eventResponse);
          })
        }
        else {
          this.alarmgridData = addSortableDates(this.rawgridData, this.kendoGrid);
        }
      }));

    } else {
      this.alarmgridData = [];
      this.loading = false;
    }

  }
  getEventAlarm(eventResponse: any) {
    eventResponse.forEach((eventitem: any) => {
      let metadata = eventitem.metadata;
      let driftWindow = metadata['window'] == 'null' ? '-' : metadata['window'];
      let allowed_deviation = metadata["allowed_deviation"] == undefined ? '-' : metadata["allowed_deviation"];
      let max_deviation = metadata["max_deviation"] == undefined ? '-' : metadata["max_deviation"];
      let starttime = moment(eventitem.startTime).format("DD-MM-YYYY HH:mm");
      let dataRow = {
        well: metadata['Well'],
        workflowName: metadata['Workflow'],
        startDateTime: starttime,
        endDateTime: starttime,
        alarmName: metadata['Alarm Name'],
        alarmFlags: metadata['Alarm Flags'] == undefined ? '-' : metadata['Alarm Flags'],
        alarmMessage: metadata['Alarm Message'] == undefined ? '-' : metadata['Alarm Message'],
        alarmType: eventitem.subtype,
        alarmLevel: metadata['Alarm Level'],
        driftWindow: driftWindow,
        maxDeviation: max_deviation,
        allowedDeviation: allowed_deviation,
        startdatetimestamp: eventitem.startTime
      };
      this.rawgridData.push(dataRow);
    });

    this.alarmgridData = addSortableDates(this.rawgridData, this.kendoGrid);
  }
  clear() {
    this.alarmgridData = [];
    this.rawgridData = [];
    this.selectedField = "";
    this.WellListToloadGrid = [];
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }
}
