import { Component, EventEmitter, Input, Output } from "@angular/core";

import {
  FilterService,
  BaseFilterCellComponent,
} from "@progress/kendo-angular-grid";
import { filterClearIcon, SVGIcon } from "@progress/kendo-svg-icons";
import * as DataQuery from "@progress/kendo-data-query";
export type CompositeFilterDescriptor = DataQuery.CompositeFilterDescriptor;

@Component({
  selector: 'app-date-time-filter',
  templateUrl: './date-time-filter.component.html',
  styleUrls: ['./date-time-filter.component.css']
})
export class DateTimeFilterComponent extends BaseFilterCellComponent {
  public clearButton: boolean = false;
  filterText: string = '';
  public clearFilterIcon: SVGIcon = filterClearIcon;
  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }
  @Output() filterChange = new EventEmitter<void>();
  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public valueField: string;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public onChange(event: any): void {
    let value = event.target.value;
    this.applyFilter(
      this.updateFilter({
        field: this.valueField,
        operator: "contains",
        value: value,
      })
    );

    this.clearButton = value.length == 0 ? false : true;
    this.filterChange.emit();
  }

  public clearFilter() {
    this.filterText = ""
    this.applyFilter(this.removeFilter(this.valueField));
    this.clearButton = false;
    this.filterChange.emit();
  }
}

