<div class="float-header-filter">
    <div class="tabcontrolpanel">
        <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab" >
            <mat-button-toggle [value]="tabOptions.VFM">VFM Summary</mat-button-toggle>
            <mat-button-toggle [value]="tabOptions.CMP">Comparison</mat-button-toggle>
            <mat-button-toggle [value]="tabOptions.VFMNE">VFM Non-Execution Well List</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="content-left-margin" *ngIf="selectedtab === 'VFM'">
        <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true"
            [showClearButton]="true" [getListofAllWellsUnderField]="true" (onclear)="clear()"
            (ondatechange)="onDateSelected($event)" [showonlyFieldControl]="true" [selectedWellType]="'Producer'"
            [AddAllFieldValue]="true"></app-hierarchy>
    </div>
</div>

<div *ngIf="selectedtab === 'VFM'">
    <app-i-frame [title]="'Cairn ASP Surveillance'" [src]="VFMSummary_iframeSrc"></app-i-frame>
</div>
<ng-container *ngIf="selectedtab === 'CMP'">
    <app-virtual-compariosn></app-virtual-compariosn>
</ng-container>
<div class="page-content" *ngIf="selectedtab === 'VFMNE'">
    <app-i-frame [title]="'Cairn ASP Surveillance'" [src]="VMFNonExecutionWellListlink"></app-i-frame>
</div>