<div class="content-left-margin float-header-filter">
  <span>
    <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)"
      (onWellPadChange)="onfilterwellPadChange($event)" (onclear)="clear()" [showDateRange]="true"
      [showClearButton]="true" [selectDefaultFieldValue]="true" (ondatechange)="onDateSelected($event)"
      (onWellchange)="onFilterWellChange($event)" [showoWellTypeFilter]="true"></app-hierarchy>
  </span>
</div>
<div class="content-left-margin content-right-margin">
  <div id="downtime" [hidden]="isResultpage">
    <div class="grid-container">
      <div class="grid-child">
        <app-lossvolumechart [data]="gridData"
          [chartTitle]="'Deferred Oil Volume'"></app-lossvolumechart>
      </div>
      <div class="grid-child">
        <app-lossvolumechart [data]="gridData" [containerid]="'downtime_container'" [valuekey]="'down_time'"
          [chartTitle]="'Downtime'" [yAxisTitle]="'Downtime (Hrs)'"
          [unit]="'Hrs'"></app-lossvolumechart>

      </div>
    </div>
    <mat-card class="example-card" style="margin-top: 10px;">
      <mat-card-header>
        <mat-card-title>Downtime Event </mat-card-title>
        <div class="header-right-tools">
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button"
            (click)="onAddrecordClick()">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add</span>
          </button>
          <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base delete-button"
            type="button" (click)="ondelete(undefined)"
            [disabled]="selectedrows === undefined || selectedrows?.length === 0">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Delete</span>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10"
          [selectable]="true" kendoGridSelectBy="sequence" [(selectedKeys)]="selectedrows" [filterable]="true"
          [sortable]="true" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div
              style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button"
                class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid"
                [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
          <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="sequence" title="sequence" [hidden]="true"></kendo-grid-column>
          <kendo-grid-column field="well" title="Well"></kendo-grid-column>
          <kendo-grid-column field="startdate_ts" title="Start Date">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="enddate_ts" title="End Date">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.enddate }}</ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="loss_oil" title="Oil Loss volume(bbl)"></kendo-grid-column>
          <kendo-grid-column field="down_time" title="Down Time (Hrs)"></kendo-grid-column>
          <kendo-grid-column field="category1" title="L1 Category"></kendo-grid-column>
          <kendo-grid-column field="category2" title="L2 Category"></kendo-grid-column>
          <kendo-grid-column field="category3" title="L3 Category"></kendo-grid-column>
          <kendo-grid-column field="comment" title="Comments" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column field="pdmscomment" title="PDMS Comment" [filterable]="false"></kendo-grid-column>
          <kendo-grid-column title="Action" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItem)">
                <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
              </a>
              <a slb-icon-button routerLink="." title="delete" (click)="ondelete(dataItem)">
                <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-excel fileName="Downtime Event.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Downtime Event.pdf" [allPages]="true">
            <kendo-grid-column field="well" title="Well"></kendo-grid-column>
            <kendo-grid-column field="startdate" title="Start Date"></kendo-grid-column>
            <kendo-grid-column field="enddate" title="End Date"></kendo-grid-column>
            <kendo-grid-column field="loss_oil" title="Oil Loss volume(bbl)"></kendo-grid-column>
            <kendo-grid-column field="down_time" title="Down Time (Hrs)"></kendo-grid-column>
            <kendo-grid-column field="category1" title="L1 Category"></kendo-grid-column>
            <kendo-grid-column field="category2" title="L2 Category"></kendo-grid-column>
            <kendo-grid-column field="category3" title="L3 Category"></kendo-grid-column>
            <kendo-grid-column field="comment" title="Comments" [filterable]="false"></kendo-grid-column>
            <kendo-grid-column field="pdmscomment" title="PDMS Comment" [filterable]="false"></kendo-grid-column>
          </kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
    <ng-template #addRecordDialog>
      <mat-dialog-content style="height: auto; width: auto;" class="update-range-dialog-content">
        <mat-card-title class="headerLabel">{{ formMode === 'Add' ? 'Add New' : 'Update' }}
          Record</mat-card-title>
        <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
        <div class="form-container" style="margin-top: 30px;">
          <form (ngSubmit)='save(formgroup)' [formGroup]='formgroup'>
            <slb-date-picker hint="" [disabled]="formMode === 'Edit'" [(value)]="startdate" label="Start Date"
              [maxValue]="todaysdate" [required]="true"></slb-date-picker>
            <div class="space-div"></div>
            <slb-date-picker hint="" [disabled]="formMode === 'Edit'" [(value)]="enddate" label="End Date"
              [required]="true"></slb-date-picker>
            <br>
            <mat-form-field slbFormField class="form-field" appearance="outline">
              <mat-label>Field</mat-label>
              <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field"
                (selectionChange)="onformfieldChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let field of fieldList" [value]="field.externalId">{{ field.Name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
              <mat-label>Well Pad</mat-label>
              <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName='wellpad'
                (selectionChange)="onformwellPadChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let wellPad of formPadList" [value]="wellPad.externalId">{{ wellPad.Name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 10px;" slbFormField class="form-field" appearance="outline">
              <mat-label>Well</mat-label>
              <mat-select placeholder="Select" formControlName='well' #input [panelClass]="'dls-panel'"
                (selectionChange)="onwellChange($event)" [hideSingleSelectionIndicator]="true">
                <mat-option *ngFor="let well of formwellList" [value]="well.externalId">{{ well.Name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="parent">
              <div class="child1">
                <mat-form-field slbFormField class="form-field" appearance="outline">
                  <mat-label>L1 Category</mat-label>
                  <mat-select placeholder="Select" [(value)]="category1selectedoption" #input [panelClass]="'dls-panel'"
                    (selectionChange)="onCategory1Change($event)" formControlName="category1"
                    [hideSingleSelectionIndicator]="true">
                    <mat-option *ngFor="let category of category1List" [value]="category.value">{{ category.name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <mat-form-field slbFormField class="form-field" appearance="outline">
                  <mat-label>L2 Category</mat-label>
                  <mat-select placeholder="Select" #input [panelClass]="'dls-panel'"
                    (selectionChange)="onCategory2Change($event)" formControlName="category2"
                    [hideSingleSelectionIndicator]="true">
                    <mat-option *ngFor="let category of category2List" [value]="category.value">{{ category.name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <mat-form-field slbFormField class="form-field" appearance="outline">
                  <mat-label>L3 Category</mat-label>
                  <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="category3"
                    [hideSingleSelectionIndicator]="true">
                    <mat-option *ngFor="let category of category3List" [value]="category.value">{{ category.name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field slbFormField class="form-field" appearance="outline">
                  <mat-label>Down Time</mat-label>
                  <input type="number" matInput formControlName="downtime"><span>Hrs</span>
                </mat-form-field>
                <mat-form-field slbFormField class="form-field" appearance="outline">
                  <mat-label>Oil Loss Volume </mat-label>
                  <input type="number" matInput formControlName="loss_oil"><span>bbl</span>
                </mat-form-field>
              </div>
              <div class="child2">
                <mat-form-field slbFormField style="width: 100%;">
                  <mat-label>Comments</mat-label>
                  <textarea #input matInput formControlName="comment" placeholder="Type here"
                    style="height: 250px;width: 300px;"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end" class="update-range-dialog-actions">
        <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
        <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="save(formgroup)"
          matDialogClose="yes">{{ formMode === 'Add' ? 'Create' : 'Update' }}
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
  <div id="result" *ngIf="isResultpage">
    <app-lossaccounting-result [data]="gridData"></app-lossaccounting-result>
  </div>
</div>
