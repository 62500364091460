import { addSortableDates } from 'src/app/utils/sort-dates';
import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validator, Validators, FormControl } from '@angular/forms';
import { TabOptionsEnum } from '../calibrationmodels';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { HierarchyService } from '../../services/hierarchy.service';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { CalibrationInputModel } from '../calibrationmodels'
import { AnyARecord } from 'dns';
import { stringify } from 'querystring';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import * as _ from 'lodash';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent {
  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP;
  private subs: Subscription[] = [];
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public calibrationInput: CalibrationInputModel;
  public gridData: CalibrationInputModel[] = [];
  public selectedrows: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public fieldList: any;
  public wellList: any;
  public formPadList: any;
  public wellPadList: any;
  public filterWellList: any;
  public selectedField: string;
  public selectedWell: string;
  public selectedWellPad: string;
  public timeseriesdata: any;
  public selectedwelllist: any[]=[];
  public sdmPropertyList: any[] = [];
  formgroup: FormGroup;
  timeseriesid: string | undefined;
  showerrormsg: boolean = false;
  public dateFilter: any;
  public dateFilterMax: any;
  dialogRef: MatDialogRef<any>;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private hierarchyService: HierarchyService,
    private changeDetector: ChangeDetectorRef,
    private dateHelper: DateHelper) {

  }
  ngOnInit() {
    this.setRecordValue();
    this.setformgroupData();
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());
    this.dateFilter = moment(moment().toDate());
    this.dateFilterMax = moment(moment().toDate());
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));


  }
  setRecordValue(data: any = undefined) {
    if (data == undefined) {
      this.calibrationInput = { field: this.selectedField, well: this.selectedWell, wellPad: this.selectedWellPad }
    }
    else {
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      let wellselected = this.selectedwelllist.filter((x: any) => x.Name == data.well)[0]
      let wellid = wellselected.externalId;
      let wellpadid = wellselected.WellPad;
      this.calibrationInput = {
        startdate: data.startdate, rpfrom: data.rpfrom == '-' ? undefined : data.rpfrom, rpto: data.rpto == '-' ? undefined : data.rpto, rpstepsize: data.rpstepsize == '-' ? undefined : data.rpstepsize,
        knfrom: data.knfrom == '-' ? undefined : data.knfrom, knto: data.knto == '-' ? undefined : data.knto, knstepsize: data.knstepsize == '-' ? undefined : data.knstepsize,
        ksfrom: data.ksfrom == '-' ? undefined : data.ksfrom, ksto: data.ksto == '-' ? undefined : data.ksto, ksstepsize: data.ksstepsize == '-' ? undefined : data.ksstepsize,
        pifrom: data.pifrom == '-' ? undefined : data.pifrom, pito: data.pito == '-' ? undefined : data.pito, pistepsize: data.pistepsize == '-' ? undefined : data.pistepsize,
        field: this.selectedField, wellPad: wellpadid, well: wellid
      }
    }
  }
  public setformgroupData() {
    //FormBuilder avoids using new FormControl in FormGroup instance
    this.formgroup = this.fb.group({
      field: new FormControl({ value: this.calibrationInput.field, disabled: this.formMode == "Edit" }, Validators.required),
      wellpad: new FormControl({ value: this.calibrationInput.wellPad, disabled: this.formMode == "Edit" }, Validators.required),
      well: new FormControl({ value: this.calibrationInput.well, disabled: this.formMode == "Edit" }, Validators.required),
      reservoirpfrom: [this.calibrationInput.rpfrom, this.selectedtab == this.tabOptions.ESP ? Validators.required : []],
      reservoirpto: [this.calibrationInput.rpto, this.selectedtab == this.tabOptions.ESP ? Validators.required : []],
      reservoirstepsize: [this.calibrationInput.rpstepsize, this.selectedtab == this.tabOptions.ESP ? Validators.required : []],
      knfrom: [this.calibrationInput.knfrom],
      knto: [this.calibrationInput.knto],
      knstepsize: [this.calibrationInput.knstepsize],
      ksfrom: [this.calibrationInput.ksfrom],
      ksto: [this.calibrationInput.ksto],
      ksstepsize: [this.calibrationInput.ksstepsize],
      pifrom: [this.calibrationInput.pifrom],
      pito: [this.calibrationInput.pito],
      pistepsize: [this.calibrationInput.pistepsize],
    });

  }
  onfieldChange(event: any) {
    this.gridData = [];
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
    this.selectedWell = "";
    this.selectedWellPad = "";
    this.selectedwelllist = event.allFieldwells;
    this.loadGridData();
  }

  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.selectedwelllist = event.allFieldwells;
    this.loadGridData();
  }

  onFilterWellChange(event: any) {
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;
    if (this.filterWellList == undefined)
      this.selectedwelllist = event.allFieldwells;
    else
      this.selectedwelllist = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadGridData();
  }
  onformwellPadChange(event: any) {
    this.getWellwithPumpType(event.value, true);
    this.loader.showLoader();
  }

  getwelldataonpadchange(value: string) {
    this.loader.showLoader();
    this.subs.push(this.hierarchyService.getWelldata(value).subscribe((data: any) => {
      this.loader.hideLoader();
      this.filterWellList = data;
      this.selectedwelllist = data;
      this.loadGridData();
    }));
  }
  getWellwithPumpType(value: string, isFormfield = false) {
    let lifttypeTimeserieslist: any[] = [];
    let listofallwell: { externalId: string; Name: string; liftType: string }[] = []
    let lifttypedata: any[] = [];
    this.loader.showLoader();
    this.subs.push(this.hierarchyService.getWelldataWithProperties(value).subscribe((data: any) => {
      let version = environment.cogniteSDMVersion
      let space = environment.cogniteSpace;
      data.forEach((item: any) => {
        let properties = item.properties;
        if (properties !== undefined) {
          let liftType = properties[space]["Well/" + version].liftType;
          listofallwell.push({ externalId: item.externalId, Name: properties[space]["Well/" + version].name.toString(), liftType: String(liftType) })
          if (liftType != undefined)
            lifttypeTimeserieslist.push({ "externalId": String(liftType),'before': new Date(this.startdate) })
        }
        //item.properties["workflow-sdm-spc"]["Field/1_0"].name

      });
      this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist).then((timeseriesdatapoints: any) => {
        this.loader.hideLoader();
        timeseriesdatapoints.forEach((datapoint: any) => {
          if (datapoint.datapoints.length > 0) {
            datapoint.datapoints.forEach((data: any) => {
              if (data != undefined && String(data.value).toUpperCase() == this.selectedtab) {
                //lifttypedata.push({ "externalId": datapoint.externalId, "lifttype": String(data.value) })
                lifttypedata.push(datapoint.externalId)

              }
            })
          }
        })
        if (isFormfield) {
          this.wellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
        }
        else {
          this.filterWellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
          this.selectedwelllist = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
          this.loadGridData();
        }

      })
    }));
  }

  clear() {
    this.selectedwelllist = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.gridData = [];
    this.dateFilter = moment(moment().toDate());
    this.hierarchyComponent.clear();
  }

  getexternalidlist(results: any, space: string, viewid: string, version: string, isoutput = false): any {
    let externalidlist: any[] = [];
    let properties = results?.properties;
    let datevalue = moment(this.dateFilter).add(1, 'days').toDate();
    let fieldlist = ["reservoirPressureLow", "reservoirPressureHigh", "reservoirPressureStepSize", "EstimatedReservoirPressure", "timeseries"];
    let proplist = ['EstimatedReservoirPressure']
    if (this.selectedtab == this.tabOptions.JP) {
      proplist = ['EstimatedReservoirPressure', 'EstimatedProductivityIndex', 'EstimatedKn', 'EstimatedKs'];
    }

    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      if (isoutput && !proplist.includes(externaliddata.property)) {
        return externalidlist;
      }
      let wellid = externaliddata["well"].externalId;
      delete externaliddata["well"]
      let values = Object.values(externaliddata);

      let propertylist = Object.keys(externaliddata).map(function (key) {
        return { "keyvalue": key, "value": externaliddata[key], "well": wellid };
      });
      if (isoutput)
        this.sdmPropertyList = this.sdmPropertyList.concat({ "keyvalue": externaliddata.property, "value": externaliddata.timeseries, "well": wellid });
      else
        this.sdmPropertyList = this.sdmPropertyList.concat(propertylist);
      if (this.selectedtab == this.tabOptions.ESP) {
        let externalidlistfilterd = propertylist.filter((x: any) => fieldlist.includes(x.keyvalue));
        externalidlist = externalidlist.concat(externalidlistfilterd.map(value => ({ "externalId": value.value, 'before': datevalue })));
      }
      else {
        //fieldlist = ["property", "unit"]
        fieldlist = ["reservoirPressureLow", "reservoirPressureHigh", "reservoirPressureStepSize", "EstimatedReservoirPressure", "timeseries", "knLow", "knHigh", "knStepSize", "EstimatedKn", "productivityIndexLow", "productivityIndexHigh", "productivityIndexStepSize", "EstimatedProductivityIndex", "ksLow", "ksHigh", "ksStepSize", "EstimatedKs"];
        let externalidlistfilterd = propertylist.filter((x: any) => fieldlist.includes(x.keyvalue));
        externalidlist = externalidlist.concat(externalidlistfilterd.map(value => ({ "externalId": value.value, 'before': datevalue })));
      }
    }
    return externalidlist;
  }
  ondatechange(event:any) {
    this.dateFilter = event._d;
    let eventdata = {
      startDate: this.dateFilter,
      endDate: this.dateFilter
    }
    this.hierarchyComponent.onDateSelected(eventdata);
  }
  onDateSelected(event: any) {
    if (event.event.startDate != null && event.event.endDate != null) {
      this.selectedwelllist = event.allFieldwells;
      this.loadGridData();
    }
  }
  getExternalId(welllist: any): Observable<any> {
    let version = environment.cogniteSDMVersion
    let viewid = 'WellCalibrationInputs'
    let space = environment.cogniteSpace;
    let welllistfilter = welllist.map((x: any) => x.externalId);
    let proplist = ['EstimatedReservoirPressure']
    if (this.selectedtab == this.tabOptions.JP) {
      proplist = ['EstimatedReservoirPressure', 'EstimatedProductivityIndex', 'EstimatedKn'];
    }
    let filter = {
      "nested": {
        "scope": [environment.cogniteSpace, viewid + "/" + version, "well"],
        "filter": {
          "in": {
            "property": ["node", "externalId"],
            "values": welllistfilter
          }
        }
      }
    };
    // let filter = {
    //   equals: {
    //     property: ["well"],
    //     value: welllistfilter[0]
    //   }
    // };
    let sources = [
      this.apiService.getInstancelist(viewid, filter, version),
    ];
    this.loader.showLoader();
    return forkJoin(sources).pipe(map((instancedata: any) => {
      this.loader.hideLoader();
      let externalidlist: any[] = []
      let inputresult = instancedata[0];
      inputresult.items.forEach((item: any) => {
        externalidlist = externalidlist.concat(this.getexternalidlist(item, space, viewid, environment.cogniteSDMVersion));
      });
      return externalidlist;
    }));
  }
  loadGridData() {
    this.gridData = [];
    if (this.selectedwelllist !=undefined && this.selectedwelllist.length > 0) {
      let version = environment.cogniteSDMVersion
      let viewid = 'WellCalibrationInputs'
      let outputviewid = 'WellCalibrationOutputs'
      let space = environment.cogniteSpace;
      //EstimatedReservoirPressure

      //this.Fieldwelllist = this.Fieldwelllist.filter((x: any) => x.externalId == 'Aish-075-01-201')
      let welllistfilter = this.selectedwelllist.map((x: any) => x.externalId);
      let proplist = ['EstimatedReservoirPressure']
      if (this.selectedtab == this.tabOptions.JP) {
        proplist = ['EstimatedReservoirPressure', 'EstimatedProductivityIndex', 'EstimatedKn', 'EstimatedKs'];
      }
      let filter = {
        "nested": {
          "scope": [environment.cogniteSpace, viewid + "/" + version, "well"],
          "filter": {
            "in": {
              "property": ["node", "externalId"],
              "values": welllistfilter
            }
          }
        }
      };
      let outputfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, outputviewid + "/" + version, "well"],
          "filter": {
            "and": [{
              "in": {
                "property": ["node", "externalId"],
                values: welllistfilter
              }
            },
            ]
          }
        }
      };

      let sources = [
        this.apiService.getInstancelist(viewid, filter, version),
        this.apiService.getInstancelist(outputviewid, outputfilter, version),
      ];
      this.loader.showLoader();
      forkJoin(sources).subscribe((instancedata: any) => {
        let externalidlist: any[] = []
        let inputresult = instancedata[0];
        let outputresult = instancedata[1];
        inputresult.items.forEach((item: any) => {
          externalidlist = externalidlist.concat(this.getexternalidlist(item, space, viewid, environment.cogniteSDMVersion));
        });
        outputresult.items.forEach((item: any) => {
          externalidlist = externalidlist.concat(this.getexternalidlist(item, space, outputviewid, environment.cogniteSDMVersion, true));
        });
        let results: any[] = [];
        for (let i = 0; i <= externalidlist.length;) {
          //this.gridData = [];
          this.loader.showLoader();
          this.apiService.getLatestTimeseriesData(externalidlist.slice(i, i + 99)).then((resultsdata: any) => {
            results = results.concat(resultsdata)
            this.loader.hideLoader();
            let tempgriddata = this.getGridData(resultsdata)
            this.gridData = addSortableDates(tempgriddata, this.kendoGrid, null, 'DD-MM-YYYY');
            // this.gridData = this.gridData.concat(this.getGridData(resultsdata));
            this.changeDetector.markForCheck();
          })
            .catch((error) => {
              this.loader.hideLoader();
              console.error("Error in getting data:", error);
            });
          i = i + 99;

        }
      });
    }
  }

  public getGridData(results: any[], iswellfilter = false) {
    let timeseriesdatapoints = results;
    let tempgridData = _.cloneDeep(this.gridData);
    //let tempgridData: CalibrationInputModel[] = [];
    let sequence = 0;
    const key1 = "ReservoirPressureLow";
    const key2 = "ReservoirPressureHigh";
    const key3 = "KnLow";
    const key4 = 'KnHigh';
    const key5 = 'ProductivityIndexLow';
    const key6 = 'ProductivityIndexHigh';
    const key7 = "EstimatedReservoirPressure";
    const key8 = 'KnStepSize';
    const key9 = "EstimatedProductivityIndex";
    const key10 = "EstimatedKn";
    const key11 = "reservoirPressureStepSize";
    const key12 = "productivityIndexStepSize";
    const key13 = "ksLow";
    const key14 = "ksHigh";
    const key15 = "ksStepSize";
    const key16 = "EstimatedKs";

    timeseriesdatapoints.forEach((datapoint: any) => {

      if (datapoint.datapoints.length > 0) {
        let idparams = datapoint.externalId.split(":");
        datapoint.datapoints.forEach((data: any) => {
          if (data != undefined) {
            let currentpointsdmdetails = this.sdmPropertyList.filter(x => x.value == datapoint.externalId)[0]//.keyvalue
            let currentwell = currentpointsdmdetails.well;
            let filtergriddata = tempgridData.filter((rowdata: any) => rowdata.well == currentwell);
            let index = filtergriddata != undefined ? tempgridData.indexOf(filtergriddata[0]) : -1;
            let parameter = currentpointsdmdetails.keyvalue;
            if (index <= -1) {
              let item: CalibrationInputModel = { sequence: sequence, id: datapoint.id }
              if (!parameter.includes("Estimated"))
                item.startdate = moment(data.timestamp).format("DD-MM-YYYY");
                item.timestamp = data.timestamp;
              item.well = currentwell;
              item.rpfrom = parameter.toLowerCase() == key1.toLowerCase() ? data.value : '-';
              item.rpto = parameter.toLowerCase() == key2.toLowerCase() ? data.value : '-';
              item.rpstepsize = parameter.toLowerCase() == key11.toLowerCase() ? Math.round(data.value) : '-';
              item.estimatedrp = parameter.toLowerCase() == key7.toLowerCase() ? data.value.toFixed(2) : '-';

              if (this.selectedtab == this.tabOptions.JP) {
                item.knfrom = parameter.toLowerCase() == key3.toLowerCase() ? data.value : '-';
                item.knto = parameter.toLowerCase() == key4.toLowerCase() ? data.value : '-';
                item.knstepsize = parameter.toLowerCase() == key8.toLowerCase() ? data.value.toFixed(2) : '-';
                item.estimatedkn = parameter.toLowerCase() == key10.toLowerCase() ? data.value.toFixed(2) : '-';

                item.ksfrom = parameter.toLowerCase() == key13.toLowerCase() ? data.value : '-';
                item.ksto = parameter.toLowerCase() == key14.toLowerCase() ? data.value : '-';
                item.ksstepsize = parameter.toLowerCase() == key15.toLowerCase() ? data.value.toFixed(2) : '-';
                item.estimatedks = parameter.toLowerCase() == key16.toLowerCase() ? data.value.toFixed(2) : '-';


                item.pifrom = parameter.toLowerCase() == key5.toLowerCase() ? data.value : '-';
                item.pito = parameter.toLowerCase() == key6.toLowerCase() ? data.value : '-';
                item.pistepsize = parameter.toLowerCase() == key12.toLowerCase() ? Math.round(data.value) : '-';
                item.estimatedpi = parameter.toLowerCase() == key9.toLowerCase() ? data.value.toFixed(2) : '-';
              }
              tempgridData.push(item);
            }
            else {
              if (!parameter.includes("Estimated") && tempgridData[index].startdate == undefined)
                tempgridData[index].startdate = moment(data.timestamp).format("DD-MM-YYYY");
              tempgridData[index].rpfrom = parameter.toLowerCase() == key1.toLowerCase() ? data.value : tempgridData[index].rpfrom;
              tempgridData[index].rpto = parameter.toLowerCase() == key2.toLowerCase() ? data.value : tempgridData[index].rpto;
              tempgridData[index].rpstepsize = parameter.toLowerCase() == key11.toLowerCase() ? Math.round(data.value) : tempgridData[index].rpstepsize;
              tempgridData[index].estimatedrp = parameter.toLowerCase() == key7.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].estimatedrp;

              if (this.selectedtab == this.tabOptions.JP) {
                tempgridData[index].knfrom = parameter.toLowerCase() == key3.toLowerCase() ? data.value : tempgridData[index].knfrom;
                tempgridData[index].knto = parameter.toLowerCase() == key4.toLowerCase() ? data.value : tempgridData[index].knto;
                tempgridData[index].knstepsize = parameter.toLowerCase() == key8.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].knstepsize;
                tempgridData[index].estimatedkn = parameter.toLowerCase() == key10.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].estimatedkn;


                tempgridData[index].ksfrom = parameter.toLowerCase() == key13.toLowerCase() ? data.value : tempgridData[index].ksfrom;
                tempgridData[index].ksto = parameter.toLowerCase() == key14.toLowerCase() ? data.value : tempgridData[index].ksto;
                tempgridData[index].ksstepsize = parameter.toLowerCase() == key15.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].ksstepsize;
                tempgridData[index].estimatedks = parameter.toLowerCase() == key16.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].estimatedks;

                tempgridData[index].pifrom = parameter.toLowerCase() == key5.toLowerCase() ? data.value : tempgridData[index].pifrom;
                tempgridData[index].pito = parameter.toLowerCase() == key6.toLowerCase() ? data.value : tempgridData[index].pito;
                tempgridData[index].pistepsize = parameter.toLowerCase() == key12.toLowerCase() ? Math.round(data.value) : tempgridData[index].pistepsize;
                tempgridData[index].estimatedpi = parameter.toLowerCase() == key9.toLowerCase() ? data.value.toFixed(2) : tempgridData[index].estimatedpi;
              }
            }
            sequence += 1;
          }

        });
      }
    });
    return tempgridData;
  }
  public getWelldataoffield(field: any[], isFilter = false) {
    let wellpadfilter = {
      "nested": {
        "scope": [environment.cogniteSpace, 'WellPad/1_0', "field"],
        "filter": {
          "in": {
            "property": ["node", "externalId"],
            "values": field
          }
        }
      }
    }
    this.loader.showLoader();
    this.subs.push(this.apiService.getInstancelist("WellPad", wellpadfilter).subscribe((wellpad: any) => {
      let wellpadid = wellpad.items.map((X: any) => X.externalId)
      let wellfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
          "filter": {
            "in": {
              "property": ["node", "externalId"],
              "values": wellpadid
            }
          }
        }
      }
      this.apiService.getInstancelist("Well", wellfilter).subscribe((well: any) => {
        this.loader.hideLoader();
        let listwell = this.cognitDataFormatter.formatNoderesponse(well, "Well", undefined);

        this.selectedwelllist = listwell;
        this.loadGridData();
      });

    }))
  }
  onChange(event: any) {
    this.clear();
    this.hierarchyComponent.clear();
  }

  public onAddrecordClick() {
    this.formMode = "Add";
    this.formPadList = this.wellPadList;
    this.wellList = this.selectedwelllist;
    this.setRecordValue();
    this.setformgroupData();
    this.dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public onEditecordClick(dataItem: any) {
    this.formMode = "Edit"
    this.formPadList = this.wellPadList;
    this.wellList = this.selectedwelllist;

    this.setRecordValue(dataItem);
    this.setformgroupData();
    this.dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public ondelete(item: any) {
    this.openDialog(item);
  }
  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete: any[] = [];
    if (selectedrows == undefined) {
      let data = this.gridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          let timestamp = record.startdate!;
          recortodelete.push({ id: record.id, inclusiveBegin: Date.parse(timestamp) })
        }
      });
    }
    else
      recortodelete.push({ id: selectedrows.id, inclusiveBegin: Date.parse(selectedrows.timestamp) })
    this.loader.showLoader();
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.selectedrows = undefined;
      this.loadGridData();
    })
  }

  save(form: any) {

    this.sdmPropertyList = [];

    if (this.formgroup.valid) {
      let rpfrom = String(this.formgroup.get("reservoirpfrom")?.value) == '-' ? undefined : Number(this.formgroup.get("reservoirpfrom")?.value);
      let rpto = this.formgroup.get("reservoirpto")?.value == '-' ? undefined : Number(this.formgroup.get("reservoirpto")?.value);
      let rpstepsize = this.formgroup.get("reservoirstepsize")?.value == '-' ? undefined : Number(this.formgroup.get("reservoirstepsize")?.value);
      let knfrom = this.formgroup.get("knfrom")?.value == '-' ? undefined : Number(this.formgroup.get("knfrom")?.value);
      let knto = this.formgroup.get("knto")?.value == '' ? undefined : Number(this.formgroup.get("knto")?.value);
      let knstepsize = this.formgroup.get("knstepsize")?.value == '' ? undefined : Number(this.formgroup.get("knstepsize")?.value);
      let ksfrom = this.formgroup.get("ksfrom")?.value == '-' ? undefined : Number(this.formgroup.get("ksfrom")?.value);
      let ksto = this.formgroup.get("ksto")?.value == '' ? undefined : Number(this.formgroup.get("ksto")?.value);
      let ksstepsize = this.formgroup.get("ksstepsize")?.value == '' ? undefined : Number(this.formgroup.get("ksstepsize")?.value);
      let pifrom = this.formgroup.get("pifrom")?.value == '-' ? undefined : Number(this.formgroup.get("pifrom")?.value);
      let pito = this.formgroup.get("pito")?.value == '-' ? undefined : Number(this.formgroup.get("pito")?.value);
      let pistepsize = this.formgroup.get("pistepsize")?.value == '-' ? undefined : Number(this.formgroup.get("pistepsize")?.value);

      let isrpdataavailable: boolean = rpfrom != undefined && rpto != undefined && rpstepsize != undefined;
      let iskndataavailable: boolean = knfrom != undefined && knto != undefined && knstepsize != undefined;
      let isksdataavailable: boolean = ksfrom != undefined && ksto != undefined && ksstepsize != undefined;
      let ispidataavailable: boolean = pifrom != undefined && pito != undefined && pistepsize != undefined;

      if (this.selectedtab == this.tabOptions.JP && !(isrpdataavailable || iskndataavailable || isksdataavailable || ispidataavailable)) {
        this.showerrormsg = true;
        return false;
      }
      else
        this.showerrormsg = false;

      let fieldlist = this.formgroup.get("field")?.value;
      let selectedWell = this.formgroup.get("well")?.value;
      let filterwelllist = this.wellList.filter((X: any) => X.externalId == selectedWell)
      this.loader.showLoader();
      this.dialogRef.close();
      this.getExternalId(filterwelllist).subscribe((list: any) => {
        let rpfromID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "reservoirpressurelow" && x.well == selectedWell)[0].value;
        let rptoID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "reservoirpressurehigh" && x.well == selectedWell)[0].value;
        let rpstepsizeID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "reservoirpressurestepsize" && x.well == selectedWell)[0].value;

        let knfromID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "knlow" && x.well == selectedWell)[0].value;
        let kntoID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "knhigh" && x.well == selectedWell)[0].value;
        let knstepID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "knstepsize" && x.well == selectedWell)[0].value;

        let pifromID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "productivityindexlow" && x.well == selectedWell)[0].value;
        let pitoID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "productivityindexhigh" && x.well == selectedWell)[0].value;
        let pistepsizeID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "productivityindexstepsize" && x.well == selectedWell)[0].value;

        let ksfromID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "kslow" && x.well == selectedWell)[0].value;
        let kstoID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "kshigh" && x.well == selectedWell)[0].value;
        let ksstepID = this.sdmPropertyList.filter((x: any) => x.keyvalue.toLowerCase() == "ksstepsize" && x.well == selectedWell)[0].value;

        let arrayexternalId = [];

        arrayexternalId.push({ "externalId": rpfromID })
        arrayexternalId.push({ "externalId": rptoID })
        arrayexternalId.push({ "externalId": rpstepsizeID })
        arrayexternalId.push({ "externalId": knfromID })
        arrayexternalId.push({ "externalId": kntoID })
        arrayexternalId.push({ "externalId": knstepID })
        arrayexternalId.push({ "externalId": pifromID })
        arrayexternalId.push({ "externalId": pitoID })
        arrayexternalId.push({ "externalId": pistepsizeID })
        arrayexternalId.push({ "externalId": ksfromID })
        arrayexternalId.push({ "externalId": kstoID })
        arrayexternalId.push({ "externalId": ksstepID })

        //let rpfromID =  selectedWell + ":ReservoirPressureLow"
        //let rptoID = selectedWell + ":ReservoirPressureHigh"
        this.apiService.getbulkTsId(arrayexternalId).then((results: any) => {
          if (results.length > 0) {
            let datapoints: any[] = [];
            let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
            datapoints.push({ id: results[0].id, datapoints: [{ timestamp: timestamp, value: rpfrom }] });
            datapoints.push({ id: results[1].id, datapoints: [{ timestamp: timestamp, value: rpto }] })
            datapoints.push({ id: results[2].id, datapoints: [{ timestamp: timestamp, value: rpstepsize }] })
            if (this.selectedtab == this.tabOptions.JP) {
              datapoints.push({ id: results[3].id, datapoints: [{ timestamp: timestamp, value: knfrom }] });
              datapoints.push({ id: results[4].id, datapoints: [{ timestamp: timestamp, value: knto }] })
              datapoints.push({ id: results[5].id, datapoints: [{ timestamp: timestamp, value: knstepsize }] })

              datapoints.push({ id: results[6].id, datapoints: [{ timestamp: timestamp, value: pifrom }] })
              datapoints.push({ id: results[7].id, datapoints: [{ timestamp: timestamp, value: pito }] })
              datapoints.push({ id: results[8].id, datapoints: [{ timestamp: timestamp, value: pistepsize }] })

              datapoints.push({ id: results[9].id, datapoints: [{ timestamp: timestamp, value: ksfrom }] });
              datapoints.push({ id: results[10].id, datapoints: [{ timestamp: timestamp, value: ksto }] })
              datapoints.push({ id: results[11].id, datapoints: [{ timestamp: timestamp, value: ksstepsize }] })


            }
            let pointstoinsert = datapoints.filter(data => data.datapoints[0].value != null);
            this.apiService.insertbulkTimeSeriesData(pointstoinsert).then(response => {
              this.loader.hideLoader();
              this.loadGridData();
              //console.log(response)
            })
          }
          else {
            this.loader.hideLoader();
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Error,
              //summary: 'Summary',
              detail: 'Timeseries not found. ',

            };
            this.messageService.add(alert);
          }
        })
          .catch((error) => {
            this.loader.hideLoader();
            console.error("Error in saving field data:", error);
          });

        this.sdmPropertyList = [];
      });

    }
    return true;
  }
  onformfieldChange(event: any) {
    this.subs.push(this.hierarchyService.getWellpad(event.value).subscribe((data: any) => {
      this.formPadList = data;
    }));
  }
  onwellChange(event: any) {

  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }


}
