import { Component, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { wellModel } from '../model';
import { HierarchyService } from '../../services/hierarchy.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

@Component({
  selector: 'app-alsalarm',
  templateUrl: './alsalarm.component.html',
  styleUrls: ['./alsalarm.component.css']
})
export class AlsalarmComponent {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public date1: any;
  public date2: any;
  public filterStartDate: any;
  public filterEndDate: any;
  public showDateRange: boolean = false;
  public showClearButton: boolean = false;
  public loading: boolean = false;
  public wellPadList: any;
  public filterWellList: any;
  public selectedField: string = "";
  public selectedWell: string = "";
  public selectedWellPad: string = "";
  private subs: Subscription[] = [];
  public alarmgridData: wellModel[] = [];
  public rawgridData: wellModel[] = [];
  public WellListToloadGrid: any[] = [];
  pcpAlarmeventType: string = "PCP_alarms";

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
  private timeseriesHelper:TimeseriesHelper) {
    this.showDateRange = true;
    this.showClearButton = true;
  }

  ngOnInit() {

  }

  clear() {
    this.alarmgridData = [];
    this.rawgridData = [];
    this.selectedField = "";
    this.selectedWell = "";
    this.selectedWellPad = "";
    this.WellListToloadGrid = [];
    this.hierarchyComponent.selectedField = "";
    this.hierarchyComponent.selectedWellPad = "";
    this.hierarchyComponent.selectedWell = "";
    this.hierarchyComponent.wellPadList = [];
    this.hierarchyComponent.filterWellList = [];
  }


  onDateSelected(event: any) {
    this.date1 = moment(event.event.startDate).toDate();
    this.date2 =moment(event.event.endDate).add(1, 'days').toDate();
    this.filterStartDate = moment(event.event.startDate).toDate();
    this.filterEndDate = moment(event.event.endDate).add(1, 'days').toDate();
    this.loadGridData();
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedWell = "";
    this.selectedWellPad = "";
    this.WellListToloadGrid = event.well;
    this.loadGridData();
    //this.getWelldata([event.event.value]);
    //this.wellPadList = event.wellPadList;
  }

  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
    this.loadGridData();
  }

  onFilterWellChange(event: any) {
    this.selectedWell = event.event.value;
    if (this.filterWellList == undefined)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadGridData();
  }

  onwellChange(event: any) {

  }


  loadGridData() {
    this.alarmgridData = [];
    this.rawgridData = [];
    let version = "1_2";
    let viewid = this.getalarmviewID();
    let space = environment.cogniteSpace;
    let filter: any = undefined;
    if ((this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) && this.date1 != undefined && this.date1 != null && this.date2 != undefined && this.date2 != null) {
      let selectedWell = this.WellListToloadGrid.map((a: any) => a.externalId);
      filter = {
        and: [{
          "nested": {
            "scope": [environment.cogniteSpace, 'AlsWellAlarms/1_2', "well"],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": selectedWell
              }
            }
          }
        }, {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "startDateTime"],
                gt: moment(this.date1).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "startDateTime"],
                lte: moment(this.date2).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }
        ]
      }
      this.loading = true;
      this.apiService.getInstancelistWithcursorData(viewid, filter, version).subscribe(data => {
        let rawData = data['items'];
        if (rawData.length > 0) {
          rawData.forEach((item: any) => {
            let property = item.properties;
            let record = property[space][viewid + "/" + version];
            if (record['well'] != undefined) {
              let starttime = moment(record['startDateTime']).format("DD-MM-YYYY HH:mm");
              let endtime = moment(record['endDateTime']).format("DD-MM-YYYY HH:mm");
              let dataRow = {
                well: record['well']['externalId'],
                workflowName: record['workflowName'],
                startDateTime: starttime,
                endDateTime: endtime,
                alarmName: record['alarmName'],
                alarmFlags: record['alarmFlags'] == undefined ? '-' : record['alarmFlags'],
                alarmMessage: record['alarmMessage'] == undefined ? '-' : record['alarmMessage'],
                alarmType: record['alarmType'],
                alarmLevel: record['alarmLevel'],
                driftWindow: '-',
                maxDeviation: '-',
                allowedDeviation: '-',
                startdatetimestamp: Date.parse(record['startDateTime'])
              };
              this.rawgridData.push(dataRow);
            }
          });
          //this.alarmgridData = this.rawgridData;
          //this.loading = false;

        } else { this.loading = false; }
        this.loading = true;
        this.timeseriesHelper.getAlsEventAlarm(this.filterStartDate,this.filterEndDate,this.pcpAlarmeventType,selectedWell).then(data=>{
          this.rawgridData = this.rawgridData.concat(data);
          this.timeseriesHelper.sortGridData(this.rawgridData)
          this.alarmgridData = this.rawgridData;
          this.loading = false;
        })
      });

      this.alarmgridData = addSortableDates(this.alarmgridData, this.kendoGrid, null, 'DD-MM-YYYY');
    }else{this.alarmgridData = [];
          this.loading = false;
        }
  }

  getalarmviewID(): string {
    let id = "AlsWellAlarms";
    return id;
  }
  refresh()
  {
    this.loadGridData();
  }
}
