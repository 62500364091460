import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { LoaderService } from '../../services/loader.service';
import { viscosityModel } from './model';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
@Component({
  selector: 'app-event-viscosity-alarm',
  templateUrl: './event-viscosity-alarm.component.html',
  styleUrls: ['./event-viscosity-alarm.component.css']
})
export class EventViscosityAlarmComponent {
  @Input()
  WellListToloadGrid: any[] = [];

  @Input()
  Type:string = '';

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input() title: string;

  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public gridData: viscosityModel[] = [];
  public rawgridData: viscosityModel[] = [];

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
    { field: 'End', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService) {
  }

  ngOnInit() {

  }
  refresh() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0
    && moment(this.startDate).isValid() && moment(this.endDate).isValid()){
    this.loadGridData();
    }
  }
 ngOnChanges() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0 && moment(this.startDate).isValid()
    && moment(this.endDate).isValid()) {

      this.loadGridData();
    } else {
      this.gridData = [];
    }
  }
  sortData() {
    if (this.rawgridData.every((obj: any) => 'startdatetimestamp' in obj)) {
      this.rawgridData.sort((a: any, b: any) => b.startdatetimestamp - a.startdatetimestamp);
    } else {
      console.error("Some objects lack the 'startdatetimestamp' key. Sorting is not feasible.");
    }
  }
  loadGridData() {
    this.gridData = [];
    this.rawgridData = [];
    this.loader.showLoader()
      let selectedwell = this.WellListToloadGrid.map((a: any) => a.externalId);
      this.apiService.getEventlist(this.startDate, this.endDate,'injector alert', selectedwell).then(eventResponse => {
        this.loader.hideLoader();
        if (eventResponse.length > 0) {
          eventResponse.forEach(item => {
            if (item.subtype.includes('viscosity')) {
            let metadata: any = item.metadata;
            let dataRow = {
              welllinkedasset: metadata['Well Name'] != undefined ? metadata['Well Name'] : "-",
              startdatetime: moment(item.startTime).format("DD-MM-YYYY HH:mm"),
              enddatetime: moment(item.endTime).format("DD-MM-YYYY HH:mm"),
              type: item?.subtype != undefined || item?.subtype != "" ? item?.subtype : "-",
              mothersvc: metadata['Mother Solution Viscosity Checkup'] != undefined ? metadata['Mother Solution Viscosity Checkup'] : "-",
              dissolvedocc: metadata['Dissolved Oxygen Concentration Checkup'] != undefined ? metadata['Dissolved Oxygen Concentration Checkup'] : "-",
              vdeviation: metadata['Viscosity Deviation'] != undefined ? metadata['Viscosity Deviation'] : "-",
              vthreshold: metadata['Viscosity Threshold'] != undefined ? metadata['Viscosity Threshold'] : "-",
              fratioc: metadata['Filter Ratio Checkup'] != undefined ? metadata['Filter Ratio Checkup'] : "-",
              startdatetimestamp: item.startTime
            }
            this.rawgridData.push(dataRow);
          }
          });
          this.sortData();
          this.gridData = addSortableDates(this.rawgridData, this.kendoGrid);
        } else { this.gridData = []; }
      });
  }

}
