import { Injectable } from '@angular/core';
import { TabOptionsEnum, ESP, JP } from './calibrationmodels';


@Injectable({
  providedIn: 'root'
})
export class CalibrationService {
  private _selectedTab: TabOptionsEnum = TabOptionsEnum.ESP;

  public get selectedTab(): TabOptionsEnum {
    return this._selectedTab;
  }

  public set selectedTab(value: TabOptionsEnum) {
    this._selectedTab = value;
  }

  constructor() { }
}
