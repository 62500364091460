<div #mainContainer>
  <div class="k-float-right">
    <div *ngIf="pumpCurveBaseSequences.length > 0 && !loading && pumpCurveBaseSequence">
      <mat-form-field slbFormField class="form-field" appearance="outline" [style]="{ width: '250px' }">
        <mat-label>Curve:</mat-label>
        <mat-select placeholder="Curve" panelClass="dls-panel" (selectionChange)="onPumpCurveBaseSelected($event)" [(value)]="pumpCurveBaseSequence.externalId" [hideSingleSelectionIndicator]="true">
          <mat-option *ngFor="let pumpCurveBaseSequence of pumpCurveBaseSequences" [value]="pumpCurveBaseSequence.externalId">{{ pumpCurveBaseSequence.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="dynamicCurveSequences.length > 0 && !loading && dynamicCurveSequence">
      <mat-form-field slbFormField class="form-field" appearance="outline" [style]="{ width: '250px' }">
        <mat-label>Operating Point:</mat-label>
        <mat-select placeholder="Operating Point" panelClass="dls-panel" (selectionChange)="onDynamicCurveSelected($event)" [(value)]="dynamicCurveSequence.externalId" [hideSingleSelectionIndicator]="true">
          <mat-option *ngFor="let dynamicCurveSequence of dynamicCurveSequences" [value]="dynamicCurveSequence.externalId">{{ dynamicCurveSequence.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="(pumpCurveBaseSequences.length > 0 || dynamicCurveSequences.length > 0) && !loading">
    <highcharts-chart *ngIf="!loading && this.chartOptions !== null" [Highcharts]="Highcharts" constructorType="chart" [options]="chartOptions"></highcharts-chart>
  </div>
  <div class="no-data-container" *ngIf="!loading && pumpCurveBaseSequences.length === 0">
    <p>No Data</p>
  </div>
  <div class="spinner-container" *ngIf="loading">
    <div class="spinner__loading">
      <mat-spinner [diameter]="30" [strokeWidth]="3"></mat-spinner>
    </div>
  </div>
</div>
