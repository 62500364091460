import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { LoaderService } from '../../services/loader.service';
import { concentrationModel } from './model';
import { GridComponent } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-event-concentration-alarm',
  templateUrl: './event-concentration-alarm.component.html',
  styleUrls: ['./event-concentration-alarm.component.css']
})
export class EventConcentrationAlarmComponent {
  @Input()
  WellListToloadGrid: any[] = [];

  @Input()
  Type: string = '';

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input() title: string;

  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public gridData: concentrationModel[] = [];
  public rawgridData: concentrationModel[] = [];

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService) {
  }

  ngOnInit() {

  }
  refresh() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0
      && moment(this.startDate).isValid() && moment(this.endDate).isValid()) {
      this.loadGridData();
    }
  }
  ngOnChanges() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0 && moment(this.startDate).isValid()
      && moment(this.endDate).isValid()) {

      this.loadGridData();
    } else {
      this.gridData = [];
    }
  }
  sortData() {
    if (this.rawgridData.every((obj: any) => 'startdatetimestamp' in obj)) {
      this.rawgridData.sort((a: any, b: any) => b.startdatetimestamp - a.startdatetimestamp);
    } else {
      console.error("Some objects lack the 'startdatetimestamp' key. Sorting is not feasible.");
    }
  }
  loadGridData() {
    this.gridData = [];
    this.rawgridData = [];
    this.loader.showLoader()
    let selectedWell = this.WellListToloadGrid.map((a: any) => a.externalId);
    this.apiService.getEventlist(this.startDate, this.endDate, 'injector alert', selectedWell).then(eventResponse => {
      this.loader.hideLoader();
      if (eventResponse.length > 0) {
        eventResponse.forEach(item => {
          if (item.subtype.includes('concentration')) {
            let metadata: any = item.metadata;
            let dataRow = {
              welllinkedasset: metadata['Well Name'] != undefined ? metadata['Well Name'] : "-",
              startdatetime: moment(item.startTime).format("DD-MM-YYYY HH:mm"),
              enddatetime: moment(item.endTime).format("DD-MM-YYYY HH:mm"),
              type: item?.subtype != undefined || item?.subtype != "" ? item?.subtype : "-",
              motherscc: metadata['Mother Solution Concentration Checkup'] != undefined ? metadata['Mother Solution Concentration Checkup'] : "-",
              fitwirc: metadata['FIT Water injection rate Checkup'] != undefined ? metadata['FIT Water injection rate Checkup'] : "-",
              cdeviation: metadata['Concentration Deviation'] != undefined ? metadata['Concentration Deviation'] : "-",
              correctedc: metadata['Corrected Concentration'] != undefined ? metadata['Corrected Concentration'] : "-",
              targetc: metadata['Target Concentration'] != undefined ? metadata['Target Concentration'] : "-",
              cthreshold: metadata['Concentration Threshold'] != undefined ? metadata['Concentration Threshold'] : "-",
              fitpolyinjrc: metadata['FIT Polymer injection rate Checkup'] != undefined ? metadata['FIT Polymer injection rate Checkup'] : "-",
              rdilutratio: metadata['Recommended Dilution Ratio'] != undefined ? metadata['Recommended Dilution Ratio'] : "-",
              rinjwrate: metadata['Recommended Injection Water Rate'] != undefined ? metadata['Recommended Injection Water Rate'] : "-",
              rpolyrate: metadata['Recommended Polymer Rate'] != undefined ? metadata['Recommended Polymer Rate'] : "-",
              backgroundc: metadata['Background Concentration'] != undefined ? metadata['Background Concentration'] : "-",
              startdatetimestamp: item.startTime
            }
            this.rawgridData.push(dataRow);
          }
        });
        this.sortData();
        this.gridData = addSortableDates(this.rawgridData, this.kendoGrid);
      } else { this.gridData = []; }
    });
  }

}
