import { Component, Input } from '@angular/core';
import { LossAccountingModel } from '../lossaccountingmodel';
import { HighchartsService } from '../../services/highcharts.service'

export enum Spacing {
  Dense = 'dense',
  Standard = 'standard',
}

@Component({
  selector: 'app-lossaccounting-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent {
  @Input()
  data: LossAccountingModel[] = [];
  spacing = Spacing.Standard;

  public options: any;
  plannedresult: any[];
  unplannedresult: any[];

  constructor(private highchart: HighchartsService) { }

  ngOnInit() {
    this.preaparechart();
  }
  ngOnChanges() {
   this.preaparechart();
  }
  preaparechart()
  {
      this.plannedresult = this.rollupdata("Planned");
      this.unplannedresult = this.rollupdata("Unplanned");
      this.getoptions();
      this.highchart.createChart('container', this.options);
  }
  rollupdata(category: string): any[] {
    var array = this.data.filter((item: any) => item.category1 == category);
    let result: any[] = [];
    let resultarray: any[] = [];
    const rollup = array.reduce(function (res: any, value: any) {
      if (!res[value.startdate]) {
        let sdate = (new Date(value.startdate)).getTime();
        res[value.startdate] = { date: sdate, loss_oil: 0 };
        result.push(res[value.startdate])
      }
      if (value.loss_oil != "-")
        res[value.startdate].loss_oil += Number(value.loss_oil);
      return res;
    }, {});
    result.forEach(item => {
      resultarray.push([item.date, item.loss_oil])

    });
    return resultarray;

  }
  showEmptyChart() {
    this.options = {
      
      chart: {
        height: '100%',
        marginTop: 0,
        
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Planned vs Unplanned Losses'
      },
    };

    this.highchart.createChart("sunburncontainer", this.options);
  }
  getoptions() {
    this.options = {
      chart: {
        type: 'area',
        height: '80%',
      },
      accessibility: {
        description: '',
      },
      title: {
        text: 'Planned vs Unplanned Losses'
      },
      xAxis: {
        type: 'datetime',
        minTickInterval: 86400000,
        tickmarkPlacement: "on",
        labels: {
          enabled: true,
        },
        //allowDecimals: false,
      },
      yAxis: {
        title: {
          text: 'Losses (bbl)'
        }
      },
      tooltip: {
          pointFormat: '<b>{series.name}<b> : {point.y:,.0f} bbl'
      },
      plotOptions: {
        area: {
          //pointStart: 1940,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 3,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [{
        name: 'Planned',
        color: '#F88750',
        data: this.plannedresult,
      //   tooltip: {
      //     formatter: function() {
      //         let obj = this as any
      //         return 'The value for <b>' + obj.x + '</b> is <b>' + obj.y + '</b>, in series '+ obj.series.name;
      //     }
      // }
      }, {
        name: 'Unplanned',
        color: '#83ACEE',
        data: this.unplannedresult
      }]
    }
  }

}

