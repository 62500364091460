import { Component, Input, OnChanges } from '@angular/core';
import { NodeAndEdgeCollectionResponseV3Response } from '@cognite/sdk/dist/src';
import { environment } from 'src/environments/environment';
import { CogniteAuthService } from 'src/app/services/auth.service';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../../well-book.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DsDepthInterpolationServiceService } from '../ds-depth-interpolation-service.service';

@Component({
  selector: 'app-deviation-calculate',
  templateUrl: './deviation-calculate.component.html',
  styleUrls: ['./deviation-calculate.component.css'],
})
export class DeviationCalculateComponent implements OnChanges {

  @Input() wellSelected: any;
  @Input() sequenceData: any[];

  public loading: boolean = false;
  public formgroup: FormGroup;
  public successMessage: string = '';
  public errorMessage: string = '';
  public data: any[] = [];

  private readonly cdfDef: CdfSpaceView | null = null;

  constructor(private readonly wbService: WellBookService, private readonly apiService: CognitApiService, private readonly fb: FormBuilder, private readonly dsDepthInterpolationService: DsDepthInterpolationServiceService, private readonly cogniteAuthService: CogniteAuthService) {
    this.cdfDef = this.wbService.getSpaceDefinition('deviation');
  }

  ngOnChanges(): void {
    this.formgroup = this.fb.group({
      eunit: new FormControl({ value: '', disabled: true }, Validators.required),
      elevation: new FormControl({ value: '', disabled: true }, Validators.required),
      coverter: new FormControl({ value: '', disabled: false }, Validators.required),
      depth: [0, Validators.required],
    });

    this.clearData();

    if (this.wellSelected != null && this.wellSelected != '')
      this.loadData();
  }

  public clearData() {
    this.successMessage = '';
    this.errorMessage = '';
    this.data = [];
  }

  public async onLocalCalculate() {
    try {
      this.clearData();

      this.loading = true;
      const values = await this.dsDepthInterpolationService.run(this.sequenceData, this.wellSelected, Number(this.formgroup.get('depth')?.value), (this.formgroup.get('coverter')?.value as any));

      this.successMessage = Object.entries(values).map(([key, value]) => `${key}: ${value}`).join(', ');
      this.data = [values];
    } catch (e) {
      this.errorMessage = (e as string);
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  public onCalculate() {
    this.loading = true;

    this.clearData();

    this.cogniteAuthService.getapiToken().then(res => {
      if (res) {
        const token = res;
        const url = `${environment.webApiUrl}/Cognite/ExecuteCogniteFunction`

        const data = {
          name: environment.DepthInterpolationFunctionName,
          data: {
            well_external_id: this.wellSelected,
            target_depth: this.formgroup.get('depth')?.value,
            conversion: this.formgroup.get('coverter')?.value,
          },
        };

        this.apiService.saveFunction(url, data, token).subscribe({
          next: (data: any) => {
            if (data.response.error) {
              this.errorMessage = data.response.error;
            } else {
              this.successMessage = Object.entries(data.response).map(([key, value]) => `${key}:${value}`).join(', ');
            }

            this.loading = false;
          },
          error: err => {
            console.error(err);
            this.loading = false;
          },
        });
      }
    }).catch(err => {
      console.error(`Error in function call: ${err}`);
      this.loading = false;
    });
  }

  private loadData() {
    this.clearData();

    if (!this.cdfDef || !this.wellSelected)
      return;

    this.loading = true;

    const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

    this.apiService.getInstancelist(this.cdfDef.id, filter, this.cdfDef.version, environment.spaces.wellbook.id).subscribe({
      next: (data: NodeAndEdgeCollectionResponseV3Response) => {
        if (data?.items?.length > 0 && this.cdfDef) {
          const properties = data?.items?.[0]?.properties?.[this.cdfDef.space]?.[this.cdfDef.fullName] ?? null;
          this.formgroup.controls.eunit.setValue(properties?.elevationUnit);
          this.formgroup.controls.elevation.setValue(properties?.elevation);
        }

        this.loading = false;
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }
}
