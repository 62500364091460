import { Component, ViewChild } from '@angular/core';
import moment from 'moment';
import { LoaderService } from '../../services/loader.service'
import { HierarchyService } from '../../services/hierarchy.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';

@Component({
  selector: 'app-driftalarm',
  templateUrl: './driftalarm.component.html',
  styleUrls: ['./driftalarm.component.css']
})
export class DriftalarmComponent {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public filterStartdate: any;
  public filterEnddate: any;
  public showDateRange: boolean = false;
  public showClearButton: boolean = false;
  public loading: boolean = false;
  public wellPadList: any;
  public filterWellList: any;
  public selectedField: string = "";
  public selectedwell: string = "";
  public selectedwellPad: string = "";
  public WellListToloadGrid: any[] = [];

  constructor(
    private hierarchyService: HierarchyService,
    ) {
    this.showDateRange = true;
    this.showClearButton = true;
  }

  ngOnInit() {

  }

  clear() {
    this.selectedField = "";
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.WellListToloadGrid = [];
    this.hierarchyComponent.selectedField = "";
    this.hierarchyComponent.selectedwellPad = "";
    this.hierarchyComponent.selectedwell = "";
    this.hierarchyComponent.wellPadList = [];
    this.hierarchyComponent.filterWellList = [];
  }


  onDateSelected(event: any) {
    this.filterStartdate = moment(event.event.startDate).toDate();
    this.filterEnddate = moment(event.event.endDate).add(1, 'days').toDate();
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.WellListToloadGrid = event.well;
  }

  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
  }

  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    if (this.filterWellList == undefined)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
  }

}
