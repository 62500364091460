import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[toggleFullscreen]',
})
export class ToggleFullscreenDirective {

  @Input('toggleFullscreen') fullscreenContainer: ElementRef | HTMLDivElement;

  @Output() onFullscreenChanged: EventEmitter<boolean> = new EventEmitter();

  private isFullscreen: boolean = false;
  private originalParent: HTMLElement | null = null;
  private originalNextSibling: HTMLElement | null;

  constructor(private readonly renderer: Renderer2) {

  }

  @HostListener('click')
  toggleFullscreen() {
    if (!this.fullscreenContainer) {
      console.warn('The container is not defined to set as fullscreen.');
      return;
    }

    this.isFullscreen ? this.setOriginalSize() : this.setFullscreen();
    this.onFullscreenChanged.emit(this.isFullscreen);
  }

  private setFullscreen() {
    const container = this.getContainer();

    this.originalParent = container.parentNode as HTMLElement;
    this.originalNextSibling = container.nextSibling;

    this.renderer.appendChild(document.body, container);

    this.isFullscreen = true;
    this.renderer.addClass(container, 'fullscreen-container');
  }

  private setOriginalSize() {
    const container = this.getContainer();

    if (this.originalNextSibling) {
      this.renderer.insertBefore(this.originalParent, container, this.originalNextSibling);
    } else if (this.originalParent) {
      this.renderer.appendChild(this.originalParent, container);
    }

    this.isFullscreen = false;
    this.renderer.removeClass(container, 'fullscreen-container');
  }

  private getContainer(): any {
    return this.fullscreenContainer instanceof ElementRef ? this.fullscreenContainer.nativeElement : this.fullscreenContainer;
  }

}
