<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onChange($event)" [(value)]="Liftselectedtab">
    <mat-button-toggle [value]="liftTypetabOptions.ESP">ESP Overview</mat-button-toggle>
    <mat-button-toggle [value]="liftTypetabOptions.JP">JP Overview</mat-button-toggle>
    <mat-button-toggle [value]="liftTypetabOptions.PCP">PCP Overview</mat-button-toggle>
    <mat-button-toggle [value]="liftTypetabOptions.HRP">HRP Overview</mat-button-toggle>
    <mat-button-toggle [value]="liftTypetabOptions.NF">NF Overview</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [showClearButton]="true" [getListofAllWellsUnderField]="true" [LiftTypeToloadWells]="Liftselectedtab" (onclear)="clear()" (ondatechange)="onDateSelected($event)" [showonlyFieldControl]="true" [selectedWellType]="'Producer'" [AddAllFieldValue]="true"></app-hierarchy>
</div>
<div class="page-content">
  <div class="mat-card">
    <div>
      <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15"></app-auto-refresh>
      <app-i-frame [title]="'Cairn ASP Surveillance'" [src]="iframeurl"></app-i-frame>
      <div class="pumpchart" *ngIf="liftTypetabOptions.JP === Liftselectedtab">
        <mat-card>
          <app-pressure-chart [chartId]="'chart'" [Title]="'MPT Chart'" [Type]="this.Liftselectedtab" [startDate]="this.filterStartdate" [endDate]="this.filterEnddate" [properties]="this.chartConditions"></app-pressure-chart>
        </mat-card>
      </div>
      <mat-card class="alrmgrid">
        <mat-card-header>
          <mat-card-title>Alert Log Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <kendo-grid appGridAutoTooltip #kendoGrid [kendoGridBinding]="alarmgridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="well" [filterable]="true" [sortable]="true" [hidden]="(liftTypetabOptions.HRP === Liftselectedtab || liftTypetabOptions.NF === Liftselectedtab)">
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                  <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="alarmgridData">
                    <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                  </button>
                  <button type="button" kendoGridPDFCommand>
                    <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-template>
            <kendo-grid-column field="well" title="Well" [width]="160"></kendo-grid-column>
            <kendo-grid-column field="workflowName" title="Workflow Name" [width]="130"></kendo-grid-column>
            <kendo-grid-column field="startDateTime_ts" title="Start Date" [width]="170">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="endDateTime_ts" title="End Date" [hidden]="true">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="alarmName" title="Alarm Name" [width]="170"></kendo-grid-column>
            <kendo-grid-column field="alarmFlags" title="Alarm Flags" [width]="170"></kendo-grid-column>
            <kendo-grid-column field="alarmMessage" title="Alarm Message" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="alarmType" title="Alarm Type" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="alarmLevel" title="Alarm Level" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="driftWindow" title="Drift Window" [width]="120" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="maxDeviation" title="Max Deviation" [width]="120" [hidden]="Liftselectedtab !== liftTypetabOptions.PCP"></kendo-grid-column>
            <kendo-grid-column field="allowedDeviation" title="Allowed Deviation" [width]="120" [hidden]="Liftselectedtab !== liftTypetabOptions.PCP"></kendo-grid-column>
            <kendo-grid-excel fileName="Alert Log Summary.xlsx" ></kendo-grid-excel>
            <kendo-grid-pdf fileName="Alert Log Summary.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
          <div *ngIf="(liftTypetabOptions.HRP === Liftselectedtab || liftTypetabOptions.NF === Liftselectedtab)">
            <app-event-alarm [WellListToloadGrid]="this.WellListToloadGrid" [Type]="this.Liftselectedtab" [startDate]="this.filterStartdate" [endDate]="this.filterEnddate" title="Alert Log Summary"></app-event-alarm>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
