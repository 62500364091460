<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [retainHierarchy]="true" [selectDefaultFieldValue]="true" (onFieldChange)="onfieldChange($event)" (onWellPadChange)="onfilterwellPadChange($event)" (onWellchange)="onFilterWellChange($event)" (ondatechange)="onDateSelected($event)" (onclear)="clear()" [showDateRange]="showDateRange" [showClearButton]="showClearButton"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>ALS Well Alarm</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15"></app-auto-refresh>
        <div *ngIf="loading" class="spinner__loading">
          <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
        </div>
        <kendo-grid #alarmGrid appGridAutoTooltip [kendoGridBinding]="alarmgridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="well" [filterable]="true" [sortable]="true" appGridTransform>
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button"
              class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid"
              [appExportToExcel]="alarmGrid" 
              [exportColumnRules]="exportColumnRules" 
              [data]="alarmgridData">
              <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
            </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
          <kendo-grid-column field="workflowName" title="Workflow  Name" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="startDateTimeValue" title="Start Date" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="startDateTime">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="endDateTime_ts" title="End Date" [hidden]="true">
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.endDateTime }}</ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter>
              <app-date-time-filter [filter]="filter" valueField="endDateTime">
              </app-date-time-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="alarmName" title="Alarm Name" [width]="140"></kendo-grid-column>
          <!-- <kendo-grid-column field="alarmFlags" title="Alarm Flags"></kendo-grid-column> -->
          <kendo-grid-column field="alarmMessage" title="Alarm Message"></kendo-grid-column>
          <kendo-grid-column field="alarmType" title="Alarm Type" [hidden]="true" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="alarmLevel" title="Alarm Level" [width]="80"></kendo-grid-column>
          <kendo-grid-excel fileName="ALS Well Alarm.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Alert Well Alarm.pdf" [allPages]="true"></kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
</div>
