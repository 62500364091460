<div *ngIf="loading" class="spinner-overlay">
  <div class="center">
    <div class="loading-text loading">Loading<span class="dots"></span></div>
  </div>
</div>
<div *ngIf="showCardTitle==true && this.chart != null">
  <span style="font-size: 1.2em; color: rgb(240, 240, 243); font-weight: bold; fill: rgb(240, 240, 243);">{{Title}}</span>
  <span
    style="margin: 10px;">
    <button type="button" style="vertical-align: middle;" (click)="refresh()"><mat-icon svgIcon="refresh" matTooltip="Click to get latest data"></mat-icon></button>
</span>
</div>
<div id="linecontainer-{{ chartId }}"> </div>