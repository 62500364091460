import { Injectable } from '@angular/core';
import { CogniteAuthService } from './auth.service';
import { Observable, catchError, throwError, from, map } from 'rxjs';
import { RawDBRow, ListRawRows, Model3D, ViewReference, NodeAndEdgeCollectionResponseV3Response, ExternalDatapointsQuery, CursorAndAsyncIterator, Timeseries, ListResponse, FilterDefinition, SourceSelectorWithoutPropertiesV3, InstanceType, CogniteEvent, ExternalEvent, EventChange, IdEither, NodeOrEdgeListRequestV3, CogniteClient, Sequence, SequenceRowsRetrieve, SequenceRow, FileInfo, FileLink } from '@cognite/sdk/dist/src';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { underlineIcon } from '@progress/kendo-svg-icons';
import { limit } from '@progress/kendo-data-query/dist/npm/array.operators';
interface ItemRow {
  rowNumber: number;
  values: (string | number)[];
}

interface Item {
  externalId: string;
  columns: string[];
  rows: ItemRow[];
}

@Injectable({
  providedIn: 'root'
})

// All APIs to get the data can go into this service
export class CognitApiService {
  private baseUrl: string = "https://westeurope-1.cognitedata.com/api/v1/projects/slb-psdc";
  private congniteSdk: CogniteClient;
  constructor(private cogniteAuthService: CogniteAuthService,
    private http: HttpClient) {
    this.congniteSdk = this.cogniteAuthService.getSdk();
  }
  /**
   * return instance record of viewid wich match filter
   * @param externalId viewID from sdm view
   * @param filter filter on property
   * @param version version number  of view
   * @returns
   */
  public getInstance(externalId: string, filter: any, version = environment.cogniteSDMVersion, space = environment.cogniteSpace, limit = 100): Observable<NodeAndEdgeCollectionResponseV3Response> {
    let viewobj: ViewReference;
    let filter1: FilterDefinition = filter;
    viewobj = {
      externalId: externalId,
      space: space,
      type: "view",
      version: version

    }
    let payload: any = {
      "view": viewobj,
      "query": "",
      filter: filter,
      limit: limit
      // "properties": [
      //   "externalId",
      //   "name"
      // ],
    }
    if (externalId == "VfmOutput") { payload.limit = 1000; }

    return from(
      this.congniteSdk.instances.search(payload)).pipe(catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }));
  }


  public getInstancelist(externalId: string, filter: any, version = environment.cogniteSDMVersion, space = environment.cogniteSpace): Observable<NodeAndEdgeCollectionResponseV3Response> {
    let viewobj: ViewReference;
    let InstanceType: InstanceType;
    InstanceType = "node"
    viewobj = {
      externalId: externalId,
      space: space,
      type: "view",
      version: version

    }
    const payload = {
      sources: [{
        source: viewobj,
      }],
      filter: filter,
      limit: 1000
    };
    return from(
      this.congniteSdk.instances.list(payload));
  }

  /**
   * get instance list data along with next cursor data
   * @param externalId viewID externalId
   * @param filter filter on list
   * @param version version of view
   * @param space model space
   * @param limit data limit
   * @returns
   */
  public getInstancelistWithcursorData(externalId: string, filter: any, version = environment.cogniteSDMVersion, space = environment.cogniteSpace, limit = 1000): Observable<NodeAndEdgeCollectionResponseV3Response> {
    let viewobj: ViewReference;
    let InstanceType: InstanceType;
    InstanceType = "node"
    viewobj = {
      externalId: externalId,
      space: space,
      type: "view",
      version: version,

    }
    const payload: NodeOrEdgeListRequestV3 = {
      sources: [{
        source: viewobj,
      }],
      filter: filter,
      limit: limit,
      // sort: [
      //   {
      //     direction: 'ascending', //descending
      //     property: ['startDateTime']
      //   }
      // ]

    };
    return from(
      this.getinstancelistcursordata(payload)
    );
  }

  private async getinstancelistcursordata(payload: NodeOrEdgeListRequestV3) {
    let cursor: any;
    let data: any[] = [];
    let previouscursor: any;
    let responsedata: any;
    do {
      previouscursor = cursor;
      const response = await this.congniteSdk.instances.list(payload).finally();
      if (responsedata == undefined) responsedata = response;
      data = data.concat(response.items)
      if (response.nextCursor != previouscursor && response.nextCursor != undefined) {
        cursor = response.nextCursor;
        payload.cursor = cursor;
        console.log(cursor);
      }
      else
        cursor = undefined

    } while (cursor != undefined)
    responsedata.items = data;
    return responsedata;
  }

  public getSequenceList(filter: any): Observable<Sequence | ListResponse<Sequence[]>> {
    return from(this.congniteSdk.sequences.list(filter));
  }

  public getSequenceRows(query: SequenceRowsRetrieve): Observable<SequenceRow | ListResponse<SequenceRow[]>> {
    return from(this.congniteSdk.sequences.retrieveRows(query));
  }

  public async getSequenceListAll(filter: any): Promise<Sequence[]> {
    const data: any[] = [];
    let nextCursor: string | null | undefined = '';

    do {
      const response: Sequence | ListResponse<Sequence[]> = await this.fetchSequenceData(filter);

      try {
        if ('items' in response) {
          data.push(...response.items);
          nextCursor = response.nextCursor;
        } else {
          data.push(response);
          nextCursor = null;
        }
      } catch (e) {
        console.error(e);
        nextCursor = null;
      }

      filter.cursor = nextCursor;
    } while (nextCursor !== null && nextCursor !== undefined && nextCursor !== '');

    return data;
  }

  public async getSequenceRowsAll(filter: any): Promise<SequenceRow[]> {
    const data: any[] = [];
    let nextCursor: string | null | undefined = '';

    do {
      const response: SequenceRow | ListResponse<SequenceRow[]> = await this.fetchSequenceRows(filter);

      try {
        if ('items' in response) {
          data.push(...response.items);
          nextCursor = response.nextCursor;
        } else {
          data.push(response);
          nextCursor = null;
        }
      } catch (e) {
        console.error(e);
        nextCursor = null;
      }

      filter.cursor = nextCursor;
    } while (nextCursor !== null && nextCursor !== undefined && nextCursor !== '');

    return data;
  }

  private fetchSequenceData(filter: any): Promise<Sequence | ListResponse<Sequence[]>> {
    return new Promise(res => {
      this.getSequenceList(filter).subscribe({
        next: data => {
          res(data);
        },
        error: err => {
          console.error(err);
          res({ nextCursor: '', items: [] });
        },
      });
    });
  }

  private fetchSequenceRows(filter: any): Promise<SequenceRow | ListResponse<SequenceRow[]>> {
    return new Promise(res => {
      this.getSequenceRows(filter).subscribe({
        next: data => {
          res(data);
        },
        error: err => {
          console.error(err);
          res({ nextCursor: '', items: [] });
        },
      });
    });
  }

  public getDocumentList(filter: any): Observable<any> {
    return from(this.congniteSdk.documents.list(filter));
  }

  public getFilesList(filter: any): Observable<FileInfo | ListResponse<FileInfo[]>> {
    return from(this.congniteSdk.files.list(filter));
  }

  public searchFiles(filter: any): Observable<FileInfo[]> {
    return from(this.congniteSdk.files.search(filter));
  }

  public getDocumentDownloadUrl(documentId: number): Observable<Object> {
    const request = this.congniteSdk.getBaseUrl() + `${(this.congniteSdk as any).filesApi.resourcePath}`.replace('/', '') + '/downloadlink?extendedExpiration=true';
    const token = (this.congniteSdk as any).tokenPromise.__zone_symbol__value;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });

    const payload = { items: [{ id: documentId }] };

    return this.http.post(request, payload, { headers: headers });
  }

  public async insertTimeSeriesData(datapoints: ExternalDatapointsQuery): Promise<{}> {
    return await this.congniteSdk.datapoints.insert([datapoints]);
  }
  public async insertbulkTimeSeriesData(datapoints: ExternalDatapointsQuery[]): Promise<{}> {
    return await this.congniteSdk.datapoints.insert(datapoints);
  }
  /**
   * get id from external id list
   * @param externalIdArray array of external ID  ex:{ "externalId": "12345" }
   * @returns
   */
  public async getbulkTsId(externalIdArray: any) {
    return await this.congniteSdk.timeseries.retrieve(externalIdArray);

  }

  public async getTsId(timeseriesname: string): Promise<ListResponse<Timeseries[]>> {
    return await this.congniteSdk.timeseries.list({ filter: { name: timeseriesname } })
  }
  /**
   * get tsid of externalid list
   * @param timeseriesexternalId  externalidlist
   * @returns
   */
  public async getTsIdusingExternalId(timeseriesexternalId: string[]): Promise<ListResponse<Timeseries[]>> {
    return await this.congniteSdk.timeseries.list({ filter: { assetExternalIds: timeseriesexternalId } })
  }

  public async deleteTimeseriesDataPoints(externalIdArray: any) {
    const data = await this.congniteSdk.datapoints.delete(externalIdArray);
    return data;
  }

  public async updateTimeseriesMetadata(id: number, propeties: any): Promise<Timeseries[]> {
    return await this.congniteSdk.timeseries.update([{
      id: id,
      update: {
        metadata: {
          set: propeties

        },
      }
    }]);
  }

  public async getLatestTimeseriesData(externalIdArray: any) {
    const data = await this.congniteSdk.datapoints.retrieveLatest(externalIdArray);
    return data;
  }
  public async getTimeseriesData(externalIdArray: any, start: any = undefined, end: any = undefined, limit: number = 1000) {
    let starttime = start == undefined ? -2206243270000 : start;
    const data = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, limit: limit });
    return data;
  }
  public async getTimeseriesDataRange(externalIdArray: any, starttime: any, endtime: any, limit = 100) {
    let finalresponse: any[] = [];
    while (true) {
      const response = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: endtime, limit: limit });
      let nextcursorinput: any[] = [];
      response.forEach((item: any) => {
        let index = finalresponse.findIndex(x => x.externalId == item.externalId && (x.unitExternalId == "" || x.unitExternalId == item.unitExternalId));
        if (index >= 0)
          finalresponse[index].datapoints = finalresponse[index].datapoints.concat(item.datapoints);
        else
          finalresponse.push(item);

        if (item["nextCursor"] != undefined && item["nextCursor"] != "") {
          var inputitem;
          inputitem = { "externalId": item.externalId, "cursor": item.nextCursor }
          nextcursorinput.push(inputitem)
        }
      });
      if (nextcursorinput.length == 0)
        break;
      else
        externalIdArray = nextcursorinput;
    }
    return finalresponse;
  }
  public async getTimeseriesDataAvgRange1(externalIdArray: any, starttime: any, endtime: any) {
    let finalresponse: any[] = [];
    while (true) {
      const response = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: endtime, granularity: "1d", aggregates: ["average"] });
      let nextcursorinput: any[] = [];
      response.forEach((item: any) => {
        if (finalresponse.length == 0)
          finalresponse = response;
        else {
          let index = finalresponse.findIndex(x => x.externalId == item.externalId);
          finalresponse[index].datapoints = finalresponse[index].datapoints.concat(item.datapoints);
          //let data = finalresponse.filter((res: any) => res.externalId == item.externalId)[0].datapoint;
        }
        if (item["nextCursor"] != undefined && item["nextCursor"] != "") {
          var inputitem;
          inputitem = { "externalId": item.externalId, "cursor": item.nextCursor }
          nextcursorinput.push(inputitem)
        }
      });
      if (nextcursorinput.length == 0)
        break;
      else
        externalIdArray = nextcursorinput;
    }
    return finalresponse;
  }
  public async getTimeseriesDataAvg(externalIdArray: any) {
    const data = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: -2206243270000, granularity: "1d", aggregates: ["average"] });
    return data;
  }

  public async getTimeseriesDataAvgRange(externalIdArray: any, starttime: any, endtime: any) {
    const data = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: endtime, granularity: "1d", aggregates: ["average"] });
    return data;
  }
  public async getTimeseriesList1(externalIdArray: any) {
    let cursor: any;
    let data: any[] = []
    do {
      const response = await this.congniteSdk.timeseries.list({ filter: { assetExternalIds: externalIdArray, metadata: { "workflow": "wtv" } }, limit: 1000, cursor: cursor }).finally();
      data = data.concat(response.items)
      cursor = response.nextCursor;

    } while (cursor != undefined)

    return data;
  }

  public async getTimeseriesList(externalIdArray: any, meta: any) {
    let cursor: any;
    let data: any[] = []
    do {
      const response = await this.congniteSdk.timeseries.list({ filter: { assetExternalIds: externalIdArray, metadata: meta }, limit: 1000, cursor: cursor }).finally();
      data = data.concat(response.items)
      cursor = response.nextCursor;

    } while (cursor != undefined)

    return data;
  }

  public async getTimeseriesAvgRange(externalIdArray: any, starttime: any, endtime: any, Granularity: string) {
    let Datapoint: any[] = [];
    do {
      const response = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: endtime, granularity: Granularity, aggregates: ["average"], limit: 200 });
      externalIdArray = [];
      if (response.length) {
        if (!Datapoint.length) {
          response.forEach((item: any) => {
            Datapoint.push({ datapoints: item.datapoints, externalId: item.externalId, unit: item.unit });
            if (item.nextCursor != undefined) {
              externalIdArray.push({ "externalId": item.externalId, cursor: item.nextCursor });
            }
          });
        } else {
          response.forEach((item: any) => {
            if (item.nextCursor != undefined && item.datapoints.length) {
              externalIdArray.push({ "externalId": item.externalId, cursor: item.nextCursor });
              Datapoint = Datapoint.map(data => {
                if (data.externalId == item.externalId) {
                  let newdata = data.datapoints.concat(item.datapoints);
                  return { ...data, datapoints: newdata };
                } else {
                  return data;
                }
              });
            } else if (item.nextCursor == undefined && item.datapoints.length) {
              Datapoint = Datapoint.map(data => {
                if (data.externalId == item.externalId) {
                  let newdata = data.datapoints.concat(item.datapoints);
                  return { ...data, datapoints: newdata };
                } else {
                  return data;
                }
              });
            }
          });
        }
      }
    } while (externalIdArray.length);
    //console.log(Datapoint);
    return Datapoint;
  }


  public async getTimeseries(externalIdArray: any) {
    const data = await this.congniteSdk.timeseries.retrieve(externalIdArray);
    return data;
  }

  public async getRawList(databaseName: string, tableName: string) {
    let cursor: any;
    let data: any[] = []
    do {
      const response = await this.congniteSdk.raw.listRows(databaseName, tableName, { limit: 1000, cursor: cursor }).finally();
      data = data.concat(response.items)
      cursor = response.nextCursor;
      console.log(cursor);
    } while (cursor != undefined)

    return data;
  }

  public saveFunction(url: string, body: any, token: string) {
    let headers = {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }

    return this.http.post<any>(url, body, { headers }).pipe(catchError((error: HttpErrorResponse) => {
      return throwError(error);
    }));;
  }

  //event Api

  /**
   * get event list
   * @param startTime start date timestamp
   * @param endTime enddatetimestap
   * @param type event type
   * @param assetExternalIds linked asset external id list
   * @returns
   */
  public async getEventlist(startTime: any, endTime: any, type: string, assetExternalIds: any[]) {
    let cursor: string | undefined;
    let data: any[] = []
    let filter = {
      startTime: { min: startTime },
      endTime: { max: endTime },
      type: type,
      assetExternalIds: assetExternalIds,
    }
    while (true) {
      const response = await this.congniteSdk.events.list({ filter: filter, cursor: cursor })
      data = data.concat(response.items)
      if (response.nextCursor != undefined) {
        cursor = response.nextCursor;
      }
      else
        break;
    }
    return data;
  }

  public async getEventListWithCustomFilter(filter: any) {
    let cursor: string | undefined;
    let data: any[] = [];

    while (true) {
      const response = await this.congniteSdk.events.list({ filter: filter, cursor: cursor });

      data = data.concat(response.items)

      if (response.nextCursor !== undefined && response.nextCursor !== null) {
        cursor = response.nextCursor;
      } else {
        break;
      }
    }

    return data;
  }

  /**
   * create events with type using external ID
   * @param eventdata list of event data
   * @returns
   */
  public async createEvent(eventdata: ExternalEvent[]) {
    return await this.congniteSdk.events.create(eventdata);
  }
  /**
   * update event
   * @param eventdata  event data to be updated
   * @returns
   */
  public async updateEvent(eventdata: EventChange[]) {
    return await this.congniteSdk.events.update(eventdata);
  }
  /**
   * delete list of events
   * @param eventdata list of id ['id':123]
   * @returns
   */
  public async deleteEvent(eventdata: any[]) {
    return await this.congniteSdk.events.delete(eventdata);
  }
  //end event API
  //Asset Apis
  /**
   * retrive assets using asset ids
   * @param idList list of asset id or externalid list
   * @returns asset details like name
   */
  public async getAssetsByID(idList: any[]) {
    return await this.congniteSdk.assets.retrieve(idList)
  }
  //end Asset API
  //dataset Apis
  /**
   * get dataset details using id list or externalid list
   * @param idlist list of id or external id
   * @returns
   */
  public async getDatasetDetailsById(idlist: any[]) {
    return await this.congniteSdk.datasets.retrieve(idlist);
  }
  public async getTimeseriesDataRangeNew(externalIdArray: any, starttime: any, endtime: any) {
    const data = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: endtime, limit: 1000 });
    return data;
  }

  public isListResponse<T>(value: any): value is ListResponse<T> {
    return 'next' in value;
  }


  public async getTimeseriesDataforWaterCutSample(externalIdArray: any, start: any = undefined, end: any = undefined) {
    let starttime = start == undefined ? -2206243270000 : start;
    const data = await this.congniteSdk.datapoints.retrieve({ items: externalIdArray, start: starttime, end: end, limit: 1000 });
    return data;
  }

  public async getAssets(parentExternalId: string, name: string) {
    try {
      const assets = await this.congniteSdk.assets.list({
        filter: {
          name: name,
          parentExternalIds: [parentExternalId],
        },
        limit: 1000,
      });
      return assets.items; // Return the list of assets
    } catch (error) {
      console.error('Error fetching assets:', error);
      return [];
    }
  }
  /**
    * get event list
    * @param startTime start date timestamp
    * @param endTime enddatetimestap
    * @param type event type
    * @param assetExternalIds linked asset external id list
    * @returns
    */
  public async getEventlistwithoutType(startTime: any, endTime: any, assetExternalIds: any[]) {
    let cursor: string | undefined;
    let data: any[] = []
    let filter = {
      startTime: { min: startTime },
      endTime: { max: endTime },

      assetExternalIds: assetExternalIds,
    }
    while (true) {
      const response = await this.congniteSdk.events.list({ filter: filter, cursor: cursor })
      data = data.concat(response.items)
      if (response.nextCursor != undefined) {
        cursor = response.nextCursor;
      }
      else
        break;
    }
    return data;
  }

  /**
    * get event list
    * @param startTime start date timestamp
    * @param endTime enddatetimestap
    * @param type event type
    * @param external_id_prefix linked external_id_prefix
    * @returns
    */
  public async getEventlistusingexternal_id_prefix(
    startTime: any,
    endTime: any,
    type: string,
    external_id_prefix: any,
    dropDown: any
  ): Promise<any[]> {
    let cursor: string | undefined;
    let data: any[] = [];
    let filter: any = {
      startTime: { min: startTime },
      endTime: { max: endTime },
      type: type,
    };

    // Apply filters based on the dropdown selection
    switch (dropDown) {
      case "All":
      case "Field":
        filter.externalIdPrefix = external_id_prefix;
        break;

      case "WellPad":
        filter.subtype = external_id_prefix;
        break;

      case "Well":
        filter.metadata = { "full_well_name": external_id_prefix };
        break;

      default:
        throw new Error("Invalid dropdown selection");
    }

    // Fetching events with pagination
    while (true) {
      const response = await this.congniteSdk.events.list({ filter: filter, cursor: cursor });
      data = data.concat(response.items);
      cursor = response.nextCursor; // Update cursor for the next iteration
      if (!cursor) break; // Exit the loop if no more pages
    }

    return data;
  }


  public async getTimeseriesDataRangeforWellTestParameter(
    externalIdArray: any,
    starttime: any,
    endtime: any,
    limit: number = 10000 // Adjust this limit to prevent exceeding 100,000 total data points
  ): Promise<any[]> {
    let finalresponse: any[] = [];

    // Determine the limit per series to avoid exceeding 100,000 total points
    const totalSeries = externalIdArray.length;
    const adjustedLimit = Math.min(Math.floor(100000 / totalSeries), limit);

    while (true) {
      try {
        const response = await this.congniteSdk.datapoints.retrieve({
          items: externalIdArray,
          start: starttime,
          end: endtime,
          limit: adjustedLimit
        });

        let nextcursorinput: any[] = [];

        response.forEach((item: any) => {
          const existingIndex = finalresponse.findIndex(x =>
            x.externalId === item.externalId &&
            (x.unitExternalId === "" || x.unitExternalId === item.unitExternalId)
          );

          if (existingIndex >= 0) {
            finalresponse[existingIndex].datapoints.push(...item.datapoints);
          } else {
            finalresponse.push(item);
          }

          if (item["nextCursor"]) {
            nextcursorinput.push({ externalId: item.externalId, cursor: item.nextCursor });
          }
        });

        if (nextcursorinput.length === 0) break;

        externalIdArray = nextcursorinput;
      } catch (error) {
        console.error("Error retrieving data:", error);
        throw new Error("Data retrieval failed");
      }
    }

    return finalresponse;
  }
  public async insertDataIntoSequence(sequenceId: number, items: Item[]) {
    // Format rows for insertion
    const formattedRows = items[0].rows.map(row => ({
      rowNumber: row.rowNumber,
      values: row.values
    }));
    // Insert rows into CDF
    const response = await this.congniteSdk.sequences.insertRows([
      { id: sequenceId, rows: formattedRows, columns: items[0].columns }
    ]);

  }
  public async deletedRowFromSequence(externalId: any, rowNumber: any) {
    const response = await this.congniteSdk.sequences.deleteRows([{ id: externalId, rows: [rowNumber] }]);
  }

}



function InstanceSort(arg0: string, arg1: string, arg2: any) {
  throw new Error('Function not implemented.');
}

