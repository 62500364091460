import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { PDFViewerComponent } from '@progress/kendo-angular-pdfviewer';

@Directive({
  selector: '[autoFitPdf]',
})
export class AutoFitPdfDirective implements AfterViewInit {

  @Input() parentId: string;

  constructor(private pdfViewer: PDFViewerComponent, private el: ElementRef) {

  }

  ngAfterViewInit(): void {
    this.pdfViewer.load.subscribe(() => this.adjustZoom());
  }

  private adjustZoom() {
    let kendoComboBox;

    if (this.parentId)
      kendoComboBox = document.getElementById(this.parentId)?.querySelector('kendo-combobox[ng-reflect-placeholder="Choose zoom level"]');

    if (!kendoComboBox)
      kendoComboBox = document.querySelector('kendo-combobox[ng-reflect-placeholder="Choose zoom level"]');

    const dropdownButton = kendoComboBox?.querySelector('button');

    if (!kendoComboBox || !dropdownButton)
      return;

    dropdownButton.click();

    setTimeout(() => {
      const fitOption: any = document.querySelector('.k-list-content ul li');
      fitOption?.click();
    }, 100);
  }

}
