import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appGridAutoTooltip]',
})
export class GridAutoTooltipDirective implements AfterViewInit, OnChanges {

  @Input() kendoGridBinding: any[];

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {

  }

  ngAfterViewInit() {
    this.applyTooltips();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kendoGridBinding && changes.kendoGridBinding.previousValue !== changes.kendoGridBinding.currentValue) {
      setTimeout(() => this.applyTooltips(), 1000);
    }
  }

  private applyTooltips() {
    const headers = this.el.nativeElement.querySelectorAll('.k-grid th');
    const cells = this.el.nativeElement.querySelectorAll('.k-grid td');

    [...headers, ...cells].forEach((cell: HTMLElement) => {
      this.renderer.setAttribute(cell, 'title', cell.textContent?.trim() ?? '');
    });
  }

}
