export enum TabOptionsEnum {
    ESP = "ESP",
    JP = "JP",
    PCP = "PCP",
}
export interface CalibrationInputModel {
    'sequence'?: number,
    'id'?: number;
    'startdate'?: string;
    'timestamp'?: string;
    'rpfrom'?: number | string;
    'rpto'?: number | string;
    'rpstepsize'?: number | string;
    'knfrom'?: number | string;
    'knto'?: number | string;
    'knstepsize'?: number | string;
    'pifrom'?: number | string;
    'pito'?: number | string;
    'pistepsize'?: number | string;
    'ksfrom'?: number | string;
    'ksto'?: number | string;
    'ksstepsize'?: number | string;
    'estimatedks'?: number | string;
    'estimatedrp'?: number | string;
    'estimatedkn'?: number | string;
    'estimatedpi'?: number | string;
    'field'?: string | undefined | string[];
    'wellPad'?: string | undefined | string[];
    'well'?: string | undefined | string[];
}


export interface ESP {
    'key': string;
    'casenumber': string;
    'wellnumber': string,
    'executedate': string;
    'lifttype': string;
    'status': string;
    'respressure': string,
    'liquidrate': string;
    'oilrate': string;
    'waterrate': string;
    'gasrate': string;
    'frictionfactor': string;
    'holdupfactor': string;
    'pumpwearfactor': string;
    'dpfriction': string;
    'dpgravity': string;
    'pi': string;
}

export interface JP {
    'key': string;
    'casenumber': string;
    'wellnumber': string,
    'executedate': string;
    'lifttype': string;
    'status': string;
    'respressure': string,
    'liquidrate': string;
    'oilrate': string;
    'waterrate': string;
    'gasrate': string;
    'frictionfactor': string;
    'holdupfactor': string;
    'suctionloss': string;
    'diffuserloss': string;
    'kn': string;
    'pi': string;
    'pip': string;
}

export interface wellModel {
    'well': string;
    'workflowName': string;
    'startDateTime': string;
    'alarmName': string;
    'alarmMessage': string;
    'alarmType': string;
    'alarmLevel': string;
}

export interface CalibrationTriggerModel {
    'id'?: number;
    'well'?: string;
    'date'?: string;
    'thp'?: number | string;
    'tht'?: number | string;
    'watercut'?: number | string;
    'gor'?: number | string;
    'liquidrate'?: number | string;
    'frequency'?: number | string;
    'pip'?: number | string;
    'pdp'?: number | string;
    'annuluspressure'?: number | string;
    'pfrate'?: number | string;
    'rpressurelow'?: number | string;
    'rpressurehigh'?: number | string;
    'rpressurestepsize'?: number | string;
    'pindexlow'?: number | string;
    'pindexhigh'?: number | string;
    'pindexstepsize'?: number | string;
    'knlow'?: number | string;
    'knhigh'?: number | string;
    'knstepsize'?: number | string;
    'kshigh'?: number | string;
    'kslow'?: number | string;
    'ksstepsize'?: number | string;
    'disable'?: boolean;
    'status'?:string;
    'logtext'?:string;
}

export interface CalibrationSumaryModel {
    'id'?: number;
    'well'?: string;
    'calrundate'?:string;
    'testdate'?: string;
    'rundate'?: string;
    'liquidrate'?: number | string;
    'inputliquidrate'?: number | string;
    'watercut'?: number | string;
    'gor'?: number | string;
    'fthp'?: number | string;
    'pip'?: number | string;
    'pdp'?: number | string;
    'inputpip'?: number | string;
    'inputpdp'?: number | string;
    'frequency'?: number | string;
    'frfactor'?: number | string;
    'holdfactor'?: number | string;
    'rpressure'?: number | string;
    'pi'?: number | string;
    'pumpwfactor'?: number | string;
    'annuluspressure'?: number | string;
    'pfrate'?: number | string;
    'ks'?: number | string;
    'kn'?: number | string;
}