import { Component, OnDestroy } from '@angular/core';
import { adminsubMenu, alssubMenu,calibrationsubMenu, lossAccountingsubMenu, menuItemList, wellSurveillancesubMenu, WellTestValidationsubMenu, WellBooksubMenu, ProductionAllocationsubMenu } from '../shared/menuItems';
import { NavItem, isDefined } from '@slb-dls/angular-material/shared';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { Subscription, filter, map } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SlbNotificationItem } from '@slb-dls/angular-material/notifications-panel';
import { CogniteAuthService } from '../services/auth.service';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
    notificationCount: number = 10;
    notificationItems: SlbNotificationItem[] = [];
    loggedInUser:string;
    showHeader: boolean = true;
    pageTitle: string = '';
    sideMenuList: NavItem[] = menuItemList;
    secondaryLinks: NavItem[] = [];

    private routerSubscription = Subscription.EMPTY;

    constructor(
        private router: Router,
        private AcRoute: ActivatedRoute,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        private cogniteAuthService: CogniteAuthService,
        private msalService: MsalService) {

        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));
        matIconRegistry.addSvgIcon("wellsurviallance", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/surviallanceicon.svg"))
            .addSvgIcon("la", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/lossaccounting.svg"))
            .addSvgIcon("wtv", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/welltestvalidation.svg"))
            .addSvgIcon("ns", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/netsurv.svg"))
            .addSvgIcon("wr", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/wellranking.svg"))
            .addSvgIcon("reservoir", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/reservoir.svg"))
            .addSvgIcon("wb", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/wellbook.svg"))
            .addSvgIcon("admin", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/admin.svg"))
            .addSvgIcon("admin1", domSanitizer.bypassSecurityTrustResourceUrl("assets/vedantalogo.svg"))
            .addSvgIcon("tachyus", domSanitizer.bypassSecurityTrustResourceUrl("assets/icon/tachyus.svg"))
            .addSvgIcon('expand', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/expand.svg'))
            .addSvgIcon('close', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/close.svg'))
            .addSvgIcon('pdf', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/pdf.svg'))
            .addSvgIcon('hide', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/hide.svg'))
            .addSvgIcon('show', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/show.svg'))
            .addSvgIcon('draw', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/draw.svg'))
            .addSvgIcon('stopDraw', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/stopDraw.svg'))
            .addSvgIcon('delete', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/delete.svg'))
            .addSvgIcon('addText', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/addText.svg'))
            .addSvgIcon('removeText', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/removeText.svg'))
            .addSvgIcon('drag', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/drag.svg'))
            .addSvgIcon('zoomIn', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/zoomin.svg'))
            .addSvgIcon('zoomOut', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/zoomout.svg'))
            .addSvgIcon('excel', domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/excel.svg'));
        // to set menu when specific url is hit in browser
        this.routerSubscription = this.AcRoute.url.subscribe(url => {
            this.setmenuSelectedUrl(window.location.pathname);
        });
        this.routerSubscription = this.router.events.pipe(filter(e => e instanceof RoutesRecognized), map(e => e as RoutesRecognized))
            .subscribe(e => {
                this.onRouteChange(e)
            });

         this.loggedInUser = this.cogniteAuthService.loggedInUser;
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }
    ngOnInit(): void {
        // this.getdata();

    }
    async getdata() {
        var cognitclient = this.cogniteAuthService.getSdk();
        try {
            var list = await cognitclient.assets.list();
            console.log('printing congite response')

            console.log(list);
        }
        catch (e) {
            console.log(e);
        }
    }

    setmenuSelectedUrl(url: string) {
        try {
            url = url.substring(1);
            let selectedsubmenu = '';

            menuItemList.forEach(menu => {
                const mainmenu = String(menu.routerLink).split('/');
                const menuurl = url.split('/')[0]?.toLowerCase();
                const module = mainmenu.length > 0 ? mainmenu[0].trim().toLowerCase() : '';

                if (module.includes(menuurl)) {
                    menu.active = true;
                    selectedsubmenu = String(menu.label);
                    this.loadsubmenu(String(menu.hint));
                } else {
                    menu.active = false;
                }

            });
            this.secondaryLinks.forEach(link => {
              let linkActive = false;

              if (link.routerLink === url || (link.routerLink as string[])[0] === url) {
                linkActive = true;
              } else {
                const urlRoutes = url.split('/');

                if (urlRoutes.every((r, i) => r === (link.routerLink as string[])[i] )) {
                  link.active = true;
                } else {
                  link.active = false
                }
              }

              if (linkActive) {
                link.active = true;
                selectedsubmenu = String(link.label);
              }
            });
            const data: any = this.AcRoute.firstChild?.data;
            if (data != undefined) {
                this.showHeader = isDefined(data.showHeader) ? data.showHeader : true;
                this.pageTitle = data._value.title;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    private onRouteChange(event: any): void {
        this.setmenuSelectedUrl(event.url);
        const data: any = event.state.root.firstChild?.data;
        this.showHeader = isDefined(data.showHeader) ? data.showHeader : true;
        if(data.title == 'Als'){
        this.pageTitle =  String(data.title).toUpperCase();
        }else{
        this.pageTitle = data.title;
        }
    }
    public onSelectPrimaryNavItem(event: any) {
        console.log("event")
    }

    /**
     * need to add case once new menu is added to load submenu related to it and get name of
     *  submenu object from the hint property of navlist menu list
     * @param submenu
     */

    public loadsubmenu(submenu: string) {
        switch (submenu) {
            case "wellSurveillancesubMenu": {
                this.secondaryLinks = wellSurveillancesubMenu;
                break;
            }
            case "lossAccountingsubMenu": {
                this.secondaryLinks = lossAccountingsubMenu;
                break;
            }
            case "adminsubMenu": {
                this.secondaryLinks = adminsubMenu;
                break;
            }
            case "alssubMenu": {
                this.secondaryLinks = alssubMenu;
                break;
            }
             case "calibrationsubMenu": {
                this.secondaryLinks = calibrationsubMenu;
                break;
            }
            case "lossAccountingsubMenu": {
                this.secondaryLinks = lossAccountingsubMenu;
                break;
            }
            case "WellTestValidationsubMenu": {
                this.secondaryLinks = WellTestValidationsubMenu;
                break;
            }
            case 'WellBooksubMenu': {
              this.secondaryLinks = WellBooksubMenu;
              break;
            }
            case 'ProductionAllocationsubMenu': {
                this.secondaryLinks = ProductionAllocationsubMenu;
                break;
              }
            default: {
                this.secondaryLinks = wellSurveillancesubMenu;
                break;
            }
        }

    }

    public onSelectSecondaryNavItem(event: any) {
        if(event.selectedItem.label == "Tachyus")
            window.open(event.selectedItem.routerLink[0]);
        else
        {
        let submenu = event.selectedItem.hint;
        this.loadsubmenu(submenu);
        this.router.navigate(event.selectedItem.routerLink)
        }

    }
    public Logout(){
        this.loggedInUser="";
        this.msalService.logout();
    }
}
