<mat-card>
  <mat-card-header>
    <mat-card-title>Pump Curve variables</mat-card-title>
    <div class="header-right-tools">
      <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="onAddVariablesClick()">
        <mat-icon svgIcon="add"></mat-icon>
        <span>Add</span>
      </button>
      <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base delete-button" type="button" (click)="ondelete(undefined)" [disabled]="selectedrows === undefined || selectedrows?.length === 0">
        <mat-icon svgIcon="delete"></mat-icon>
        <span>Delete</span>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content style="margin-top: 10px;">
    <kendo-grid appGridAutoTooltip [pageable]="true" [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [filterable]="true" [sortable]="true" [pageSize]="10" kendoGridSelectBy="sequence" [selectable]="true" [(selectedKeys)]="selectedrows" appGridTransform>
      <ng-template kendoGridToolbarTemplate>
        <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
          <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
            <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
          </button>
          <button type="button" kendoGridPDFCommand>
            <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
          </button>
        </div>
      </ng-template>
      <kendo-grid-checkbox-column [showSelectAll]="true" [width]="50"></kendo-grid-checkbox-column>
      <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
      <kendo-grid-column field="well" title="Well"></kendo-grid-column>
      <kendo-grid-column field="startdate_ts" title="Start Date Time" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="viscosity" title="Viscosity (cP)" [filterable]="false"></kendo-grid-column>
      <kendo-grid-column title="Action" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItemvalue>
          <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItemvalue)">
            <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
          </a>
          <a slb-icon-button routerLink="." title="delete" (click)="ondelete(dataItemvalue)">
            <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel fileName="Pump Curve Variables.xlsx"></kendo-grid-excel>
      <kendo-grid-pdf fileName="Pump Curve Variables.pdf" [allPages]="true">
        <kendo-grid-column field="well" title="Well"></kendo-grid-column>
        <kendo-grid-column field="startdate" title="Start Date Time"></kendo-grid-column>
        <kendo-grid-column field="viscosity" title="Viscosity (cP)"></kendo-grid-column>
      </kendo-grid-pdf>
    </kendo-grid>
  </mat-card-content>
</mat-card>
<ng-template #addWllRecordDialog>
  <mat-dialog-content style="height: auto; width: 500px;" class="update-range-dialog-content">
    <mat-card-title class="headerLabel">{{ formMode === 'Add' ? 'Add New' : 'Update' }} Record</mat-card-title>
    <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
    <div class="form-container" style="margin-top: 30px;">
      <form (ngSubmit)='savewellleveldata(wellformgroup)' [formGroup]='wellformgroup'>
        <slb-date-picker hint="" [(value)]="startdate" label="Start Date Time" [disabled]="false" [maxValue]="todaysdate" [disabled]="formMode === 'Edit'"></slb-date-picker>
        <br>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Field</mat-label>
          <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field" (selectionChange)="onwellformfieldChange($event)" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let field of fieldList" [value]="field.externalId">{{ field.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Well Pad</mat-label>
          <mat-select placeholder="Select" formControlName='wellpad' #input [panelClass]="'dls-panel'" (selectionChange)="onformwellpadChange($event)" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let wellpad of formwellPadList" [value]="wellpad.externalId">{{ wellpad.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Well</mat-label>
          <mat-select placeholder="Select" formControlName='well' #input [panelClass]="'dls-panel'" (selectionChange)="onwellChange($event)" [hideSingleSelectionIndicator]="true" [multiple]="true">
            <mat-option *ngFor="let well of formwellList" [value]="well.externalId">{{ well.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Viscosity</mat-label>
          <input type="number" matInput formControlName="viscosity"><span>cP</span>
        </mat-form-field>
        <div class="space-div"></div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="update-range-dialog-actions">
    <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
    <button slb-button [disabled]="!wellformgroup.valid" color="primary" (click)="savewellleveldata(wellformgroup)" matDialogClose="yes">{{ formMode === 'Add' ? 'Create' : 'Update' }}</button>
  </mat-dialog-actions>
</ng-template>
