export interface TolaranceModel {
    'sequence'?: number,
    'id'?: number;
    'condition': string;
    'parameterType': string;
    'value': number|string;
    'unit'?: string;
    'valueunit'?: string;
    'operationType': string;
    'notificationType'?: string;
    'criticality'?: string;
    'userRole'?: string;
    'status': string;
    'startdate'?: string;
    'timestamp'?: string;
    'field'?:string;
    'type'?:string;
    'timeframelogic'?:string;
    'metadata'?:any;
}


export interface ThresholdModel {
    'sequence'?: number,
    'id'?: number;
    'condition': string;
    'value': number;
    'startdate'?: string;
    'timestamp'?: string;
    'field'?:string;
}
export interface WellTolaranceModel {
    'sequence'?: number,
    'id'?: number;
    'condition': string;
    'parameterType': string;
    'value': number;
    'unit'?: string;
    'valueunit'?: string;
    'operationType': string;
    'notificationType'?: string;
    'criticality'?: string;
    'userRole'?: string;
    'status': string;
    'startdate'?: string;
    'field'?: string;
    'wellpad'?: string;
    'well'?: string;
    'timestamp'?: string;
    'type'?:string;
    'timeframelogic'?:string;
    'metadata'?:any;
}
export enum TabOptionsEnum {
    ESP = "ESP",
    JP = "JP",
    PCP = "PCP",
    NONE = "NONE",
    HRP ="HRP"
}
export enum TabOptionsEnumforWellTestDataSummary {
    Summary = "Summary",
    WellTestParameter = "Well Test Parameter",
    WellTestHistory = "Well Test History",
    WellEvent = "Well Event"
}
export interface PumpCurvVariables {
    'sequence'?: number,
    'id'?: number;
    'gasgravity'?: number;
    'apiofoil'?: number;
    'viscosity'?: number;
    'startdate'?: string;
    'timestamp'?:string;
    'formation'?: string;
    'pw'?: number;
    'field'?: string  | string[];
    'wellpad'?: string  | string[];
    'well'?: string  | string[];
}

export interface BaseModel {
    'sequence'?: number,
    'id'?: number;
    'startdate'?: string;
    'timestamp'?: string;
    'field'?:string;
    'wellpad'?:string;
    'well'?:string;
}
export interface InjectorMoitoringTolModel extends BaseModel {
    'targetPolymerConentration'?:number;
    'conentrationDeviation'?:number;
    'viscocityDeviation'?:number;
}