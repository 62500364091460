export enum TabOptionsEnum {
    OVR = "OVR",
    WSS = "WSS"
}

export interface WellSummaryModel
{
    'well':string;
    'wellstatus'?:string;
    'startdate'?:string;
    'starttime'?:string;
    'enddate'?:string;
    'endtime'?:string;
    'duration'?:string;
}
export enum LiftTabOptionsEnum {
    ESP = "ESP",
    HRP = "HRP",
    JP = "JP",
    PCP = "PCP",
    NF = "NATURAL",
    Injector = "Injector"
}