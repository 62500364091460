import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { ExportColumnRule } from './export-column-rule';

@Directive({
  selector: '[appExportToExcel]',
})
export class ExportToExcelDirective implements OnDestroy {

  @Input('appExportToExcel') grid: GridComponent;
  @Input() data: any[] = [];
  @Input() exportColumnRules: ExportColumnRule[];

  private excelExportSubscribe: Subscription;

  constructor() {}

  @HostListener('click') onClick() {
    if (!this.grid)
      return;

    if (this.exportColumnRules?.length > 0) {
      if (!this.excelExportSubscribe)
        this.excelExportSubscribe = this.grid.excelExport.subscribe((args: any) => this.excelExport(args));
    }

    const originalPageSize = this.grid.pageSize;
    const originalData = this.grid.data;

    this.grid.pageSize = this.data?.length ?? 0;
    this.grid.data = this.data;
    this.grid.saveAsExcel();

    this.grid.pageSize = originalPageSize;
    this.grid.data = originalData;
  }

  ngOnDestroy(): void {
    if (this.excelExportSubscribe)
      this.excelExportSubscribe.unsubscribe();
  }

  private excelExport(args: any): void {
    try {
      const headers: any = [];

      args.workbook.sheets[0].rows.filter((e: any) => e.type === 'header').forEach((e: any) => {
        for (const cell of e.cells) {
          if (!cell.colSpan || cell.colSpan === 1)
            headers.push(cell);
        }

        for (const rule of this.exportColumnRules) {
          if (rule.hide === true) {
            const index = e.cells.findIndex((cell: any) => cell.value === rule.field);

            if (index !== -1)
              e.cells.splice(index, 1);
          }
        }
      });

      for (const rule of this.exportColumnRules) {
        try {
          const index = headers.findIndex((cell: any) => cell.value === rule.field);

          if (index >= 0)
            rule.index = index;
        } catch (e) {
          console.error('Failed to evaluate the column rules: ', e);
        }
      }

      args.workbook.sheets[0].rows.filter((e: any) => e.type === 'data').forEach((e: any) => {
        for (const rule of this.exportColumnRules) {
          rule.index = rule.index ?? -1;

          if (rule.index >= 0) {
            if (rule.format) {
              try {
                e.cells[rule.index].value = rule.format(e.cells[rule.index].value);
              } catch (e) {
                console.error('Error trying to apply cell format: ', e);
              }
            }

            if (rule.hide === true) {
              try {
                e.cells.splice(rule.index, 1);
              } catch (e) {
                console.error('Error trying to hide column: ', e);
              }
            }
          }
        }
      });
    } catch (e) {
      console.error('Error while setting excel export options: ', e);
    }
  }

}
