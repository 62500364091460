import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment, { Moment } from 'moment';
import { LoaderService } from '../../services/loader.service';
import { ALShrpNfModel } from './model';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
@Component({
  selector: 'app-event-alarm',
  templateUrl: './event-alarm.component.html',
  styleUrls: ['./event-alarm.component.css']
})
export class EventAlarmComponent {
  @Input()
  WellListToloadGrid: any[] = [];

  @Input()
  Type:string = '';

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input() title: string;

  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public gridData: ALShrpNfModel[] = [];
  public rawgridData: ALShrpNfModel[] = [];

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
    { field: 'End', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService) {
  }

  ngOnInit() {

  }
  refresh() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0
    && moment(this.startDate).isValid() && moment(this.endDate).isValid()){
    this.loadGridData();
    }
  }
 ngOnChanges() {
    if (this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0 && moment(this.startDate).isValid()
    && moment(this.endDate).isValid()) {

      this.loadGridData();
    } else {
      this.gridData = [];
    }
  }
  sortData() {
    if (this.rawgridData.every((obj: any) => 'startdatetimestamp' in obj)) {
      this.rawgridData.sort((a: any, b: any) => b.startdatetimestamp - a.startdatetimestamp);
    } else {
      console.error("Some objects lack the 'startdatetimestamp' key. Sorting is not feasible.");
    }
  }
  loadGridData() {
    this.gridData = [];
    this.rawgridData = [];
    this.loader.showLoader()
      let selectedWell = this.WellListToloadGrid.map((a: any) => a.externalId);
      this.apiService.getEventlist(this.startDate, this.endDate,'parameter_drift', selectedWell).then(eventResponse => {
        this.loader.hideLoader();
        if (eventResponse.length > 0) {
          eventResponse.forEach(item => {
            let metadata: any = item.metadata;
            let dataRow = {
              well: metadata?.well_external_id != undefined || metadata?.well_external_id != "" ? metadata?.well_external_id : "-",
              startdatetime: moment(item.startTime).format("DD-MM-YYYY HH:mm"),
              enddatetime: moment(item.endTime).format("DD-MM-YYYY HH:mm"),
              window: item?.subtype != undefined || item?.subtype != "" ? item?.subtype : "-",
              severity:metadata?.severity != undefined || metadata?.severity != "" ? metadata?.severity : "-",
              driftbasis: metadata?.time_series_external_ids != undefined || metadata?.time_series_external_ids != "" ? metadata?.time_series_external_ids : "-",
              startdatetimestamp: item.startTime
            }
            this.rawgridData.push(dataRow);
          });
          this.sortData();
          this.gridData = addSortableDates(this.rawgridData, this.kendoGrid);
        } else { this.gridData = []; }
      });
  }

}
