import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { ConfirmationDialog } from '../../common-component/confirmation-dialog/confirmation-dialog.component';
import { TabOptionsEnum, ESP, JP } from '../calibrationmodels';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription } from 'rxjs';
import { GroupDescriptor, GroupResult, State, groupBy, process } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import * as _ from 'lodash';

@Component({
  selector: 'app-calibration',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css']
})
export class OutputComponent {

  @ViewChild('espKendoGrid') public espKendoGrid: GridComponent;
  @ViewChild('jpKendoGrid') public jpKendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP;
  public functionId = 'cairn-workflow-calibration-postprocessing';
  private subs: Subscription[] = [];
  public fieldList: any;
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any = [];
  public selectedField: string;
  public selectedwell: string | undefined;
  public selectedwellPad: string | undefined;
  public espData: ESP[] = [];
  public espFilterData: ESP[] = [];
  public jpData: JP[] = [];
  public jpFilterData: JP[] = [];
  public groups: GroupDescriptor[] = [{ field: 'wellnumber' }];
  public espgridData: any = [];
  public jpgridData: any = [];
  public jpUnitobject: any = {};
  public espUnitobject: any = {};
  public wellCount: number = 0;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Executed On Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Click To Save', hide: true },
  ];
  public state: State = {
    skip: 0,
    take: 10,
    group: this.groups
  };

  constructor(
    private dialog: MatDialog,
    private cogniteAuthService: CogniteAuthService,
    private apiService: CognitApiService,
    private loader: LoaderService,
    private cognitDataFormatter: CognitDataFormatter,
    private hierarchyService: HierarchyService) {
  }

  onRadioClick(dataItem: any) {
    this.openDialog(dataItem);
  }

  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Want To Save?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.saveRow(selectedrows);
      }
    });

  }

  private funtionCall(selectedrows: any) {
    let url = 'https://az-pnq-gp-001.cognitedata.com/api/v1/projects/cairn-test/functions/1541445705503934/call';
    // let url = 'https://az-pnq-gp-001.cognitedata.com/api/v1/projects/cairn-test/functions/byids';
    let body = {
      "data": {
        "key": selectedrows.key,
        "casenumber": selectedrows.casenumber
      },
      "nonce": "string"

    }

    /* this.apiService.saveFunction(url, body).subscribe((data: any) => {
           console.log(data);
         }); */
  }

  private async saveRow(selectedrows: any) {
    this.loader.showLoader();
    await this.cogniteAuthService.getapiToken().then(res => {
      if (res) {
        let token = res;

        let url = `${environment.webApiUrl}/Cognite/ExecuteCogniteFunction`

        let data = {
          "name": "cairn-workflow-calibration-postprocessing",
          "data": {
            "key": selectedrows.key,
            "casenumber": selectedrows.casenumber
          }
        }

        this.apiService.saveFunction(url, data, token).subscribe((data: any) => {
          this.loader.hideLoader();
          this.loadGridData();
        },
          error => {
            this.loader.hideLoader();
            console.log(error);
          });


      }
    },
      (error) => {

        if (error) {
          console.log('Error DCA for type well')
        }
      });
  }

  ngOnInit() {
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
    this.loadGridData();
  }

  loadGridData() {
    this.loader.showLoader();
    this.espData = [];
    this.jpData = [];
    this.espgridData = [];
    this.jpgridData = [];
    this.espUnitobject = {};
    this.jpUnitobject = {};

    const promises: Promise<any>[] = [
      this.apiService.getRawList('workflow:sdm:db', 'CalibrationProcessingData')
    ];

    Promise.all(promises)
      .then((results: any[]) => {
        this.loader.hideLoader();
        let data = results[0];
        if (data.length > 0) {
          data.forEach((item: any) => {
            if (item['columns'].WellLiftType == 'ESP' && item['columns'].SimulationStatus == 'Processed') {

              let output = JSON.parse(item['columns'].Output);
              if (output != null || output != undefined) {
                if (output.data != null || output.data != undefined) {
                  output.data.forEach((row: any) => {

                    let espRow: ESP = {
                      key: '',
                      casenumber: '',
                      wellnumber: '',
                      executedate: '',
                      lifttype: '',
                      status: '',
                      respressure: '',
                      liquidrate: '',
                      oilrate: '',
                      waterrate: '',
                      gasrate: '',
                      frictionfactor: '',
                      holdupfactor: '',
                      pumpwearfactor: '',
                      dpfriction: '',
                      dpgravity: '',
                      pi: ''
                    }

                    espRow.key = item['key'];
                    espRow.wellnumber = item['columns'].WellExternalId;
                    espRow.executedate = moment(item['columns'].WellTestStartDate).format('DD-MM-YYYY');
                    espRow.casenumber = row['casenumber'];

                    row.data.forEach((element: any) => {
                      switch (element.propertyName) {
                        case 'ReservoirPressure':
                          espRow.respressure = element.propertyValue.toFixed(2);
                          this.espUnitobject['respressure'] = element.simulationUnit;
                          break;
                        case 'EstLiquidRate':
                          espRow.liquidrate = element.propertyValue.toFixed(2);
                          this.espUnitobject['liquidrate'] = element.simulationUnit;
                          break;
                        case 'EstOilRate':
                          espRow.oilrate = element.propertyValue.toFixed(2);
                          this.espUnitobject['oilrate'] = element.simulationUnit;
                          break;
                        case 'EstGasRate':
                          espRow.gasrate = element.propertyValue.toFixed(3);
                          this.espUnitobject['gasrate'] = element.simulationUnit;
                          break;
                        case 'EstWaterRate':
                          espRow.waterrate = element.propertyValue.toFixed(2);
                          this.espUnitobject['waterrate'] = element.simulationUnit;
                          break;
                        case 'FrictionFactor':
                          espRow.frictionfactor = element.propertyValue.toFixed(2);
                          this.espUnitobject['frictionfactor'] = element.simulationUnit;
                          break;
                        case 'EstDpFriction':
                          espRow.dpfriction = element.propertyValue.toFixed(2);
                          this.espUnitobject['dpfriction'] = element.simulationUnit;
                          break;
                        case 'EstDpGravity':
                          espRow.dpgravity = element.propertyValue.toFixed(2);
                          this.espUnitobject['dpgravity'] = element.simulationUnit;
                          break;
                        case 'PI':
                          espRow.pi = element.propertyValue.toFixed(2);
                          this.espUnitobject['pi'] = element.simulationUnit;
                          break;
                        case 'PumpWearFactor':
                          espRow.pumpwearfactor = element.propertyValue.toFixed(2);
                          this.espUnitobject['pumpwearfactor'] = element.simulationUnit;
                          break;
                        case 'HoldupFactor':
                          espRow.holdupfactor = element.propertyValue.toFixed(2);
                          this.espUnitobject['holdupfactor'] = element.simulationUnit;
                          break;
                      }
                    });
                    this.espData.push(espRow);
                  });
                }
              }
            }

            if ((item['columns'].WellLiftType == 'Jet Pump' || item['columns'].WellLiftType == 'JP')
              && item['columns'].SimulationStatus == 'Processed') {
              let output = JSON.parse(item['columns'].Output);
              if (output != null || output != undefined) {
                if (output.data != null || output.data != undefined) {
                  output.data.forEach((row: any) => {
                    let jpRow: JP = {
                      key: '',
                      casenumber: '',
                      wellnumber: '',
                      executedate: '',
                      lifttype: '',
                      status: '',
                      respressure: '',
                      liquidrate: '',
                      oilrate: '',
                      waterrate: '',
                      gasrate: '',
                      frictionfactor: '',
                      holdupfactor: '',
                      suctionloss: '',
                      diffuserloss: '',
                      kn: '',
                      pi: '',
                      pip: ''
                    }

                    jpRow.key = item['key'];
                    jpRow.wellnumber = item['columns'].WellExternalId;
                    jpRow.executedate = moment(item['columns'].WellTestStartDate).format('DD-MM-YYYY');
                    jpRow.casenumber = row['casenumber'];

                    let resPressureProp = row['SensitivityVariables'].filter((senvar: any) => senvar['sensitivityType'] == 'CaseReservoirPressure')
                    if (resPressureProp && resPressureProp.length > 0) {
                      jpRow.respressure = resPressureProp[0]['sensitivityVariableValue'].toFixed(2);
                      this.jpUnitobject['respressure'] = resPressureProp[0]['sensitivityVariableUom'];
                    }

                    let knProp = row['SensitivityVariables'].filter((senvar: any) => senvar['sensitivityType'] == 'CaseKn')
                    if (knProp && knProp.length > 0) {
                      jpRow.kn = knProp[0]['sensitivityVariableValue'].toFixed(2);
                      this.jpUnitobject['kn'] = knProp[0]['sensitivityVariableUom'];
                    }
                    let piProp = row['SensitivityVariables'].filter((senvar: any) => senvar['sensitivityType'] == 'CasePI')
                    if (piProp && piProp.length > 0) {
                      jpRow.pi = piProp[0]['sensitivityVariableValue'].toFixed(2);
                      this.jpUnitobject['pi'] = piProp[0]['sensitivityVariableUom'];
                    }

                    row.data.forEach((element: any) => {
                      switch (element.propertyName) {
                        // case 'ReservoirPressure':
                        //   jpRow.respressure = element.propertyValue;
                        //   break;
                        case 'Estimated Liquid Rate':
                          jpRow.liquidrate = element.propertyValue.toFixed(2);
                          this.jpUnitobject['liquidrate'] = element.simulationUnit;
                          break;
                        case 'Estimated Oil Rate':
                          jpRow.oilrate = element.propertyValue.toFixed(2);
                          this.jpUnitobject['oilrate'] = element.simulationUnit;
                          break;
                        case 'Estimated Water Rate':
                          jpRow.waterrate = element.propertyValue.toFixed(2);
                          this.jpUnitobject['waterrate'] = element.simulationUnit;
                          break;
                        case 'Estimated Gas Rate':
                          jpRow.gasrate = element.propertyValue.toFixed(3);
                          this.jpUnitobject['gasrate'] = element.simulationUnit;
                          break;
                        case 'Friction Factor':
                          jpRow.frictionfactor = element.propertyValue.toFixed(2);
                          this.jpUnitobject['frictionfactor'] = element.simulationUnit;
                          break;
                        case 'Holdup Factor':
                          jpRow.holdupfactor = element.propertyValue.toFixed(2);
                          this.jpUnitobject['holdupfactor'] = element.simulationUnit;
                          break;

                        // case 'PI':
                        //   jpRow.pi = element.propertyValue;
                        //   break;
                        case 'PIP':
                          jpRow.pip = element.propertyValue.toFixed(2);
                          this.jpUnitobject['pip'] = element.simulationUnit;
                          break;
                        case 'EstimatedKs':
                          jpRow.suctionloss = element.propertyValue.toFixed(2);
                          this.jpUnitobject['suctionloss'] = element.simulationUnit;
                          break;
                        case 'EstimatedKd':
                          jpRow.diffuserloss = element.propertyValue.toFixed(2);
                          this.jpUnitobject['diffuserloss'] = element.simulationUnit;
                          break;
                      }
                    });
                    this.jpData.push(jpRow);
                  });
                }
              }
            }
          });
          this.espData = addSortableDates(this.espData, this.espKendoGrid);
          this.jpData = addSortableDates(this.jpData, this.jpKendoGrid);
          this.processdata();

          //this.espgridData = addSortableDates(groupBy(this.espData, this.groups), this.espKendoGrid);
          //this.jpgridData = addSortableDates(groupBy(this.jpData, this.groups), this.jpKendoGrid);

        } else { this.loader.hideLoader(); this.wellCount = 0;}
      })
      .catch((error) => {
        this.loader.hideLoader();
        this.wellCount = 0;
        console.error("At least one promise rejected:", error);
      });
  }
  protected espdataStateChange(state: DataStateChangeEvent): void {
    state.group?.map(
      group => group.field = this.groups[0].field);
    this.state = state;
    this.espgridData = process(this.espFilterData, this.state);
  }
  protected jpdataStateChange(state: DataStateChangeEvent): void {
    state.group?.map(
      group => group.field = this.groups[0].field);
    this.state = state;
    this.jpgridData = process(this.jpFilterData, this.state);
  }
  public getFielTextWithUnit(paramName: string, unit: string, defaultUnit: string = '') {
    const unitTxt = this.getUnit(unit, this.selectedtab);
    if (!!unitTxt)
      return `${paramName} (${unitTxt})`;

    return defaultUnit ? `${paramName} (${defaultUnit})` : `${paramName}`;
  }

  private getUnit(field: string, type: string): string {
    const typeObject = type === 'ESP' ? this.espUnitobject : this.jpUnitobject;
    return typeObject[field] || '';
  }

  clear() {
    this.filterWellList = [];
    this.selectedField = '';
    this.selectedwell = '';
    this.selectedwellPad = '';
    this.processdata();
    this.espUnitobject = {};
    this.jpUnitobject = {};
  }


  onfieldChange(event: any) {
    this.selectedwell = ""
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.allFieldwells;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.processdata();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }

  }
  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.filterWellList = event.well;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.processdata();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }
  }
  onfilterwellChange(event: any) {
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.allFieldwells;
    this.selectedwell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedwellPad = event.selectedwellPad;
    if (this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.processdata();
    }
    else {
      this.espgridData = [];
      this.jpgridData = [];
      this.wellCount = 0;
    }
  }


  onTabChange($event: any) {
    this.selectedtab = $event.value;
    this.filterWellList = [];
    this.selectedField = '';
    this.selectedwell = '';
    this.selectedwellPad = '';
    this.processdata();
  }

  processdata() {

    this.state = {
      skip: 0,
      take: 10,
      group: this.groups
    };
    let externalid = this.filterWellList.map((x: any) => x.externalId);
    if (this.selectedtab == this.tabOptions.ESP) {
      let filtereddata = _.cloneDeep(this.espData.filter((x: any) => externalid.includes(x.wellnumber)));
      this.espFilterData = externalid.length > 0 ? addSortableDates(filtereddata, this.espKendoGrid) : this.espData;
      this.espgridData = process(this.espFilterData, this.state);
      const uniqueGroupNames = new Set(this.espFilterData.map((item:any) => item.wellnumber));
      this.wellCount = uniqueGroupNames.size;
    }
    else if (this.selectedtab == this.tabOptions.JP) {
      let filtereddata = _.cloneDeep(this.jpData.filter((x: any) => externalid.includes(x.wellnumber)));
      this.jpFilterData = externalid.length > 0 ? addSortableDates(filtereddata, this.jpKendoGrid) : this.jpData;
      this.jpgridData = process(this.jpFilterData, this.state);
      const uniqueGroupNames = new Set(this.jpFilterData.map((item:any) => item.wellnumber));
      this.wellCount = uniqueGroupNames.size;
    }


  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

}
