import { Injectable } from '@angular/core';
import { ListResponse, Sequence, SequenceRow } from '@cognite/sdk/dist/src';
import { lastValueFrom, sequenceEqual } from 'rxjs';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { Interp1d } from './Interp1d';

declare interface Config {
  externalId: string;
  targetDetph: number;
  conversion: 'MD to TVDRT/TVDSS' | 'TVDRT to MD' | 'TVDSS to MD';
}

@Injectable({
  providedIn: 'root',
})
export class DsDepthInterpolationServiceService {

  private sequendeData: SequenceRow[] = [];

  constructor(private readonly apiService: CognitApiService) {

  }

  public run(sequenceData: SequenceRow[], externalId: string, targetDepth: number, conversion: 'MD to TVDRT/TVDSS' | 'TVDRT to MD' | 'TVDSS to MD'): { [propName: string]: any } {
    if (!externalId || !targetDepth || !conversion)
      throw new Error('Invalid data');

    this.sequendeData = sequenceData;

    return this.handle({ externalId: externalId, targetDetph: targetDepth, conversion: conversion});
  }

  private async handle(config: Config) {
    if (!config)
      this.logger('The data is invalid', 'error');

    this.logger(`Running with config(${JSON.stringify(config)})`);

    const [ deviationSurvey, rtElevation ] = await this.getSequenceByAssetExternalId(config.externalId);
    this.checkUserProvidedDepthVersusSurveyData(config, (deviationSurvey as { [propName: string]: number }[]));

    const interpolationMethod = this.checkSurveyIsSuitableForMinimumCurvature((deviationSurvey as { [propName: string]: number }[])) ? 'minimumCurvature' : 'linear';

    this.logger(`Selected interpolation method: ${interpolationMethod}`);

    const depthResult = this.interpolateDepth(config, (deviationSurvey as { [propName: string]: number }[]), interpolationMethod);

    return this.logAndReturnResults(config, interpolationMethod, depthResult, (rtElevation as number), config.conversion);
  }

  private async getSequenceByAssetExternalId(externalId: string) {
    let deviationSurvey: any[] | undefined;
    let rtElevation: number | undefined;

    try {
      const filter: any = { filter: { externalIdPrefix: 'Deviation_', assetSubtreeIds: [{ externalId: externalId }] }};
      const data: Sequence | ListResponse<Sequence[]> = await lastValueFrom(this.apiService.getSequenceList(filter));
      let sequence: Sequence;

      if (this.apiService.isListResponse(data)) {
        if (data.items.length === 0)
          throw new Error(`No deviation data found for well ${externalId}`);

        if (data.items.length > 1)
          throw new Error(`Multiple sequences found for well external id ${externalId}`);

        sequence = data.items[0];
      } else {
        sequence = data;
      }

      const deviationDf = this.sequendeData?.length > 0 ? this.sequendeData : await this.apiService.getSequenceRowsAll({ externalId: sequence.externalId });
      rtElevation = Number(sequence.metadata?.RT_elevation_above_AMSL_m ?? 0);

      if (deviationDf === undefined || deviationDf === null || deviationDf.length === 0)
        this.logger(`No deviation data found for well ${externalId}, sequence external id: ${sequence.externalId}`);

      this.logger(`Found deviation survey data for well ${externalId}, sequence external id: ${sequence.externalId}`);

      deviationSurvey = this.apiService.convertSequenceRowsToDict(deviationDf);
      deviationSurvey.sort((a, b) => a.MD - b.MD);

      return [deviationSurvey, rtElevation];
    } catch (e) {
      this.logger(`${e}`, 'error');
    }

    return [undefined, undefined];
  }

  private checkUserProvidedDepthVersusSurveyData(config: Config, deviationSurvey: { [propName: string]: number }[]): void {
    let conversionColumn: string;
    let targetDetph = config.targetDetph;
    let minDepth: number = 0;
    let maxDepth: number = 0;

    if (config.conversion === 'MD to TVDRT/TVDSS') {
      conversionColumn = 'MD';
    } else if (config.conversion === 'TVDRT to MD') {
      conversionColumn = 'TVDRT';
    } else if (config.conversion === 'TVDSS to MD') {
      conversionColumn = 'TVDSS';
      targetDetph = Math.abs(config.targetDetph);
    } else {
      this.logger('Invalid conversion type provided in configuration', 'error');
      return;
    }

    const columnValues = deviationSurvey.map(row => row[conversionColumn]);
    minDepth = Math.min(...columnValues);
    maxDepth = Math.max(...columnValues);

    if (targetDetph < minDepth || targetDetph > maxDepth)
      this.logger(`User provided depth ${targetDetph} is not within the range of the deviation survey data for well ${config.externalId}: min survey depth: ${minDepth}, max surve depth: ${maxDepth}`, 'error');
  }

  private checkSurveyIsSuitableForMinimumCurvature(deviationSurvey: { [propName: string]: number }[]): boolean {
    const requiredColumns = ['MD', 'INC', 'AZI', 'DLS'];

    if (!deviationSurvey.every(row => requiredColumns.every(col => col in row && row[col] !== null && row[col] !== undefined && !isNaN(row[col]))))
      return false;

    return true;
  }

  private interpolateDepth(config: Config, deviationSurvey: { [propName: string]: number }[], interpolationMethod: string) {
    if (interpolationMethod === 'minimumCurvature') {
      if (config.conversion === 'MD to TVDRT/TVDSS')
        return this.minimumCurvatureMDToTVD(config, deviationSurvey);

      if (config.conversion === 'TVDRT to MD' || config.conversion === 'TVDSS to MD')
        return this.minimumCurvatureTVDToMD(config, deviationSurvey);
    } else if (interpolationMethod === 'linear') {
      if (config.conversion === 'MD to TVDRT/TVDSS')
        return this.linearInterpolationMDToTVD(config, deviationSurvey);

      if (config.conversion === 'TVDRT to MD' || config.conversion === 'TVDSS to MD')
        return this.linearInterpolationTVDToMD(config, deviationSurvey);
    }

    return null;
  }

  private minimumCurvatureMDToTVD(config: Config, deviationSurvey: { [propName: string]: number }[], dlsThreshold: number = 1e-2): [ number, number] {
    let interpolatedTvdrt: number = 0;
    let interpolatedTvdss: number = 0;

    deviationSurvey.sort((a, b) => a.MD - b.MD);
    const targetMd = config.targetDetph;

    const below = deviationSurvey.filter(point => point.MD <= targetMd).at(-1);
    const above = deviationSurvey.filter(point => point.MD > targetMd).at(0);

    if (!above || !below) {
      this.logger('MD invalid values', 'error');
      throw new Error('MD invalid values');
    }

    if (targetMd === below.MD)
      return [below.TVDRT, below.TVDSS];

    if (targetMd === above.MD)
      return [above.TVDRT, above.TVDSS];

    const deltaMd = above.MD - below.MD;
    const deltaInc = (above.INC - below.INC) * (Math.PI / 180);
    const deltaAzi = (above.AZI - below.AZI) * (Math.PI / 180);
    const dogLeg = Math.sqrt(deltaInc ** 2 + (Math.sin(below.INC * (Math.PI / 180)) * deltaAzi) ** 2);

    const dls = dogLeg / deltaMd;

    if (dls < dlsThreshold) {
      const deltaTvd = deltaMd * Math.cos(below.INC * (Math.PI / 180));
      interpolatedTvdrt = below.TVDRT + (targetMd - below.MD) * (deltaTvd / deltaMd);
      interpolatedTvdss = Math.abs(below.TVDSS) + (targetMd - below.MD) * (deltaTvd / deltaMd);
    } else {
      const factor = dls !== 0 ? (2 / dls) * Math.tan(dogLeg / 2) : 1;
      const deltaTvd = deltaMd * Math.cos((below.INC + factor) * (Math.PI / 180));
      interpolatedTvdrt = below.TVDRT + deltaTvd * ((targetMd - below.MD) / deltaMd);
      interpolatedTvdss = Math.abs(below.TVDSS) + deltaTvd * ((targetMd - below.MD) / deltaMd);
    }

    return [interpolatedTvdrt, interpolatedTvdss];
  }

  private minimumCurvatureTVDToMD(config: Config, deviationSurvey: { [propName: string]: number }[], dlsThreshold: number = 1e-2): number {
    let interpolatedMd: number = 0;

    deviationSurvey.sort((a, b) => a.MD - b.MD);
    const targetTvd = Math.abs(config.targetDetph);
    const conversionColumn = config.conversion.includes('TVDRT') ? 'TVDRT' : 'TVDSS';

    const below = deviationSurvey.filter(point => point[conversionColumn] <= targetTvd).at(-1);
    const above = deviationSurvey.filter(point => point[conversionColumn] > targetTvd).at(0);

    if (!above || !below) {
      this.logger('MD invalid values', 'error');
      throw new Error('MD invalid values');
    }

    if (targetTvd === below[conversionColumn])
      return below.MD;

    if (targetTvd === above[conversionColumn])
      return above.MD;

    const deltaMd = above.MD - below.MD;
    const deltaTvd = Math.abs(above[conversionColumn] - below[conversionColumn]);
    const deltaInc = (above.INC - below.INC) * (Math.PI / 180);
    const deltaAzi = (above.AZI - below.AZI) * (Math.PI / 180);
    const dogLeg = Math.sqrt(deltaInc ** 2 + (Math.sin(below.INC * (Math.PI / 180)) * deltaAzi) ** 2);

    const dls = deltaMd !== 0 ? dogLeg / deltaMd : 0;

    if (dls < dlsThreshold) {
      interpolatedMd = below.MD + (targetTvd - below[conversionColumn]) * (deltaMd / deltaTvd);
    } else {
      const factor = (2 / dls) * Math.tan(dogLeg / 2);
      const deltaMdInterpolated = deltaMd * ((targetTvd - below[conversionColumn]) / deltaTvd);
      interpolatedMd = below.MD + deltaMdInterpolated * factor;
    }

    return interpolatedMd;
  }

  private linearInterpolationMDToTVD(config: Config, deviationSurvey: { [propName: string]: number }[]): [number[], number[]] {
    const fTvdrt = new Interp1d(deviationSurvey.map(e => e.MD), deviationSurvey.map(e => e.TVDRT));
    const fTvdss = new Interp1d(deviationSurvey.map(e => e.MD), deviationSurvey.map(e => e.TVDSS));

    const targetMd = config.targetDetph;

    const interpolatedTvdrt = fTvdrt.call([targetMd]);
    const interpolatedTvdss = fTvdss.call([targetMd]);

    return [interpolatedTvdrt, interpolatedTvdss];
  }

  private linearInterpolationTVDToMD(config: Config, deviationSurvey: { [propName: string]: number }[]): number[] {
    const conversionColumn = config.conversion.includes('TVDRT') ? 'TVDRT' : 'TVDSS';
    const depthValues = deviationSurvey.map(row => row[conversionColumn]);

    const fMd = new Interp1d(depthValues, deviationSurvey.map(e => e.MD));

    const targetTvd = Math.abs(config.targetDetph);
    const interpolatedMd = fMd.call([targetTvd]);

    return interpolatedMd;
  }

  private logAndReturnResults(config: Config, interpolationMethod: string, depthResult: any, rtElevation: number, convertionType: 'MD to TVDRT/TVDSS' | 'TVDRT to MD' | 'TVDSS to MD') {
    const targetDept = config.targetDetph;
    const results: any = {};

    if (convertionType === 'MD to TVDRT/TVDSS') {
      const [ tvdrt, tvdss ] = depthResult;

      this.logger(`Results for ${targetDept} ${convertionType}: TVDRT: ${Math.round(tvdrt)}, TVDSS: ${Math.round(tvdss)}, RT Elevation Above AMSL (m): ${rtElevation}, Interpolation Method: ${interpolationMethod}`);

      results[`TVDRT for ${targetDept} MD`] = Math.round(tvdrt);
      results[`TVDSS for ${targetDept} MD`] = Math.round(tvdss);
      results['md'] = targetDept;
      results['tvdrt'] = tvdrt;
      results['tvdss'] = tvdss;
    } else {
      const md = depthResult;
      this.logger(`Results for ${targetDept} ${convertionType}: MD: ${Math.round(md)}, RT Elevation Above AMSL (m): ${rtElevation}, Interpolation Method: ${interpolationMethod}`);

      results[`MD for ${targetDept} ${convertionType.split(' to ')[0]}`] = Math.round(md);
      results['md'] = md;
      results['tvdrt'] = convertionType.includes('TVDRT') ? targetDept : null;
      results['tvdss'] = convertionType.includes('TVDSS') ? targetDept : null;
    }

    results['RT Elevation Above AMSL (m)'] = rtElevation;
    results['Interpolation Method'] = interpolationMethod;

    for (const [key, value] of Object.entries(results)) {
      try {
        if (!isNaN((value as any)) && `${value}`.includes('.')) {
          results[key] = parseFloat((value as any)).toFixed(2);
        }
      } catch(e) {
        this.logger('Not a number.', 'warning');
      }
    }

    return results;
  }

  private logger(msg: string, type: 'error' | 'info' | 'warning' = 'info', error?: string) {
    if (type === 'info') {
      console.info(msg);
    } else if (type === 'warning') {
      console.warn(msg);
    } else if (type === 'error') {
      console.error(msg);
      throw new Error(error ?? msg);
    }
  }

}
