import { Injectable } from '@angular/core';
import { NodeAndEdgeCollectionResponseV3Response, ViewOrContainer } from '@cognite/sdk/dist/src';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})

// All APIs to get the data can go into this service
export class CognitDataFormatter {
    constructor() {

    }
    public formatNoderesponse(data: NodeAndEdgeCollectionResponseV3Response, viewid: string, version: string|undefined,space = environment.cogniteSpace): any {
        if (version == undefined)
            version = environment.cogniteSDMVersion
       
        let nodeProperties: { externalId: string; Name: string;  }[] = []
        data.items.forEach(item => {
            let properties = item.properties;
            if (properties !== undefined) {
                nodeProperties.push({ externalId: item.externalId, Name: properties[space][viewid+"/"+version].name.toString() })
            }
            //item.properties["workflow-sdm-spc"]["Field/1_0"].name

        });
        return nodeProperties;

    }
    
    public getNoderesponseProperties(data: NodeAndEdgeCollectionResponseV3Response): any {
        let nodeProperties: { externalId: string; properties: Record<string, ViewOrContainer>; }[] = []
        data.items.forEach(item => {
            let properties = item.properties;
            if (properties !== undefined) {
                nodeProperties.push({ externalId: item.externalId, properties: properties })
            }
            //item.properties["workflow-sdm-spc"]["Field/1_0"].name

        });
        return nodeProperties;

    }
    public getNoderesponsePropertieslist(data: NodeAndEdgeCollectionResponseV3Response,viewid: string, version = environment.cogniteSDMVersion ,space = environment.cogniteSpace): any {
        let nodeProperties:any[]=[]
        data.items.forEach(item => {
            let properties = item.properties;
           
            if (properties !== undefined) {
                let propertieslist = properties[space][viewid+"/"+version];
                nodeProperties.push({ externalId: item.externalId, properties: propertieslist })
            }
        });
        return nodeProperties;

    }
}