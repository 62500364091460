<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.ESP">ESP</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.JP">JP</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [LiftTypeToloadWells]="selectedtab" [getListofAllWellsUnderField]="true" (onWellchange)="onFilterWellChange($event)" (onclear)="clear()" (ondatechange)="onDateSelected($event)"></app-hierarchy>
</div>
<ng-container>
  <div class="page-content">
    <div *ngIf="loading;" style="display: flex;justify-content: center;align-items: center;">
      <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
    </div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Calibration Summary</mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 10px;">
        <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true" [sortable]="true" [appGridTransform]="{ fixHeaders: true }">
          <ng-template kendoGridToolbarTemplate>
            <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
              <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
              </button>
              <button type="button" kendoGridPDFCommand>
                <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
              </button>
            </div>
          </ng-template>
          <kendo-grid-column field="well" title="Well" [width]="120"></kendo-grid-column>
          <kendo-grid-column-group title="Calibration Result">
            <kendo-grid-column field="testdate_ts" title="Well Test Date" [width]="130">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.testdate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="liquidrate" title="Liquid Rate (bbl/day)" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="frfactor" title="{{ !!(this.unitobject['frfactor']) ? 'Friction Factor ('+(this.unitobject['frfactor'])+')' : 'Friction Factor'}}" [width]="100" [hidden]="this.selectedtab === 'JP'"></kendo-grid-column>
            <kendo-grid-column field="holdfactor" title="{{ !!(this.unitobject['holdfactor']) ? 'Hold Up Factor ('+(this.unitobject['holdfactor'])+')' : 'Hold Up Factor '}}" [width]="100" [hidden]="this.selectedtab === 'JP'"></kendo-grid-column>
            <kendo-grid-column field="rpressure" title="Reservoir Pressure (psia)" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="pi" title="PI (bbl/d/psi)" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="pumpwfactor" title="{{ !!(this.unitobject['pumpwfactor']) ? 'Pump Wear Factor ('+(this.unitobject['pumpwfactor'])+')' : 'Pump Wear Factor '}}" [width]="100" [hidden]="this.selectedtab === 'JP'"></kendo-grid-column>
            <kendo-grid-column field="ks" title="{{ !!(this.unitobject['ks']) ? 'Ks ('+(this.unitobject['ks'])+')' : 'Ks'}}" [width]="100" [hidden]="this.selectedtab === 'ESP'"></kendo-grid-column>
            <kendo-grid-column field="kn" title="{{ !!(this.unitobject['kn']) ? 'Kn ('+(this.unitobject['kn'])+')' : 'Kn'}}" [width]="100" [hidden]="this.selectedtab === 'ESP'"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Calibration Input">
            <kendo-grid-column field="inputliquidrate" title="Liquid Rate (bbl/day)" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="watercut" title="{{ !!(this.unitobject['watercut']) ? 'Water Cut('+(this.unitobject['watercut'])+')' : 'Water Cut(%)'}}" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="gor" title="{{ !!(this.unitobject['gor']) ? 'GOR('+(this.unitobject['gor'])+')' : 'GOR(scf/stb)'}}" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="fthp" title="FTHP (psia)" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="inputpip" title="PIP (psia)" [width]="100" [hidden]="this.selectedtab === 'JP'"></kendo-grid-column>
            <kendo-grid-column field="inputpdp" title="PDP (psia)" [width]="100" [hidden]="this.selectedtab === 'JP'"></kendo-grid-column>
            <kendo-grid-column field="frequency" title="{{ !!(this.unitobject['frequency']) ? 'Frequency('+(this.unitobject['frequency'])+')' : 'Frequency (Hz)'}}" [width]="120" [hidden]="this.selectedtab === 'JP'">
            </kendo-grid-column>
            <kendo-grid-column field="annuluspressure" title="{{ !!(this.unitobject['annuluspressure']) ? 'Annulus Pressure('+(this.unitobject['annuluspressure'])+')' : 'Annulus Pressure (psia)'}}" [hidden]="this.selectedtab === 'ESP'" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="pfrate" title="{{ !!(this.unitobject['pfrate']) ? 'PF Flow Rate('+(this.unitobject['pfrate'])+')' : 'PF Flow Rate (bbl/day)'}}" [hidden]="this.selectedtab === 'ESP'" [width]="100"></kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-excel fileName="Calibration Summary.xlsx"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Calibration Summary.pdf" [allPages]="true"></kendo-grid-pdf>
        </kendo-grid>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
