import { NavItem } from '@slb-dls/angular-material/shared';
export const menuItemList: NavItem[] = [
    {
        'label': 'Well Surveillance',
        'routerLink': [
            'Wellsurveillance/alsmonitoring',
            // 'Wellsurveillance/productionoverview'
        ],
        'icon': 'wellsurviallance',
        'hint': 'wellSurveillancesubMenu',
        'active': true,
    },
    {
        'label': 'Admin Module',
        'routerLink': [
            'admin/alstolerance',
        ],
        'icon': 'admin',
        'hint': 'adminsubMenu'
    },
    {
        'label': 'Alarms',
        'routerLink': [
            'alarms/alsalarm',
        ],
        'icon': 'wtv',
        'hint': 'alssubMenu'
    },
    {
        'label': 'Calibration Module',
        'routerLink': [
            'calibration/input',
        ],
        'icon': 'ns',
        'hint': 'calibrationsubMenu'
    },
    {
        'label': 'Loss Accounting',
        'routerLink': [
            'lossaccounting/downtimeevent'
        ],
        'icon': 'la',
        'hint': 'lossAccountingsubMenu',

    },

    {
        'label': 'Well Test Validation',
            'routerLink': [
                'Welltestvalidation/inputdatacorrection'
            ],
            'icon':'wtv',
            'hint':'WellTestValidationsubMenu',
    },
    {
        'label': 'Well Book',
        'routerLink': ['wellbook', 'wellbook-summary'],
        'icon':'wb',
        'hint':'WellBooksubMenu',
    },
    {
        'label': 'Well Ranking',
        'routerLink': ['well-ranking', 'tolerance-configuration'],
        'icon': 'wr',
        'hint': 'WellRankingsubMenu',
    },
    {
        'label': 'Production Allocation',
            'routerLink': [
                'ProductionAllocation/InputConfiguration'
            ],
            'icon':'wtv',
            'hint':'ProductionAllocationsubMenu',
            'disabled': false
    },
    {
        'label': 'Automated Report',
        'routerLink': [
            'AutomatedReport/ExecutiveDashboard'
        ],
        'icon':'report',
        'hint': 'automatedDashboardsubMenu',
    },
    {
        'label': 'Network Surveillance',
        'routerLink': [
            ''
        ],
        'icon': 'ns',
        'disabled': true
    },
    {
        'label': 'Reservoir & Flood',
        'routerLink': [
            ''
        ],
        'icon': 'reservoir',
        'disabled': true
    },


    {
        'label': 'Tachyus',
        'routerLink': [
            'https://azure-slb.tachyus.com/'
        ],
        'icon':'tachyus',
    },
];

export const wellSurveillancesubMenu: NavItem[] = [
    // {
    //     'label': 'Production Overview',
    //     'routerLink': [
    //         'Wellsurveillance/productionoverview',
    //     ],
    //     'active':true

    // },
    // {
    //     'label': 'ALS Monitoring Deprecated',
    //     'routerLink': [
    //         'Wellsurveillance/alsmonitoring',
    //     ],
    //     'active': true
    // },
    {
        'label': 'ALS Monitoring',
        'routerLink': [
            'Wellsurveillance/alsmonitoring',
        ],
        'active': true
    },
    {
        'label': 'Virtual Metering',
        'routerLink': [
            'Wellsurveillance/virtualmetering'
        ]
    },
    // {
    //     'label': 'Well Monitoring Deprecated',
    //     'routerLink': [
    //         'Wellsurveillance/wellmonitoring'
    //     ],
    //     'disabled': false
    // },
    {
        'label': 'Well Monitoring',
        'routerLink': [
            'Wellsurveillance/wellmonitoring'
        ],
        'disabled': false
    },
    {
        'label': 'Production Overview',
        'routerLink': [
            'Wellsurveillance/productionoverview'
        ],
        'disabled': false
    }

]

export const lossAccountingsubMenu: NavItem[] = [
    {
        'label': 'Downtime Event',
        'routerLink': [
            'lossaccounting/downtimeevent',
        ],
        'active': true
    },
    // {
    //     'label': 'Result',
    //     'routerLink': [
    //         'lossaccounting/results'
    //     ],
    // }

]
export const adminsubMenu: NavItem[] = [
    {
        'label': 'ALS Field Tolerance',
        'routerLink': [
            'admin/alstolerance',
        ],
        'active': true
    },
    {
        'label': 'ALS Well Tolerance',
        'routerLink': [
            'admin/alswelltolerance',
        ],
        'active': true
    },
    {
        'label': 'ALS WF Threshold',
        'routerLink': [
            'admin/threshold',
        ],
    },
    {
        'label': 'Pump Curve variables',
        'routerLink': [
            'admin/pumpcurve',
        ],
    },
    {
        'label': 'Injector Monitoring Tolerance',
        'routerLink': [
            'admin/viscositymonitoring',
        ],
    },
]

export const alssubMenu: NavItem[] = [
    {
        'label': 'ALS Well Alarm',
        'routerLink': [
            'alarms/alsalarm',
        ],
        'active': true
    },
    {
        'label': 'Drift Alarm',
        'routerLink': [
            'alarms/driftalarm',
        ]
    }
    // ,
    // {
    //     'label': 'Poc - HF/Well Alarm/Power BI',
    //     'routerLink': [
    //         'als/hfalarm',
    //     ],
    // }
]

export const calibrationsubMenu: NavItem[] = [
    {
        'label': 'Input',
        'routerLink': [
            'calibration/input',
        ],
        'active': true
    },
    {
        'label': 'Trigger',
        'routerLink': [
            'calibration/caltrigger',
        ],
    },
    {
        'label': 'Output',
        'routerLink': [
            'calibration/output',
        ],
    },
    {
        'label': 'Summary',
        'routerLink': [
            'calibration/calsummary',
        ],
    },
    {
        'label': 'Alarm',
        'routerLink': [
            'calibration/calalarm',
        ],
    },
]

export const WellTestValidationsubMenu: NavItem[] = [
    {
        'label': 'Input Data Correction',
        'routerLink': [
            'Welltestvalidation/inputdatacorrection',
        ],
        'active':true,
    },
    {
        'label': 'Well Test Data Summary',
        'routerLink': [
            'Welltestvalidation/calculateddatasummary',
        ],
    },
]

export const WellBooksubMenu: NavItem[] = [{
  label: 'Well Book Summary',
  routerLink: ['wellbook', 'wellbook-summary'],
  active: true,
}, {
  label: 'Deviation Summary',
  routerLink: ['wellbook', 'deviation-summary'],
}, {
  label: 'Survey Data',
  routerLink: ['wellbook', 'logdata'],
}, {
  label: 'Well Completion Schematic',
  routerLink: ['wellbook', 'completion-schematic'],
}, {
  label: 'Well Map View',
  routerLink: ['wellbook', 'map-view'],
}, {
  label: 'Event Viewer',
  routerLink: ['wellbook', 'event-viewer'],
}, {
  label: 'Tags',
  routerLink: ['wellbook', 'tags'],
}, {
  label: 'File Search',
  routerLink: ['wellbook', 'file-search'],
}];

export const WellRankingsubMenu: NavItem[] = [{
  label: 'Tolerance Configuration',
  routerLink: ['well-ranking', 'tolerance-configuration'],
  active: true,
}, {
  label: 'Automated Screening Result',
  routerLink: ['well-ranking', 'automated-result'],
}, {
  label: 'Opportunity Register',
  routerLink: ['well-ranking', 'opportunity-register'],
}, {
  label: 'Realization Summary',
  routerLink: ['well-ranking', 'realization-summary'],
}, {
  label: 'Alerts',
  routerLink: ['well-ranking', 'alerts'],
}];

export const ProductionAllocationsubMenu: NavItem[] = [
    {
        'label': 'Input Configuration',
        'routerLink': [
            'ProductionAllocation/InputConfiguration',
        ],
        'active':true,
    },
    {
        'label': 'Allocation Workbench',
        'routerLink': [
            'ProductionAllocation/AllocationWorkbench',
        ],
    },
    {
        'label': 'Allocation Input Review',
        'routerLink': [
            'ProductionAllocation/AllocationInputReview',
        ],
    },
    {
        'label': 'Allocation Results',
        'routerLink': [
            'ProductionAllocation/AllocationResults',
        ],
    },
]

export const automatedDashboardsubMenu: NavItem[] = [
    {
        'label': 'Executive Dashboard',
        'routerLink': [
            'AutomatedReport/ExecutiveDashboard',
        ],
        'active':true,
    },
    {
        'label': 'Well Rate Comparison',
        'routerLink': [
            'AutomatedReport/WellRateComparison',
        ],
    },]