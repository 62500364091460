import { Component, ViewChild } from '@angular/core';
import { CognitApiService } from '../../../services/cognit-api.service'
import { CognitDataFormatter } from '../../../services/cognit-data-formatter'
import moment from 'moment';
import { environment } from '../../../../../src/environments/environment';
import { LoaderService } from '../../../services/loader.service';
import { TabOptionsEnum, comparisonModel } from '../model';
import { HierarchyService } from '../../../services/hierarchy.service';
import { Subscription } from 'rxjs';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterDefinition, DataModelsLeafFilter, DataModelsBoolFilter, InFilterV3 } from "@cognite/sdk"

@Component({
  selector: 'app-virtual-compariosn',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.css']
})

export class ComparisonComponent {
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.CMP;
  public selectedsubtab = '';
  private subs: Subscription[] = [];
  public sdmPropertyList: any[] = [];
  public externalidlist: any[] = [];
  public timeseriesData: any[] = [];
  public liftTypearray: any[] = [];
  public lifttypedata: { Name: string; Date: string; liftType: string }[] = [];
  public well_lift_ts = new Map<string, string>();
  public wellDataList: any[] = [];
  public unitobject: any = {};
  public startdate: any;
  public enddate: any;
  public datepick1: any;
  public datepick2: any;
  public date1: any;
  public date2: any;
  public comparisongridData: comparisonModel[] = [];
  public fieldList: any;
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any;
  public selectedwelllist: any;
  public selectedField: string;
  public selectedwell: string;
  public selectedwellPad: string;

  public oilId = '';
  public oilIdml = '';
  public oil1 = 0;
  public oil2 = 0;
  public oil3 = 0;
  public oil4 = 0;
  public liquidId = '';
  public liquidIdml = '';
  public liq1 = 0;
  public liq2 = 0;
  public liq3 = 0;
  public liq4 = 0;

  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService,
    private cognitDataFormatter: CognitDataFormatter,
    private hierarchyService: HierarchyService) {
  }

  ngOnInit() {
    this.startdate = moment(moment('1/1/2017'));
    this.enddate = moment(moment().toDate());
    this.datepick1 = moment(moment().toDate()).add(-1, 'days');
    this.date1 = moment(moment().toDate()).add(-1, 'days').format('DD-MM-YYYY');
    this.datepick2 = moment(moment().toDate());
    this.date2 = moment(moment().toDate()).format('DD-MM-YYYY');
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
  }

  TabChange($event: any) {
    this.selectedsubtab = $event.value;
    this.clear();
    this.hierarchyComponent.clear();
  }

  clear() {
    this.unitobject = {};
    this.wellPadList = [];
    this.filterWellList = [];
    this.selectedwelllist = [];
    this.comparisongridData = [];
    this.lifttypedata = [];
    this.liftTypearray = [];
    this.timeseriesData = [];
    this.selectedsubtab = '';
    this.selectedField = '';
    this.selectedwell = '';
    this.selectedwellPad = '';
    this.datepick1 = moment(moment().toDate()).add(-1, 'days');
    this.datepick2 = moment(moment().toDate());
    this.date1 = moment(moment().toDate()).add(-1, 'days').format('DD-MM-YYYY');
    this.date2 = moment(moment().toDate()).format('DD-MM-YYYY');
    this.oilId = '';
    this.oilIdml = '';
    this.oil1 = 0;
    this.oil2 = 0;
    this.oil3 = 0;
    this.oil4 = 0;
    this.liquidId = '';
    this.liquidIdml = '';
    this.liq1 = 0;
    this.liq2 = 0;
    this.liq3 = 0;
    this.liq4 = 0;
    this.hierarchyComponent.clear();
  }

  onDateChange1(event: any) {
    this.date1 = moment(event).format('DD-MM-YYYY');
    if (this.selectedwelllist) {
      let wells = this.selectedwelllist.map((x: any) => x.externalId);
      if (wells.length > 0 && this.date2 !== null) {
        this.getWellLiftTypeForSelectedWells(wells);
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }


  onDateChange2(event: any) {
    this.date2 = moment(event).format('DD-MM-YYYY');
    if (this.selectedwelllist) {
      let wells = this.selectedwelllist.map((x: any) => x.externalId);
      if (wells.length > 0 && this.date1 !== null) {
        this.getWellLiftTypeForSelectedWells(wells);
      }
    }
  }

  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.comparisongridData = [];
    this.selectedwelllist = event.allFieldwells;
    let wells = this.selectedwelllist.map((x: any) => x.externalId);
    this.getWellLiftTypeForSelectedWells(wells);

  }

  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.selectedwelllist = event.well;
    this.selectedwell = '';
    this.comparisongridData = [];
    let wells = this.selectedwelllist.map((x: any) => x.externalId);
    this.getWellLiftTypeForSelectedWells(wells);
  }

  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    //this.getWellLiftType(this.selectedwell);
    this.getWellLiftTypeForSelectedWells([this.selectedwell]);
  }


  getWellLiftTypeForSelectedWells(wells: any) {
    this.lifttypedata = [];
    let lifttypeTimeserieslist: any[] = [];
    this.well_lift_ts = new Map<string, string>();

    if ((this.date1 !== null && this.date1 !== undefined) && (this.date2 !== null && this.date2 !== undefined) && (this.selectedwell !== undefined)) {
      this.subs.push(this.hierarchyService.getFieldWellsdataLiftType(wells).subscribe(async (data: any) => {
        let version = environment.cogniteSDMVersion;
        let space = environment.cogniteSpace;
        data.forEach((item: any) => {
          let properties = item.properties;
          let well = item.externalId;
          if (properties !== undefined) {
            let liftType = properties[space]["Well/" + version].liftType;
            if (liftType != undefined) {
              lifttypeTimeserieslist.push({ "externalId": String(liftType) })
              this.well_lift_ts.set(liftType, well);
            }
          }
        });
        if (lifttypeTimeserieslist.length > 0) {
          let i = 0;
          let breaker = 100;
          let process = false;
          let chunk;
          do {
            this.timeseriesData = [];
            chunk = lifttypeTimeserieslist.slice(i, i + breaker);
            const results = await this.apiService.getTimeseriesDataRange(chunk, moment(this.datepick1._d).add(-1, 'days').valueOf(), moment(this.datepick2._d).add(1, 'days').valueOf())
            let timeseriesdatapoints = results
            timeseriesdatapoints.forEach((datapoint: any) => {
              if (datapoint.datapoints.length > 0) {
                datapoint.datapoints.forEach((data: any) => {
                  if (data != undefined && (moment(data.timestamp).format('DD-MM-YYYY') == this.date1 || moment(data.timestamp).format('DD-MM-YYYY') == this.date2)) {
                    let lift_ts = datapoint["externalId"]
                    let well = this.well_lift_ts.get(lift_ts);
                    if (well == undefined)
                      return;

                    this.lifttypedata.push({ Name: well, Date: moment(data.timestamp).format('DD-MM-YYYY'), liftType: data.value });
                  }
                })
              }
            });
            this.lifttypedata = [...new Set(this.lifttypedata.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
            i = i + breaker;
            if (i < lifttypeTimeserieslist.length) {
              process = true;
            } else {
              process = false
            }
          } while (process)
          this.loadGridDataforHierarchy();
        } else { this.comparisongridData = []; }
      }));
    }
  }

  getWellLiftType(well: string) {
    this.lifttypedata = [];
    let lifttypeTimeserieslist: any[] = [];
    if ((this.date1 !== null && this.date1 !== undefined) && (this.date2 !== null && this.date2 !== undefined) && (this.selectedwell !== undefined)) {
      this.subs.push(this.hierarchyService.getWelldataLiftType(well).subscribe((data: any) => {
        let version = environment.cogniteSDMVersion;
        let space = environment.cogniteSpace;
        data.forEach((item: any) => {
          let properties = item.properties;
          if (properties !== undefined) {
            let liftType = properties[space]["Well/" + version].liftType;
            if (liftType != undefined)
              lifttypeTimeserieslist.push({ "externalId": String(liftType) })
          }
        });
        if (lifttypeTimeserieslist.length > 0) {
          this.apiService.getTimeseriesDataRange(lifttypeTimeserieslist, moment(this.datepick1._d).add(-1, 'days').valueOf(), moment(this.datepick2._d).add(1, 'days').valueOf()).then((timeseriesdatapoints: any) => {
            timeseriesdatapoints.forEach((datapoint: any) => {
              if (datapoint.datapoints.length > 0) {
                datapoint.datapoints.forEach((data: any) => {
                  if (data != undefined && (moment(data.timestamp).format('DD-MM-YYYY') == this.date1 || moment(data.timestamp).format('DD-MM-YYYY') == this.date2)) {
                    this.lifttypedata.push({ Name: well, Date: moment(data.timestamp).format('DD-MM-YYYY'), liftType: data.value });
                  }
                })
              }
            });
            this.loadGridData();
          });
        } else { this.comparisongridData = []; }
      }));
    }
  }


  getexternalidlisthrp(results: any, space: string, viewid: string, version: string) {
    let properties = results?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      let wellnumber = externaliddata['wellName'];
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'oilVolumeRateVFM_Physics' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'oilph.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'liquidVolumeRateVFM_Physics' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'liqph.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.externalidlist = this.externalidlist.concat(Object.keys(externaliddata).map((key) => ((externaliddata[key] == 'oilVolumeRateVFM_Physics' || externaliddata[key] == 'liquidVolumeRateVFM_Physics') && externaliddata['timeseriesExtId'] != undefined) ?
        { "externalId": externaliddata['timeseriesExtId'] } : null).filter(v => v));
    }
  }

  getexternalidlistpcp(results: any, space: string, viewid: string, version: string) {
    let properties = results?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      let wellnumber = externaliddata['wellName'];
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'oilVolumeRateVFM_Physics' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'oilph.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'liquidVolumeRateVFM_Physics' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'liqph.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'oilVolumeRateVFM_ML' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'oilml.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => (externaliddata[key] == 'liquidVolumeRateVFM_ML' && externaliddata['timeseriesExtId'] != undefined) ?
        { "keyvalue": wellnumber, "value": 'liqml.' + externaliddata['timeseriesExtId'] } : null).filter(v => v));
      this.externalidlist = this.externalidlist.concat(Object.keys(externaliddata).map((key) => ((externaliddata[key] == 'oilVolumeRateVFM_Physics' || externaliddata[key] == 'liquidVolumeRateVFM_Physics' || externaliddata[key] == 'oilVolumeRateVFM_ML' || externaliddata[key] == 'liquidVolumeRateVFM_ML') && externaliddata['timeseriesExtId'] != undefined) ?
        { "externalId": externaliddata['timeseriesExtId'] } : null).filter(v => v));
    }
  }

  getexternalidlist(results: any, space: string, viewid: string, version: string) {
    let properties = results?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      let wellnumber = externaliddata['well']['externalId'];
      this.sdmPropertyList = this.sdmPropertyList.concat(Object.keys(externaliddata).map((key) => key == 'oilRate' || key == 'liquidRate' ?
        { "keyvalue": wellnumber, "value": externaliddata[key] } : null).filter(v => v));
      this.externalidlist = this.externalidlist.concat(Object.keys(externaliddata).map((key) => key == 'oilRate' || key == 'liquidRate' ?
        { "externalId": externaliddata[key] } : null).filter(v => v));
    }
  }

  groupBy(array: any[], key: string): { [key: string]: any[] } {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }

  async loadGridDataforHierarchy() {
    this.selectedsubtab = '';
    let version = '';
    let viewid = '';
    let space = '';
    let filter_list: FilterDefinition[];
    let filter: FilterDefinition;
    let pcpFilter = {};
    this.externalidlist = [];
    this.sdmPropertyList = [];
    this.loader.showLoader();
    filter_list = [];
    if ((this.lifttypedata.length > 0) && (this.date1 !== null && this.date1 !== undefined) && (this.date2 !== null && this.date2 !== undefined) && (this.selectedwell !== undefined)) {
      //get groups based on pump type
      let pumptypegroups = this.groupBy(this.lifttypedata, "liftType");
      for (const pumptype in pumptypegroups) {
        if (pumptype == 'PCP') {
          let exteridlist = pumptypegroups[pumptype].map((x: any) => x.Name);
          version = "1_0";
          viewid = "PcpParameters";
          space = environment.cogniteSpace;
          pcpFilter = {
            "in": {
              "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
              "values": exteridlist
            }
          }

          //filter_list.push(filter);
          let data2: any = {}
          data2 = await this.apiService.getInstancelist(viewid, pcpFilter, version).toPromise();
          let rawData = data2['items'];

          if (rawData.length > 0) {

            rawData.forEach((item: any) => {
              this.getexternalidlistpcp(item, space, viewid, version);
            });


          }

        }
        else if (pumptype == 'HRP') {
          let exteridlist = pumptypegroups[pumptype].map((x: any) => x.Name);
          version = "1_0";
          viewid = "HrpParameters";
          space = environment.cogniteSpace;

          filter = {
            "in": {
              "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
              "values": exteridlist
            }
          }
          //filter_list.push(filter);
          let data2: any = {}
          data2 = await this.apiService.getInstancelist(viewid, filter, version).toPromise();
          let rawData = data2['items'];

          if (rawData.length > 0) {
            rawData.forEach((item: any) => {
              this.getexternalidlisthrp(item, space, viewid, version);
            });
          }

        } else {
          let exteridlist = pumptypegroups[pumptype].map((x: any) => x.Name);
          version = "1_4";
          viewid = "VfmOutput";
          space = environment.cogniteSpace;

          filter = {
            "nested": {
              "scope": [environment.cogniteSpace, viewid + '/' + version, "well"],
              "filter": {
                "in": {
                  "property": ["node", "externalId"],
                  "values": exteridlist
                }
              }
            }
          }

          let data2: any = {}
          data2 = await this.apiService.getInstancelist(viewid, filter, version).toPromise();
          let rawData = data2['items'];

          if (rawData.length > 0) {
            rawData.forEach((item: any) => {
              this.getexternalidlist(item, space, viewid, version);
            });
          }
          // filter_list.push(filter);
        }
      }

      this.loader.hideLoader();
      this.externalidlist = [...new Set(this.externalidlist.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
      this.sdmPropertyList = [...new Set(this.sdmPropertyList.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
      this.getTimeseries();
    }

  }


  async loadGridData() {
    this.selectedsubtab = '';
    let version = '';
    let viewid = '';
    let space = '';
    let filter = {};
    this.externalidlist = [];
    this.sdmPropertyList = [];

    if ((this.lifttypedata.length > 0) && (this.date1 !== null && this.date1 !== undefined) && (this.date2 !== null && this.date2 !== undefined) && (this.selectedwell !== undefined)) {
      for (let i = 0; i < this.lifttypedata.length; i++) {
        let data = this.lifttypedata[i];
        this.selectedsubtab = data.liftType;

        if (this.selectedsubtab == 'PCP') {
          version = "1_0";
          viewid = "PcpParameters";
          space = environment.cogniteSpace;

          filter = {
            "equals": {
              "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
              "value": this.selectedwell
            }
          }
        } else if (this.selectedsubtab == 'HRP') {
          version = "1_0";
          viewid = "HrpParameters";
          space = environment.cogniteSpace;

          filter = {
            "equals": {
              "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
              "value": this.selectedwell
            }
          }
        } else {
          version = "1_4";
          viewid = "VfmOutput";
          space = environment.cogniteSpace;

          filter = {
            "nested": {
              "scope": [environment.cogniteSpace, viewid + '/' + version, "well"],
              "filter": {
                "in": {
                  "property": ["node", "externalId"],
                  "values": [this.selectedwell]
                }
              }
            }
          }
        }

        this.loader.showLoader();
        let data2: any = {}
        data2 = await this.apiService.getInstancelist(viewid, filter, version).toPromise();
        let rawData = data2['items'];
        if (rawData.length > 0) {
          if (this.selectedsubtab == 'PCP') {
            rawData.forEach((item: any) => {
              this.getexternalidlistpcp(item, space, viewid, version);
            });
          } else if (this.selectedsubtab == 'HRP') {
            rawData.forEach((item: any) => {
              this.getexternalidlisthrp(item, space, viewid, version);
            });
          } else {
            rawData.forEach((item: any) => {
              this.getexternalidlist(item, space, viewid, version);
            });
          }
          this.loader.hideLoader();
        } else {
          this.loader.hideLoader();
        }
      }

      this.externalidlist = [...new Set(this.externalidlist.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
      this.sdmPropertyList = [...new Set(this.sdmPropertyList.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
      this.getTimeseries();
    }
  }

  async getTimeseries() {
    this.wellDataList = [];
    if (this.externalidlist.length > 0) {
      this.unitobject = {};
      let i = 0;
      let breaker = 50;
      let process = false;
      let chunk;
      do {
        this.timeseriesData = [];
        chunk = this.externalidlist.slice(i, i + breaker);
        const results = await this.apiService.getTimeseriesAvgRange(
          chunk,
          moment(this.datepick1).add(-1, 'days').valueOf(),
          moment(this.datepick2).add(1, 'days').valueOf(), "1d");
        this.timeseriesData = results;
        this.loader.hideLoader();
        this.well_lift_ts.forEach((well) => {
          let wellname = well;
          let liftdataToProcess = this.lifttypedata.filter(x => x.Name == wellname)
          this.oilId = '';
          this.oilIdml = '';
          this.oil1 = 0;
          this.oil2 = 0;
          this.oil3 = 0;
          this.oil4 = 0;
          this.liquidId = '';
          this.liquidIdml = '';
          this.liq1 = 0;
          this.liq2 = 0;
          this.liq3 = 0;
          this.liq4 = 0;
          let matchfound = false;
          liftdataToProcess.forEach((liftdata) => {
            let liftType = liftdata.liftType;
            this.selectedwell = liftdata.Name;
            this.sdmPropertyList.forEach((property) => {
              if (property.keyvalue == this.selectedwell && property.value.toLowerCase().includes('oil')) {
                if ((liftType == 'PCP' || liftType == 'HRP') && property.value.toLowerCase().includes('oilph.')) {
                  this.oilId = property.value.substring('oilph.'.length);
                } else if ((liftType == 'PCP') && property.value.toLowerCase().includes('oilml.')) {
                  this.oilIdml = property.value.substring('oilml.'.length);
                } else if ((liftType == 'ESP' || liftType == 'JP' || liftType == 'NF') && !property.value.toLowerCase().includes('oilml.') && !property.value.toLowerCase().includes('oilph.')) {
                  this.oilId = property.value;
                }
              }
              if (property.keyvalue == this.selectedwell && property.value.toLowerCase().includes('liq')) {
                if ((liftType == 'PCP' || liftType == 'HRP') && property.value.toLowerCase().includes('liqph.')) {
                  this.liquidId = property.value.substring('liqph.'.length);
                } else if ((liftType == 'PCP') && property.value.toLowerCase().includes('liqml.')) {
                  this.liquidIdml = property.value.substring('liqml.'.length);
                } else if ((liftType == 'ESP' || liftType == 'JP' || liftType == 'NF') && !property.value.toLowerCase().includes('liqph.') && !property.value.toLowerCase().includes('liqml.')) {
                  this.liquidId = property.value;
                }
              }
            });
            this.timeseriesData.forEach((data) => {
              let datapoints: any[] = data.datapoints;
              if (data.externalId == this.oilId) {
                matchfound = true;
                this.unitobject['oil'] = data.unit;
                datapoints.forEach((item) => {
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date1) && (this.date1 == liftdata.Date)) {
                    this.oil1 = item.average;
                  }
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date2) && (this.date2 == liftdata.Date)) {
                    this.oil2 = item.average;
                  }
                });
              }
            });

            this.timeseriesData.forEach((data) => {
              let datapoints: any[] = data.datapoints;
              if (data.externalId == this.oilIdml) {
                matchfound = true;
                this.unitobject['oilml'] = data.unit;
                datapoints.forEach((item) => {
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date1) && (this.date1 == liftdata.Date)) {
                    this.oil3 = item.average;
                  }
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date2) && (this.date2 == liftdata.Date)) {
                    this.oil4 = item.average;
                  }
                });
              }
            });
            this.timeseriesData.forEach((data) => {
              let datapoints: any[] = data.datapoints;
              if (data.externalId == this.liquidId) {
                matchfound = true;
                this.unitobject['liquid'] = data.unit;
                datapoints.forEach((item) => {
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date1) && (this.date1 == liftdata.Date)) {
                    this.liq1 = item.average;
                  }
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date2) && (this.date2 == liftdata.Date)) {
                    this.liq2 = item.average;
                  }
                });
              }
            });
            this.timeseriesData.forEach((data) => {
              let datapoints: any[] = data.datapoints;
              if (data.externalId == this.liquidIdml) {
                matchfound = true;
                this.unitobject['liquidml'] = data.unit;
                datapoints.forEach((item) => {
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date1) && (this.date1 == liftdata.Date)) {
                    this.liq3 = item.average;
                  }
                  if ((moment(item.timestamp).format('DD-MM-YYYY') == this.date2) && (this.date2 == liftdata.Date)) {
                    this.liq4 = item.average;
                  }
                });
              }
            });
          });

          if (matchfound) {
            let welldata = {
              "well": wellname,
              "oil1": this.oil1,
              "oil2": this.oil2,
              "oil3": this.oil3,
              "oil4": this.oil4,
              "liq1": this.liq1,
              "liq2": this.liq2,
              "liq3": this.liq3,
              "liq4": this.liq4,
            }
            this.wellDataList.push(welldata);
          }

        })
        i = i + breaker;
        if (i < this.externalidlist.length) {
          process = true;
        } else { process = false }

      } while (process);
      this.getGridForallWells();

    } else {
      this.comparisongridData = [];
    }
  }


  getGridForallWells() {
    const data: any = [];

    this.comparisongridData = [];

    this.liftTypearray = [];
    let oilDifference = '';
    let liquidDifference = '';
    let oilDifferenceml = '';
    let liquidDifferenceml = '';
    let startType = '';
    let endType = '';
    this.well_lift_ts.forEach((well) => {
      let wellname = well;
      let liftdataToProcess = this.lifttypedata.filter(x => x.Name == wellname)
      liftdataToProcess.forEach((liftdata) => {

        this.selectedwell = liftdata.Name;
        let welldata = this.wellDataList.filter(x => x.well == this.selectedwell)[0];
        if (this.date1 == liftdata.Date) {
          startType = liftdata.liftType;
          this.liftTypearray.push(startType);
        }
        if (this.date2 == liftdata.Date) {
          endType = liftdata.liftType;
          this.liftTypearray.push(endType);
        }

        if (welldata) {
          oilDifference = (welldata.oil2 - welldata.oil1) == 0 ? '-' : (welldata.oil2 - welldata.oil1).toFixed(2).toString();
          liquidDifference = (welldata.liq2 - welldata.liq1) == 0 ? '-' : (welldata.liq2 - welldata.liq1).toFixed(2).toString();

          oilDifferenceml = (welldata.oil4 - welldata.oil3) == 0 ? '-' : (welldata.oil4 - welldata.oil3).toFixed(2).toString();
          liquidDifferenceml = (welldata.liq4 - welldata.liq3) == 0 ? '-' : (welldata.liq4 - welldata.liq3).toFixed(2).toString();
        }
      });
      data.push({
        well: this.selectedwell,
        startDateType: startType,
        endDateType: endType,
        ovd: oilDifference,
        lvd: liquidDifference,
        ovdml: oilDifferenceml,
        lvdml: liquidDifferenceml,
      });
    });

    this.comparisongridData = data;
  }

  getGrid() {
    this.comparisongridData = [];
    const data: any = [];
    this.liftTypearray = [];
    let oilDifference = '';
    let liquidDifference = '';
    let oilDifferenceml = '';
    let liquidDifferenceml = '';
    let startType = '';
    let endType = '';
    this.lifttypedata.forEach((data) => {
      if (this.date1 == data.Date) {
        startType = data.liftType;
        this.liftTypearray.push(startType);
      }
      if (this.date2 == data.Date) {
        endType = data.liftType;
        this.liftTypearray.push(endType);
      }
    });
    oilDifference = (this.oil2 - this.oil1) == 0 ? '-' : (this.oil2 - this.oil1).toFixed(2).toString();
    liquidDifference = (this.liq2 - this.liq1) == 0 ? '-' : (this.liq2 - this.liq1).toFixed(2).toString();

    oilDifferenceml = (this.oil4 - this.oil3) == 0 ? '-' : (this.oil4 - this.oil3).toFixed(2).toString();
    liquidDifferenceml = (this.liq4 - this.liq3) == 0 ? '-' : (this.liq4 - this.liq3).toFixed(2).toString();

    data.push({
      well: this.selectedwell,
      startDateType: startType,
      endDateType: endType,
      ovd: oilDifference,
      lvd: liquidDifference,
      ovdml: oilDifferenceml,
      lvdml: liquidDifferenceml,
    });

    this.comparisongridData = data;
  }

}
