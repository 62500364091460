import { AfterViewInit, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { PowerBiService } from 'src/app/services/powerbi.service';

@Component({
  selector: 'app-production-overview',
  templateUrl: './production-overview.component.html',
  styleUrls: ['./production-overview.component.css']
})
export class ProductionOverviewComponent implements AfterViewInit {

  public iframeSrc: SafeResourceUrl;
  public selectedField: string = "";
  public filterStartDate: any;
  public filterEndDate: any;
  report_link: any;
  constructor(private sanitizer: DomSanitizer,private powerBiService: PowerBiService) {

  }
  ngOnInit() {
    this.loadIframe();
  }

  ngAfterViewInit(): void {
    //this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.powerBIResource);
  }
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.loadIframe();
  }
  clear() {
    this.selectedField = "";
  }
  onDateSelected(event: any) {
    this.filterStartDate = moment(event.event.startDate).toDate();
    this.filterEndDate = moment(event.event.endDate).toDate();
    //moment(event.event.endDate).add(1, 'days').toDate();
    if (event.event.startDate != null && event.event.endDate != null) {
      this.loadIframe();
    }
  }

  loadIframe() {
    let startdate = moment(this.filterStartDate).format("YYYY-MM-DD");
    let enddate = moment(this.filterEndDate).format("YYYY-MM-DD");
    let url = PowerBIReportLink.production_overview;
    if (this.selectedField == "" || this.selectedField == undefined)
      this.report_link = url
    else if (this.selectedField == "All")
      this.report_link = url + "&$filter=CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    else
      this.report_link = url + "&$filter=FlatHierarchy/fieldName eq '" + this.selectedField + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    this.powerBiService.setReportLinkData(this.report_link,this.sanitizer.bypassSecurityTrustResourceUrl(this.report_link))
  }

}
