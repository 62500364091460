import { Component, TemplateRef, ViewChild } from '@angular/core';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';
import { DateHelper } from 'src/app/services/datehelper.service';

import { environment } from 'src/environments/environment';
import { InjectorMoitoringTolModel } from '../model'
import { CognitApiService } from 'src/app/services/cognit-api.service';
import moment from 'moment';
import { LoaderService } from 'src/app/services/loader.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { Observable, Subscription, map } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/common-component/confirmation-dialog/confirmation-dialog.component';
import { MessageService, SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import * as _ from 'lodash';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';



@Component({
  selector: 'app-concviscosity-monitoring',
  templateUrl: './concviscosity-monitoring.component.html',
  styleUrls: ['./concviscosity-monitoring.component.css']
})
export class ConcentrationviscosityMonitoringComponent {
  @ViewChild('addRecordDialog') addRecordDialog: TemplateRef<any>;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  wellListToLoadgrid: any;
  private subs: Subscription[] = [];

  gridData: InjectorMoitoringTolModel[] = []
  InjectorMoitoringTol: InjectorMoitoringTolModel;
  selectedrows: any;
  sdmPropertyList: any[] = [];
  public selectedField: string;
  public selectedwell: string;
  public selectedwellPad: string;
  filterstartDate: any;
  filterendDate: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  formgroup: FormGroup;
  formwellList: any;
  formfieldList: any;
  formwellPadList: any;
  public viewid = "InjectionWell";
  public viewversion = environment.version.injectionwellVersion;
  propertiestoshow = ["targetPolymerConcentrationTs", "viscosityDeviationTs", "concentrationDeviationTs"]
  wellPadList: any;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date Time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
    { field: 'Action', hide: true },
  ];

  constructor(private timeseriesHelper: TimeseriesHelper,
    private dateHelper:DateHelper,
    private apiService: CognitApiService,
    private loader: LoaderService,
    private hierarchyService: HierarchyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private messageService: MessageService,
  ) {

  }
  ngOnInit() {
    this.setRecordValue();
    this.setformgroupData();
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.formfieldList = data;
    }));
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());
  }

  public setformgroupData() {
    //FormBuilder avoids using new FormControl in FormGroup instance
    this.formgroup = this.fb.group({
      targetPolymerConentration: [this.InjectorMoitoringTol.targetPolymerConentration, Validators.required],
      conentrationDeviation: [this.InjectorMoitoringTol.conentrationDeviation, Validators.required],
      viscocityDeviation: [this.InjectorMoitoringTol.viscocityDeviation, Validators.required],

      field: new FormControl({ value: this.InjectorMoitoringTol.field, disabled: this.formMode == "Edit" }, this.formMode == "Add" ? Validators.required : []),
      well: new FormControl({ value: this.InjectorMoitoringTol.well, disabled: this.formMode == "Edit" }, Validators.required),
      wellpad: new FormControl({ value: this.InjectorMoitoringTol.wellpad, disabled: this.formMode == "Edit" }, this.formMode == "Add" ? Validators.required : []),
    });
  }
  setRecordValue(data: any = undefined) {
    if (data == undefined) {
      this.InjectorMoitoringTol = { field: this.selectedField, wellpad: this.selectedwellPad, well: this.selectedwell }
    }
    else {
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      let wellselected = this.wellListToLoadgrid.filter((x: any) => x.Name == data.well)[0]
      let wellid = wellselected.externalId;
      let wellpadid = wellselected.WellPad;
      this.InjectorMoitoringTol = {
        startdate: data.startdate, targetPolymerConentration: data.targetPolymerConentration,
        conentrationDeviation: data.conentrationDeviation, viscocityDeviation: data.viscocityDeviation,
        field: this.selectedField, well: wellid, timestamp: data.timestamp,
        wellpad: this.selectedwellPad == undefined || this.selectedwellPad == "" ? wellpadid : this.selectedwellPad
      }
    }
  }
  // filter hierarchy
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedwell = "";
    this.wellPadList = event.wellPadList;
    this.selectedwellPad = "";
    this.wellListToLoadgrid = event.allFieldwells;
    this.loadGrid();
  }
  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.wellListToLoadgrid = event.allFieldwells;
    this.loadGrid();
  }

  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedwellPad = event.selectedwellPad;
    this.wellListToLoadgrid = event.allFieldwells;
    this.loadGrid();
  }
  onDateRangeChange(event: any) {
    this.filterstartDate = moment(event.event.startDate);
    this.filterendDate = moment(event.event.endDate);
    if (event.event.startDate != undefined && event.event.endDate != undefined)
      this.loadGrid();

  }
  clear() {
    this.selectedField = '';
    this.selectedwell = '';
    this.selectedwellPad = '';
    this.wellListToLoadgrid = [];
    this.gridData = [];

  }
  loadGrid() {
    this.gridData = [];

    if (this.wellListToLoadgrid != undefined && this.wellListToLoadgrid.length > 0) {
      let list = this.wellListToLoadgrid.map((x: any) => x.externalId)
      this.loader.showLoader();
      this.timeseriesHelper.getExternalIdsusingexternalId(list, this.viewid, this.propertiestoshow, this.viewversion, environment.cogniteInformationSDMSpace).subscribe(data => {
        let externalidlist = data.externalidlist;
        this.sdmPropertyList = data.sdmPropertyList;
        //console.log(this.sdmPropertyList);
        if (externalidlist.length > 0) {
          let results: any[] = [];
          for (let i = 0; i <= externalidlist.length;) {
            this.loader.showLoader();
            this.apiService.getTimeseriesDataRange(externalidlist.slice(i, i + 99), this.filterstartDate._d, this.filterendDate._d).then((resultsdata: any) => {
              this.loader.hideLoader();
              let tempgriddata = this.getGridData(resultsdata)
              //this.gridData =[];
              //this.gridData =

              this.gridData = addSortableDates(tempgriddata, this.kendoGrid);
              //this.getGridData(resultsdata);
              //console.log("Griddata")
              //console.log( this.gridData)
            })
              .catch((error) => {
                console.error("Error in getting data:", error);
              });
            i = i + 99;
          }
        }
        else
          this.loader.hideLoader();

      })
    }
  }
  getGridData(results: any) {
    //this.gridData = [];
     let tempgridData = _.cloneDeep(this.gridData);
    //["targetPolymerConcentration", "viscosityDeviation", "concentrationDeviation","polymerConcentration"]
    const key1 = 'targetpolymerconcentrationts';
    const key2 = "viscositydeviationts";
    const key3 = "concentrationdeviationts";
    const key4 = "polymerconcentration";
    //console.log(results);
    let timeseriesdatapoints = results;
    let sequence = 0;
    timeseriesdatapoints.forEach((datapoint: any) => {
      if (datapoint.datapoints.length > 0) {
        let parameter = this.sdmPropertyList.filter((X: any) => X.value == datapoint.externalId)[0].keyvalue;
        datapoint.datapoints.forEach((data: any) => {
          let wellid = this.sdmPropertyList.filter((x: any) => x.value == datapoint.externalId)[0].FilterPropertyId;
          let wellname = this.wellListToLoadgrid.filter((x: any) => x.externalId == wellid)[0].Name;
          let datetime = moment(data.timestamp).format("DD-MM-YYYY")
          let filtergriddata =tempgridData.filter((rowdata: any) => rowdata.startdate == datetime && rowdata.well == wellname);
          let index = filtergriddata != undefined ? tempgridData.indexOf(filtergriddata[0]) : -1;
          if (index <= -1) {
            let item: InjectorMoitoringTolModel = { sequence: sequence, id: datapoint.id, startdate: datetime, timestamp: data.timestamp }
            item.field = this.selectedField;
            item.well = wellname;
            item.targetPolymerConentration = parameter == key1 ? data.value : undefined;
            item.viscocityDeviation = parameter == key2 ? data.value : undefined;
            item.conentrationDeviation = parameter == key3 ? data.value : undefined;
            tempgridData.push(item);
            sequence += 1;
          }
          else {
            tempgridData[index].field = this.selectedField;
            tempgridData[index].well = wellname;
            tempgridData[index].targetPolymerConentration = parameter == key1 ? data.value : tempgridData[index].targetPolymerConentration;
            tempgridData[index].viscocityDeviation = parameter == key2 ? data.value : tempgridData[index].viscocityDeviation;
            tempgridData[index].conentrationDeviation = parameter == key3 ? data.value : tempgridData[index].conentrationDeviation;
          }
        })
      }
    });
    // console.log("tempgridData")
    // console.log(tempgridData)
    return tempgridData;
  }
  selectionchange(event: any) {

  }
  onAddrecordClick() {
    this.formMode = "Add";
    this.getwellpaddata(this.selectedField);
    this.getformwelldata(this.selectedwellPad);
    this.setRecordValue();
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public onEditecordClick(dataItem: any) {
    this.formMode = "Edit"
    this.formwellPadList = this.wellPadList;
    this.formwellList = this.wellListToLoadgrid;
    this.setRecordValue(dataItem);
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public ondelete(dataitem: any) {
    this.openDialog(dataitem);
  }
  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    let recortodelete: any[] = [];

    let targetpolymerid;
    let concedeviationid;
    let viscositydeviationid;

    if (selectedrows == undefined) {
      let data = this.gridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          let well = this.wellListToLoadgrid.filter((well: any) => well.Name == record.well)[0].externalId;
          targetpolymerid = this.getexternalIdfromSDMList(well, "targetpolymerconcentrationts")
          concedeviationid = this.getexternalIdfromSDMList(well, "concentrationdeviationts")
          viscositydeviationid = this.getexternalIdfromSDMList(well, "viscositydeviationts")

          recortodelete.push({ externalId: targetpolymerid, inclusiveBegin: Date.parse(String(record.timestamp)) })
          recortodelete.push({ externalId: concedeviationid, inclusiveBegin: Date.parse(String(record.timestamp)) })
          recortodelete.push({ externalId: viscositydeviationid, inclusiveBegin: Date.parse(String(record.timestamp)) })
        }
      });
    }
    else {
      let well = this.wellListToLoadgrid.filter((well: any) => well.Name == selectedrows.well)[0].externalId;
      targetpolymerid = this.getexternalIdfromSDMList(well, "targetpolymerconcentrationts")
      concedeviationid = this.getexternalIdfromSDMList(well, "concentrationdeviationts")
      viscositydeviationid = this.getexternalIdfromSDMList(well, "viscositydeviationts")

      recortodelete.push({ externalId: targetpolymerid, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
      recortodelete.push({ externalId: concedeviationid, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })
      recortodelete.push({ externalId: viscositydeviationid, inclusiveBegin: Date.parse(String(selectedrows.timestamp)) })

    }
    this.loader.showLoader();
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.loader.hideLoader();
      this.selectedwell = "";

      this.loadGrid();
    })
    this.selectedrows = [];

  }
  save(formgroup: any) {

    if (this.formgroup.valid) {
      let targetPolymerConentration = this.formgroup.get("targetPolymerConentration")?.value;
      let conentrationDeviation = this.formgroup.get("conentrationDeviation")?.value;
      let viscocityDeviation = this.formgroup.get("viscocityDeviation")?.value;
      let well = this.formgroup.get("well")?.value;

      this.loader.showLoader();
      this.timeseriesHelper.getExternalIdsusingexternalId([well], this.viewid, this.propertiestoshow, this.viewversion, environment.cogniteInformationSDMSpace).subscribe(data => {
        let externalid = data.externalidlist;
        this.sdmPropertyList = data.sdmPropertyList;
        if (externalid.length > 0) {
          let datapoints: any[] = []
          let externalidlist = [...new Set(externalid.map((asset: any) => ({ "externalId": asset.externalId })))];
          let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
          if (this.formMode == "Edit")
            timestamp = Number(this.InjectorMoitoringTol.timestamp);
          this.apiService.getbulkTsId(externalidlist).then((idresponse: any) => {

            let targetpolymerid = this.gettsidfromlist(this.getexternalIdfromSDMList(well, "targetpolymerconcentrationts"), idresponse);
            let concedeviationid = this.gettsidfromlist(this.getexternalIdfromSDMList(well, "concentrationdeviationts"), idresponse)
            let viscositydeviationid = this.gettsidfromlist(this.getexternalIdfromSDMList(well, "viscositydeviationts"), idresponse)

            datapoints.push({ id: targetpolymerid, datapoints: [{ timestamp: timestamp, value: targetPolymerConentration }] });
            datapoints.push({ id: concedeviationid, datapoints: [{ timestamp: timestamp, value: conentrationDeviation }] });
            datapoints.push({ id: viscositydeviationid, datapoints: [{ timestamp: timestamp, value: viscocityDeviation }] });


            this.apiService.insertbulkTimeSeriesData(datapoints).then(response => {
              this.loader.hideLoader();
              this.loadGrid();
            })

          })
        }
        else {
          this.loader.hideLoader();
          const alert: SlbMessage = {
            target: 'modal',
            severity: SlbSeverity.Info,
            detail: 'No data available to show',

          };
          this.messageService.add(alert);
        }
      });
    }

  }
  getexternalIdfromSDMList(field: string, propertyinSdm: string): string {
    let filteredlist = this.sdmPropertyList.filter((property: any) => property.FilterPropertyId == field && property.keyvalue == propertyinSdm)
    let externalid = "";
    if (filteredlist.length > 0) {
      externalid = filteredlist[0].value;
    }
    return externalid;
  }
  gettsidfromlist(externalId: string, list: any[]): string {
    let idlist = list.filter((item: any) => item.externalId == externalId);
    let id = "";
    if (idlist.length > 0) {
      id = idlist[0].id;
    }
    return id;
  }
  onformfieldChange(event: any) {
    this.getwellpaddata(event.value)
  }
  getwellpaddata(fieldvalue: string) {
    if (fieldvalue != "" && fieldvalue != undefined) {
      this.loader.showLoader();
      this.subs.push(this.hierarchyService.getWellpad(fieldvalue).subscribe((data: any) => {
        this.formwellPadList = data;
      }));
      this.loader.hideLoader();
    }
  }
  onformwellpadChange(event: any) {
    this.InjectorMoitoringTol.wellpad = event.value;
    this.getformwelldata(event.value);
  }
  filterInjectionWell(wellist: any): Observable<any> {
    let wells = wellist.map((x: any) => x.externalId)
    return this.hierarchyService.getInjectionFilterWelldata(wells).pipe(map((injectordata: any) => {
      this.loader.hideLoader();
      injectordata = injectordata.map((data: any) => data.externalId)
      let welllist = wellist.filter((well: any) => injectordata.includes(well.externalId))
      return welllist;
    }))
  }
  getformwelldata(wellpad: string) {
    if (wellpad != "" && wellpad != undefined) {
      this.loader.showLoader();
      this.subs.push(this.hierarchyService.getWelldata(wellpad).subscribe((data: any) => {
        this.filterInjectionWell(data).subscribe((welldata: any) => {
          this.formwellList = welldata;
          if (this.formwellList.length == 0) {
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Info,
              detail: 'There is no well which has Polymerconcentration value',

            };
            this.messageService.add(alert);
          }
        });
      }));
      this.loader.hideLoader();
    }
  }
  onformwellChange(event: any) {

  }

}
