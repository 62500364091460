import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-well-data',
  templateUrl: './well-data.component.html',
  styleUrls: ['./well-data.component.css'],
})
export class WellDataComponent implements OnChanges {

  @Input() masterWellDataSheet: any;

  public data: { title: string, value: any }[] = [];

  constructor(private cd : ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.masterWellDataSheet?.previousValue !== changes?.masterWellDataSheet?.currentValue)
      this.prepareData();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }
  
  private prepareData() {
    this.data = [];

    if (this.masterWellDataSheet) {
      for (const key of (Object.keys(this.masterWellDataSheet))) {
        this.data.push({ title: this.convertStringToHumanRedable(key), value: this.masterWellDataSheet[key] });
      }
    }
  }

  private convertStringToHumanRedable(str: string) {
    return (str ?? '')
      .replace(/[^a-zA-Z0-9]+/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

}
