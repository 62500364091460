import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { environment } from 'src/environments/environment';
import { NodeAndEdgeCollectionResponseV3Response } from '@cognite/sdk/dist/src';
import moment, { Moment } from 'moment';
import * as Highcharts from 'highcharts/highstock';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import HC_exporting from 'highcharts/modules/exporting';
import SunsetTheme from 'highcharts/themes/high-contrast-dark';
import { WellBookService } from '../../well-book.service';

SunsetTheme(Highcharts);
HC_exporting(Highcharts);

export interface SeriesDef {
  name: string,
  unit: string,
  points: number[][]
}

@Component({
  selector: 'app-production-data',
  templateUrl: './production-data.component.html',
  styleUrls: ['./production-data.component.css'],
})
export class ProductionDataComponent implements AfterViewInit, OnChanges {

  @Input() wellSelected: any;

  public loading: boolean = false;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public chartDef: SeriesDef[] = [];
  public range: DateRange<Moment>;
  public dateFilterMax: any;

  private startDate: number = 0;
  private endDate: number = 0;
  private eventDef: SeriesDef[] = [];
  private filterStartDate: any;
  private filterEndDate: any;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService, private cd : ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {
    this.dateFilterMax = moment().add(24, 'hours');
    this.setDateRangeDefault();
    this.cd.detectChanges();
  }

  ngOnChanges(): void {
    if (this.wellSelected != null && this.wellSelected != '') {
      this.setDateRangeDefault();
      this.loadData();
    }
  }

  private setDateRangeDefault(): void {
    this.filterStartDate = moment(moment()).add(-1, 'years');
    this.filterEndDate = moment();

    this.range = { startDate: this.filterStartDate, endDate: this.filterEndDate };
  }

  public onDateSelected(event: any) {
    this.filterStartDate = moment(event.startDate);
    this.filterEndDate = moment(event.endDate);

    if (event.startDate != undefined && event.endDate != undefined)
      this.loadData();
  }

  private loadData() {
    this.chartDef = [];
    this.eventDef = [];
    this.loading = true;
    //this.startDate = moment(this.filterStartDate, 'DD-MMM-YYYY HH:mm').valueOf();
  //  this.endDate = moment(this.filterEndDate, 'DD-MMM-YYYY HH:mm').add(1, 'minute').valueOf();
    this.startDate = this.filterStartDate.utc().toDate();
    // Add one day to the end date and subtract 1 second to make sure it includes the full end date.
    this.endDate = this.filterEndDate.clone().add(1, 'days').subtract(1, 'seconds').utc().toDate();
    Promise.allSettled([
      this.loadWellData(),
      this.loadEventsData('EDM.DMEvent'),
      this.loadEchoData(),
    ]).then(() => {
      this.chartDef = this.chartDef.filter(e => e.points?.length > 0);
      this.eventDef = this.eventDef.filter(e => e.points?.length > 0);
      this.loading = false;
      this.setChartOptions();
    });
  }

  private loadWellData(): Promise<void> {
    return new Promise(res => {
      const view = environment.spaces.workflow.views.Welltheoreticaldata;

      const cdfSpaceView: CdfSpaceView = {
        space: environment.spaces.workflow.id,
        id: view.id,
        version: view.version,
        fullName: `${view.id}/${view.version}`,
      };

      if (cdfSpaceView) {
        const filter: any = this.wbService.getCdfFilter(cdfSpaceView, 'well', ['node', 'externalId'], [this.wellSelected]);

        this.apiService.getInstancelist(view.id, filter, view.version, environment.spaces.workflow.id).subscribe({
          next: async (data: NodeAndEdgeCollectionResponseV3Response) => {
            const { theoreticalLiquidRate, theoreticalOilRate, theoreticalGOR, theoreticalWaterCut } = this.getPropertyExternalIds(data, ['theoreticalLiquidRate', 'theoreticalOilRate', 'theoreticalGOR', 'theoreticalWaterCut'], cdfSpaceView);
            const tlrDataPoints = await this.loadTimeSeriesData(theoreticalLiquidRate, this.startDate, this.endDate);
            const torDataPoints = await this.loadTimeSeriesData(theoreticalOilRate, this.startDate, this.endDate);
            const gorDataPoints = await this.loadTimeSeriesData(theoreticalGOR, this.startDate, this.endDate);
            const twcDataPoints = await this.loadTimeSeriesData(theoreticalWaterCut, this.startDate, this.endDate);

            this.chartDef.push(this.processTimeseriesData(tlrDataPoints, 'Liquid Rate'));
            this.chartDef.push(this.processTimeseriesData(torDataPoints, 'Oil Rate'));
            this.chartDef.push(this.processTimeseriesData(gorDataPoints, 'GOR'));
            this.chartDef.push(this.processTimeseriesData(twcDataPoints, 'Water Cut'));

            res();
          },
          error: err => {
            console.error(err);
            res();
          },
        });
      } else {
        res();
      }
    });
  }

  private loadEventsData(source: string): Promise<void> {
    return new Promise(async res => {
      if (this.wellSelected) {
        let eventsData = [];
        const startDate = this.startDate;
        const endDate = this.endDate;

        const filter = this.buildFilter(source, startDate, endDate, this.wellSelected);

        try {
          eventsData = await this.apiService.getEventListWithCustomFilter(filter);

          if (eventsData?.length > 0) {
            for (const event of eventsData) {
              const eventData: SeriesDef = { name: '', unit: '', points: [] };
              eventData.name = event.description;
              eventData.points.push([event.startTime, 0]);
              eventData.points.push([event.startTime, 1500]);
              this.eventDef.push(eventData);
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          res()
        }
      } else {
        res()
      }
    });
  }

  private loadEchoData(): Promise<void> {
    return new Promise(res => {
      const view = environment.spaces.wellbook.views.echometer;

      const cdfSpaceView: CdfSpaceView = {
        space: environment.spaces.wellbook.id,
        id: view.id,
        version: view.version,
        fullName: `${view.id}/${view.version}`,
      };

      if (cdfSpaceView) {
        const filter: any = this.wbService.getCdfFilter(cdfSpaceView, 'well', ['node', 'externalId'], [this.wellSelected]);

        this.apiService.getInstancelist(view.id, filter, view.version, environment.spaces.wellbook.id).subscribe({
          next: async (data: NodeAndEdgeCollectionResponseV3Response) => {
            const { gaseousLiquidSubmergence, pumpSubmergence, foamFreeLiquidLevel, pumpDepth } = this.getPropertyExternalIds(data, ['gaseousLiquidSubmergence', 'pumpSubmergence', 'foamFreeLiquidLevel', 'pumpDepth'], cdfSpaceView);
            const glsDataPoints = await this.loadTimeSeriesData(gaseousLiquidSubmergence, this.startDate, this.endDate);
            const psDataPoints = await this.loadTimeSeriesData(pumpSubmergence, this.startDate, this.endDate);
            const fflDataPoints = await this.loadTimeSeriesData(foamFreeLiquidLevel, this.startDate, this.endDate);
            const pdDataPoints = await this.loadTimeSeriesData(pumpDepth, this.startDate, this.endDate);

            this.chartDef.push(this.processTimeseriesData(glsDataPoints, 'Liquid Submerge'));
            this.chartDef.push(this.processTimeseriesData(psDataPoints, 'Pump Submerge'));
            this.chartDef.push(this.processTimeseriesData(fflDataPoints, 'Foam Free Liquid'));
            this.chartDef.push(this.processTimeseriesData(pdDataPoints, 'Pump Depth'));
            res();
          },
          error: err => {
            console.error(err);
            res();
          },
        });
      } else {
        res();
      }
    });
  }

  private buildFilter(source: string, startTime: number, endTime: number, externalId: string): any {
    return {
      startTime: { min: startTime, max: endTime },
      source: source,
      assetSubtreeIds: [{ externalId: externalId }],
    };
  }

  private loadTimeSeriesData(externaIds: string[], startDate: number, endDate: number): Promise<any> {
    return new Promise(async res => {
      if (externaIds?.length <= 0 || !startDate || !endDate) {
        res([]);
      } else {
        const items = externaIds.map(e => {
          return { externalId: e, cursor: '' };
        });

        const data: any = await this.apiService.getTimeseriesDataAvgRange1(items, this.startDate, this.endDate);
        res(data);
      }
    });
  }

  private getPropertyExternalIds(data: NodeAndEdgeCollectionResponseV3Response, columns: string[], cdfDef: CdfSpaceView) {
    const propExternalIds: { [propName: string]: string[] } = {};

    for (const column of columns)
      propExternalIds[column] = [];

    if (cdfDef && data?.items?.length > 0 && columns?.length > 0) {
      data.items.forEach(item => {
        const properties = item.properties?.[cdfDef.space]?.[cdfDef.fullName];
        for (const column of columns) {
          if (properties?.[column])
            propExternalIds[column].push((properties[column] as string));
        }
      });
    }

    return propExternalIds;
  }

  private processTimeseriesData(data: any, name: string): SeriesDef {
    const timeSeriesData: SeriesDef = { name: name, unit: '', points: [] };

    if (data?.length > 0) {
      const details = data[0];
      timeSeriesData.unit = details.unit ?? '';

      if (data[0].datapoints?.length > 0) {
        for (const point of data[0].datapoints) {
          timeSeriesData.points.push([point.timestamp.getTime(), +point.average.toFixed(3)]);
        }
      }
    }

    return timeSeriesData;
  }

  private setChartOptions() {
    const pointInterval: number = 24 * 3600 * 1000;
    const yAxis: any = [];
    const series: any = [];
    const unitYAxisMap = new Map();
    const unitNamesMap = new Map();

    for (const def of this.chartDef) {
      if (def.points?.length > 0) {
        const name = def.unit ? `${def.name} (${def.unit})` : def.name;

        let yAxisIndex;

        if (def.unit) {
          if (unitYAxisMap.has(def.unit)) {
            yAxisIndex = unitYAxisMap.get(def.unit);
            unitNamesMap.get(def.unit).push(name);
          } else {
            yAxisIndex = yAxis.length;

            unitYAxisMap.set(def.unit, yAxisIndex);
            unitNamesMap.set(def.unit, [name]);

            yAxis.push({
              title: { text: name, style: { color: '#FFFFFF' } },
              labels: { style: { color: '#FFFFFF' } },
              gridLineColor: '#38385A',
              offset: yAxis.filter((e: any) => !e.opposite).length * 60,
              opposite: false,
              allowDecimals: false,
            });
          }
        } else {
          yAxisIndex = yAxis.length;

          yAxis.push({
            title: { text: name, style: { color: '#FFFFFF' } },
            labels: { style: { color: '#FFFFFF' } },
            gridLineColor: '#38385A',
            offset: yAxis.filter((e: any) => e.opposite).length * 60,
            opposite: true,
            allowDecimals: false,
          });
        }

       // def.points.forEach(e => e[0] += 86400000);
        def.points.forEach(e => e[0]);
        series.push({
          pointInterval: pointInterval,
          data: def.points,
          name: name,
          yAxis: yAxisIndex,
          type: name.includes('Pump Submerge') ? 'scatter':'line',
        });
      }
    }
    let opposite = true;

    for (const [unit, names] of unitNamesMap.entries()) {
      opposite = names.toString().toLowerCase().includes('oil') || names.toString().toLowerCase().includes('liquid') ? false : true;
      const concatenatedName = names.join(', ');
      const yAxisIndex = unitYAxisMap.get(unit);

      yAxis[yAxisIndex].title.text = concatenatedName;
      yAxis[yAxisIndex].opposite = opposite;
    }

    let maxValue = Math.max.apply(
      Math.max, series.filter((e: any) => e.yAxis === 0)
                      .map((e: any) => Math.max.apply(Math.max, e.data.map((o: any) => o[1])))
    );

    maxValue = Math.ceil(maxValue * 1.10);

    for (const [i, def] of this.eventDef.entries()) {
      if (def.points?.length > 0) {
        const data = def.points[1];
        data[1] = maxValue;
        def.points[1] = data;

        series.push({
          name: def.name,
          yAxis: 0,
          showInLegend: false,
          type: 'line',
          data: def.points,
          marker: {
            enabled: false,
          },
          lineWidth: 2,
          color: '#ff0000',
          dataLabels: {
            rotation: 270,
            enabled: true,
            allowOverlap: true,
            formatter: function () {
              if (this.y !== 0) {
                return def.name;
              }

              return null;
            },
            style: {
              fontWeight: 'bold',
              fontSize: '12px',
              color: 'violet',
            },
            verticalAlign: 'top',
            align: 'center',
            x: 10,
            y: -5 * i,
          },
        });
      }
    }

    series.forEach((e: any) => {
      const maxValue = Math.max.apply(Math.max, e.data.map((e: any) => e[1]));

      if (maxValue > (yAxis[0].max ?? 0))
        yAxis[e.yAxis ?? 0].maxValue = maxValue;
    });

    this.chartOptions = {
      lang: { noData: 'no data' },
      chart: {
        backgroundColor: 'transparent',
        width: document.getElementById('chart-container')?.offsetWidth.toString(),
        zooming: {
          type: 'xy',
        },
      },
      credits: { enabled: false },
      title: { text: '' },
      legend: {
        enabled: true,
        itemStyle: {
          color: '#FFFFFF',
        },
      },
      xAxis: {
        lineColor: '#FFFFFF',
        type: 'datetime',
        minTickInterval: 86400000,
        tickmarkPlacement: 'on',
        labels: {
          enabled: true,
          style: {
            color: '#FFFFFF',
          },
          format: '{value:%e %b %Y}',
        },
      },
      yAxis: yAxis,
      exporting: {
        enabled: true,
        filename: `${this.wellSelected}_Production_Data`,
        chartOptions: {
          chart: {
            backgroundColor: '#12122D',
          },
        },
      },
      tooltip: {
        backgroundColor: '#38385A',
        style: {
          color: '#FFFFFF',
        },
        shared: true,
        pointFormat: '{series.name} : {point.y:.2f}',
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function () {
              let yAxis;

              if (typeof this.yAxis === 'number') {
                yAxis = this.chart.yAxis[this.yAxis];
              } else if (typeof this.yAxis === 'object' && this.yAxis) {
                yAxis = this.yAxis;
              }

              if (yAxis) {
                if (this.visible) {
                  yAxis.update({ title: { text: '' } });
                } else {
                  yAxis.update({
                    title: {
                      text: this.name,
                      style: { color: '#FFFFFF' },
                    },
                  });
                }
              }

              return true;
            },
          },
        },
      },
      responsive: {
        rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
            chart: {
              height: '100%',
            },
            yAxis: {
              labels: {
                style: { fontSize: '10px' },
              },
            },
          },
        }],
      },
      rangeSelector: { enabled: false },
      series: series,
    };
  }

}
