<div class="tabcontrolpanel">
  <mat-button-toggle-group (change)="onTabChange($event)" [(value)]="selectedtab">
    <mat-button-toggle [value]="tabOptions.OVR">Overview</mat-button-toggle>
    <mat-button-toggle [value]="tabOptions.WSS">Well Status Summary</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<ng-container *ngIf="selectedtab === 'OVR'">
  <div class="tabcontrolpanel">
    <mat-button-toggle-group (change)="onChange($event)" [(value)]="Liftselectedtab">
      <mat-button-toggle [value]="liftTypetabOptions.ESP">ESP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.HRP">HRP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.Injector">Injector</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.NF">NF</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.JP">JP</mat-button-toggle>
      <mat-button-toggle [value]="liftTypetabOptions.PCP">PCP</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="content-left-margin">
    <span>
      <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [showClearButton]="true" [getListofAllWellsUnderField]="true" [LiftTypeToloadWells]="Liftselectedtab" (onclear)="clear()" (ondatechange)="onDateSelected($event)" [selectedWellType]="(Liftselectedtab === liftTypetabOptions.Injector?'Injector':'Producer' )" (onWellPadChange)="onfilterwellPadChange($event)" [selectDefaultWellValue]="true" (onWellchange)="onFilterWellChange($event)" [retainWellName]="retainWellName" componentName="WellMonitoring" ></app-hierarchy>
    </span>
  </div>
  <div class="page-content">
    <div class="mat-card">
      <app-auto-refresh (TimerExpired)="refresh()" [hidden]="'true'" [RefreshTime]="15"></app-auto-refresh>
      <app-i-frame title="Cairn ASP Surveillance" [src]="iframeurl"></app-i-frame>
      <div class="pumpchart" *ngIf="!hide" >
        <mat-card>
          <pump-chart [chartId]="'1'" [showCardTitle]="true" [Title]="'Pump Parameters'" [granularity]="pumpparametergranuality" [well]="this.selectedWell" [Lifttype]="this.Lifttype ?? ''" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.propertyConditions"></pump-chart>
        </mat-card>
      </div>
      <div class="injectchart">
        <div class="pumpchart1">
          <mat-card *ngIf="Liftselectedtab == 'PCP'">
            <pump-chart [chartId]="'2'" [Title]="'PIP Comparison'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.comparisonConditions"></pump-chart>
          </mat-card>
        </div>
        <div class="pumpchart2">
          <mat-card *ngIf="Liftselectedtab == 'PCP'">
            <pump-chart [chartId]="'3'" [Title]="'Surface Line DP'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.surfacedpConditions"></pump-chart>
          </mat-card>
        </div>
      </div>
      <div class="pumpchart">
          <mat-card *ngIf="Liftselectedtab == 'HRP'">
            <pump-chart [chartId]="'3'" [Title]="'Surface Line DP'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.surfacedpConditions"></pump-chart>
          </mat-card>
        </div>
      <div class="injectchart">
      <div class="pumpchart1">
        <mat-card *ngIf="Liftselectedtab == 'ESP'">
          <pump-chart [chartId]="'4'" [Title]="'RNDP'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.rndpConditions"></pump-chart>
        </mat-card>
      </div>
      <div class="pumpchart2">
          <mat-card *ngIf="Liftselectedtab == 'ESP'">
            <pump-chart [chartId]="'3'" [Title]="'Surface Line DP'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.surfacedpConditions"></pump-chart>
          </mat-card>
        </div>
      </div>
      <div class="pumpchart">
        <mat-card *ngIf="Liftselectedtab == 'NATURAL'">
          <pump-chart [chartId]="'5'" [Title]="'Flowline Pressure'" [well]="this.selectedWell" [Lifttype]="this.Lifttype" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.flpConditions"></pump-chart>
        </mat-card>
      </div>
      <div class="pump-curve-chart" *ngIf="selectedtab === 'OVR' && Liftselectedtab === liftTypetabOptions.ESP">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Pump Curve</mat-card-title>
          </mat-card-header>
          <mat-card-content style="margin-top: 10px;">
            <app-pump-curve-chart [well]="this.selectedWell" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate"></app-pump-curve-chart>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="injectchart">
        <div class="pumpchart1">
          <mat-card *ngIf="Liftselectedtab == 'Injector'">
            <app-pressure-chart [chartId]="'1'" [Title]="'Temperature and Pressure'" [well]="this.selectedWell" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.pressureConditions"></app-pressure-chart>
          </mat-card>
          </div>
          <div class="pumpchart2">
          <mat-card *ngIf="Liftselectedtab == 'Injector'">
            <app-pressure-chart [chartId]="'2'" [Title]="'Total Injection Rate, Ploymer Injection Rate'" [well]="this.selectedWell" [startDate]="this.filterStartDate" [endDate]="this.ChartEnddate" [properties]="this.injectorConditions"></app-pressure-chart>
          </mat-card>
      </div>
      </div>
      <mat-card class="alrmgrid" *ngIf="Liftselectedtab !== 'Injector' ">
        <mat-card-header>
          <mat-card-title>Alert Log Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="alarmgridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="well" [filterable]="true" [sortable]="true">
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="alarmgridData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </ng-template>
            <kendo-grid-column field="well" title="Well" [width]="160"></kendo-grid-column>
            <kendo-grid-column field="workflowName" title="Workflow Name" [width]="130"></kendo-grid-column>
            <kendo-grid-column field="startDateTime_ts" title="Start Date" [width]="170">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startDateTime }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="startDateTime">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="endDateTime_ts" title="End Date" [hidden]="true">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.endDateTime }}</ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter>
                <app-date-time-filter [filter]="filter" valueField="endDateTime">
                </app-date-time-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="alarmName" title="Alarm Name" [width]="170"></kendo-grid-column>
            <kendo-grid-column field="alarmFlags" title="Alarm Flags" [width]="170"></kendo-grid-column>
            <kendo-grid-column field="alarmMessage" title="Alarm Message" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="alarmType" title="Alarm Type" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="alarmLevel" title="Alarm Level" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="driftWindow" title="Drift Window" [width]="120" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="maxDeviation" title="Max Deviation" [width]="120" [hidden]="Liftselectedtab !== liftTypetabOptions.PCP"></kendo-grid-column>
            <kendo-grid-column field="allowedDeviation" title="Allowed Deviation" [width]="120" [hidden]="Liftselectedtab !== liftTypetabOptions.PCP"></kendo-grid-column>
            <kendo-grid-excel fileName="Alert Log Summary.xlsx" ></kendo-grid-excel>
            <kendo-grid-pdf fileName="Alert Log Summary.pdf" [allPages]="true" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
      <mat-card class="alrmgrid" *ngIf="Liftselectedtab !== 'Injector' ">
        <mat-card-header>
          <mat-card-title>Event Log Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-event-alarm [WellListToloadGrid]="this.WellListToloadGrid" [Type]="this.Liftselectedtab" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate" title="Event Log Summary"></app-event-alarm>
        </mat-card-content>
      </mat-card>

      <mat-card class="alrmgrid" *ngIf="Liftselectedtab === 'Injector' ">
        <mat-card-header>
          <mat-card-title>Concetration Log Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-event-concentration-alarm [WellListToloadGrid]="this.WellListToloadGrid" [Type]="this.Liftselectedtab" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate" title="Concentration Log Summary"></app-event-concentration-alarm>
        </mat-card-content>
      </mat-card>

      <mat-card class="alrmgrid" *ngIf="Liftselectedtab === 'Injector' ">
        <mat-card-header>
          <mat-card-title>Viscosity Log Summary</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-event-viscosity-alarm [WellListToloadGrid]="this.WellListToloadGrid" [Type]="this.Liftselectedtab" [startDate]="this.filterStartDate" [endDate]="this.filterEndDate" title="Viscosity Log Summary"></app-event-viscosity-alarm>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="selectedtab === 'WSS'">
  <app-well-summary></app-well-summary>
</ng-container>
