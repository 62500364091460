import { Component, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { HierarchyService } from '../../services/hierarchy.service';
import { CognitApiService } from '../../services/cognit-api.service';
import { Observable, Subscription, map } from 'rxjs';
import moment, { Moment } from 'moment';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { environment } from 'src/environments/environment';
import { MessageService, SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: "app-hierarchy",
  templateUrl: "./hierarchy.component.html",
  styleUrls: ["./hierarchy.component.css"],
})
export class HierarchyComponent {

  @Input() showDatePicker: boolean = false;
  @Input() showDateRange: boolean = false;
  @Input() showClearButton: boolean = false;
  @Input() showonlyFieldControl: boolean = false;
  @Input() showoWellTypeFilter: boolean = false;
  @Input() screeId: string = '';
  @Input() getListofAllWellsUnderField: boolean = false;
  @Input() LiftTypeToloadWells: string;
  @Input() timerangeDifference: any;
  @Input() selectDefaultFieldValue: boolean = false;
  @Input() AddAllFieldValue: boolean = false;
  @Input() selectedWellType: string;
  @Input() filterOnWellType: boolean = true;
  @Input() selectDefaultWellValue: boolean = false;
  @Input() componentName: string = '';
  @Input() showMonthYearDateFilter: boolean = false;
  @Input() showErrorFromComponentToHierarchyMessage = false;
  @Input() errorMessage = '';
  @Input() retainHierarchy: boolean = false;
  @Input() retainWellName: boolean = false;

  @Output() onFieldChange = new EventEmitter<any>();
  @Output() onWellPadChange = new EventEmitter<any>();
  @Output() onWellchange = new EventEmitter<any>();
  @Output() ondatechange = new EventEmitter<any>();
  @Output() onclear = new EventEmitter<any>();
  @Output() onMonthYearDate = new EventEmitter<any>();

  public range: DateRange<Moment>;
  public startDate: any;
  public fieldList: any[] = [];
  public selectedField: string;
  public wellPadList: any;
  public filterWellList: any;
  public selectedWell: string;
  public selectedWellPad: string;
  public curDate: moment.Moment;
  public monthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
  public CurrentmonthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
  public filterStartDate: any;
  public filterEndDate: any;

  private readonly subs: Subscription[] = [];
  private liftType: string;
  private filedListRaw: any[] = [];
  private allWellList: any[] = [];
  private loadWellDetails: boolean = true;

  constructor(
    private readonly apiService: CognitApiService,
    private readonly loader: LoaderService,
    private readonly hierarchyService: HierarchyService,
    private readonly messageService: MessageService,
    private readonly cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.setDateRangeDefault();

    if (this.filterOnWellType === true && (this.selectedWellType === undefined || this.selectedWellType === ''))
      this.selectedWellType = 'Producer';

    if (this.retainHierarchy == true) {
      this.selectedField = this.hierarchyService.getfield();
      this.selectedWell = this.showonlyFieldControl != true ? this.hierarchyService.getWell() : '';
    }

    this.subs.push(
      this.hierarchyService.getFieldData().subscribe((data: any) => {
        this.fieldList = _.cloneDeep(data);
        this.filedListRaw = _.cloneDeep(data);
        if (this.fieldList.length > 0) {
          this.setAllFieldsAsDefault();
          this.selectDeafaultfield();
          if (this.isFieldselected() && !this.isWellSelected())
            return;
          if ((this.showonlyFieldControl == true && this.getListofAllWellsUnderField == true) || this.showonlyFieldControl != true)
            this.loadAllWells();
        }
      })
    );
  }

  public getCurDate() {
    return new Date();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.liftType = this.LiftTypeToloadWells;

    if (changes.LiftTypeToloadWells !== undefined &&
      changes.LiftTypeToloadWells.previousValue !== undefined &&
      changes.LiftTypeToloadWells.currentValue !==
      changes.LiftTypeToloadWells.previousValue)
      this.liftTypeChange();
  }

  public setAllFieldsAsDefault() {
    if (this.AddAllFieldValue === true && this.fieldList.length > 0) {
      if (this.fieldList[0].externalId !== 'All') {
        const field = { externalId: 'All', Name: 'All' };
        this.fieldList.splice(0, 0, field);
      }

      this.selectedField = this.isFieldselected() ? this.selectedField : 'All';
      // if ((!this.isWellSelected() || this.showonlyFieldControl) && this.retainHierarchy == true)) {
      //   const event = { value: this.selectedField };
      //   this.onfieldChange(event);
      // }
    } else {
      this.fieldList = _.cloneDeep(this.filedListRaw);
    }
  }

  private selectDeafaultWell() {
    if (this.filterWellList?.length > 0) {
      let event;
      if (this.selectDefaultWellValue === true && this.retainWellName == true && this.componentName === 'WellMonitoring') {
        this.selectedWell = this.hierarchyService.getWell();
        event = { value: this.selectedWell };
        this.onFilterWellChange(event);
      } else if (this.selectDefaultWellValue === true) {
        let wellindex = this.filterWellList.findIndex((x: any) => x.externalId == this.selectedWell);
        this.selectedWell = this.selectedWell && wellindex > -1 ? this.selectedWell : this.filterWellList[0].externalId;
        event = { value: this.selectedWell };
        this.onFilterWellChange(event);
      } else if (this.selectedWell && this.retainHierarchy === true && this.showonlyFieldControl != true) {
        this.selectedWell = this.hierarchyService.getWell();
        event = { value: this.selectedWell };
        this.onFilterWellChange(event);
      }
    }
  }

  private selectDeafaultfield() {
    if (this.fieldList.length > 0) {
      this.selectedField = (this.selectDefaultFieldValue === true && this.selectedField === undefined) || (this.selectDefaultFieldValue === true && this.selectedField === "All") ? this.fieldList[0].externalId : this.selectedField
      if (this.selectedField == "All" && !this.AddAllFieldValue)
        this.selectedField = '';
      const event = { value: this.selectedField };
      if (this.selectedField && (!this.isWellSelected() || this.showonlyFieldControl == true) && (this.selectDefaultFieldValue === true || this.AddAllFieldValue == true)) {
        this.onfieldChange(event);
      } else if (
        this.selectedField && this.retainHierarchy === true && (!this.isWellSelected())
      ) {
        this.onfieldChange(event);
      }
    }
  }

  private liftTypeChange() {
    if (this.selectedField !== undefined && this.selectedField !== '') {
      const event = { value: this.selectedField };
      this.onfieldChange(event);
    } else {
      this.loadAllWells();
    }
  }

  private loadAllWells(filter: any = undefined, event: any = undefined, changeEvent: any = undefined) {
    this.loader.showLoader();

    this.subs.push(
      this.hierarchyService.getAllWells(filter).subscribe((data: any) => {
        if (this.screeId === 'InjectorMonitoringTol') {
          this.filterInjectionWell(data).subscribe((welldata: any) => {
            this.filterWellList = welldata;
            this.getSelectedWellList();

            if (this.filterWellList.length === 0) {
              const alert: SlbMessage = {
                target: 'modal',
                severity: SlbSeverity.Info,
                detail: 'There is no well which has Polymerconcentration value',
              };

              this.messageService.add(alert);
            } else if (changeEvent != undefined) {
              changeEvent.emit({
                event: event,
                well: this.filterWellList,
                wellPadList: this.wellPadList,
                allFieldwells: this.allWellList,
                allFields: this.fieldList,
              });
            }
          });
        } else if (this.selectedWellType !== undefined && this.selectedWellType !== '') {
          this.filterWellDataOnWellType(data).then((list: any) => {
            if (this.LiftTypeToloadWells !== undefined && this.LiftTypeToloadWells.toLocaleLowerCase() !== 'injector') {
              this.filterLisftTypeData(list).then((listfilterlist: any) => {
                this.filterWellList = listfilterlist;
                this.getSelectedWellList();
                this.selectDeafaultWell();
                if (changeEvent != undefined)
                  changeEvent.emit({
                    event: event,
                    well: this.filterWellList,
                    wellPadList: this.wellPadList,
                    allFieldwells: this.allWellList,
                    allFields: this.fieldList,
                  });
              });
            } else {
              this.filterWellList = list;
              this.getSelectedWellList();
              this.selectDeafaultWell();

              if (changeEvent != undefined)
                changeEvent.emit({
                  event: event,
                  well: this.filterWellList,
                  wellPadList: this.wellPadList,
                  allFieldwells: this.allWellList,
                  allFields: this.fieldList,
                });
            }
          });
        } else if (this.LiftTypeToloadWells != undefined) {
          this.filterLisftTypeData(data).then((list: any) => {
            this.filterWellList = list;
            this.getSelectedWellList();
            this.selectDeafaultWell();

            if (changeEvent != undefined)
              changeEvent.emit({
                event: event,
                well: this.filterWellList,
                wellPadList: this.wellPadList,
                allFieldwells: this.allWellList,
                allFields: this.fieldList,
              });
          });
        } else {
          this.loader.hideLoader();
          this.filterWellList = data.slice();
          this.getSelectedWellList();
          this.selectDeafaultWell();
          if (changeEvent != undefined)
            changeEvent.emit({
              event: event,
              well: this.filterWellList,
              wellPadList: this.wellPadList,
              allFieldwells: this.allWellList,
              allFields: this.fieldList,
            });
        }
      })
    );
  }

  private getSelectedWellList() {
    if (this.isWellSelected() && this.filterWellList !== undefined && this.filterWellList.length > 0) {
      this.allWellList = this.filterWellList.filter((item: any) => item.externalId == this.selectedWell);
    } else {
      this.allWellList = this.filterWellList != undefined ? this.filterWellList.slice() : undefined;
    }
  }

  public onWellTypeChange(event: any) {
    this.selectedWellType = event.value;
    this.filterWellList = [];
    this.wellPadList = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';

    this.onclear.emit();

    this.hierarchyService.setWell(null);
    this.hierarchyService.setField(null);

    this.selectDeafaultfield();

    if (this.selectDefaultFieldValue !== true)
      this.loadAllWells();
  }

  public onfieldChange(event: any) {
    this.loadWellDetails = false;
    this.selectedField = event.value;

    this.filterWellList = [];
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.hierarchyService.setField(this.selectedField);
    this.hierarchyService.setWell(this.selectedWell);

    if (this.selectedField === 'All') {
      if (!this.showonlyFieldControl) {
        this.loadWellDetails = true;
        this.getWellPadListForAllFields();
      }

      if ((this.getListofAllWellsUnderField != false && this.showonlyFieldControl === true) || this.showonlyFieldControl !== true) {
        this.loadAllWells(undefined, event, this.onFieldChange);
      } else {
        this.onFieldChange.emit({
          event: event,
          wellPadList: this.wellPadList,
          allFieldwells: undefined,
        });
      }
    } else {
      this.loader.showLoader();

      this.subs.push(
        this.hierarchyService.getWellpad(event.value).subscribe((data: any) => {
          this.loader.hideLoader();
          this.wellPadList = data;

          if (this.getListofAllWellsUnderField === true || !this.showonlyFieldControl) {
            this.getSelectedFielWellData([event.value], event, this.onFieldChange);
          } else {
            this.onFieldChange.emit({
              event: event,
              wellPadList: this.wellPadList,
              allFieldwells: undefined,
              allFields: this.fieldList,
            });
          }
        })
      );
    }
  }

  private getWellPadListForAllFields() {
    this.loader.showLoader();

    const wellpadObservable = this.hierarchyService.getWellpad('All');

    this.subs.push(wellpadObservable.subscribe({
      next: result => {
        this.loader.hideLoader();
        this.wellPadList = result;

        this.wellPadList.forEach((pad: any) => {
          if (pad.externalId !== pad.Name)
            pad.Name = pad.externalId;
        });
      },
      error: err => {
        this.loader.hideLoader();
        console.error('Error fetching wellpad data', err);
      },
    }));
  }

  private getWellPadFilter() {
    let wellfilter = undefined;

    if (this.selectedWellPad !== undefined && this.selectedWellPad !== '')
      wellfilter = {
        nested: {
          scope: [environment.cogniteSpace, 'Well/1_0', 'wellPad'],
          filter: {
            equals: {
              property: ['node', 'externalId'],
              value: this.selectedWellPad,
            },
          },
        },
      };

    return wellfilter;
  }

  public onFilterWellPadChange(event: any) {
    this.loadWellDetails = false;
    const wellfilter = this.getWellPadFilter();

    this.loadAllWells(wellfilter, event, this.onWellPadChange);
  }

  private async waitForProperty(property: () => any): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (property()) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  public async onFilterWellChange(event: any, updateWellSelection: boolean = false) {
    await this.waitForProperty(() => this.filterWellList);

    this.selectedWell = event?.value;
    this.hierarchyService.setWell(this.selectedWell);

    this.allWellList = this.filterWellList.filter((item: any) => item.externalId == event?.value);

    if (this.loadWellDetails === true) {
      const wellpadid = this.allWellList[0]?.WellPad;

      if (wellpadid != undefined) {
        const filter = {
          equals: {
            property: ["externalId"],
            value: wellpadid,
          },
        };

        this.loader.showLoader();
        this.apiService
          .getInstance('WellPad', filter)
          .subscribe((wellpad: any) => {
            const properties = wellpad.items[0].properties;
            let field: string;

            if (properties !== undefined) {
              field = properties[environment.cogniteSpace]['WellPad/' + environment.cogniteSDMVersion].field.externalId;

              this.selectedField = field;
              this.hierarchyService.setField(field);

              this.subs.push(this.hierarchyService
                .getWellpad(this.selectedField)
                .subscribe((data: any) => {
                  this.loader.hideLoader();

                  this.wellPadList = data;
                  this.selectedWellPad = wellpadid;

                  this.onWellchange.emit({
                    event: event,
                    well: this.filterWellList,
                    wellPadList: this.wellPadList,
                    allFieldwells: this.allWellList,
                    selectedWellPad: this.selectedWellPad,
                    selectedField: this.selectedField,
                  });

                  if (updateWellSelection) {
                    this.selectedWell = event.value;
                    this.hierarchyService.setWell(this.selectedWell);
                  }
                })
              );
            }
          });
      }
    } else {
      this.selectedWellPad = this.filterWellList.filter((item: any) => item.externalId == this.selectedWell)[0]?.WellPad;

      this.onWellchange.emit({
        event: event,
        well: this.filterWellList,
        wellPadList: this.wellPadList,
        allFieldwells: this.allWellList,
        selectedWellPad: this.selectedWellPad,
        selectedField: this.selectedField,
      });
    }
  }

  ngOnDestroy() {
    while (this.subs.length > 0)
      this.subs.pop()?.unsubscribe();
  }

  public setDateRangeDefault() {
    this.curDate = moment(moment().toDate());
    this.startDate = moment(moment().toDate());

    const daterange = this.hierarchyService.getDateRange();

    if (this.componentName === 'AutomatedDashboardComponent') {
      this.curDate = moment(moment().subtract(1, 'day').toDate());
      this.startDate = moment(moment().subtract(1, 'day').toDate());
    }
    if (this.timerangeDifference !== undefined) {
      this.filterStartDate = moment(moment()).add(-this.timerangeDifference, 'days');
      this.filterEndDate = moment();
    } else if (daterange && this.retainHierarchy === true) {
      this.filterStartDate = daterange.startDate;
      this.filterEndDate = daterange.endDate;
      if(this.componentName === 'AutomatedDashboardComponent' && this.filterEndDate.isAfter(this.curDate))
        this.filterEndDate = this.curDate;
    } else if (this.componentName === 'AutomatedDashboardComponent') {
      this.filterStartDate = moment(moment().subtract(1, 'day')).add(-1, 'months');
      this.filterEndDate = moment().subtract(1, 'day');
    } else {
      this.filterStartDate = moment(moment()).add(-1, 'months');
      this.filterEndDate = moment();
    }

    this.range = { startDate: this.filterStartDate, endDate: this.filterEndDate };
    this.hierarchyService.setDateRange(this.range);

    const dateevent = { startDate: this.filterStartDate, endDate: this.filterEndDate };
    this.ondatechange.emit({ event: dateevent });

    if (this.showMonthYearDateFilter) {
      this.range = { startDate: moment(this.monthYearDate.value).startOf('month'), endDate: this.monthYearDate.value };
      if(!this.hierarchyService.getMonthYearDateRange()){
      this.hierarchyService.setMonthYearDateRange(this.range ?? null);
      }
       this.onMonthYearDate.emit({ event: this.range });
    }
  }

  public onDateSelected(event: any) {
    if (this.showDateRange) {
      this.range.startDate = event?.startDate ?? null;
      this.range.endDate = event?.endDate ?? null;

      this.filterStartDate = event.startDate;
      this.filterEndDate = moment(event.endDate).add(1, "days");

      if (!event.startDate || !event.endDate)
        return;
    } else {
      this.startDate = event.startDate;
    }
    this.hierarchyService.setDateRange(this.range);
    if (this.selectedWellType || this.LiftTypeToloadWells) {
      if (this.isFieldselected() && !this.isWellPadSelected() && !this.loadWellDetails) {
        this.getSelectedFielWellData([this.selectedField], event, this.ondatechange);
      } else if (this.isWellPadSelected() && !this.loadWellDetails) {
        //&& !this.loadWellDetails
        const wellfilter = this.getWellPadFilter();
        this.loadAllWells(wellfilter, event, this.ondatechange);
      } else {
        this.loadAllWells(undefined, event, this.ondatechange);
      }

    } else {
      this.ondatechange.emit({ event: event });
    }
  }

  private isWellPadSelected() {
    return this.selectedWellPad !== undefined && this.selectedWellPad !== '' && this.selectedWellPad !== null;
  }

  private isWellSelected() {
    return this.selectedWell !== undefined && this.selectedWell !== '' && this.selectedWell !== null;
  }

  private isFieldselected() {
    return this.selectedField !== undefined && this.selectedField !== '' && this.selectedField !== null;
  }

  public clear(loadwells = true) {
    this.loadWellDetails = true;
    this.filterWellList = [];
    this.wellPadList = [];
    this.selectedWell = '';
    this.selectedWellPad = '';
    this.selectedField = '';

    this.hierarchyService.setField('');
    this.hierarchyService.setWell('');
    this.hierarchyService.setMonthYearDateRange('');
    this.hierarchyService.setDateRange(undefined);
    this.onclear.emit();
    this.setAllFieldsAsDefault();
    this.selectDeafaultfield();

    this.selectedWell = '';

    if (
      this.filterOnWellType === true &&
      (this.selectedWellType === undefined || this.selectedWellType === '')
    ) {
      this.selectedWellType = 'Producer';
    }

    if (loadwells === true && !this.selectedField)
      this.loadAllWells();

    this.monthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
    this.setDateRangeDefault();
  }

  private filterInjectionWell(wellist: any): Observable<any> {
    if (this.screeId === 'InjectorMonitoringTol') {
      const wells = wellist.map((x: any) => x.externalId);
      this.loader.showLoader();

      return this.hierarchyService.getInjectionFilterWelldata(wells).pipe(
        map((injectordata: any) => {
          this.loader.hideLoader();

          injectordata = injectordata.map((data: any) => data.externalId);
          const welllist = wellist.filter((well: any) => injectordata.includes(well.externalId));

          return welllist;
        })
      );
    } else {
      return wellist;
    }
  }

  private async filterLisftTypeData(welllist: any): Promise<any> {
    if (this.LiftTypeToloadWells != undefined) {
      welllist = welllist.filter((well: any) => well.LiftType != undefined);

      let lifttypeTimeserieslist: any[] = [];

      if (this.screeId === 'inputcalibration') {
        lifttypeTimeserieslist = [
          ...new Set(
            welllist.map((asset: any) => ({
              externalId: asset.LiftType,
              before: new Date(this.filterEndDate),
            }))
          ),
        ];
      } else {
        lifttypeTimeserieslist = [
          ...new Set(
            welllist.map((asset: any) => ({ externalId: asset.LiftType }))
          ),
        ];
      }

      const lifttypedata: any[] = [];
      let letliftTypedataavailable = false;

      if (lifttypeTimeserieslist.length > 0) {
        let i = 0;

        this.loader.showLoader();

        while (i <= lifttypeTimeserieslist.length) {
          const timeseriesdatapoints = await this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist.slice(i, i + 99));

          timeseriesdatapoints.forEach((datapoint: any) => {
            if (datapoint.datapoints.length > 0) {
              letliftTypedataavailable = true;

              datapoint.datapoints.forEach((data: any) => {
                if (data != undefined && String(data.value).toUpperCase() === this.liftType)
                  lifttypedata.push(datapoint.externalId);
              });
            }
          });

          i = i + 99;
        }

        this.loader.hideLoader();

        if (letliftTypedataavailable === false) {
          this.showAlertforDataNotAvailable('Lift Type Data not available for selected dates. Please contact administrator.');
          return [];
        } else {
          return welllist.filter((item: any) => lifttypedata.includes(item.LiftType));
        }
      }
    }
  }

  /**
   * get all well under selected field
   * @param field selected field
   * @param event field change event
   */
  public getSelectedFielWellData(field: any[], event: any, emitevent: any) {
    if (this.selectedField === 'All') {
      this.loadAllWells(undefined, event, emitevent);
    } else {
      const wellpadid = this.wellPadList.map((X: any) => X.externalId);
      const wellfilter = {
        nested: {
          scope: [environment.cogniteSpace, "Well/1_0", "wellPad"],
          filter: {
            in: {
              property: ["node", "externalId"],
              values: wellpadid,
            },
          },
        },
      };

      this.loadAllWells(wellfilter, event, emitevent);
    }
  }

  private async filterWellDataOnWellType(welllist: any): Promise<any> {
    if (this.selectedWellType != undefined && this.selectedWellType !== '') {
      welllist = welllist.filter((well: any) => well.WellType != undefined);

      const WellTypeTimeserieslist = [
        ...new Set(
          welllist.map((asset: any) => ({
            externalId: asset.WellType,
            before: new Date(this.filterEndDate),
          }))
        ),
      ];

      const WellTypedata: any[] = [];

      if (WellTypeTimeserieslist.length > 0) {
        let i = 0;

        this.loader.showLoader();
        let letwellTypedataavailable = false;

        while (i <= WellTypeTimeserieslist.length) {
          const welltype = this.selectedWellType;
          const timeseriesdatapoints = await this.apiService.getLatestTimeseriesData(WellTypeTimeserieslist.slice(i, i + 99));

          timeseriesdatapoints.forEach((datapoint: any) => {
            if (datapoint.datapoints.length > 0) {
              letwellTypedataavailable = true;

              datapoint.datapoints.forEach((data: any) => {
                if (data != undefined && String(data.value).toLowerCase() == welltype.toLowerCase())
                  WellTypedata.push(datapoint.externalId);
              });
            }
          });

          i = i + 99;
        }

        this.loader.hideLoader();

        if (letwellTypedataavailable === false) {
          this.showAlertforDataNotAvailable('Well Type Data not available for selected dates. Please contact administrator.');
          return [];
        } else
          return welllist.filter((item: any) => WellTypedata.includes(item.WellType));
      }
    }
  }

  private showAlertforDataNotAvailable(msg: string) {
    const alert: SlbMessage = {
      target: "modal",
      severity: SlbSeverity.Error,
      summary: "Data Not Available",
      detail: msg,
    };

    this.messageService.add(alert);
  }

  public chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.monthYearDate.value;
    ctrlValue?.year(normalizedYear.year());
    this.monthYearDate.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.monthYearDate.value;
    ctrlValue?.month(normalizedMonth.month());

    this.monthYearDate.setValue(ctrlValue);

    const enddate = moment(this.monthYearDate.value).endOf('month');

    this.range = { startDate: moment(this.monthYearDate.value).startOf('month'), endDate: enddate };
    this.hierarchyService.setMonthYearDateRange(this.range ?? null);
    this.onMonthYearDate.emit({ event: this.range });

    datepicker.close();
  }

  public emitMonthyearDatevalue(range: any) {
    this.monthYearDate.setValue(range.endDate);
    this.onMonthYearDate.emit({ event: range });
  }

  ngAfterViewInit() {
    if (this.hierarchyService.getMonthYearDateRange() && this.retainHierarchy)
      this.emitMonthyearDatevalue(this.hierarchyService.getMonthYearDateRange());

    this.cd.detectChanges();
  }
}
