import { Component } from '@angular/core';
import { PowerBIReportLink } from 'src/environments/powerBILinks';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



export enum TabOptionsEnum {
  ESP,
  JP,
  PCP,
  HRP,
  NF,
  IM
}

@Component({
  selector: 'app-als-monitoring',
  templateUrl: './als-monitoring.component.html',
  styleUrls: ['./als-monitoring.component.css']
})

export class AlsMonitoringComponent {
  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP
  public esp_report_link:SafeResourceUrl;
  public esp_report_link_cairn:SafeResourceUrl;


  onChange($event: any) {
    //console.log($event.value);
    this.selectedtab = $event.value;
  }
  constructor(private sanitizer: DomSanitizer) { 
    this.esp_report_link = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellsSurveillance_AlsEspMonitoring);
    this.esp_report_link_cairn = this.sanitizer.bypassSecurityTrustResourceUrl(PowerBIReportLink.wellsSurveillance_AlsEspMonitoring_cairn);


  }
  ngOnInit()
  {
    console.log(this.esp_report_link);
  }

}
