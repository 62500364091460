import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import SunsetTheme from 'highcharts/themes/high-contrast-dark';

SunsetTheme(Highcharts);

declare interface LasData {
  curveInfo?: { name: string, unit: string, description: string }[];
  series?: { [propName: string]: number[] };
};

@Component({
  selector: 'app-las-viewer',
  templateUrl: './las-viewer.component.html',
  styleUrls: ['./las-viewer.component.css'],
})
export class LasViewerComponent implements AfterViewInit {

  @Input() lasData: LasData | null = {};

  @ViewChild('chartContainer') chartContainer: ElementRef;

  public loading: boolean = false;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOpts: Highcharts.Options;
  public series: any[] = [];
  public selectedSeries: any[] = [];
  public curveInfo: any[];
  public showCurveInfoRows: boolean = true;
  public isZoomed: boolean = false;
  public isFullscreen: boolean = false;
  public chart: Highcharts.Chart;

  private depthValues: number[] = [];
  private chartSize: { width: number, height: number } = { width: 0, height: 0 };

  constructor() {

  }

  ngAfterViewInit(): void {
    if (this.lasData?.series) {
      this.depthValues = this.lasData.series['DEPTH'] || this.lasData.series['DEPT'];

      this.prepareSeries();
      this.setChartOptions();
    }
  }

  public onSeriesChange() {
    this.setChartOptions();
  }

  public toggleCurveInfoRowsVisibility() {
    this.showCurveInfoRows = !this.showCurveInfoRows;
  }

  public rowClass = () => {
    return { 'hide-row': !this.showCurveInfoRows };
  }

  public toggleFullscreen(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
  }

  public resetZoom(): void {
    this.Highcharts.charts[0]?.zoomOut();
    this.isZoomed = false;
  }

  public setChartInstance(chart: Highcharts.Chart) {
    this.chart = chart;
  }

  private prepareSeries() {
    this.series = [];
    this.curveInfo = [];

    if (!this.lasData?.series)
      return;

    for (const [ key, values ] of Object.entries(this.lasData.series)) {
      if (key === 'DEPTH' || key === 'DEPT')
        continue;

      const curveInfo = this.lasData.curveInfo?.find(e => e.name === key);

      if (curveInfo)
        this.curveInfo.push(curveInfo);

      this.series.push({
        type: 'area',
        name: curveInfo?.unit ? `${key} (${curveInfo.unit})` : key,
        data: values.map((e, i) => [this.depthValues[i] ?? 0, e]).filter(e => e[1] !== -999.25),
        label: '',
      });
    }

    this.selectedSeries = [...this.series];
  }

  private setChartSize(width: number = 0, height: number = 0) {
    this.chartSize = { width: width, height: height };
  }

  private getYAxis() {
    const axisSize = 100 / this.selectedSeries.length;

    const yAxis: any = [];

    let index = 0;

    for (const serie of this.selectedSeries) {
      const { min, max } = this.calculateMinAndMaxSeriesValues(serie.data.map((e: number[]) => e[1]));

      yAxis.push({
        title: { text: serie.name },
        opposite: true,
        offset: 0,
        width: `${axisSize - 2}%`,
        left: `${axisSize * index}%`,
        labels: { enabled: false },
        startOnTick: false,
        endOnTick: false,
        min: min,
        max: max,
        plotBands: [{ from: min, to: max, color: 'rgba(240, 240, 240, 0.2)' }],
        plotLines: [{ value: min, color: '#fff', width: 2, zIndex: 5 }],
      });

      serie.yAxis = index;

      index++;
    }

    return yAxis;
  }

  private getChartDimensions() {
    const seriesCount = this.selectedSeries.length;

    const heightBaseOnPoints = Math.max.apply(Math.max, this.selectedSeries.map(e => e.data.length)) * 10;

    let chartHeight = heightBaseOnPoints;

    if (heightBaseOnPoints < 600) {
      chartHeight = 600;
    } else if (heightBaseOnPoints > 2000) {
      chartHeight = 2000;
    }

    const minSeriesWidth = seriesCount * 150;

    let chartWidth = this.chartContainer.nativeElement.offsetWidth ?? minSeriesWidth;

    if (chartWidth < minSeriesWidth)
      chartWidth = minSeriesWidth;

    this.setChartSize(chartWidth - 25, chartHeight);
  }

  private setChartOptions() {
    if (!this.lasData)
      return;

    this.loading = true;

    setTimeout(() => {
      this.getChartDimensions();
      const yAxis = this.getYAxis();
      const depthUnit = this.lasData?.curveInfo?.find(e => e.name === 'DEPTH' || e.name === 'DEPT')?.unit;
      const depthName = 'DEPTH' + (depthUnit ? ` (${depthUnit})` : '');

      const that = this;

      this.chartOpts = {
        lang: { noData: 'No data' },
        chart: {
          backgroundColor: 'transparent',
          inverted: true,
          width: this.chartSize.width,
          height: this.chartSize.height,
          zooming: { type: 'x' },
          events: {
            selection: function (e) {
              if (e.xAxis || e.yAxis)
                that.isZoomed = true;

              return true;
            },
          },
        },
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF'],
              align: 'left',
            },
          },
        },
        credits: { enabled: false },
        plotOptions: {
          area: {
            marker: { enabled: false },
          },
          series: {
            events: {
              legendItemClick: function (e) {
                that.selectedSeries = that.selectedSeries.filter(e => e.name !== this.name);
                that.setChartOptions();

                e.preventDefault();
              },
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: 'linear',
          startOnTick: false,
          endOnTick: false,
          min: Math.min.apply(Math.min, this.depthValues),
          max: Math.max.apply(Math.max, this.depthValues),
        },
        yAxis: yAxis,
        tooltip: {
          formatter: function () {
            return `<span>${depthName}: <b>${this.x}</b></span><br><span>${this.series.name}: <b>${this.y}</b></span>`
          },
        },
        legend: {
          enabled: true,
          verticalAlign: 'top',
          align: 'center',
          layout: 'horizontal',
        },
        series: this.selectedSeries,
      };

      this.loading = false;
    }, 10);
  }

  private calculateMinAndMaxSeriesValues(values: number[]): { min: number, max: number } {
    let min = 0;
    let max = 1;

    if (values.length > 0) {
      min = Math.min.apply(Math.min, values);
      max = Math.max.apply(Math.max, values);

      let totalDiff = 0;

      for (let i = 1; i < values.length; i++) {
        totalDiff += Math.abs(values[i] - values[i - 1]);
      }

      const averageDiff = totalDiff / (values.length - 1);

      min -= averageDiff * 2;
      max += averageDiff * 2;

      if (min === max) {
        if (min === 0) {
          max = 1;
        } else if (min < 0) {
          max = 0;
        } else {
          min = 0;
        }
      }
    }

    return { min, max };
  }

}
