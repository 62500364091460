import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { addSortableDates } from 'src/app/utils/sort-dates';
import moment from 'moment';

@Component({
  selector: 'app-event-viewer',
  templateUrl: './event-viewer.component.html',
  styleUrls: ['./event-viewer.component.css'],
})
export class EventViewerComponent implements AfterViewInit, OnDestroy {

  @ViewChild('hierarchyComponent') public hierarchyComponent: HierarchyComponent;
  @ViewChild('eventsGrid') public eventsGrid: GridComponent;
  @ViewChild('dailyEventsGrid') public dailyEventsGrid: GridComponent;
  @ViewChild('activityEventsGrid') public activityEventsGrid: GridComponent;
  @ViewChild('perforationDetailsGrid') public perforationDetailsGrid: GridComponent;

  public globalLoading: boolean = false;
  public loadingEvents: boolean = false;
  public loadingDailyEvents: boolean = false;
  public loadingActivityEvents: boolean = false;
  public wellSelected: string | null;
  public events: any[] = [];
  public dailyEvents: any[] = [];
  public activityEvents: any[] = [];
  public perforationDetails: any[] = [];
  public eventTypes: { value: string, text: string }[];
  public defaultEventType: string | null = null;
  public eventTypeSelected: { value: string, text: string } = { text: '', value: 'all' };
  public displayEventsGridRows: boolean = true;
  public displayPerforationDetailsGridRows: boolean = true;
  public displayDailyOperationGridRows: boolean = true;
  public displayDailyActivityGridRows: boolean = true;
  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
    { field: 'Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
    { field: 'Start Date & Time', format: (value: any) => moment(value).format('DD-MM-YYYY') },
  ];

  private dateRange: any;
  private loaderSubscriber: Subscription;
  private allEvents: any[] = [];
  private allDailyEvents: any[] = [];
  private allActivityEvents: any[] = [];
  private eventSelectedExternalId: string;
  private dailyEventSelectedExternalId: string;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService, private readonly loaderService: LoaderService,
    private cd : ChangeDetectorRef
  ) {

  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);

    this.loaderSubscriber = this.loaderService.isLoading$.subscribe({
      next: (loading: boolean) => this.globalLoading = loading,
      error: () => this.globalLoading = false,
    });
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.loaderSubscriber)
      this.loaderSubscriber.unsubscribe();
  }

  public toggleRowsVisibility(gridName: 'events' | 'perforationDetails' | 'dailyOperation' | 'dailyActivity') {
    switch (gridName) {
      case 'events':
        this.displayEventsGridRows = !this.displayEventsGridRows;
        break;
      case 'perforationDetails':
        this.displayPerforationDetailsGridRows = !this.displayPerforationDetailsGridRows;
        break;
      case 'dailyOperation':
        this.displayDailyOperationGridRows = !this.displayDailyOperationGridRows;
        break;
      case 'dailyActivity':
        this.displayDailyActivityGridRows = !this.displayDailyActivityGridRows;
        break;
    }
  }

  public rowCallback = (context: RowClassArgs) => {
    const rowSelected = this.eventSelectedExternalId === context.dataItem?.externalId || this.dailyEventSelectedExternalId === context.dataItem?.externalId;
    return { rowSelected: rowSelected };
  };

  public rowEventsClass = (context: RowClassArgs) => {
    return { 'hide-row': !this.displayEventsGridRows, ...this.rowCallback(context) };
  }

  public rowPerforationDetailsClass = () => {
    return { 'hide-row': !this.displayPerforationDetailsGridRows };
  }

  public rowDailyOperationClass = (context: RowClassArgs) => {
    return { 'hide-row': !this.displayDailyOperationGridRows, ...this.rowCallback(context) };
  }

  public rowDailyActivityClass = () => {
    return { 'hide-row': !this.displayDailyActivityGridRows };
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadEvents();
  }

  public handleDateRangeChange() {
    this.loadEvents();
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.wbService.setWell(null);

    this.clearData();
  }

  public onEventTypeChanged(eventType: any) {
    this.eventTypeSelected = { text: '', value: eventType.value };
    this.filterEvents();
  }

  public onEventSelected(event: any) {
    this.eventSelectedExternalId = event?.dataItem?.externalId ?? '';
    this.loadDailyEvents(event?.dataItem?.externalId);
  }

  public onDailyEventSelected(event: any) {
    this.dailyEventSelectedExternalId = event?.dataItem?.externalId ?? '';
    this.loadActivityEvents(event?.dataItem?.externalId);
  }

  private clearData(): void {
    this.events = [];
    this.dailyEvents = [];
    this.activityEvents = [];
    this.allEvents = [];
    this.allDailyEvents = [];
    this.allActivityEvents = [];
    this.eventTypes = [];
    this.perforationDetails = [];

    this.defaultEventType = 'all';
    this.eventTypeSelected = { text: '', value: 'all' };
  }

  private filterEvents(): void {
    this.events = [];
    const tmpEvents: any[] = [];

    if (this.allEvents?.length > 0)
      for (const event of this.allEvents)
        if (this.eventTypeSelected.value === 'all' || event.jobType === this.eventTypeSelected.value)
          tmpEvents.push(event);

    setTimeout(() => {
      if (this.eventsGrid)
        this.events = addSortableDates(tmpEvents, this.eventsGrid, null, 'YYYY-MM-DD');

      if (this.events?.length > 0)
        this.onEventSelected({ dataItem: { externalId: this.events[0].externalId }});
    }, 1000);
  }

  private filterDailyEvents(): void {
    this.dailyEvents = [];
    this.perforationDetails = [];
    const tmpDailyEvents: any = [];

    if (this.allDailyEvents?.length > 0)
      for (const event of this.allDailyEvents)
        tmpDailyEvents.push(event);

    setTimeout(() => {
      if (this.dailyEventsGrid)
        this.dailyEvents = addSortableDates(tmpDailyEvents, this.dailyEventsGrid, null, 'YYYY-MM-DD');

      if (this.dailyEvents?.length > 0)
        this.onDailyEventSelected({ dataItem: { externalId: this.dailyEvents[0].externalId }});
    });
  }

  private filterActivityEvents(): void {
    this.activityEvents = [];
    const tmpActivityEvents: any[] = [];

    if (this.allActivityEvents?.length > 0)
      for (const event of this.allActivityEvents)
        tmpActivityEvents.push(event);

    setTimeout(() => {
      if (this.activityEventsGrid)
        this.activityEvents = addSortableDates(tmpActivityEvents, this.activityEventsGrid, null, 'YYYY-MM-DD');
    });
  }

  private async loadEvents() {
    this.clearData();
    this.dateRange = this.hierarchyComponent?.range ?? null;

    if (this.wellSelected && this.dateRange) {
      this.loadingEvents = true;

      const events = await this.loadEventsData('EDM.DMEvent');
      this.allEvents = this.processEvents(events);

      this.setEventTypes(this.allEvents);
      this.filterEvents();

      this.loadingEvents = false;
    }
  }

  private async loadDailyEvents(externalId: string) {
    this.allDailyEvents = [];
    this.dailyEvents = [];

    this.loadingDailyEvents = true;

    const dailyEvents = await this.loadEventsData('EDM.DMDaily', { metadata: { 'EDM.EventExternalId': externalId } });

    this.allDailyEvents = this.processDailyEvents(dailyEvents);
    this.filterDailyEvents();

    this.loadingDailyEvents = false;
  }

  private async loadActivityEvents(externalId: string) {
    this.allActivityEvents = [];
    this.activityEvents = [];
    this.perforationDetails = [];

    this.loadingActivityEvents = true;

    this.perforationDetails = this.dailyEvents.filter(e => e.externalId === externalId && e.hasOwnProperty('perforationDetails'));

    const activityEvents = await this.loadEventsData('EDM.DMActivity', { metadata: { 'EDM.DailyExternalId': externalId } });

    this.allActivityEvents = this.processActivityEvents(activityEvents);
    this.filterActivityEvents();

    this.loadingActivityEvents = false;
  }

  private loadEventsData(source: string, additionalFilters?: any): Promise<any[]> {
    return new Promise(async res => {
      if (this.wellSelected) {
        let events = [];

        const startDate = this.dateRange.startDate.valueOf();
        const endDate = this.dateRange.endDate.valueOf();

        let filter = this.buildFilter(source, startDate, endDate, this.wellSelected);

        if (additionalFilters)
          filter = {...filter, ...additionalFilters };

        try {
          events = await this.apiService.getEventListWithCustomFilter(filter);
        } catch (e) {
          console.error(e);
        } finally {
          res(events);
        }
      } else {
        res([]);
      }
    });
  }

  private buildFilter(source: string, startTime: number, endTime: number, externalId: string): any {
    return {
      startTime: { min: startTime, max: endTime },
      source: source,
      assetSubtreeIds: [{ externalId: externalId }],
    };
  }

  private processEvents(events: any[]): any[] {
    return events.map(e => {
      return {
        startTime: e.startTime,
        startDate: moment(e.startTime).format('YYYY-MM-DD'),
        wellName: this.wellSelected,
        eventObjective: e.metadata?.EventObjective1 ?? '-',
        unitName: e.metadata?.EquipmentType ?? '-',
        jobType: e.type,
        externalId: e.externalId,
      };
    });
  }

  private processDailyEvents(events: any[]): any[] {
    return events.map(e => {
      const event: any = {
        startTime: e.startTime,
        startDate: moment(e.startTime).format('YYYY-MM-DD'),
        status: e.metadata?.Status ?? '-',
        forecast: e.metadata?.CommentForecast24 ?? '-',
        summary: e.metadata?.CommentSummary ?? '-',
        externalId: e.externalId,
      };

      if (e.metadata?.hasOwnProperty('PerforationDetails'))
        event['perforationDetails'] = e.metadata.PerforationDetails;

      return event;
    });
  }

  private processActivityEvents(events: any[]): any[] {
    return events.map(e => {
      let duration = e.metadata?.ActivityDuration ?? '-';

      if (!isNaN(duration) && `${duration}`.includes('.'))
        duration = Number(duration).toFixed(1);

      return {
        startTime: e.startTime,
        startDate: moment(e.startTime).format('YYYY-MM-DD'),
        duration: duration,
        details: e.metadata?.ActivityMemo ?? '-',
        externalId: e.externalId,
      }
    });
  }

  private setEventTypes(events: any[]): void {
    this.eventTypes = [];

    if (events?.length > 0) {
      const options: any[] = [];

      for (const event of events) {
        if (options.findIndex(e => e.value === event.jobType) === -1)
          options.push({ value: event.jobType, text: this.convertStringToHumanRedable(event.jobType) });
      }

      options.sort((a: any, b: any) => a.text <= b.text ? -1 : 1);
      this.eventTypes = options;
    }
  }

  private convertStringToHumanRedable(str: string) {
    try {
      return (str ?? '')
        .replace(/[^a-zA-Z0-9]+/g, ' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    } catch {
      return str;
    }
  }

}
