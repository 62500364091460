import { Component, Input } from '@angular/core';
import { HighchartsService } from '../../services/highcharts.service'
import { LossAccountingModel } from '../lossaccountingmodel';
import { Category1Dataset, Category2Dataset, Category3Dataset } from '../lossAccounting-catgorydataset';
import { color, Pointer, Series } from 'highcharts';
import { title } from 'process';
import { text } from 'stream/consumers';
import { style } from '@angular/animations';



@Component({
  selector: 'app-lossvolumechart',
  templateUrl: './lossvolumechart.component.html',
  styleUrls: ['./lossvolumechart.component.css']
})
export class LossvolumechartComponent {
  public options: any;
  @Input()
  data: LossAccountingModel[] = [];
  @Input()
  chartTitle: string = "Oil Loss Volume";
  @Input()
  yAxisTitle: string = "Oil Loss Volume (bbl)";
  @Input()
  valuekey: string = "loss_oil";
  @Input()
  unit: string = "bbl";
  @Input()
  containerid = 'loss_oil_container'
  seriesdata: any[] = [];
  drildowndata: any[] = [];
  constructor(private highchart: HighchartsService) {

  }
  ngOnInit() {
  }
  ngOnChanges() {
    this.preaparechart();
  }

  showEmptyChart() {
    this.options = {
      chart: {
        type: 'column',
        style:
        {
          fontFamily: 'SLBSans, Roboto, "Helvetica Neue", sans-serif',
          fontSize: "16px"
        }
      },
      title: {
        text: this.chartTitle,
        style:
        {
          fontSize: "18px"
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          cursor: Pointer,
          
        }
      },
      lang: {
        noData: 'No Data Available'
      }

    }
    this.highchart.createChart(this.containerid, this.options);
  }

  preaparechart() {
    if (this.data.length > 0) {
      this.getseriesData();
      this.getDrillDownData();
      this.getOptions();
      this.highchart.createChart(this.containerid, this.options);
    }
    else
      this.showEmptyChart();

  }
  getDrillDownData() {
    let drilldowndata: any[] = [];
    Category2Dataset.forEach(category2 => {
      let category3dataarray: any[] = [];
      Category3Dataset.forEach(category3 => {
        let alreadyprocessedrecord = category3dataarray.indexOf(category3.name);
        if (alreadyprocessedrecord == -1) {
          let filtereddata = this.data.filter((result: any) => result.category1 == category2.category1type && result.category2 == category2.name && result.category3 == category3.name);
          if (filtereddata.length > 0) {
            let datavalue = this.sumfunction(filtereddata, this.valuekey);
            let dataitem = [category3.name, Number(datavalue)];
            category3dataarray.push(dataitem);
          }
        }
      });
      let item = { name: category2.category1type + "-" + category2.name, id: category2.category1type + '_' + category2.name, data: category3dataarray };
      drilldowndata.push(item);
      //console.log(drilldowndata);
      this.drildowndata = drilldowndata;

    });

  }
  getseriesData() {
    type arraydata = { name: string, y: any, drilldown: string }
    type resultarraytype = { name: string, data?: arraydata[], color?: string };
    let seriesarray: resultarraytype[] = [];
    Category1Dataset.forEach(category1 => {
      let item: resultarraytype;
      let category2array: arraydata[] = [];
      item = { name: category1.name }
      Category2Dataset.forEach(category2 => {
        let alreadyprocessedrecord = category2array.findIndex((record: any) => record.name == category2.name);

        if (alreadyprocessedrecord == -1) {
          let filteredata = this.data.filter((item: any) => item.category1 == category1.name && item.category2 == category2.name)
          let category2data: arraydata
          let sumvalue = this.sumfunction(filteredata, this.valuekey);
          category2data = { name: category2.name, y: Number(sumvalue), drilldown: category1.name + '_' + category2.name }
          category2array.push(category2data);
        }
      });
      item = { name: category1.name, data: category2array, color: category1.name == 'Planned' ? '#83ACEE' : '#F88750' };
      seriesarray.push(item);
    });
    this.seriesdata = seriesarray;
  }

  sumfunction(data: LossAccountingModel[], key: string) {
    let filtedata = data;
    let sum = filtedata.reduce((a: any, b: any) => a + (Number(b[key]) || 0), 0);
    return Number(sum).toFixed(2);
  }
  getOptions() {
    this.options = {
      chart: {
        type: 'column',
        style:
        {
          color: "#FFFFFF",
          fontColor: "#FFFFFF",
          fontFamily: 'SLBSans, Roboto, "Helvetica Neue", sans-serif',
          fontSize: "16px"
        }
      },
      title: {
        text: this.chartTitle,
        style:
        {
          fontSize: "18px"
        }
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: this.yAxisTitle,
        },

      },

      legend: {
        enabled: true,
        itemStyle: {
          fontFamily: 'SLBSans, Roboto, "Helvetica Neue", sans-serif',
        },
      },
      tooltip: {
        headerFormat: '<b><span style="font-size:12px;">{point.key}</span></b><br>',
        pointFormat: '<span style="color:{series.color};padding:0">{series.name}: </span>' +
          '<span style="padding:0"><b>{point.y:.1f} ' + this.unit + '</b></span><br>',
        //footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          colors: [
            '#F88750',
            '#00ff00',
            '#0000ff'
          ]
        },
        colors: [
          '#F88750',
          '#00ff00',
          '#0000ff'
        ],
        dataLabels: {
          enabled: true,

        },
        series: {
          borderWidth: 0,
          cursor: Pointer,
          dataLabels: {
            enabled: true,
          }
        }
      },

      series: this.seriesdata,
      drilldown: {
        breadcrumbs: {
          buttonTheme: {
            states: {
              hover: {
                backgroundcolor: '#000000'
              }
            },
            style: {
              color: '#FFFFFF',
              fill: '#FFFFFF',
              hover: '#000000',
              fontWeight: 'normal',
              fontFamily: 'SLBSans, Roboto, "Helvetica Neue", sans-serif',
              stroke: 'white',
              //fontSize: '20px'
              states: {
                hover: {
                  color: '#000000'
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '<b><span style="font-size:12px;">{}</span></b><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{point.key}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} ' + this.unit + '</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        activeAxisLabelStyle: {
          color: '#FFFFFF'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          color: '#FFFFFF'
        },

        series: this.drildowndata,
        legend: {
          enabled: false
        }
      },
    }
  }
}
