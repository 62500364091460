import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import moment from 'moment';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { NodeAndEdgeCollectionResponseV3Response, Timeseries } from '@cognite/sdk/dist/src';
import { addSortableDates } from 'src/app/utils/sort-dates';

declare interface Tag {
  externalId?: string;
  tagName?: string;
  unit?: string;
  description?: string;
  createdDate?: string;
  lastUpdatedOn?: string;
  type?: string;
};

@Component({
  selector: 'app-tag-catalogue',
  templateUrl: './tag-catalogue.component.html',
  styleUrls: ['./tag-catalogue.component.css'],
})
export class TagCatalogueComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') public hierarchyComponent: HierarchyComponent;
  @ViewChild('wellTagsGrid') public wellTagsGrid: GridComponent;
  @ViewChild('equipmentTagsGrid') public equipmentTagsGrid: GridComponent;

  public loading: boolean = false;
  public wellTags: Tag[] = [];
  public equipmentTags: Tag[] = [];
  public equipmentSelectionTags: { equipment: string, tags: Tag[], enabled: boolean }[] = [];
  public displayWellTagsGrid: boolean = true;
  public displayEquipmentTagsGrid: boolean = true;
  public liftTypeSelected: string = 'all';
  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Created Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
    { field: 'Last Updated On', format: (value: any) => moment(value).format('DD-MM-YYYY') },
  ];

  private wellSelected: string | null = null;
  private readonly cdfDef: CdfSpaceView | null = null;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService) {
    this.cdfDef = this.wbService.getSpaceDefinition('tags');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onfilterwellChange(this.wbService.getWell(), true);
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadData();
  }

  public clearWellSelection() {
    this.wellSelected = null;
    this.wbService.setWell(null);

    this.clearData();
  }

  public toggleRowsVisibility(gridName: 'wellTags' | 'equipmentTags') {
    switch (gridName) {
      case 'wellTags':
        this.displayWellTagsGrid = !this.displayWellTagsGrid;
        break;
      case 'equipmentTags':
        this.displayEquipmentTagsGrid = !this.displayEquipmentTagsGrid;
        break;
    }
  }

  public onLiftTypeChange() {
    let entities: Tag[] = [];

    if (this.liftTypeSelected !== 'all') {
      entities = this.equipmentSelectionTags.find(e => e.equipment === this.liftTypeSelected)?.tags ?? [];
    } else {
      for (const entity of this.equipmentSelectionTags) {
        entities = entities.concat(entity.tags);
      }
    }

    this.equipmentTags = addSortableDates(entities, this.equipmentTagsGrid, null, 'YYYY-DD-MM');
  }

  private clearData() {
    this.wellTags = [];
    this.equipmentTags = [];
    this.equipmentSelectionTags = [];
    this.liftTypeSelected = 'all';
  }

  private loadData() {
    this.clearData();

    if (!this.cdfDef || !this.wellSelected)
      return;

    this.loading = true;

    const filter: any = this.wbService.getCdfFilter(this.cdfDef, 'well', ['node', 'externalId'], [this.wellSelected]);

    this.apiService.getInstancelist(this.cdfDef.id, filter, this.cdfDef.version, this.cdfDef.space).subscribe({
      next: data => {
        this.loading = false;
        this.processData(data);
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private getEquipmentNames(data: any): string[] {
    let properties: string[] = [];

    if (data)
      properties = Object.keys(data).filter(key => !key.startsWith('well') && key.endsWith('Tags')).map(key => key.replace('Tags', ''));

    return properties;
  }

  private processData(data: NodeAndEdgeCollectionResponseV3Response) {
    if (this.cdfDef && data?.items?.length > 0) {
      for (const item of data.items) {
        const properties: any = item.properties?.[this.cdfDef.space]?.[this.cdfDef.fullName];

        if (properties) {
          const equipments = this.getEquipmentNames(properties);

          const wellTags = this.getTagsData(properties.wellTags, properties.wellTagsCreateDate, properties.wellTagsUpdateDate, 'well');
          let equipmentTags = [];

          for (const equipment of equipments) {
            const tags = this.getTagsData(properties[`${equipment}Tags`], properties[`${equipment}TagsCreateDate`], properties[`${equipment}TagsUpdateDate`], equipment);
            equipmentTags.push({ equipment: equipment, tags: tags, enabled: tags?.length > 0 });
          }

          this.setData(wellTags, equipmentTags);
        }
      }
    }
  }

  private async setData(wellTags: Tag[], equipmentTags: { equipment: string, tags: Tag[], enabled: boolean }[]) {
    if (wellTags?.length > 0) {
      wellTags = await this.setAdditionalInfoToTags(wellTags);
      this.wellTags = addSortableDates(wellTags ?? [], this.wellTagsGrid, null, 'YYYY-MM-dd');
    }

    if (equipmentTags?.length > 0 && equipmentTags.some(e => e.enabled)) {
      for (const entities of equipmentTags) {
        if (entities.enabled)
          entities.tags = await this.setAdditionalInfoToTags(entities.tags);

        this.equipmentSelectionTags.push(entities);
      }

      if (this.equipmentSelectionTags.length === 1)
        this.liftTypeSelected = this.equipmentSelectionTags[0].equipment;

      this.onLiftTypeChange();
    }
  }

  private async setAdditionalInfoToTags(tags: Tag[]) {
    const details = await this.getTimeSeriesInfo(tags.map(e => e.externalId ?? ''));

    if (details?.length > 0) {
      for (const tag of tags) {
        const info = details.find(e => e.externalId === tag.externalId);

        if (info) {
          tag.tagName = info.name ?? tag.externalId;
          tag.unit = info.unit ?? '-';
          tag.description = info.description ?? '-';
        }
      }
    }

    return tags;
  }

  private getTagsData(tagsId: string[], tagsCreatedDate: string[], tagsUpdatedDate: string[], type: string): Tag[] {
    const rows: Tag[] = [];

    if (tagsId?.length > 0) {
      for (const i in tagsId) {
        rows.push({ externalId: tagsId[i], createdDate: tagsCreatedDate[i] ?? '', lastUpdatedOn: tagsUpdatedDate[i] ?? '', type: type });
      }
    }

    return rows;
  }

  private getTimeSeriesInfo(externalIds: string[]): Promise<Timeseries[]> {
    return new Promise(res => {
      const values: any[] = [];
      externalIds = externalIds.filter(e => e !== null && e !== undefined && e.trim() !== '');

      if (externalIds.length > 0) {
        this.apiService.getbulkTsId(externalIds.map(e => { return { externalId: e }})).then(data => {
          res(data);
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          res(values);
        });
      } else {
        res(values);
      }
    });
  }

}
