import { Component, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';

import { CognitDataFormatter } from 'src/app/services/cognit-data-formatter';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import moment, { Moment } from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HierarchyService } from 'src/app/services/hierarchy.service';
import { MessageService } from '@slb-dls/angular-material/notification';
import { PumpCurvVariables } from '../../model';
import { ConfirmationDialog } from 'src/app/common-component/confirmation-dialog/confirmation-dialog.component';
import { DateHelper } from 'src/app/services/datehelper.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';


@Component({
  selector: 'app-wellvariables',
  templateUrl: './wellvariables.component.html',
  styleUrls: ['./wellvariables.component.css']
})
export class WellvariablesComponent {
  @Input()
  DataItem: any;
  wellList: any;
  formwellList: any;
  private subs: Subscription[] = [];
  sdmPropertyList: any[] = [];
  gridData: any[] = [];
  @ViewChild('addWllRecordDialog') addWllRecordDialog: TemplateRef<any>;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;
  @Input()
  public pumpCurvevariable: PumpCurvVariables;
  @Input()
  filterstartDate: any;
  @Input()
  filterendDate: any;
  public selectedrows: any;
  public startdate: any;
  public todaysdate: any;
  public formMode = 'Add';
  public fieldList: any;
  public filterWellList: any;
  public selectedField: string;
  public selectedwell: string;
  public timeseriesdata: any;
  wellformgroup: FormGroup;
  timeseriesid: string | undefined;
  public welllisttoLoadGridData: any
  unit: string | undefined;
  selectedwellPad: string;
  wellPadList: any;
  formwellPadList: any;
  public viewid = "PumpCurveWellInputs";

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date Time', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];

  defaultviscosity: number | undefined = 100;
  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService,
    private messageService: MessageService,
    private hierarchyService: HierarchyService,
    private timeseriesHelper: TimeseriesHelper,
    private dateHelper: DateHelper,) {

  }
  ngOnInit() {
    this.setRecordValue();
    this.setformgroupData();
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));
    this.startdate = moment(moment().toDate());
    this.todaysdate = moment(moment().toDate());
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.gridData = [];
    // this.loadgridData();
  }
  public setformgroupData() {
    //FormBuilder avoids using new FormControl in FormGroup instance
    this.wellformgroup = this.fb.group({
      viscosity: [this.pumpCurvevariable.viscosity, Validators.required],
      field: new FormControl({ value: this.pumpCurvevariable.field, disabled: this.formMode == "Edit" }, this.formMode == "Add" ? Validators.required : []),
      well: new FormControl({ value: this.pumpCurvevariable.well, disabled: this.formMode == "Edit" }, Validators.required),
      wellpad: new FormControl({ value: this.pumpCurvevariable.wellpad, disabled: this.formMode == "Edit" }, this.formMode == "Add" ? Validators.required : []),
    });
  }
  setRecordValue(data: any = undefined) {
    if (data == undefined) {
      this.pumpCurvevariable = { field: this.selectedField, wellpad: this.selectedwellPad, well: this.selectedwell, viscosity: this.defaultviscosity }
    }
    else {
      let wellselected = this.wellList.filter((x: any) => x.Name == data.well)[0]
      let wellid = wellselected.externalId;
      let wellpadid = wellselected.WellPad;
      this.startdate = this.dateHelper.convertStringToDate(data.startdate);
      this.pumpCurvevariable = {
        startdate: data.startdate, viscosity: data.viscosity,
        field: this.selectedField, well: [wellid], wellpad: wellpadid, timestamp: data.timestamp
      }
    }
  }

  // Well level form hierarchy
  onwellformfieldChange(event: any) {
    this.getwellpaddata(event.value)
  }
  getwellpaddata(fieldvalue: string) {
    if (fieldvalue != "" && fieldvalue != undefined) {
      this.loader.showLoader();
      this.subs.push(this.hierarchyService.getWellpad(fieldvalue).subscribe((data: any) => {
        this.formwellPadList = data;
      }));
      this.loader.hideLoader();
    }
  }
  onformwellpadChange(event: any) {
    this.pumpCurvevariable.wellpad = event.value;
    this.getformwelldata(event.value);
  }
  getformwelldata(wellpad: string) {
    if (wellpad != "" && wellpad != undefined) {
      this.loader.showLoader();
      this.subs.push(this.hierarchyService.getWelldata(wellpad).subscribe((data: any) => {
        this.formwellList = data;
      }));
      this.loader.hideLoader();
    }
  }
  onwellChange(event: any) {
    this.pumpCurvevariable.viscosity = this.defaultviscosity;;// = { gasgravity:1,apiofoil:30,pw:1000,viscosity:100}
    this.pumpCurvevariable.well = event.value;
    this.setformgroupData();

  }
  public onDateRangeChange(event: any) {
    this.filterstartDate = moment(event.event.startDate)
    this.filterendDate = moment(event.event.endDate)
    if (event.event.startDate != undefined && event.event.endDate != undefined && this.selectedField != undefined && this.selectedField != "")
      this.loadgridData();
  }
  clear() {
    this.selectedField = '';
    this.selectedwell = '';
    this.selectedwellPad = '';
    this.wellList = [];
    this.gridData = [];
  }
  onAddVariablesClick() {
    this.pumpCurvevariable.viscosity = this.defaultviscosity;
    this.formMode = "Add";
    this.getwellpaddata(this.selectedField);
    this.getformwelldata(this.selectedwellPad);
    //console.log(this.pumpCurvevariable);
    this.setRecordValue();
    this.setformgroupData();
    this.pumpCurvevariable.viscosity = 100;
    const dialogRef = this.dialog.open(this.addWllRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  /////

  savewellleveldata(form: any) {
    try {
      if (this.wellformgroup.valid) {
        let viscosity_value = this.wellformgroup.get("viscosity")?.value;
        let selectedwell = this.wellformgroup.get("well")?.value;
        if (selectedwell != undefined && selectedwell.length > 0) {
          this.loader.showLoader();
          let selectwelllist = this.formwellList.filter((item: any) => selectedwell.includes(item.externalId));
          let welllistfilter = selectwelllist.map((x: any) => x.externalId);
          this.timeseriesHelper.getExternalId(welllistfilter, this.viewid, "well").subscribe((list: any) => {
            let externalid = list.externalidlist;
            this.sdmPropertyList = list.sdmPropertyList;
            if (externalid.length > 0) {
              let datapointlist: any[] = []
              let externalidlist = [...new Set(externalid.map((asset: any) => ({ "externalId": asset.externalId })))];
              this.apiService.getbulkTsId(externalidlist).then((idresponse: any) => {
                idresponse.forEach((externalidvalue: any) => {
                  let timestamp = Date.parse(this.startdate.format("YYYY-MM-DD"));
                  if (this.formMode == "Edit")
                    timestamp = Number(this.pumpCurvevariable.timestamp);
                  let datapoint = { id: externalidvalue.id, datapoints: [{ timestamp: timestamp, value: viscosity_value }] }
                  datapointlist.push(datapoint)
                });
                this.apiService.insertbulkTimeSeriesData(datapointlist).then(response => {
                  this.loader.hideLoader();
                  //console.log("well viscocity saved " + response);
                  this.loadgridData();
                })
              })
            }
          })
        }
      }
    }
    catch (exceception) {
      console.log(exceception);
      this.loader.hideLoader();
    }
  }
  public onEditecordClick(dataItem: any) {
    this.formMode = "Edit";
    this.formwellPadList = this.wellPadList;
    this.formwellList = [{ "externalId": dataItem.well, Name: dataItem.well }]
    this.setRecordValue(dataItem);
    this.setformgroupData();
    const dialogRef = this.dialog.open(this.addWllRecordDialog, {
      panelClass: 'update-range-dialog',
    });
  }
  public ondelete(dataitem: any) {
    this.openDialog(dataitem);
  }
  openDialog(selectedrows: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteRecord(selectedrows);
      }
    });

  }
  public deleteRecord(selectedrows: any) {
    this.loader.showLoader();
    let recortodelete: any[] = [];
    if (selectedrows == undefined) {
      let data = this.gridData.filter((x: any) => this.selectedrows.includes(x.sequence));
      data.forEach(record => {
        if (record.startdate != undefined) {
          recortodelete.push({ id: record.id, inclusiveBegin: Date.parse(record.timestamp) })
        }
      });
    }
    else {
      recortodelete = [{ id: selectedrows.id, inclusiveBegin: Date.parse(selectedrows.timestamp) }]
    }
    this.apiService.deleteTimeseriesDataPoints(recortodelete).then(x => {
      this.loadgridData();
    })
    this.selectedrows = [];

  }
  onfilterfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.wellPadList = event.wellPadList;
    this.wellList = event.allFieldwells;
    this.loadgridData();
    //this.getWelldata([event.event.value]);
  }
  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.filterWellList = event.well;
    this.wellList = event.well;
    this.loadgridData();
  }

  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedwellPad = event.selectedwellPad;
    if (this.filterWellList == undefined)
      this.wellList = event.allFieldwells;
    else
      this.wellList = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadgridData();
  }
  public getWelldata(field: any[], isFilter = false) {
    let wellpadfilter = {
      "nested": {
        "scope": [environment.cogniteSpace, 'WellPad/1_0', "field"],
        "filter": {
          "in": {
            "property": ["node", "externalId"],
            "values": field
          }
        }
      }
    }
    this.loader.showLoader();
    this.apiService.getInstancelist("WellPad", wellpadfilter).subscribe((wellpad: any) => {
      let wellpadid = wellpad.items.map((X: any) => X.externalId)
      let wellfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
          "filter": {
            "in": {
              "property": ["node", "externalId"],
              "values": wellpadid
            }
          }
        }
      }
      this.apiService.getInstancelist("Well", wellfilter).subscribe((well: any) => {
        this.loader.hideLoader();
        let listwell = this.cognitDataFormatter.formatNoderesponse(well, "Well", undefined);
        this.wellList = listwell;
        this.loadgridData();
        // else
        //   this.wellList = listwell;
      });

    }
    )
  }
  loadgridData() {
    try {
      this.gridData = [];
      if (this.wellList != undefined && this.wellList.length > 0) {
        this.loader.showLoader();
        let welllistfilter = this.wellList.map((x: any) => x.externalId);
        this.subs.push(this.timeseriesHelper.getExternalId(welllistfilter, this.viewid, "well").subscribe((list: any) => {
          let externalidlist = list.externalidlist;
          this.sdmPropertyList = list.sdmPropertyList;
          this.gridData = [];
          if (externalidlist.length > 0) {
            let results: any[] = [];
            for (let i = 0; i <= externalidlist.length;) {
              //this.gridData = [];
              this.loader.showLoader();
              this.apiService.getTimeseriesDataRange(externalidlist.slice(i, i + 99), this.filterstartDate._d, this.filterendDate._d).then((pumpvariables: any) => {
                this.loader.hideLoader();
                let timeseriesdata = pumpvariables;
                this.gridData = this.gridData.concat(this.getGridData(timeseriesdata));
              })
                .catch((error) => {
                  console.error("Error in getting data:", error);
                });
              i = i + 99;
            }
          }
        }));
      }

      this.gridData = addSortableDates(this.gridData, this.kendoGrid);

    }
    catch (exceception) {
      console.log(exceception);
      this.loader.hideLoader();
    }
  }
  getGridData(results: any): any {
    let timeseriesdatapoints = results;
    let tempgridData: any[] = [];
    let sequence = 0;
    timeseriesdatapoints.forEach((datapoint: any) => {
      if (datapoint.datapoints.length > 0) {
        let wellid = this.sdmPropertyList.filter((x: any) => x.value == datapoint.externalId)[0].FilterPropertyId;
        datapoint.datapoints.forEach((data: any) => {
          let existingitem = tempgridData.filter((griditem: any) => griditem.well == wellid && griditem.timestamp == data.timestamp);
          if (existingitem.length == 0) {
            let item = {
              sequence: sequence,
              id: datapoint.id, "well": wellid, "startdate": moment(data.timestamp).format("DD-MM-YYYY"), "viscosity": data.value,
              timestamp: data.timestamp
            }
            tempgridData.push(item)
            sequence += 1;
          }
        })
      }
    })
    return tempgridData;
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

}
