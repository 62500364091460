import { Component, EventEmitter, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, Configuration, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { CogniteAuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.css']
})
export class AppLoginComponent {

  @Output() loginSuccess: EventEmitter<any> = new EventEmitter();

  authService: PublicClientApplication
  constructor(
    private auth: MsalService,
    private cogniteAuthService:CogniteAuthService,
    private loader:LoaderService
  ) {
    this.authService = auth.instance as PublicClientApplication;
    
  }
  async ngOnInit() {
 
    console.log("Inside ngOnInit event");
    try {
      this.loader.showLoader();
      let init = await this.authService.initialize();
      let tokenResponse = await this.authService.handleRedirectPromise();
      let accountObj;
      if (tokenResponse) {
        accountObj = tokenResponse.account;
      } else {
        accountObj = this.authService.getAllAccounts()[0];
      }

      if (accountObj) {   
        this.loader.hideLoader();       
        console.log("user is authenticated") 
        this.loginSuccess.emit();  // as login is done as well as token is received take it
        this.cogniteAuthService.getSdk().authenticate();
      } else {
        this.loader.hideLoader();    
        console.log(" No accountObject or tokenResponse present. User must now login.");
        await this.authService.loginRedirect({ scopes: ["user.read"] })
      }
    } 
    catch (error) {
      this.loader.hideLoader();    
      console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
    }




  }

}
