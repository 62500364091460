import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-i-frame',
  templateUrl: './i-frame.component.html',
  styleUrls: ['./i-frame.component.css'],
})
export class IFrameComponent implements AfterViewInit, OnChanges {

  @Input() title: string;
  @Input() src: SafeResourceUrl;

  @ViewChild('iframeContainer') iframeContainer: ElementRef;

  public isViewReadyForIframe: boolean = false;

  constructor(private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    this.setIFrameHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && changes.src.previousValue !== changes.src.currentValue) {
      this.src = changes.src.currentValue;
      this.setIFrameHeight();
    }
  }

  private setIFrameHeight() {
    this.isViewReadyForIframe = false;

    if (this.iframeContainer) {
      const height = this.calculateHeight(this.iframeContainer.nativeElement);
      this.renderer.setStyle(this.iframeContainer.nativeElement, 'height', `${height}px`);

      if (this.src)
        setTimeout(() => this.isViewReadyForIframe = true, 250);
    }
  }

  private calculateHeight(element: HTMLElement): number {
    let containerElement: HTMLElement = element;

    while (!containerElement.classList.contains('mat-card') && containerElement.parentElement) {
      containerElement = containerElement.parentElement ?? containerElement;
    }

    const viewportHeight = window.innerHeight;
    const elementTop = containerElement.getBoundingClientRect().top;
    const heightDifference = viewportHeight - elementTop;
    return heightDifference;
  }


}
