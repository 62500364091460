import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[autoHeight]',
})
export class AutoHeightDirective implements OnInit, OnDestroy {

  @Input('autoHeight') useViewport?: boolean | string | undefined;

  private resizeObserver?: ResizeObserver;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {

  }

  ngOnInit(): void {
    if (this.useViewport !== true && this.useViewport !== false)
      this.useViewport = true;

    this.adjustHeight();

    const parent = this.el.nativeElement.parentElement;

    if (parent) {
      this.resizeObserver = new ResizeObserver(() => this.adjustHeight());
      this.resizeObserver.observe(parent);
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver)
      this.resizeObserver.disconnect();
  }

  @HostListener('window.resize')
  onResize() {
    this.adjustHeight();
  }

  private adjustHeight() {
    let calcHeight;

    if (this.useViewport) {
      const positionFromTop = this.el.nativeElement.getBoundingClientRect().top;
      calcHeight = `calc(100vh - ${positionFromTop + 20}px)`;
    } else {
      const parent = this.el.nativeElement.parentElement;

      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const elementRect = this.el.nativeElement.getBoundingClientRect();

        const positionFromTop = elementRect.top - parentRect.top;
        const parentHeight = parentRect.height;

        const style = getComputedStyle(this.el.nativeElement);
        const paddingTop = parseFloat(style.paddingTop);
        const paddingBottom = parseFloat(style.paddingBottom);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);

        const parentStyle = getComputedStyle(parent);
        const parentPaddingTop = parseFloat(parentStyle.paddingTop);
        const parentPaddingBottom = parseFloat(parentStyle.paddingBottom);

        calcHeight = `${parentHeight - positionFromTop - paddingTop - paddingBottom - marginTop - marginBottom - parentPaddingTop - parentPaddingBottom}px`;
      }
    }

    this.renderer.setStyle(this.el.nativeElement, 'height', calcHeight);
    this.renderer.setStyle(this.el.nativeElement, 'minHeight', 'auto');
  }

}
