import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,     
      redirectUri: environment.returnUrl,
      postLogoutRedirectUri: environment.returnUrl
    },
    
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
  });
}

//TODO check if needed dwon the line
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //TODO this url with respect to cognite
  protectedResourceMap.set(`${environment.baseUrl}user_impersonation`, ["user_impersonation"]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [`${environment.baseUrl}DATA.VIEW`]
    },
    loginFailedRoute: "./login-failed" //TODO : need to implement this
  };
}
