<div class="content-left-margin">
  <span>
    <app-hierarchy #hierarchyComponent [filterOnWellType]="false" [showDateRange]="false" (onFieldChange)="clearWellSelection()" (onWellPadChange)="clearWellSelection()" (onWellchange)="handleWellChange($event)" (onclear)="clearWellSelection()" [showClearButton]="true"></app-hierarchy>
  </span>
</div>
<div class="page-content">
  <div>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>Well Events</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="loading" class="spinner__loading loading-container">
          <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
        </div>
        <div>
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="events" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="event" [filterable]="true" appGridTransform>
            <kendo-grid-column field="startDate" title="Start Date & Time"></kendo-grid-column>
            <kendo-grid-column field="endDate" title="End Date & Time"></kendo-grid-column>
            <kendo-grid-column field="type" title="Type"></kendo-grid-column>
            <kendo-grid-column field="description" title="Description"></kendo-grid-column>
            <kendo-grid-column field="assetIds" title="Asset IDs"></kendo-grid-column>
            <kendo-grid-column field="eastDeparture" title="East Departure"></kendo-grid-column>
            <kendo-grid-column field="id" title="ID"></kendo-grid-column>
            <kendo-grid-column field="createdTime" title="Created Time"></kendo-grid-column>
            <kendo-grid-column field="lastUpdated" title="Last Updated"></kendo-grid-column>
          </kendo-grid>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
