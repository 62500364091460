<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="false" [showClearButton]="false" (onWellPadChange)="onfilterwellPadChange($event)" [getListofAllWellsUnderField]="true" (onWellchange)="onfilterwellChange($event)"></app-hierarchy>
  <span style="margin-left: 10px;">
    <slb-date-picker [(value)]="datepick1" [minValue]="startdate" [maxValue]="enddate" (dateChange)="onDateChange1($event)" placeholder="Choose date 1" hint=""></slb-date-picker>
  </span>
  <span style="margin-left: 10px;">
    <slb-date-picker [(value)]="datepick2" [minValue]="startdate" [maxValue]="enddate" (dateChange)="onDateChange2($event)" placeholder="Choose date 2" hint=""></slb-date-picker>
  </span>
  <span style="margin-left: 10px;">
    <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base" type="button" (click)="clear()" [disabled]="false">
      <span>Clear</span>
    </button>
  </span>
</div>
<div class="page-content">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Comparison</mat-card-title>
    </mat-card-header>
    <mat-card-content style="margin-top: 10px;">
      <kendo-grid appGridAutoTooltip [kendoGridBinding]="comparisongridData" [resizable]="true" [pageable]="true" [pageSize]="10" [filterable]="true">
        <ng-template kendoGridToolbarTemplate>
          <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
            <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [data]="comparisongridData">
              <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
            </button>
            <button type="button" kendoGridPDFCommand>
              <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
            </button>
          </div>
        </ng-template>
        <kendo-grid-column field="well" title="Well Number"></kendo-grid-column>
        <kendo-grid-column field="startDateType" title="Start Date Lift Type" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="endDateType" title="End Date Lift Type" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="ovd" [filterable]="false" title="{{ !!(this.unitobject['oil']) ? 'Oil Rate Difference - '+(this.unitobject['oil']) : 'Oil Rate Difference'}}"></kendo-grid-column>
        <kendo-grid-column field="lvd" [filterable]="false" title="{{ !!(this.unitobject['liquid']) ? 'Liquid Rate Difference - '+(this.unitobject['liquid']) : 'Liquid Rate Difference'}}"></kendo-grid-column>
        <kendo-grid-column field="ovdml" [filterable]="false" title="{{ !!(this.unitobject['oilml']) ? 'Oil Rate Difference(ML) - '+(this.unitobject['oilml']) : 'Oil Rate Difference(ML)'}}" [hidden]="!this.liftTypearray.includes('PCP')"></kendo-grid-column>
        <kendo-grid-column field="lvdml" [filterable]="false" title="{{ !!(this.unitobject['liquidml']) ? 'Liquid Rate Difference(ML) - '+(this.unitobject['liquidml']) : 'Liquid Rate Difference(ML)'}}" [hidden]="!this.liftTypearray.includes('PCP')"></kendo-grid-column>
        <kendo-grid-excel fileName="Comparison.xlsx"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Comparison.pdf" [allPages]="true"></kendo-grid-pdf>
      </kendo-grid>
    </mat-card-content>
  </mat-card>
</div>
