export const PowerBIReportLink = {
   production_overview:"https://app.powerbi.com/reportEmbed?reportId=750d245c-5803-4b09-a862-648711c07735&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   wellsSurveillance_AlsEspMonitoring_cairn: "https://app.powerbi.com/reportEmbed?reportId=1fcfb315-f7b1-456b-9a3f-f891a330f4e1&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   wellsSurveillance_AlsEspMonitoring: "https://app.powerbi.com/reportEmbed?reportId=1fcfb315-f7b1-456b-9a3f-f891a330f4e1&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   virtualMentoring_VFMSummary: "https://app.powerbi.com/reportEmbed?reportId=abef7686-ba09-4cdb-9e5c-4ccbf1094973&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   virtualMentoring_VMFNonExecutionWellList: "https://app.powerbi.com/reportEmbed?reportId=a7bc07b2-9820-4fb4-8329-e1aef0b8f57c&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   wellmonitoring:
   {
      ESP: "https://app.powerbi.com/reportEmbed?reportId=fa9b3897-ccdb-44fa-912e-36a79020d265&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      HRP: "https://app.powerbi.com/reportEmbed?reportId=88bfd994-5785-468a-aafa-d75077e67be7&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      Injector: "https://app.powerbi.com/reportEmbed?reportId=faddfce1-8fec-43a0-a2ea-3fe6927611df&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      JP: "https://app.powerbi.com/reportEmbed?reportId=2ff3da35-bd7e-4a64-b948-d1ad267cb613&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      NF: "https://app.powerbi.com/reportEmbed?reportId=435cf349-b367-440f-a110-047a5afbfb81&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      PCP: "https://app.powerbi.com/reportEmbed?reportId=9276ec25-5af1-4856-ab04-b6353a5e3842&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false"

   },
   well_monitoring:
   {
      ESP: "https://app.powerbi.com/reportEmbed?reportId=8a3481e1-e4fb-4ee7-b169-534025880ebf&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      HRP: "https://app.powerbi.com/reportEmbed?reportId=127dd64a-137e-427b-bbe6-d4a01f71eef3&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      Injector: "https://app.powerbi.com/reportEmbed?reportId=449c159b-e404-4529-8f92-6619c4398882&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      JP: "https://app.powerbi.com/reportEmbed?reportId=0160e4cd-e3ed-4eef-90cd-1ab391975161&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      NF: "https://app.powerbi.com/reportEmbed?reportId=f8235a51-5a11-4858-8c06-72c83bff743d&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      PCP: "https://app.powerbi.com/reportEmbed?reportId=f61137f3-68c4-464b-9710-6083542aab54&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false"

   },
   alsmonitoring:
   {
      ESP: "https://app.powerbi.com/reportEmbed?reportId=d78d33c1-e0ca-4800-8050-e4f9b12359c4&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      HRP: "https://app.powerbi.com/reportEmbed?reportId=b821f607-5be5-4eac-a8e6-2551e5d290f6&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      JP: "https://app.powerbi.com/reportEmbed?reportId=d97c7b07-1646-433d-81f5-c0c84561e50b&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      NF: "https://app.powerbi.com/reportEmbed?reportId=6af8e355-3efd-4309-82a2-617b08f9e69f&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
      PCP: "https://app.powerbi.com/reportEmbed?reportId=6d982909-ebde-4071-8922-de0215a85c53&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&navContentPaneEnabled=false",
   }


}