<div class="image-preview-container" #expandableDiv>
  <div class="image-viewer" *ngIf="imageUrl">
    <div class="controls">
      <div class="controls-group">
        <button (click)="zoomIn()" [disabled]="scaleFactor >= 2" class="custom-btn">
          <mat-icon svgIcon="zoomIn" aria-hidden="false" aria-label="ZoomIn" title="Zoom In"></mat-icon>
        </button>
        <button (click)="zoomOut()" [disabled]="scaleFactor <= 0.1" class="custom-btn">
          <mat-icon svgIcon="zoomOut" aria-hidden="false" aria-label="ZoomOut" title="Zoom Out"></mat-icon>
        </button>
        <button *ngIf="showFullScreenButton" expand [toggleFullscreen]="expandableDiv" (onFullscreenChanged)="toggleFullscreen($event)" class="custom-btn">
          <mat-icon *ngIf="!isFullScreen" svgIcon="expand" aria-hidden="false" aria-label="Expand" title="View full screen"></mat-icon>
          <mat-icon *ngIf="isFullScreen" svgIcon="close" aria-hidden="false" aria-label="Close" title="Close full screen"></mat-icon>
        </button>
        <ng-container *ngIf="enableAnnotations === true">
          <button (click)="mode = 'DRAG'" [disabled]="mode === 'DRAG'" class="custom-btn">
            <mat-icon svgIcon="drag" aria-hidden="false" aria-label="Move" title="Move image"></mat-icon>
          </button>
          <button (click)="mode = 'DRAW'"  [disabled]="mode === 'DRAW'" class="custom-btn">
            <mat-icon svgIcon="draw" aria-hidden="false" aria-label="Draw" title="Draw"></mat-icon>
          </button>
          <button (click)="mode = 'TEXT'" [disabled]="mode === 'TEXT'" class="custom-btn">
            <mat-icon svgIcon="addText" aria-hidden="false" aria-label="Write" title="Add text"></mat-icon>
          </button>
          <div class="color-selector">
            <input type="color" value="#000000" (change)="onColorChange($event)">
          </div>
          <button (click)="undo()" class="custom-btn" style="margin-left: 5px;" [disabled]="backHistory.length === 0">
            <mat-icon svgIcon="undo" aria-hidden="false" aria-label="Undo" title="Discard last change"></mat-icon>
          </button>
          <button (click)="redo()" class="custom-btn" [disabled]="frontHistory.length === 0">
            <mat-icon svgIcon="redo" aria-hidden="false" aria-label="Redo" title="Redo last change"></mat-icon>
          </button>
          <button (click)="clearCanvas()" class="custom-btn" style="margin-left: 5px;" [disabled]="backHistory.length === 0 && frontHistory.length === 0">
            <mat-icon svgIcon="delete" aria-hidden="false" aria-label="Clear canvas" title="Clear annotations"></mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="image-container" [ngClass]="{ 'dragging': isDragging }">
      <img #image [src]="imageUrl" alt="Preview" (dblclick)="resetZoom()" (mousedown)="onMouseDown($event)" draggable="false" (load)="onImageLoaded()">
      <canvas *ngIf="enableAnnotations" [ngClass]="{ 'drawing': mode === 'DRAW', 'writting': mode === 'TEXT' }" #canvas (dblclick)="resetZoom()" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUpOrLeave()" (mouseleave)="onMouseUpOrLeave()"></canvas>
    </div>
  </div>
  <div class="no-preview" *ngIf="!imageUrl">
    <p>No available preview</p>
  </div>
</div>
