import { Component, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '../../services/loader.service'
import { HierarchyService } from '../../services/hierarchy.service';
import { CognitApiService } from '../../services/cognit-api.service'
import { Observable, Subscription, map } from 'rxjs';
import { BlobOptions } from 'buffer';
import moment, { Moment } from 'moment';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { environment } from 'src/environments/environment';
import { CognitDataFormatter } from 'src/app/services/cognit-data-formatter';
import { MessageService, SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { event } from 'cypress/types/jquery';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.css']
})
export class HierarchyComponent {
  @Input()
  showDateRange: boolean = false;
  @Input()
  showClearButton: boolean = false;
  @Input()
  showonlyFieldControl: boolean = false;
  @Input()
  showoWellTypeFilter: boolean = false;
  @Input()
  screeId: string = "";
  @Input()
  getListofAllWellsUnderField: boolean = false;
  @Input()
  LiftTypeToloadWells: string;
  @Input()
  timerangeDifference: any;
  @Input()
  selectDefaultFieldValue: boolean = false;
  @Input()
  AddAllFieldValue: boolean = false;
  liftType: string;
  @Input()
  public selectedWellType: string;
  @Input()
  public filterOnWellType: boolean = true;
  @Input()
  selectDefaultWellValue: boolean = false;
  @Input()
  componentName: string = "";
  @Input()
  showMonthYearDateFilter: boolean = false;
  @Input()
  showErrorFromComponentToHierarchyMessage = false;
  @Input()
  errorMessage = "";
  @Input()
  retainHierarchy: boolean = false;

  private subs: Subscription[] = [];
  public fieldList: any[] = [];
  public fieldListraw: any[] = [];
  public selectedField: string;
  public wellPadList: any;
  public filterWellList: any;
  public selectedwell: string;
  public selectedwellPad: string;

  public filterstartDate: any;
  public filterendDate: any;
  allWellList: any[] = [];
  public range: DateRange<Moment>;
  loadWelldeatils: boolean = true;
  public dateFilterMax: any;
  //public allWellList:any[]=[];

  @Output() onFieldChange = new EventEmitter<any>();
  @Output() onWellPadChange = new EventEmitter<any>();
  @Output() onWellchange = new EventEmitter<any>();
  @Output() ondatechange = new EventEmitter<any>();
  @Output() onclear = new EventEmitter<any>();
  @Output() onMonthYearDate = new EventEmitter<any>();
  dateFilter: string | number | Date;
  todaysdate: moment.Moment;
  monthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
  CurrentmonthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
  constructor(
    private apiService: CognitApiService,
    private loader: LoaderService,
    private hierarchyService: HierarchyService,
    private cognitDataFormatter: CognitDataFormatter,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,) {
  }
  ngOnInit() {
    this.setdateRangeDefaultDate();
    if (this.filterOnWellType == true && (this.selectedWellType == undefined || this.selectedWellType == "")) {
      this.selectedWellType = "Producer";
    }
    if (this.componentName === 'WelltestvalidationComponent') {
      this.selectedWellType = "";
    }
    if (this.retainHierarchy == true)
      this.selectedField = this.hierarchyService.getfield();
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = _.cloneDeep(data);
      this.fieldListraw = _.cloneDeep(data);
      this.setAllFieldasDefault()
      this.selectDeafaultfield();
    }));
    this.dateFilterMax = moment(moment().add(+1, 'days').toDate());
    if (this.selectDefaultFieldValue != true)
      this.loadAllWells();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.liftType = this.LiftTypeToloadWells;
    if (changes.LiftTypeToloadWells != undefined && changes.LiftTypeToloadWells.previousValue != undefined && changes.LiftTypeToloadWells.currentValue != changes.LiftTypeToloadWells.previousValue)
      this.liftTypechange();
    //this.setAllFieldasDefault();
    //this.selectDeafaultfield();
  }
  setAllFieldasDefault() {
    if (this.AddAllFieldValue == true && this.fieldList.length > 0) {
      if (this.fieldList[0].externalId != "All") {
        let field = { "externalId": "All", "Name": "All" };
        this.fieldList.splice(0, 0, field);
      }
      this.selectedField = "All";
      let event = {
        value: this.selectedField,
      }
      this.onfieldChange(event);
    }
    else {
      this.fieldList = _.cloneDeep(this.fieldListraw);
    }
  }

  selectDeafaultfield() {
    if (this.fieldList.length > 0) {
      this.selectedField = this.selectDefaultFieldValue == true && this.selectedField == undefined ? this.fieldList[0].externalId : this.selectedField;
      let event = {
        value: this.selectedField,
      }
      if (this.selectedField && this.selectDefaultFieldValue == true) {
        this.onfieldChange(event);
      }
      else if (this.selectedField && this.retainHierarchy == true && !this.selectedwell) {
        this.onfieldChange(event);
      }
    }
  }
  selectDeafaultWell() {
    if (this.selectDefaultWellValue == true && this.filterWellList != undefined && this.filterWellList.length > 0) {
      this.selectedwell = this.filterWellList[0].externalId;
      let event = {
        value: this.selectedwell,
      }
      this.onfilterwellChange(event);
    }
  }
  liftTypechange() {
    if (this.selectedField != undefined && this.selectedField != "") {
      let event = {
        value: this.selectedField,
      }
      this.onfieldChange(event);
    }
    else {
      this.loadAllWells();
    }
  }

  loadAllWells(filter: any = undefined, event: any = undefined, changeEvent: any = undefined) {
    this.loader.showLoader();
    //this.selectedwell = "";
    this.subs.push(this.hierarchyService.getAllWells(filter).subscribe((data: any) => {
      if (this.screeId == "InjectorMonitoringTol") {
        this.filterInjectionWell(data).subscribe((welldata: any) => {
          this.filterWellList = welldata;
          this.getSelectedWelllist();
          //this.onWellchange.emit({ event: event, allFieldwells: this.filterWellList });
          if (this.filterWellList.length == 0) {
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Info,
              detail: 'There is no well which has Polymerconcentration value',
            };
            this.messageService.add(alert);
          }
          else if (changeEvent != undefined) {
            changeEvent.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList });

          }

        });
      }
      else if (this.selectedWellType != undefined && this.selectedWellType != "") {
        this.FilterWelldataonWellType(data).then((list: any) => {
          if (this.LiftTypeToloadWells != undefined && this.LiftTypeToloadWells.toLocaleLowerCase() != "injector") {
            this.filterLisftTypeData(list).then((listfilterlist: any) => {
              this.filterWellList = listfilterlist;
              this.getSelectedWelllist();
              this.selectDeafaultWell();
              if (changeEvent != undefined)
                changeEvent.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList });
            });
          }
          else {
            this.filterWellList = list;
            this.getSelectedWelllist();
            this.selectDeafaultWell();
            if (changeEvent != undefined)
              changeEvent.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList });
          }
        });
      }
      else if (this.LiftTypeToloadWells != undefined) {
        this.filterLisftTypeData(data).then((list: any) => {
          this.filterWellList = list;
          this.getSelectedWelllist();
          this.selectDeafaultWell();
          if (changeEvent != undefined)
            changeEvent.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList });
        });
      }
      else {
        this.loader.hideLoader();
        this.filterWellList = data.slice();
        this.getSelectedWelllist();
        if (changeEvent != undefined)
          changeEvent.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList });
        // this.onWellchange.emit({ event: event, allFieldwells: this.filterWellList });
      }
    }));
  }
  getSelectedWelllist() {
    if (this.isWellselected() && this.filterWellList != undefined && this.filterWellList.length > 0)
      this.allWellList = this.filterWellList.filter((item: any) => item.externalId == this.selectedwell);
    else
      this.allWellList = this.filterWellList != undefined ? this.filterWellList.slice() : undefined;
  }
  onWellTypeChange(event: any) {
    this.selectedWellType = event.value;
    this.filterWellList = [];
    this.wellPadList = [];
    this.selectedField = "";
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.onclear.emit();
    this.selectDeafaultfield();
    if (this.selectDefaultFieldValue != true)
      this.loadAllWells();
  }
  onfieldChange(event: any) {
    this.loadWelldeatils = false;
    this.selectedField = event.value;
    this.hierarchyService.setField(this.selectedField)
    this.filterWellList = [];
    this.selectedwell = "";
    this.selectedwellPad = "";
    if (this.selectedField == "All") {
      if (!this.showonlyFieldControl) {
        this.getWellPadListForAllFields();
      }
      this.loadAllWells(undefined, event, this.onFieldChange);
    }
    else {
      this.loader.showLoader();
      this.subs.push(this.hierarchyService.getWellpad(event.value).subscribe((data: any) => {
        this.loader.hideLoader();
        this.wellPadList = data;
        // if (this.getListofAllWellsUnderField)
        this.getselectedFieldWelldata([event.value], event, this.onFieldChange)
        // else
        //   this.onFieldChange.emit({ event: event, wellPadList: this.wellPadList, allFieldwells: undefined });
      }));
    }
  }
  getWellPadListForAllFields() {
    this.loader.showLoader();
    const wellpadObservable = this.hierarchyService.getWellpad("All");
    this.subs.push(
      wellpadObservable.subscribe(
        (result: any) => {
          this.loader.hideLoader();
          this.wellPadList = result;
          this.wellPadList.forEach((pad: any) => {
            if (pad.externalId !== pad.Name) {
              pad.Name = pad.externalId;
            }
          });
        },
        error => {
          this.loader.hideLoader();
          console.error('Error fetching wellpad data', error);
        }
      )
    );
  }

  getwellpadfilter() {
    let wellfilter = undefined;
    if (this.selectedwellPad != undefined && this.selectedwellPad != "")
      wellfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
          "filter": {
            "equals": {
              "property": ["node", "externalId"],
              "value": this.selectedwellPad
            }
          }
        }
      }
    return wellfilter;
  }
  onfilterwellPadChange(event: any) {
    this.loadWelldeatils = false;
    let wellfilter = this.getwellpadfilter();
    this.loadAllWells(wellfilter, event, this.onWellPadChange);
  }

  async waitForProperty(property: () => any): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (property()) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  async onfilterwellChange(event: any, updateWellSelection: boolean = false) {
    await this.waitForProperty(() => this.filterWellList);

    this.selectedwell = event.value;
    this.allWellList = this.filterWellList.filter((item: any) => item.externalId == event.value);
    if (this.loadWelldeatils == true) {
      let wellpadid = this.allWellList[0].WellPad;
      if (wellpadid != undefined) {
        let filter = {
          equals: {
            property: ["externalId"],
            "value": wellpadid
          }
        };

        this.loader.showLoader();
        this.apiService.getInstance("WellPad", filter).subscribe((wellpad: any) => {
          let properties = wellpad.items[0].properties;
          let field: string;
          if (properties !== undefined) {
            field = properties[environment.cogniteSpace]["WellPad/" + environment.cogniteSDMVersion].field.externalId;
            this.selectedField = field;
            this.hierarchyService.setField(field);
            this.subs.push(this.hierarchyService.getWellpad(this.selectedField).subscribe((data: any) => {
              this.loader.hideLoader();
              this.wellPadList = data;
              this.selectedwellPad = wellpadid;
              this.onWellchange.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList, selectedwellPad: this.selectedwellPad, selectedField: this.selectedField });

              if (updateWellSelection)
                this.selectedwell = event.value
            }))
          }

        })
      }
    }
    else {
      if (this.selectedwellPad == undefined || this.selectedwellPad == "")
        this.selectedwellPad = this.allWellList[0].WellPad;
      this.onWellchange.emit({ event: event, well: this.filterWellList, wellPadList: this.wellPadList, allFieldwells: this.allWellList, selectedwellPad: this.selectedwellPad, selectedField: this.selectedField });

    }

  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe())
  }

  setdateRangeDefaultDate() {
    this.todaysdate = moment(moment().toDate());
    if (this.timerangeDifference !== undefined) {
      this.filterstartDate = moment(moment()).add(-this.timerangeDifference, 'days');
      this.filterendDate = moment();
    } else {
      this.filterstartDate = moment(moment()).add(-1, 'months');
      this.filterendDate = moment();
    }

    this.range = ({
      startDate: this.filterstartDate,
      endDate: this.filterendDate
    });

    const dateevent = {
      startDate: this.filterstartDate,
      endDate: this.filterendDate
    }
    this.ondatechange.emit({ event: dateevent });
    if (this.showMonthYearDateFilter) {
      this.range = ({
        startDate: moment(this.monthYearDate.value).startOf('month'),
        endDate: this.monthYearDate.value
      });
      this.onMonthYearDate.emit({ event: this.range })
    }
  }

  onDateSelected(event: any) {
    this.range.startDate = event?.startDate ?? null;
    this.range.endDate = event?.endDate ?? null;
    //this.dateFilter = event.endDate;
    this.filterstartDate = (event.startDate);
    this.filterendDate = moment(event.endDate).add(1, 'days');
    if (event.startDate == undefined || event.endDate == undefined)
      return;
    if ((this.selectedWellType != undefined && this.selectedWellType != "") || (this.LiftTypeToloadWells != undefined || this.LiftTypeToloadWells != "")) {
      if (this.isFieldselected() == true && this.isWellpadselected() != true) {
        this.getselectedFieldWelldata([this.selectedField], event, this.ondatechange);
      }
      else if (this.isWellpadselected() == true) {
        let wellfilter = this.getwellpadfilter();
        this.loadAllWells(wellfilter, event, this.ondatechange);
      }
      else {
        this.loadAllWells(undefined, event, this.ondatechange);
      }
    }
    else
      this.ondatechange.emit({ event: event });
  }

  isWellpadselected() {
    return this.selectedwellPad != undefined && this.selectedwellPad != "";
  }
  isWellselected() {
    return this.selectedwell != undefined && this.selectedwell != "";
  }
  isFieldselected() {
    return this.selectedField != undefined && this.selectedField != "";
  }

  clear(loadwells = true) {
    this.loadWelldeatils = true;
    this.filterWellList = [];
    this.wellPadList = [];
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.selectedField = "";
    this.setAllFieldasDefault();
    this.hierarchyService.setField(null);
    this.hierarchyService.setMonthYearDateRange(null);
    this.onclear.emit();
    this.selectedwell = '';
    if (this.filterOnWellType == true && (this.selectedWellType == undefined || this.selectedWellType == "")) {
      this.selectedWellType = "Producer";
    }
    if (this.componentName === 'WelltestvalidationComponent') {
      this.selectedWellType = "";
    }
    if (loadwells == true)
      this.loadAllWells();
    this.monthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));
    this.setdateRangeDefaultDate();
  }
  //for Injector monitoring screen show wells which has Polymerconcentration property value
  filterInjectionWell(wellist: any): Observable<any> {
    if (this.screeId == "InjectorMonitoringTol") {
      let wells = wellist.map((x: any) => x.externalId)
      this.loader.showLoader();
      return this.hierarchyService.getInjectionFilterWelldata(wells).pipe(map((injectordata: any) => {
        this.loader.hideLoader();
        injectordata = injectordata.map((data: any) => data.externalId)
        let welllist = wellist.filter((well: any) => injectordata.includes(well.externalId))
        return welllist;
      }))
    }
    else
      return wellist;
  }
  async filterLisftTypeData(welllist: any): Promise<any> {
    if (this.LiftTypeToloadWells != undefined) {
      welllist = welllist.filter((well: any) => well.LiftType != undefined);
      let lifttypeTimeserieslist: any[] = []
      if (this.screeId == "inputcalibration")
        lifttypeTimeserieslist = [...new Set(welllist.map((asset: any) => ({ "externalId": asset.LiftType, 'before': new Date(this.filterendDate) })))];
      else
        lifttypeTimeserieslist = [...new Set(welllist.map((asset: any) => ({ "externalId": asset.LiftType })))];

      let lifttypedata: any[] = [];
      let letliftTypedataavailable = false;
      if (lifttypeTimeserieslist.length > 0) {
        let i = 0;
        this.loader.showLoader();
        while (i <= lifttypeTimeserieslist.length) {
          const timeseriesdatapoints = await this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist.slice(i, i + 99))
          timeseriesdatapoints.forEach((datapoint: any) => {
            if (datapoint.datapoints.length > 0) {
              letliftTypedataavailable = true
              datapoint.datapoints.forEach((data: any) => {
                if (data != undefined && String(data.value).toUpperCase() == this.liftType) {
                  lifttypedata.push(datapoint.externalId)
                }
              })
            }

          })
          i = i + 99;
        }
        this.loader.hideLoader();
        if (letliftTypedataavailable == false) {
          this.showAlertforDataNotAvailable("Lift Type Data not available for selected dates. Please contact administrator.")
          return [];
        }
        else
          return welllist.filter((item: any) => lifttypedata.includes(item.LiftType));
      }
    }
  }
  /**
   * get all well under selected field
   * @param field selected field
   * @param event field change event
   */
  public getselectedFieldWelldata(field: any[], event: any, emitevent: any) {
    // if (field.length > 0) {
    //   let wellpadfilter = {
    //     "nested": {
    //       "scope": [environment.cogniteSpace, 'WellPad/1_0', "field"],
    //       "filter": {
    //         "in": {
    //           "property": ["node", "externalId"],
    //           "values": field
    //         }
    //       }
    //     }
    //   }
    //   this.loader.showLoader();
    //   this.apiService.getInstancelist("WellPad", wellpadfilter).subscribe((wellpad: any) => {
    //let wellpadid = wellpad.items.map((X: any) => X.externalId)

    if (this.selectedField == "All") {
      this.loadAllWells(undefined, event, emitevent);
    }
    else {
      let wellpadid = this.wellPadList.map((X: any) => X.externalId)
      let wellfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
          "filter": {
            "in": {
              "property": ["node", "externalId"],
              "values": wellpadid
            }
          }
        }
      }
      this.loadAllWells(wellfilter, event, emitevent);
    }
    //})
    //}
  }

  async FilterWelldataonWellType(welllist: any): Promise<any> {
    if (this.selectedWellType != undefined && this.selectedWellType != "") {

      welllist = welllist.filter((well: any) => well.WellType != undefined);
      let WellTypeTimeserieslist = [...new Set(welllist.map((asset: any) => ({ "externalId": asset.WellType, 'before': new Date(this.filterendDate) })))];
      let WellTypedata: any[] = [];

      if (WellTypeTimeserieslist.length > 0) {
        let i = 0;
        this.loader.showLoader();
        let letwellTypedataavailable = false;
        while (i <= WellTypeTimeserieslist.length) {
          let welltype = this.selectedWellType;
          const timeseriesdatapoints = await this.apiService.getLatestTimeseriesData(WellTypeTimeserieslist.slice(i, i + 99))
          timeseriesdatapoints.forEach((datapoint: any) => {
            if (datapoint.datapoints.length > 0) {
              letwellTypedataavailable = true;
              datapoint.datapoints.forEach((data: any) => {
                if (data != undefined && String(data.value).toLowerCase() == welltype.toLowerCase()) {
                  WellTypedata.push(datapoint.externalId)
                }
              })
            }

          })
          i = i + 99;
        }
        this.loader.hideLoader();
        if (letwellTypedataavailable == false) {
          this.showAlertforDataNotAvailable("Well Type Data not available for selected dates. Please contact administrator.")
          //console.log("");
          return [];
        }
        else
          return welllist.filter((item: any) => WellTypedata.includes(item.WellType));
      }
    }
  }
  showAlertforDataNotAvailable(msg: string) {
    const alert: SlbMessage = {
      target: 'modal',
      severity: SlbSeverity.Error,
      summary: 'Data Not Available',
      detail: msg,

    };
    this.messageService.add(alert);
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.monthYearDate.value;
    ctrlValue?.year(normalizedYear.year());
    this.monthYearDate.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.monthYearDate.value;
    ctrlValue?.month(normalizedMonth.month());
    this.monthYearDate.setValue(ctrlValue);
    let enddate = moment(this.monthYearDate.value).endOf('month')
    this.range = ({
      startDate: moment(this.monthYearDate.value).startOf('month'),
      endDate: enddate
    });

    this.hierarchyService.setMonthYearDateRange(this.range ?? null);
    this.onMonthYearDate.emit({ event: this.range });
    datepicker.close();
  }
  emitMonthyearDatevalue(range: any) {
    this.monthYearDate.setValue(range.endDate);
    this.onMonthYearDate.emit({ event: range });
  }

  ngAfterViewInit() {
    if (this.hierarchyService.getMonthYearDateRange() && this.retainHierarchy)
      this.emitMonthyearDatevalue(this.hierarchyService.getMonthYearDateRange());
    this.cd.detectChanges();
  }
}

