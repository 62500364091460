import { Component, Input } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { CognitApiService } from '../../services/cognit-api.service';
import { Subscription, forkJoin } from 'rxjs';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { HighchartsService } from 'src/app/services/highcharts.service';
import { Chart, Point, Series } from 'highcharts';
import StockChart from 'highcharts/modules/stock';
import { LoaderService } from '../../services/loader.service';
import * as Highcharts from 'highcharts';
import { TimeseriesHelper } from 'src/app/services/timeserieshelper.service';

StockChart(Highcharts);

@Component({
  selector: 'pump-chart',
  templateUrl: './pump-chart.component.html',
  styleUrls: ['./pump-chart.component.css']
})


export class PumpchartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  @Input()
  well: string;


  @Input()
  Title: string;

  @Input()
  chartId: string = 'chart';

  @Input()
  Lifttype: string;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input()
  properties: any;

  @Input()
  getdatafromWellOperationalInputsHf = false;

  @Input()
  granularity='1m';


  public externalidlist: any[] = [];
  public externalId: any = {};
  public typeProperties: any = {};
  public unit: any = {};
  public pdp: any[] = [];
  public pip: any[] = [];
  public mf: any[] = [];
  public strokelength: any[] = [];
  public mc: any[] = [];
  public mwt: any[] = [];
  public sibhp: any[] = [];
  public shutin: any[] = [];
  public steady: any[] = [];
  public rampup: any[] = [];
  public rampdown: any[] = [];
  public restart: any[] = [];
  public pfrate: any[] = [];
  public spm: any[] = [];
  public waterrate: any[] = [];
  public tht: any[] = [];
  public rpm: any[] = [];
  public torque: any[] = [];
  public fat: any[] = [];
  public ap: any[] = [];
  public thp: any[] = [];
  public apressure: any[] = [];
  public pi: any[] = [];
  public pipph: any[] = [];
  public pipml: any[] = [];
  public sdp: any[] = [];
  public rndp: any[] = [];
  public flp: any[] = [];

  public Type: string = '';
  public loading: boolean = false;
  public chartWidth: any;
  chartData: any[] = [];
  public options: Highcharts.Options;
  chart: any;
  constructor(private highchart: HighchartsService,
    private loader: LoaderService,
    private apiService: CognitApiService,
    private timeseriesHelper: TimeseriesHelper) {

  }

  async ngOnChanges() {
    this.typeProperties = {};
    if (this.well != undefined && this.well != '' && this.startDate != undefined && this.endDate != undefined) {
      this.chart = null;
      this.showEmptyChart();
      this.Type = '';
      this.loading = true;
      this.showEmptyChart();
      const datapoints = await this.apiService.getLatestTimeseriesData([{ "externalId": this.Lifttype }]);
      datapoints.forEach((datapoint: any) => {
        if (datapoint.datapoints.length > 0) {
          datapoint.datapoints.forEach((data: any) => {
            if (data != undefined) {
              //console.log(String(data.value).toUpperCase());
              this.Type = String(data.value).toUpperCase();
              this.typeProperties = this.properties[this.Type];
              //console.log(this.typeProperties);
              if (this.typeProperties) { this.loadData() } else { this.setloading(false);
               }
            } else { this.setloading(false);
            }
          })
        } else { this.setloading(false);
        }
      })
    } else {
      this.chart = null;
      this.showEmptyChart();
    }
  }


  getexternalidlist(results: any, space: string, viewid: any, version: string, propertyConditions: any) {
    const properties = results?.properties;
    if (properties !== undefined) {
      let externaliddata = properties[space][viewid + "/" + version];
      if (externaliddata) {
        const property = externaliddata['property'];
        if (propertyConditions[property] && (externaliddata['timeseriesExtId'] !== undefined || externaliddata['timeseries'] !== undefined)) {
          let seriesID = externaliddata['timeseriesExtId'] != undefined ? externaliddata['timeseriesExtId'] : externaliddata['timeseries'];

          this.externalidlist = [...this.externalidlist, { "externalId": seriesID, cursor: '' }];
          this.externalId[property] = seriesID;
        }
      }
    }
  }


  ngOnInit() {
    this.chartWidth = document.getElementById('divhfchart')?.offsetWidth.toString();
  }

  loadData() {
    this.externalidlist = [];
    this.externalId = {};
    this.unit = {};
    this.pdp = [];
    this.pip = [];
    this.mf = [];
    this.mc = [];
    this.mwt = [];
    this.sibhp = [];
    this.shutin = [];
    this.steady = [];
    this.rampup = [];
    this.rampdown = [];
    this.restart = [];
    this.pfrate = [];
    this.spm = [];
    this.waterrate = [];
    this.tht = [];
    this.rpm = [];
    this.torque = [];
    this.fat = [];
    this.ap = [];
    this.thp = [];
    this.apressure = [];
    this.pi = [];
    this.pipph = [];
    this.pipml = [];
    this.sdp = [];
    this.rndp = [];
    this.flp = [];

    let space = environment.cogniteSpace;
    let version = '';
    let viewid = '';

    switch (this.Type) {
      case 'ESP':
        version = environment.version.espParameter;
        viewid = environment.view.espParameter;
        break;
      case 'PCP':
        version = environment.version.pcpParameter;
        viewid = environment.view.pcpParameter;
        break;
      case 'JP':
        version = environment.version.jpParameter;
        viewid = environment.view.jpParameter;
        break;
      case 'HRP':
        version = environment.version.hrpParameter;
        viewid = environment.view.hrpParameter;
        break;
      case 'INJECTOR':
        version = environment.version.injectorWellproperty;
        viewid = environment.view.injectorWellproperty;
        break;
      case 'NATURAL':
        version = environment.version.nf;
        viewid = environment.view.nf;
        break;
    }

    if ((this.Title == 'Surface Line DP' || this.Title == 'RNDP') && this.Type == 'ESP') {
      version = environment.version.espParameter_2;
      viewid = environment.view.espParameter_2;
    }

    if (this.Title == 'Flowline Pressure' && this.Type == 'NATURAL') {
      version = environment.version.ProducerWellProperties;
      viewid = environment.view.ProducerWellProperties;
    }

    let filter = {};
    let OperationalInputsviewid = "WellOperationalInputsHf";
    let OperationalInputsversion = environment.cogniteSDMVersion;
    if (this.Type == 'NATURAL' && this.Title != 'Flowline Pressure') {
      filter = {
        "nested": {
          "scope": [environment.cogniteSpace, viewid + '/' + version, "well"],
          "filter": {
            "equals": {
              "property": ["node", "externalId"],
              "value": this.well
            }
          }
        }
      }
    } else {
      filter = {
        "equals": {
          "property": [environment.cogniteSpace, viewid + '/' + version, "wellName"],
          "value": this.well
        }
      }
    }
    let sources = [
      this.apiService.getInstancelist(viewid, filter, version)
    ];
    if (this.getdatafromWellOperationalInputsHf == true) {

      filter = {
        "nested": {
          "scope": [environment.cogniteSpace, OperationalInputsviewid + '/' + OperationalInputsversion, "well"],
          "filter": {
            "equals": {
              "property": ["node", "externalId"],
              "value": this.well
            }
          }
        }
      }
      sources.push(this.apiService.getInstancelist(OperationalInputsviewid, filter, OperationalInputsversion))
    }

    forkJoin(sources).subscribe(async (instancedata: any) => {
      this.loading = true;
      const data = instancedata[0];
      let rawData = data['items'];
      if (rawData.length) {
        rawData.forEach((item: any) => {
          this.getexternalidlist(item, space, viewid, version, this.typeProperties);
        });
      }   
      if (instancedata.length == 2) {
        const data = instancedata[1];
        let rawData = data['items'];
        let listofexternalid: any[] = [];

        if (rawData.length) {
          rawData.forEach((item: any) => {
            listofexternalid = listofexternalid.concat(this.timeseriesHelper.getexternalidlist(item, space, OperationalInputsviewid, OperationalInputsversion, "well"));
            let propertyList = this.timeseriesHelper.sdmPropertyList.filter(x => Object.keys(this.typeProperties).includes(x.keyvalue))
            let list = [...new Set(propertyList.map((asset: any) => ({ "externalId": asset.value, cursor: '' })))];
            this.externalidlist = this.externalidlist.concat(list);
            propertyList.forEach(property => {
              let prop = property.keyvalue == "tubingheadtemp" ? "THT" : "THP";
              this.externalId[prop] = property.value;
            });
            console.log(this.externalId);
          });
        }
      }
      if (this.externalidlist.length) {
        //this.loader.showLoader();
        let results: any[] = await this.apiService.getTimeseriesAvgRange(this.externalidlist, moment(this.startDate).valueOf(), moment(this.endDate).add(1, 'days').valueOf(), this.granularity);
        this.loading = false;
        let result = [];
        results.forEach((data: any) => {
          if ((data.externalId == this.externalId['motorFrequency'] || data.externalId == this.externalId['operatingFrequency'])) {
            this.unit['mf'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.mf.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['strokeLength']) {
            this.unit['strokelength'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.strokelength.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if ((data.externalId == this.externalId['motorCurrent'] || data.externalId == this.externalId['Current'])) {
            this.unit['mc'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.mc.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['MWT']) {
            this.unit['mwt'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.mwt.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['PIP']) {
            this.unit['pip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.pip.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['PDP'] || data.externalId == this.externalId['pumpDischargePressure']) {
            this.unit['pdp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.pdp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['SIBHP']) {
            this.unit['sibhp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.sibhp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['RPM']) {
            this.unit['rpm'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.rpm.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['Torque']) {
            this.unit['torque'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.torque.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['SPM']) {
            this.unit['spm'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.spm.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['FAT']) {
            this.unit['fat'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.fat.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['AP']) {
            this.unit['ap'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.ap.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['THT'] || data.externalId == this.externalId['tubingheadtemp']) {
            this.unit['tht'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.tht.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['THP'] || data.externalId == this.externalId['tubingheadpress']) {
            this.unit['thp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.thp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['A Pressure']) {
            this.unit['apressure'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.apressure.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['powerFluidRate']) {
            this.unit['pfrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.pfrate.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
          if (data.externalId == this.externalId['waterInjectionRate']) {
            this.unit['waterrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
            data.datapoints.forEach((datapoint: any) => {
              this.waterrate.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
            });
          }
	if (data.externalId == this.externalId['productivityIndex']) {
              this.unit['pi'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pi.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['PIP - Calculated from Curve']) {
              this.unit['pipph'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pipph.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['PIP - Calculated from ML']) {
              this.unit['pipml'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.pipml.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['surfaceDp']) {
              this.unit['sdp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.sdp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['RNDP']) {
              this.unit['rndp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.rndp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
            if (data.externalId == this.externalId['flowlinePressure']) {
              this.unit['flp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
              data.datapoints.forEach((datapoint: any) => {
                this.flp.push([moment(datapoint.timestamp).valueOf(), +datapoint.average.toFixed(3)]);
              });
            }
          });
        this.showChart();
      } else {
        this.loading = false;
        this.showEmptyChart();
      }
      // } else { this.loading = false; }


    });
  }

  showEmptyChart() {
     let nodatatext = this.loading == true?'':'No Data to display';
    this.options = {
      chart: {
        width: this.chartWidth,
        backgroundColor: "transparent",
        marginTop: 0,
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Select Well To Show ' + this.Title
      },
      lang: {
        noData: nodatatext
      }
    };

    this.highchart.createChart(`linecontainer-${this.chartId}`, this.options);
  }

  showChart() {
    this.options = {
      chart: {
        zooming: {
          type: 'x'
        },
        width: this.chartWidth,
        backgroundColor: "transparent"
      },
      time: {
        timezone: moment.tz.guess()
      },
      credits: {
        enabled: false
      },
      title: {
        text: this.Title,
        align: 'left'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
          style: {
            fontSize: '20px'
          }
        }
      },
      yAxis: [{
        alignTicks: false,
        title: {
          text: 'current(A),Frequency(Hz),MWT(Deg C)'
        },
        opposite: false,
      }, {
        alignTicks: false,
        gridLineWidth: 0,
        title: {
          text: 'SIBHP,PDP(psi),PIP(psi)'
        },
        opposite: true,
      }], legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        layout: 'horizontal'
      },
      series: [{
        type: 'line',
        name: this.unit['pdp'] != '' && this.unit['pdp'] != undefined ? 'PDP(' + this.unit['pdp'] + ')' : 'PDP(psi)',
        data: this.pdp,
        showInLegend: this.typeProperties['PDP'] == undefined && this.typeProperties['pumpDischargePressure'] == undefined ? false : true,
        yAxis: 1,
      }, {
        type: 'line',
        name: this.unit['pip'] != '' && this.unit['pip'] != undefined ? 'PIP(' + this.unit['pip'] + ')' : 'PIP(psi)',
        data: this.pip,
        showInLegend: this.typeProperties['PIP'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['mf'] != '' && this.unit['mf'] != undefined ? 'Frequency(' + this.unit['mf'] + ')' : 'Frequency(Hz)',
        data: this.mf,
        showInLegend: this.typeProperties['motorFrequency'] == undefined && this.typeProperties['operatingFrequency'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['mc'] != '' && this.unit['mc'] != undefined ? 'Current(' + this.unit['mc'] + ')' : 'Current(A)',
        data: this.mc,
        showInLegend: this.typeProperties['motorCurrent'] == undefined && this.typeProperties['Current'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['mwt'] != '' && this.unit['mwt'] != undefined ? 'MWT(' + this.unit['mwt'] + ')' : 'MWT(Deg C)',
        data: this.mwt,
        showInLegend: this.typeProperties['MWT'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['sibhp'] != '' && this.unit['sibhp'] != undefined ? 'SIBHP(' + this.unit['sibhp'] + ')' : 'SIBHP(psi)',
        data: this.sibhp,
        showInLegend: this.typeProperties['SIBHP'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['rpm'] != '' && this.unit['rpm'] != undefined ? 'RPM(' + this.unit['rpm'] + ')' : 'RPM',
        data: this.rpm,
        showInLegend: this.typeProperties['RPM'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['torque'] != '' && this.unit['torque'] != undefined ? 'Torque(' + this.unit['torque'] + ')' : 'Torque(Ft-lbs)',
        data: this.torque,
        showInLegend: this.typeProperties['Torque'] == undefined ? false : true,
        yAxis: 0
      },
      {
        type: 'line',
        name: this.unit['ap'] != '' && this.unit['ap'] != undefined ? 'AP(' + this.unit['ap'] + ')' : 'AP(psi)',
        data: this.ap,
        showInLegend: this.typeProperties['AP'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['fat'] != '' && this.unit['fat'] != undefined ? 'FAT(' + this.unit['fat'] + ')' : 'FAT(Deg C)',
        data: this.fat,
        showInLegend: this.typeProperties['FAT'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['thp'] != '' && this.unit['thp'] != undefined ? 'THP(' + this.unit['thp'] + ')' : 'THP(kPag)',
        data: this.thp,
        showInLegend: this.typeProperties['THP'] == undefined && this.typeProperties['tubingheadpress'] == undefined ? false : true,
        yAxis: 1
      },
      {
        type: 'line',
        name: this.unit['apressure'] != '' && this.unit['apressure'] != undefined ? 'A Pressure(' + this.unit['apressure'] + ')' : 'A Pressure(psi)',
        data: this.apressure,
        showInLegend: this.typeProperties['A Pressure'] == undefined ? false : true,
        yAxis: 1
      },
      {
        type: 'line',
        name: this.unit['spm'] != '' && this.unit['spm'] != undefined ? 'SPM(' + this.unit['spm'] + ')' : 'SPM',
        data: this.spm,
        showInLegend: this.typeProperties['SPM'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['waterrate'] != '' && this.unit['waterrate'] != undefined ? 'Injector Rate(' + this.unit['waterrate'] + ')' : 'Injector Rate',
        data: this.waterrate,
        showInLegend: this.typeProperties['waterInjectionRate'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['pfrate'] != '' && this.unit['pfrate'] != undefined ? 'PF Rate(' + this.unit['pfrate'] + ')' : 'PF Rate(m3/hr)',
        data: this.pfrate,
        showInLegend: this.typeProperties['powerFluidRate'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['tht'] != '' && this.unit['tht'] != undefined ? 'THT(' + this.unit['tht'] + ')' : 'THT(Deg C)',
        data: this.tht,
        showInLegend: this.typeProperties['THT'] == undefined && this.typeProperties['tubingheadtemp'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['pi'] != '' && this.unit['pi'] != undefined ? 'PI (' + this.unit['pi'] + ')' : 'PI (STB/D/psi)',
        data: this.pi,
        showInLegend: this.typeProperties['productivityIndex'] == undefined ? false : true,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['pipph'] != '' && this.unit['pipph'] != undefined ? 'PIP Physics(' + this.unit['pipph'] + ')' : 'PIP Physics(psi)',
        data: this.pipph,
        showInLegend: this.typeProperties['PIP - Calculated from Curve'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['pipml'] != '' && this.unit['pipml'] != undefined ? 'PIP ML(' + this.unit['pipml'] + ')' : 'PIP ML(psi)',
        data: this.pipml,
        showInLegend: this.typeProperties['PIP - Calculated from ML'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        name: this.unit['pipml'] != '' && this.unit['pipml'] != undefined ? 'PIP Measured(' + this.unit['pipml'] + ')' : 'PIP Measured(psi)',
        data: this.pipml,
        showInLegend: this.typeProperties['PIP - Calculated from ML'] == undefined ? false : true,
        yAxis: 1
      }, {
        type: 'line',
        id: 'sdp',
        name: this.unit['sdp'] != '' && this.unit['sdp'] != undefined ? 'Pressure(' + this.unit['sdp'] + ')' : 'Pressure(psi)',
        data: this.sdp,
        showInLegend: false,
        yAxis: 0
      }, {
        type: 'line',
        id: 'rndp',
        name: this.unit['rndp'] != '' && this.unit['rndp'] != undefined ? '' + this.unit['rndp'] + '' : 'psi/tt',
        data: this.rndp,
        showInLegend: false,
        yAxis: 0
      }, {
        type: 'line',
        name: this.unit['flp'] != '' && this.unit['flp'] != undefined ? 'flowline Pressure(' + this.unit['flp'] + ')' : 'flowline Pressure(kpag)',
        data: this.flp,
        showInLegend: this.typeProperties['flowlinePressure'] == undefined ? false : true,
        yAxis: 0
      },
      {
        type: 'line',
        id: 'strokelength',
        name: this.unit['strokelength'] != '' && this.unit['strokelength'] != undefined ? '' + this.unit['strokelength'] + '' : 'm',
        data: this.strokelength,
        showInLegend: false,
        yAxis: 0
      },
      ],
      rangeSelector: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: this.Title == 'Pump Parameters' ? true : false
          }
        }
      },
      tooltip: {
        shared: true,
        pointFormat: "{series.name} : {point.y:.2f}"
      }
    }

    this.chart = Highcharts.stockChart(`linecontainer-${this.chartId}`, this.options);
    this.updateYaxis();
  }

  updateYaxis() {
    let yTitle1: string[] = [];
    let yTitle2: string[] = [];
    if (this.typeProperties['surfaceDp']) {
      this.chart.series.forEach((element: any) => {
        if (element.options.yAxis == 0 && element.options.id == 'sdp') { yTitle1.push(element.options.name) };
      })
    } else if (this.typeProperties['RNDP']) {
      this.chart.series.forEach((element: any) => {
        if (element.options.yAxis == 0 && element.options.id == 'rndp') { yTitle1.push(element.options.name) };
      })
    } else {
      this.chart.series.forEach((element: any) => {
        if (element.options.yAxis == 0 && element.options.showInLegend) { yTitle1.push(element.options.name) };
        if (element.options.yAxis == 1 && element.options.showInLegend) { yTitle2.push(element.options.name) };
      })
    }

    this.chart.update({
      yAxis: [{
        title: {
          text: yTitle1.join(',')
        }
      }, {
        title: {
          text: yTitle2.join(',')
        }
      }]
    });
    this.loading = false;
  }
  setloading(loading:boolean)
  {
    this.loading = loading;
    this.showEmptyChart()
  }
  
  ngAfterViewInit() {
    this.showEmptyChart();
  }
}
