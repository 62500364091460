import { AfterViewInit, Directive, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { calculateRowsPerPage } from '../utils/calculate-table-height';

declare interface GridSettings {
  calculateRowsPerPage?: boolean;
  fixHeaders?: boolean;
  mainGridContainerId?: string;
  heightFragment?: number;
  marginHeader?: number;
}

@Directive({
  selector: '[appGridTransform]',
})
export class GridTransformDirective implements AfterViewInit, OnChanges {

  @Input('appGridTransform') gridSettings: GridSettings;
  @Input() kendoGridBinding: any[];

  private defaultRowsPerPage: number = 10;

  constructor(private grid: GridComponent, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    if (this.grid)
      this.defaultRowsPerPage = this.grid.pageSize ?? 10;

    this.updateHeaders();
    this.setRowsPerPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kendoGridBinding?.firstChange === false && changes.kendoGridBinding?.previousValue !== changes.kendoGridBinding?.currentValue)
      this.setRowsPerPage();
  }

  private updateHeaders() {
    if (this.gridSettings?.fixHeaders) {
      const headers = document.querySelectorAll('.k-grid .k-grid-header .k-table-th:first-child[aria-colindex]');

      if (headers) {
        headers.forEach(header => {
          this.renderer.addClass(header, 'fix-header-border');
        });
      }
    }
  }

  private setRowsPerPage() {
    this.setDefaultRowsPerPage();

    if (this.grid && this.gridSettings?.calculateRowsPerPage && this.gridSettings?.mainGridContainerId) {
      const rowsPerPage = calculateRowsPerPage(document.getElementById(this.gridSettings.mainGridContainerId), this.gridSettings.heightFragment, 21, this.gridSettings.marginHeader);
      const previousPageSize = Number(`${this.grid.pageSize}`);
      const totalRows = (this.grid.data as GridDataResult).total;
      this.grid.pageSize = rowsPerPage;

      if (this.grid.data && (totalRows > rowsPerPage || totalRows > previousPageSize))
        (this.grid.data as GridDataResult).data = this.kendoGridBinding.slice(0, rowsPerPage);
    }
  }

  private setDefaultRowsPerPage() {
    if (this.grid) {
      this.grid.pageSize = this.defaultRowsPerPage;
      const data = this.kendoGridBinding ?? [];

      (this.grid.data as GridDataResult).data = data.slice(0, this.defaultRowsPerPage);
    }
  }

}
