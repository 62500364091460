<div>
  <div class="container1">
     <slb-date-picker-range class="calendar" placeholder="Select Date Range" (rangeSelected)="onDateSelected($event)"
        [dateRange]="range" [maxValue]="dateFilterMax">
    </slb-date-picker-range>
  </div>
  <div *ngIf="loading" class="spinner__loading loading-container">
    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
  </div>
  <div id="chart-container" style="width: 100%;">
    <highcharts-chart *ngIf="!loading && chartDef.length > 0" [Highcharts]="Highcharts" [constructorType]="'stockChart'" [options]="chartOptions"></highcharts-chart>
  </div>
  <div class="no-data" *ngIf="!loading && chartDef.length === 0">
    <p>No data to display</p>
  </div>
</div>
