import { Injectable } from '@angular/core';
import { CognitApiService } from './cognit-api.service'
import { CognitDataFormatter } from './cognit-data-formatter'
import { LoaderService } from './loader.service'
import { BehaviorSubject, Observable, Subject, Observer } from 'rxjs';
import { switchMap, map } from "rxjs/operators";
import { environment } from '../../../src/environments/environment';
import { FormControl } from '@angular/forms';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import moment from 'moment';
import { Moment } from 'moment';


@Injectable({
  providedIn: 'root'
})
export class HierarchyService {
  private fieldViewId = "Field";
  
  private fieldList = new BehaviorSubject<any[]>([]);
  public fieldList$ = this.fieldList.asObservable();
  private wellList = new BehaviorSubject<any[]>([]);
  public wellList$ = this.wellList.asObservable();
  public fielddatasub = new Subject<any>();
  public injectionwellViewVersion = environment.version.injectionwellVersion;
  private Field: any;
  private monthYearDateRange: any;
  public range: DateRange<Moment>;
  monthYearDate = new FormControl(moment().subtract(1, 'months').endOf('month'));

  constructor(
    private apiService: CognitApiService,
    private cognitDataFormatter: CognitDataFormatter,
    private loader: LoaderService) {
    this.loadFieldData();
    this.getAllWells();

  }

  public setField(Field: any = null) {
    this.Field = Field;
  }

  public getfield() {
    return this.Field;
  }

  public setMonthYearDateRange(monthYearDateRange: any = null) {

    if(monthYearDateRange){
     this.monthYearDateRange = monthYearDateRange;
     }else{
       this.monthYearDate.setValue(moment().subtract(1, 'months').endOf('month'));
       this.range = ({
         startDate: moment(this.monthYearDate.value).startOf('month'),
         endDate: this.monthYearDate.value
       });
       this.monthYearDateRange = this.range;
     }
   }
 
   public getMonthYearDateRange() {
     return this.monthYearDateRange;
   }
 
  loadFieldData() {
    this.loader.showLoader();
    let datasub = this.apiService.getInstance(this.fieldViewId, undefined).subscribe(data => {
      this.loader.hideLoader();
      let list = this.cognitDataFormatter.formatNoderesponse(data, this.fieldViewId, undefined);
      list = list.sort((obj1: any, obj2: any) => obj1.Name.localeCompare(obj2.Name));
      this.fieldList.next(list);
    })
  }

  public getFieldData(): Observable<any> {
    return this.fieldList$;
  }

  getWellpad(field: string): Observable<any> {
    let wellpaddlist = [];
    let wellpadfilter : any= {
      "nested": {
        "scope": [environment.cogniteSpace, 'WellPad/1_0', "field"],
        "filter": {
          "equals": {
            "property": ["node", "externalId"],
            "value": field
          }
        }
      }
    }
    if(field == 'All'){
      wellpadfilter = undefined;
    }
    return this.apiService.getInstancelist("WellPad", wellpadfilter).pipe(map((wellpad: any) => {
      let list = this.cognitDataFormatter.formatNoderesponse(wellpad, "WellPad", undefined);
      list = list.sort((obj1: any, obj2: any) => obj1.Name.localeCompare(obj2.Name));
      return list;
    }));
  }

  getWelldata(wellpad: any): Observable<any> {
    let wellfilter = {
      "nested": {
        "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
        "filter": {
          "equals": {
            "property": ["node", "externalId"],
            "value": wellpad
          }
        }
      }
    }
    return this.apiService.getInstancelist("Well", wellfilter).pipe(map((well: any) => {
      let list = this.cognitDataFormatter.formatNoderesponse(well, "Well", undefined);
      list = list.sort((obj1: any, obj2: any) => obj1.Name.localeCompare(obj2.Name));
      return list;
    }));
  }

  getWelldataWithProperties(wellpad: any): Observable<any> {
    let wellfilter = {
      "nested": {
        "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
        "filter": {
          "equals": {
            "property": ["node", "externalId"],
            "value": wellpad
          }
        }
      }
    }
    return this.apiService.getInstancelist("Well", wellfilter).pipe(map((well: any) => {
      let list = this.cognitDataFormatter.getNoderesponseProperties(well);
      //list = list.sort((obj1: any, obj2: any) => obj1.Name.localeCompare(obj2.Name));
      return list;
    }));
  }

  getWelldataLiftType(well: any): Observable<any> {
    let wellfilter = {
      "equals": {
        "property": [environment.cogniteSpace, 'Well/1_0', "name"],
        "value": well
      }
    }
    return this.apiService.getInstancelist("Well", wellfilter).pipe(map((well: any) => {
      let list = this.cognitDataFormatter.getNoderesponseProperties(well);
      return list;
    }));
  }

  getFieldWellsdataLiftType(well: any): Observable<any> {
    let wellfilter = {
      "in": {
        "property": [environment.cogniteSpace, 'Well/1_0', "name"],
        "values": well
      }
    }
    return this.apiService.getInstancelist("Well", wellfilter).pipe(map((well: any) => {
      let list = this.cognitDataFormatter.getNoderesponseProperties(well);
      return list;
    }));
  }

  getInjectionFilterWelldata(wells: any[]): Observable<any> {
    let wellfilter = {
      "in": {
        "property": ["externalId"],
        "values": wells
      }
    }
    return this.apiService.getInstance("InjectionWell", wellfilter, this.injectionwellViewVersion, environment.cogniteInformationSDMSpace, 1000).pipe(map((well: any) => {
      //console.log(well);
      let list = this.cognitDataFormatter.getNoderesponsePropertieslist(well, "InjectionWell", this.injectionwellViewVersion, environment.cogniteInformationSDMSpace)
      list = list.filter((x: any) => x.properties["polymerConcentration"] != undefined)
      return list;
    }));
  }
  /**
   * 
   * @returns list of all wells available in system
   */
  getAllWellsfromServer(filter: any = undefined) {
    this.loader.showLoader();
    return this.apiService.getInstancelist("Well", filter).pipe(map((well: any) => {
      let list = this.cognitDataFormatter.getNoderesponseProperties(well);
      let welllist: { externalId: string; Name: string; WellPad: string, LiftType: string, WellType: string }[] = []
      list.forEach((item: any) => {
        let properties = item.properties;
        if (properties !== undefined) {
          properties = properties[environment.cogniteSpace]["Well/" + environment.cogniteSDMVersion]
          welllist.push({ externalId: item.externalId, Name: properties.name.toString(), WellPad: properties.wellPad.externalId, LiftType: properties.liftType, WellType: properties.wellType })
        }
      });
      welllist = welllist.sort((obj1: any, obj2: any) => obj1.Name.localeCompare(obj2.Name));
      this.loader.hideLoader()
      return welllist;
    }, (error: any) => {
      this.loader.hideLoader();
      return [];
    }));
  }
  getAllWells(filter: any = undefined): Observable<any> { 
    if (filter == undefined || filter == "") {
      if (this.wellList.getValue().length == 0) {
        return this.getAllWellsfromServer().pipe(map((welllist: any) => {
          this.wellList.next(welllist);
          return this.wellList.getValue();
        }))
      }
      else
        return this.wellList$;
    }
    else
      return this.getAllWellsfromServer(filter).pipe(map((welllist: any) => {
        return welllist;
      }))
  }


}

