import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import SolidGaugeModule from 'highcharts/modules/solid-gauge';


// Initialize the SolidGauge module
SolidGaugeModule(Highcharts);
@Component({
  selector: 'app-injection-chart-card',
  templateUrl: './injection-chart-card.component.html',
  styleUrls: ['./injection-chart-card.component.css']
})
export class InjectionChartCardComponent implements OnInit, OnChanges {


  @Input() cardTitle: string = '';
  @Input() injectionRateToday: number = 0;
  @Input() injectionRateYesterday: number = 0;
  @Input() asOfDate: string = '';
  @Input() unit: string = '';
  @Input() loading: boolean = false;
  // @Input() percentage: string = '';


  gaugeChartConstructor: string = 'chart';
  gaugeHighcharts: typeof Highcharts = Highcharts;
  gaugeChartOptions: Highcharts.Options;


  public percentage: number;
  public diffrenceToShow: string;
  public isNanPercentage: boolean;
  public dummyChartOptions: Highcharts.Options;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const percentageChange = ((this.injectionRateToday - this.injectionRateYesterday) / this.injectionRateToday) * 100;
      this.percentage = Math.round(percentageChange * 100) / 100;
      this.diffrenceToShow = this.formatNumber(Math.abs(this.injectionRateToday - this.injectionRateYesterday))
      this.isNanPercentage = isNaN(Math.round(percentageChange * 100) / 100);
      if (!this.isNanPercentage) {
        const maxValue = Math.max(this.injectionRateYesterday, this.injectionRateToday);
        let calculatedMax = Math.ceil(maxValue / 100) * 100;
        if (((this.injectionRateToday / calculatedMax) * 100 > 95) || ((this.injectionRateYesterday / calculatedMax) * 100 > 95)) {
          calculatedMax = calculatedMax + (calculatedMax * 0.05);
        }
        let yesterdayInjectionRatePercent = (this.injectionRateYesterday / calculatedMax) * 100;
        let injectionRatePercent = (this.injectionRateToday / calculatedMax) * 100;
        const injectionRateValue = this.injectionRateToday;
        const formatValue = this.formatNumber;
        this.gaugeChartOptions = {
          chart: {
            backgroundColor: '',
            type: 'solidgauge',
            margin: [0, 0, 0, 0],
            height: 120
          },
          title: {
            text: '',
          },
          exporting: {
            enabled: false // Disable the exporting module to remove the hamburger menu
          },

          credits: {
            enabled: false // Disable the Highcharts credits
          },
          pane: {
            startAngle: 0,
            center: ['50%', '50%'],
            size: '90%',
            endAngle: 360,
            background: [{
              backgroundColor: '#222',
              innerRadius: '60%',
              outerRadius: '100%',
              borderWidth: 0
            }]
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              if (this.point) {
                return `<b>Injection Rate:</b> ${formatValue(injectionRateValue)}`;
              } else {
                console.log("point none", this)
                return ''
              }
            }
          },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
            plotLines: [
              {
                value: yesterdayInjectionRatePercent,
                width: 3,
                color: '#ff0000', // Red for the dotted line
                dashStyle: 'ShortDot', // Dotted style
                zIndex: 5, // Ensure it appears on top
              }
            ]
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                enabled: false
              },
              linecap: 'round',
              rounded: true,
              stickyTracking: false
            }
          },
          series: [
            {
              name: 'Injection Rate',
              type: 'solidgauge',
              data: [{
                radius: '90%',
                innerRadius: '70%',
                y: injectionRatePercent,
                color: this.percentage >= 0 ? '#80ff80' : '#ff6666'
              }],
            },
          ]
        };
      }
    }
  }
  ngOnInit() {
    const percentageChange = ((this.injectionRateToday - this.injectionRateYesterday) / this.injectionRateToday) * 100;
    this.percentage = Math.round(percentageChange * 100) / 100;
    this.diffrenceToShow = this.formatNumber(Math.abs(this.injectionRateToday - this.injectionRateYesterday))
    this.isNanPercentage = isNaN(Math.round(percentageChange * 100) / 100)
    // const progressPercent = (this.actualValue / this.potentialValue) * 100; // Calculate progress
    if (!this.isNanPercentage) {
      this.gaugeChartOptions = {
        chart: {
          backgroundColor: '',
          type: 'solidgauge',
          margin: [0, 0, 0, 0],
          height: 120
        },
        title: {
          text: '',
        },
        exporting: {
          enabled: false // Disable the exporting module to remove the hamburger menu
        },

        credits: {
          enabled: false // Disable the Highcharts credits
        },
        pane: {
          startAngle: 0,
          center: ['50%', '50%'],
          size: '90%',
          endAngle: 360,
          background: [{
            backgroundColor: '#222',
            innerRadius: '60%',
            outerRadius: '100%',
            borderWidth: 0
          }]
        },
        tooltip: { enabled: false },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false
            },
            linecap: 'round',
            rounded: true,
            stickyTracking: false
          }
        },
        series: [
          {
            name: 'Injection Rate',
            type: 'solidgauge',
            data: [{
              radius: '90%',
              innerRadius: '70%',
              y: 60,
              color: this.percentage >= 0 ? 'green' : 'red'
            }],
          }
        ]
      };
    } else {
      this.dummyChartOptions = {
        chart: {
          backgroundColor: '',
          type: 'solidgauge',
          margin: [0, 0, 0, 0],
          height: 120
        },
        title: {
          text: '',
        },
        exporting: {
          enabled: false // Disable the exporting module to remove the hamburger menu
        },

        credits: {
          enabled: false // Disable the Highcharts credits
        },
        pane: {
          startAngle: 0,
          center: ['50%', '50%'],
          size: '90%',
          endAngle: 360,
          background: [{
            backgroundColor: '#222',
            innerRadius: '60%',
            outerRadius: '100%',
            borderWidth: 0
          }]
        },
        tooltip: { enabled: false },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: false
            },
            linecap: 'round',
            rounded: true,
            stickyTracking: false
          }
        },
        series: [
          {
            name: 'No Value',
            type: 'solidgauge',
            data: [{
              radius: '90%',
              innerRadius: '70%',
              y: 100,
              color: '#4d4d4d'
            }],
          }
        ]
      };

    }
  }
  getPercentageColor() {
    return this.percentage >= 0 ? 'green' : 'red'
  }

  formatNumber(value: number): string {
    return value.toLocaleString('en-US');
  }
}
