import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import moment, { Moment } from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { TabOptionsEnum, ESP, JP } from '../calibrationmodels';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription, forkJoin } from 'rxjs';
import { CalibrationTriggerModel } from '../calibrationmodels'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InformationDialog } from '../../common-component/information-dialog/information-dialog.component';
import * as _ from 'lodash';
import { CalibrationService } from '../calibration-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calibration-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.css'],
})
export class TriggerComponent {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(DataBindingDirective) directive: any;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  errorToHierarchyMessage = "Please select at least one well.";
  showErrorFromComponentToHierarchyMessage: boolean = true;

  public tabOptions = TabOptionsEnum;
  public selectedtab: any;
  public espText: any = {};
  public jpText: any = {};
  public TempStatus: any = {};
  public unitobject: any = {};
  public failedCall: any = {};
  private subs: Subscription[] = [];
  public filterWellList: any;
  public selectedwelllist: any;
  public selectedWell: string | undefined;
  public setInterval: any;
  public statusInterval: any;
  public gridInterval: any;
  public filterStartDate: any;
  public filterEndDate: any;
  public loading: boolean = false;
  public clearGrid: boolean = false;

  public dateList: any[] = [];
  public externalId: any = {};
  public timelist: any = {};
  public inputDatelist: any[] = [];
  public gridData: CalibrationTriggerModel[] = [];
  public itemToDisplay: string;
  public valuedate = '';
  public rpressurelow: any = {};
  public rpressurehigh: any = {};
  public pindexlow: any = {};
  public pindexhigh: any = {};
  public knlow: any = {};
  public knhigh: any = {};
  public knstepsize: any = {};
  public kshigh: any = {};
  public kslow: any = {};
  public ksstepsize: any = {};
  public pindexstepsize: any = {};
  public rpressurestepsize: any = {};
  version = environment.cogniteSDMVersion;

  operationalviewid = 'WellOperationalInputsHf'
  theoreticalviewid = 'WellTheoreticalData'
  calibrationprocessingviewid = environment.spaces.callibrationModule.views.CalibrationProcessingData.id;
  calibrationprocessingversion = environment.spaces.callibrationModule.views.CalibrationProcessingData.version;
  espinputviewid = 'ESPInputs'
  jpinputviewid = 'JPInputs'
  calinputviewid = 'WellCalibrationInputs'


  WTprocessedData: any[] = [];

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).format('DD-MM-YYYY') },
    { field: 'Action', hide: true },
  ];
  simulationData: any;

  constructor(
    private dialog: MatDialog,
    private cogniteAuthService: CogniteAuthService,
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private calService: CalibrationService,
    private router: Router) {
    this.selectedtab = this.calService.selectedTab;
    this.setInterval = null;
    this.statusInterval = null;
    this.gridInterval = null;
  }

  public showDetails: boolean = false;

  public openLog(dataItem: any) {
    this.itemToDisplay = '';
    this.itemToDisplay = dataItem.logtext;

    const dialogRef = this.dialog.open(InformationDialog, {
      data: {
        message: this.itemToDisplay,
      }
    });
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.stopIntervals();
    this.subs.forEach(s => s.unsubscribe())
  }

  stopIntervals() {
    clearTimeout(this.setInterval);
    clearTimeout(this.statusInterval);
    clearTimeout(this.gridInterval);
    this.setInterval = null;
    this.statusInterval = null;
    this.gridInterval = null;
  }

  startIntervals(well: string, interval = 10000) {
    this.setInterval = setTimeout(() => { this.refreshGrid(well) }, interval);
  }

  clear() {
    this.filterWellList = [];
    this.selectedwelllist = [];
    this.gridData = [];
    this.selectedWell = '';
    this.showErrorFromComponentToHierarchyMessage = true;
    this.clearGrid = false;
    this.stopIntervals();
    this.failedCall = {};
    this.gridData = [];
  }

  public statusCode(code: boolean): SafeStyle {
    let color = 'inherit'
    if (code)
      color = "red"
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }
  OpenAlarmPage(dataItem: any) {
    if (dataItem.status === 'Failed' || dataItem.status === 'CompletedWithError')
      this.router.navigate(['/calibration/calalarm']);
    else if (dataItem.status === 'Processed')
      this.router.navigate(['/calibration/output']);
    else if (dataItem.status === 'Completed')
      this.router.navigate(['/calibration/calsummary']);
  }


  refreshGrid(well: string) {
    //this.statusInterval = setTimeout(() => { this.getStatus(); }, 20000);
    // console.log('refreshing grid');
    this.gridInterval = setTimeout(() => { this.getLatestCalibrationStatus(well); }, 21000);
  }


  editRow(dataItem: any, error?: boolean) {
    this.loading = true;
    let date = dataItem.date;
    let disableflag = true;
    let status = 'Queued';
    if (error) {
      disableflag = false;
      status = '';

    }
    const updatedItem = { ...dataItem, disable: disableflag, status: status };
    this.gridData = this.gridData.map(item => item.id === dataItem.id ? updatedItem : item);
    const existingSimData = this.simulationData.find((sim: any) => sim.welltestdate === date);
    if (existingSimData) {
      existingSimData.simulationStatus = 'Queued';
    } else {
      this.simulationData.push({
        simulationStatus: 'Queued',
        simulationLog: '',
        simulationAlarm: '',
        date: date
      });
      this.gridData = this.gridData.slice(0);
      this.loading = false;
    }
  }

  async onCalibration(dataItem: any) {
    const updatedItem = { ...dataItem, disable: true, status: 'Request Initiated' };
    const index = this.gridData.findIndex(item => item.id === dataItem.id);
    if (index !== -1) {
      this.gridData[index] = updatedItem;
    }
    this.gridData = this.gridData.slice(0);

    this.loading = true;
    let date = dataItem.date.split(' ')[0];
    let time = this.timelist[date] != undefined ? this.timelist[date] : '00:00';
    await this.cogniteAuthService.getapiToken().then(res => {
      if (res) {
        let token = res;

        let url = `${environment.webApiUrl}/Cognite/ExecuteCogniteFunction`
        let wtstartdate = moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm');
        let wtutcDateTime = moment(wtstartdate).utc().format("YYYY-MM-DDTHH:mm");
        let enddate = moment(moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm')).add(1, 'days');
        let wtutcenddate = moment(enddate).utc().format("YYYY-MM-DDTHH:mm");
        let workflow = this.selectedtab == "ESP" ? "WellCalibrationEsp" : "WellCalibrationJp";
        let data = {
          "start_date": wtutcDateTime,
          "end_date": wtutcenddate,
          "run_mode": "prod",
          "workflow_name": workflow,
          "force_run": "True",
          "entity_ext_ids": [dataItem.well]
        }
        let DataObject = {
          "name": "cairn-workflow-calibration-preprocessing",
          "data": data
        }
        this.stopIntervals();
        this.apiService.saveFunction(url, DataObject, token).subscribe((data: any) => {
          if (data.response.ExecutionStatus == "Success") {
            delete this.failedCall[dataItem.well + date];
            this.editRow(dataItem, false);
            this.startIntervals(dataItem.well, 30000);
            this.loading = false;
          } else {
            this.failedCall[dataItem.well + date] = data.response.ExecutionStatus;
            const updatedItem = { ...dataItem, disable: false, status: data.response.ExecutionStatus };
            const index = this.gridData.findIndex(item => item.id === dataItem.id);
            if (index !== -1) {
              this.gridData[index] = updatedItem;
            }
            this.gridData = this.gridData.slice(0);
            this.startIntervals(dataItem.well, 30000);
            this.loading = false;
          }
        },
          error => {
            this.editRow(dataItem, true);
            this.loading = false;
            this.startIntervals(dataItem.well, 30000);
            console.log(error);
            const alert: SlbMessage = {
              target: 'modal',
              severity: SlbSeverity.Error,
              //summary: 'Summary',
              detail: "service call failed, kindly contact administrator",

            };
            this.messageService.add(alert);
          });
      }
    },
      (error) => {
        this.editRow(dataItem, true);
        if (error) {
          console.log('Error DCA for type well')
        }
      });
  }

  onDateSelected(event: any) {
    this.filterStartDate = event?.event.startDate?._d
    this.filterStartDate = moment(this.filterStartDate).startOf('day');
    this.filterEndDate = event?.event.endDate?._d
    this.filterEndDate = moment(this.filterEndDate).endOf('day');
    if (this.filterStartDate != undefined && this.filterEndDate != undefined && (this.selectedWell != undefined && this.selectedWell != '')) {
      this.stopIntervals();
      this.loadGridData(this.selectedWell);
    }
  }

  onfieldChange(event: any) {
    this.selectedWell = '';
    this.gridData = [];
    this.stopIntervals();
  }

  onfilterwellPadChange(event: any) {
    this.gridData = [];
    this.stopIntervals();
  }

  onFilterWellChange(event: any) {
    this.filterWellList = event.well;
    this.selectedWell = event.event.value;

    if (this.filterWellList == undefined || this.filterWellList.length == 0)
      this.filterWellList = event.allFieldwells;
    this.showErrorFromComponentToHierarchyMessage = false;
    this.selectedwelllist = this.filterWellList.filter((x: any) => x.externalId == event.event.value);
    this.selectedWell = this.selectedwelllist[0].externalId;
    if (this.filterStartDate != undefined && this.filterEndDate != undefined && this.selectedWell != undefined) {
      this.stopIntervals();
      this.loadGridData(this.selectedWell);
    }
  }

  onTabChange($event: any) {
    this.selectedtab = $event.value;
    this.calService.selectedTab = this.selectedtab;
    this.stopIntervals();
    this.clear();
    this.hierarchyComponent.clear();
  }

  getFilter(viewId: string, wellid: string[], version: string = environment.cogniteSDMVersion) {
    let filter: any;
    if (viewId == this.calibrationprocessingviewid) {
      filter = {
        and: [{
          "nested": {
            "scope": [environment.cogniteSpace, viewId + "/" + version, "well"],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": wellid
              }
            }
          }
        }, {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                gt: moment(this.filterStartDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, viewId + "/" + version, "wellTestStartDateTime"],
                lte: moment(this.filterEndDate).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }
        ]
      }
    }
    else {
      filter = {
        "nested": {
          "scope": [environment.cogniteSpace, viewId + "/" + version, "well"],
          "filter": {
            "and": [{
              "in": {
                "property": ["node", "externalId"],
                values: wellid
              }
            },
            ]
          }
        }
      }
    }
    return filter;
  }

  getLatestCalibrationStatus(well: string) {
    let filter = this.getFilter(this.calibrationprocessingviewid, [well], this.calibrationprocessingversion);
    this.subs.push(this.apiService.getInstancelist(this.calibrationprocessingviewid, filter, this.calibrationprocessingversion)
      .subscribe((instancedata: any) => {
        this.getStatusFromCallibrationProcessing(instancedata, this.calibrationprocessingviewid, this.calibrationprocessingversion);
        this.getGriddata(well)
      }));
  }
  getWellTestDataExternalID(instancedata: any) {
    let externalidlist: any[] = [];
    let item = instancedata[0]['items'][0]['properties'][environment.cogniteSpace][this.theoreticalviewid + "/1_2"];
    externalidlist = [{ "externalId": item.theoreticalGOR }, { "externalId": item.theoreticalWaterCut }, { "externalId": item.theoreticalLiquidRate }];
    this.externalId['theoreticalGOR'] = item.theoreticalGOR;
    this.externalId['theoreticalWaterCut'] = item.theoreticalWaterCut;
    this.externalId['theoreticalLiquidRate'] = item.theoreticalLiquidRate;
    item = instancedata[1]['items'][0]['properties'][environment.cogniteSpace][this.operationalviewid + "/" + this.version];
    externalidlist.push({ "externalId": item.tubingHeadPress, "targetUnit": "pressure:psi" }, { "externalId": item.tubingHeadTemp, "targetUnit": "temperature:deg_c" });
    this.externalId['tubingHeadPress'] = item.tubingHeadPress;
    this.externalId['tubingHeadTemp'] = item.tubingHeadTemp;
    item = instancedata[2]['items'][0]['properties'][environment.cogniteSpace][this.espinputviewid + "/" + this.version];
    if (this.selectedtab == this.tabOptions.ESP) {
      externalidlist.push({ "externalId": item.operatingFrequencyPmm, "targetUnit": "frequency:hz" }, { "externalId": item.pumpIntakePress, "targetUnit": "pressure:psi" }, { "externalId": item.pumpDischargePress, "targetUnit": "pressure:psi" });
      this.externalId['operatingFrequencyPmm'] = item.operatingFrequencyPmm;
      this.externalId['pumpIntakePress'] = item.pumpIntakePress;
      this.externalId['pumpDischargePress'] = item.pumpDischargePress;
    }
    else if (this.selectedtab == this.tabOptions.JP) {
      item = instancedata[2]['items'][0]['properties'][environment.cogniteSpace][this.jpinputviewid + "/" + this.version];
      externalidlist.push({ "externalId": item.aAnnulusPress, "targetUnit": "pressure:psi" }, { "externalId": item.powerFluidRateCorrected });
      this.externalId['aAnnulusPress'] = item.aAnnulusPress;
      this.externalId['powerFluidRateCorrected'] = item.powerFluidRateCorrected;
    }
    return externalidlist;
  }
  loadGridData(selectedWell: any) {
    this.loading = true;
    this.dateList = [];
    this.gridData = [];
    this.clearGrid = false;
    this.externalId = {};
    this.unitobject = {};
    this.timelist = {};
    this.rpressurelow = {};
    this.rpressurehigh = {};
    this.pindexlow = {};
    this.pindexhigh = {};
    this.knlow = {};
    this.knhigh = {};
    this.knstepsize = {};
    this.kshigh = {};
    this.kslow = {};
    this.ksstepsize = {};
    this.pindexstepsize = {};
    this.rpressurestepsize = {};
    let well = selectedWell;
    let externalidlist: any[] = [];
    let item: any = {};

    let sources = [
      this.apiService.getInstancelist(this.theoreticalviewid, this.getFilter(this.theoreticalviewid, [well], "1_2"), "1_2"),
      this.apiService.getInstancelist(this.operationalviewid, this.getFilter(this.operationalviewid, [well]), this.version),
    ];
    if (this.selectedtab == 'ESP')
      sources.push(this.apiService.getInstancelist(this.espinputviewid, this.getFilter(this.espinputviewid, [well]), this.version));
    else if (this.selectedtab == 'JP')
      sources.push(this.apiService.getInstancelist(this.jpinputviewid, this.getFilter(this.jpinputviewid, [well]), this.version));
    sources.push(this.apiService.getInstancelist(this.calibrationprocessingviewid, this.getFilter(this.calibrationprocessingviewid, [well], this.calibrationprocessingversion), this.calibrationprocessingversion));
    sources.push(this.apiService.getInstancelist(this.calinputviewid, this.getFilter(this.calinputviewid, [well]), this.version));

    this.loading = true;
    this.subs.push(
      forkJoin(sources).subscribe((instancedata: any) => {
        item = instancedata[0]['items'][0]['properties'][environment.cogniteSpace][this.theoreticalviewid + "/1_2"];
        externalidlist = this.getWellTestDataExternalID(instancedata)
        this.getStatusFromCallibrationProcessing(instancedata[3], this.calibrationprocessingviewid, this.calibrationprocessingversion);
        const promise1: Promise<any>[] = [
          this.apiService.getTimeseriesDataAvgRange(externalidlist, this.filterStartDate.valueOf(), moment(this.filterEndDate).valueOf())
        ];
        Promise.all(promise1)
          .then((results: any[]) => {
            let result = results[0];
            result.forEach((data: any) => {
              let unit = data.unit || '';
              let externalId = data.externalId;
              data.datapoints.forEach((datapoint: any) => {
                let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
                let dataDate = moment(datapoint.timestamp);
                if (dataDate != this.filterStartDate && !dataDate.isAfter(this.filterStartDate))
                  return;
                let time = moment(datapoint.timestamp).format("HH:mm");
                if (!this.dateList.includes(date)) {
                  this.dateList.push(date);
                  this.timelist[date] = time;
                }
                let existingEntry = this.WTprocessedData.find(entry => entry.date === date);
                if (!existingEntry) {
                  existingEntry = { date };
                  this.WTprocessedData.push(existingEntry);
                }
                let propertyValue = this.roundValue(datapoint.average);
                switch (externalId) {
                  case this.externalId['theoreticalGOR']:
                    this.unitobject['gor'] = unit;
                    existingEntry['gor'] = propertyValue;
                    break;
                  case this.externalId['theoreticalWaterCut']:
                    this.unitobject['watercut'] = unit;
                    existingEntry['watercut'] = propertyValue;
                    break;
                  case this.externalId['theoreticalLiquidRate']:
                    this.unitobject['liquidrate'] = unit;
                    existingEntry['liquidrate'] = propertyValue;
                    break;
                  case this.externalId['tubingHeadPress']:
                    this.unitobject['thp'] = unit;
                    existingEntry['thp'] = propertyValue;
                    break;
                  case this.externalId['tubingHeadTemp']:
                    this.unitobject['tht'] = unit;
                    existingEntry['tht'] = propertyValue;
                    break;
                  case this.externalId['operatingFrequencyPmm']:
                    this.unitobject['frequency'] = unit;
                    existingEntry['frequency'] = propertyValue;
                    break;
                  case this.externalId['pumpIntakePress']:
                    this.unitobject['pip'] = unit;
                    existingEntry['pip'] = propertyValue;
                    break;
                  case this.externalId['pumpDischargePress']:
                    this.unitobject['pdp'] = unit;
                    existingEntry['pdp'] = propertyValue;
                    break;
                  case this.externalId['aAnnulusPress']:
                    this.unitobject['annuluspressure'] = unit;
                    existingEntry['annuluspressure'] = propertyValue;
                    break;
                  case this.externalId['powerFluidRateCorrected']:
                    this.unitobject['pfrate'] = unit;
                    existingEntry['pfrate'] = propertyValue;
                    break;
                }
              });
            });
            this.WTprocessedData.forEach((entry) => {
              ['gor', 'watercut', 'liquidrate', 'thp', 'tht', 'frequency', 'pip', 'pdp', 'annuluspressure', 'pfrate'].forEach(field => {
                if (!entry.hasOwnProperty(field)) entry[field] = '-';
              });
            });
            this.getToleranceData(instancedata[4], well);
          })
          .catch((error) => {
            this.loading = false;
            console.error("At least one promise rejected call1:", error);
          });

      }));
  }
  getStatusFromCallibrationProcessing(instanceData: any, calibrationprocessingviewid: string, calibrationprocessingversion: string) {
    debugger;
    const latestRecords = instanceData.items.reduce((acc: any, item: any) => {
      const properties = item['properties'][environment.cogniteSpace][calibrationprocessingviewid + "/" + calibrationprocessingversion];
      const wellTestStartDateTime = _.cloneDeep(moment(properties.wellTestStartDateTime).format("YYYY/MM/DD"));
      if (!acc[wellTestStartDateTime] || moment(properties.createdDateTime).isAfter(acc[wellTestStartDateTime].createdDateTime)) {
        acc[wellTestStartDateTime] = { ...properties, wellTestStartDateTime };
      }
      return acc;
    }, {});

    this.simulationData = Object.values(latestRecords).map((properties: any) => ({
      simulationStatus: properties.simulationStatus,
      simulationLog: properties.simulationLog,
      simulationAlarm: properties.simulationAlarm,
      date: moment(properties.wellTestStartDateTime).format("YYYY/MM/DD"),
      welltestdate: moment(properties.wellTestStartDateTime).format('DD-MM-YYYY')
    }));
    console.log(this.simulationData)
  }

  roundValue(value: number): string {
    if (value == null || value == undefined) {
      return '-';
    }
    return value.toFixed(2);
  }

  getCalibrationTolExternalIdlist(instancedata: any) {
    let externalidlist: any[] = [];
    let item = instancedata['items'][0]['properties'][environment.cogniteSpace][this.calinputviewid + "/" + this.version];
    externalidlist.push({ "externalId": item.reservoirPressureLow, "targetUnit": "pressure:psi" },
      { "externalId": item.reservoirPressureHigh, "targetUnit": "pressure:psi" },
      { "externalId": item.reservoirPressureStepSize }
    );
    this.externalId['reservoirPressureLow'] = item.reservoirPressureLow;
    this.externalId['reservoirPressureHigh'] = item.reservoirPressureHigh;
    this.externalId['reservoirPressureStepSize'] = item.reservoirPressureStepSize;
    if (this.selectedtab == this.tabOptions.JP) {
      externalidlist.push(
        { "externalId": item.productivityIndexLow }, { "externalId": item.productivityIndexHigh }, { "externalId": item.productivityIndexStepSize },
        { "externalId": item.knLow }, { "externalId": item.knHigh }, { "externalId": item.knStepSize },
        { "externalId": item.ksHigh }, { "externalId": item.ksLow }, { "externalId": item.ksStepSize }
      );
    }
    this.externalId['productivityIndexLow'] = item.productivityIndexLow;
    this.externalId['productivityIndexHigh'] = item.productivityIndexHigh;
    this.externalId['productivityIndexStepSize'] = item.productivityIndexStepSize;
    this.externalId['knLow'] = item.knLow;
    this.externalId['knHigh'] = item.knHigh;
    this.externalId['knStepSize'] = item.knStepSize;
    this.externalId['ksHigh'] = item.ksHigh;
    this.externalId['ksLow'] = item.ksLow;
    this.externalId['ksStepSize'] = item.ksStepSize;
    return externalidlist;
  }
  getToleranceData(instancedata: any, well: string) {
    this.gridData = [];
    this.valuedate = '';
    this.dateList = this.dateList
      .map(date => moment(date))
      .sort((a, b) => b.valueOf() - a.valueOf())
      .map(date => date.format("YYYY/MM/DD"));

    this.inputDatelist = [];
    let externalidlist = this.getCalibrationTolExternalIdlist(instancedata);
    this.apiService.getTimeseriesData(externalidlist).then((result: any) => {
      result.forEach((data: any) => {
        let unit = data.unit || '';
        let externalId = data.externalId;
        data.datapoints.forEach((datapoint: any) => {
          let date = moment(datapoint.timestamp).format("YYYY/MM/DD");
          if (!this.inputDatelist.includes(date)) {
            this.inputDatelist.push(date);
          }
          switch (externalId) {
            case this.externalId['reservoirPressureLow']:
              this.unitobject['rpressurelow'] = unit;
              this.rpressurelow[date] = datapoint.value;
              break;
            case this.externalId['reservoirPressureHigh']:
              this.unitobject['rpressurehigh'] = unit;
              this.rpressurehigh[date] = datapoint.value;
              break;
            case this.externalId['reservoirPressureStepSize']:
              this.unitobject['rpressurestepsize'] = unit;
              this.rpressurestepsize[date] = datapoint.value;
              break;
            case this.externalId['productivityIndexLow']:
              this.unitobject['pindexlow'] = unit;
              this.pindexlow[date] = datapoint.value;
              break;
            case this.externalId['productivityIndexHigh']:
              this.unitobject['pindexhigh'] = unit;
              this.pindexhigh[date] = datapoint.value;
              break;
            case this.externalId['productivityIndexStepSize']:
              this.unitobject['pindexstepsize'] = unit;
              this.pindexstepsize[date] = datapoint.value;
              break;
            case this.externalId['knLow']:
              this.unitobject['knlow'] = unit;
              this.knlow[date] = datapoint.value;
              break;
            case this.externalId['knHigh']:
              this.unitobject['knhigh'] = unit;
              this.knhigh[date] = datapoint.value;
              break;
            case this.externalId['knStepSize']:
              this.unitobject['knstepsize'] = unit;
              this.knstepsize[date] = datapoint.value;
              break;
            case this.externalId['ksHigh']:
              this.unitobject['kshigh'] = unit;
              this.kshigh[date] = datapoint.value;
              break;
            case this.externalId['ksLow']:
              this.unitobject['kslow'] = unit;
              this.kslow[date] = datapoint.value;
              break;
            case this.externalId['ksStepSize']:
              this.unitobject['ksstepsize'] = unit;
              this.ksstepsize[date] = datapoint.value;
              break;
          }
        });
      });

      if (this.inputDatelist.length > 0) {
        this.inputDatelist = this.inputDatelist.map(date => moment(date))
          .sort((a, b) => b.valueOf() - a.valueOf())
          .map(date => date.format("YYYY/MM/DD"));
        let fisrstdate = moment(this.dateList[0]);
        const index = this.inputDatelist.map(date => moment(date)).findIndex(date => date.valueOf() - fisrstdate.valueOf() <= 0);
        this.valuedate = this.inputDatelist[index];
        this.dateList.forEach((date) => {
          this.valuedate = !this.inputDatelist.includes(date) ? this.valuedate : date;
          this.rpressurelow[date] = this.rpressurelow[this.valuedate];
          this.rpressurehigh[date] = this.rpressurehigh[this.valuedate];
          this.rpressurestepsize[date] = this.rpressurestepsize[this.valuedate];
          this.pindexlow[date] = this.pindexlow[this.valuedate];
          this.pindexhigh[date] = this.pindexhigh[this.valuedate];
          this.pindexstepsize[date] = this.pindexstepsize[this.valuedate];
          this.knlow[date] = this.knlow[this.valuedate];
          this.knhigh[date] = this.knhigh[this.valuedate];
          this.knstepsize[date] = this.knstepsize[this.valuedate];
          this.kshigh[date] = this.kshigh[this.valuedate];
          this.kslow[date] = this.kslow[this.valuedate];
          this.ksstepsize[date] = this.ksstepsize[this.valuedate];
        });
      }
      this.getGriddata(well);
    })
  }

  getGriddata(well: string) {
    this.loading = true;
    this.gridData = [];
    let id = 0;
    this.stopIntervals();
    let wellData = _.cloneDeep(this.WTprocessedData);
    wellData.forEach((wtData, index: number) => {
      const simData = this.simulationData.find((sim: any) => sim.date === wtData.date);
      if (simData) {
        wtData.status = simData.simulationStatus;
        wtData.logtext = simData.simulationLog;
        wtData.simulationAlarm = simData.simulationAlarm;
      } else {
        wtData.status = '';
        wtData.logtext = '';
        wtData.simulationAlarm = '';
      }
      wtData.id = index;
      wtData.well = well;
      wtData.date_ts = moment(wtData.date).valueOf();
      wtData.rpressurelow = this.rpressurelow[wtData.date] != undefined ? this.rpressurelow[wtData.date].toFixed(2) : '-';
      wtData.rpressurehigh = this.rpressurehigh[wtData.date] != undefined ? this.rpressurehigh[wtData.date].toFixed(2) : '-';
      wtData.rpressurestepsize = this.rpressurestepsize[wtData.date] != undefined ? Math.round(this.rpressurestepsize[wtData.date]) : '-';
      wtData.pindexlow = this.pindexlow[wtData.date] != undefined ? this.pindexlow[wtData.date].toFixed(2) : '-';
      wtData.pindexhigh = this.pindexhigh[wtData.date] != undefined ? this.pindexhigh[wtData.date].toFixed(2) : '-';
      wtData.pindexstepsize = this.pindexstepsize[wtData.date] != undefined ? Math.round(this.pindexstepsize[wtData.date]) : '-';
      wtData.knlow = this.knlow[wtData.date] != undefined ? this.knlow[wtData.date].toFixed(2) : '-';
      wtData.knhigh = this.knhigh[wtData.date] != undefined ? this.knhigh[wtData.date].toFixed(2) : '-';
      wtData.knstepsize = this.knstepsize[wtData.date] != undefined ? this.knstepsize[wtData.date].toFixed(2) : '-';
      wtData.kshigh = this.kshigh[wtData.date] != undefined ? this.kshigh[wtData.date].toFixed(2) : '-';
      wtData.kslow = this.kslow[wtData.date] != undefined ? this.kslow[wtData.date].toFixed(2) : '-';
      wtData.ksstepsize = this.ksstepsize[wtData.date] != undefined ? this.ksstepsize[wtData.date].toFixed(2) : '-';
      wtData.date = moment(wtData.date).format("DD-MM-YYYY");
      if (this.selectedtab == 'ESP')
        wtData.disable = wtData.status == 'Queued' || wtData.status == 'InProgress' || wtData.liquidrate == '-' || wtData.liquidrate == '0' || wtData.liquidrate == '0.00' || wtData.thp == '-' || wtData.frequency == '-' || wtData.pip == '-' || wtData.pdp == '-' || wtData.rpressurelow == '-' || wtData.rpressurehigh == '-' || wtData.rpressurestepsize == '-' ? true : false;
      else if (this.selectedtab == 'JP')
        wtData.disable = wtData.status == 'Queued' || wtData.status == 'InProgress' || wtData.gor == '-' || wtData.watercut == '-' || wtData.liquidrate == '-' || wtData.liquidrate == '0' || wtData.liquidrate == '0.00' || wtData.thp == '-' || wtData.annuluspressure == '-' || wtData.pfrate == '-'
          || wtData.rpressurelow == '-' || wtData.rpressurehigh == '-' || wtData.rpressurestepsize == '-' || wtData.pindexlow == '-' || wtData.pindexhigh == '-' || wtData.knlow == '-' || wtData.knhigh == '-' || wtData.knstepsize == '-' || wtData.kshigh == '-' || wtData.kslow == '-' || wtData.ksstepsize == '-' || wtData.pindexstepsize == '-' ? true : false;
    });
    if (this.clearGrid) {
      this.gridData = [];
      this.clearGrid = false;
    } else {
      this.directive.skip = 0;
      this.gridData = addSortableDates(wellData, this.kendoGrid, null, 'DD-MM-YYYY');
      this.startIntervals(well);
    }
    this.loading = false;
  }

}
