<div class="content-left-margin">
  <app-hierarchy #hierarchyComponent (onFieldChange)="onfieldChange($event)" [showDateRange]="true" [showClearButton]="true" (onWellPadChange)="onfilterwellPadChange($event)" [screeId]="'InjectorMonitoringTol'" [getListofAllWellsUnderField]="true" [selectedWellType]="'Injector'" (onWellchange)="onfilterwellChange($event)" (onclear)="clear()" (ondatechange)="onDateRangeChange($event)"></app-hierarchy>
</div>
<div class="page-content">
  <div>
    <div>
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Injector Monitoring Tolerance</mat-card-title>
          <div class="header-right-tools">
            <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base add-button" type="button" (click)="onAddrecordClick()">
              <mat-icon svgIcon="add"></mat-icon>
              <span>Add</span>
            </button>
            <button slb-secondary-button class="ddls-medium dls-secondary-button dls-button-base delete-button" type="button" (click)="ondelete(undefined)" [disabled]="selectedrows === undefined || selectedrows?.length === 0">
              <mat-icon svgIcon="delete"></mat-icon>
              <span>Delete</span>
            </button>
          </div>
        </mat-card-header>
        <mat-card-content style="margin-top: 10px;">
          <kendo-grid appGridAutoTooltip [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="10" [selectable]="true" kendoGridSelectBy="sequence" [(selectedKeys)]="selectedrows" (selectionChange)="selectionchange($event)" [filterable]="true" [sortable]="true">
            <kendo-grid-checkbox-column [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
            <ng-template kendoGridToolbarTemplate>
              <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [appExportToExcel]="kendoGrid" [exportColumnRules]="exportColumnRules" [data]="gridData">
                  <mat-icon svgIcon="excel" aria-hidden="false" aria-label="Excel" title="Download to Excel"></mat-icon>
                </button>
                <button type="button" kendoGridPDFCommand>
                  <mat-icon svgIcon="pdf" aria-hidden="false" aria-label="PDF" title="Download to PDF"></mat-icon>
                </button>
              </div>
            </ng-template>
            <kendo-grid-column field="id" title="id" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="sequence" title="sequence" [hidden]="true"></kendo-grid-column>
            <kendo-grid-column field="well" title="Well" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="startdate_ts" title="Start Date Time" [filterable]="true">
              <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.startdate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="targetPolymerConentration" title="Target Polymer Concentration (ppm)" [filterable]="false"></kendo-grid-column>
            <kendo-grid-column field="conentrationDeviation" title="Concentration Deviation(%)" [filterable]="false"></kendo-grid-column>
            <kendo-grid-column field="viscocityDeviation" title="Viscocity Deviation(%)" [filterable]="false"></kendo-grid-column>
            <kendo-grid-column title="Action" [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                <a slb-icon-button routerLink="." title="Edit" (click)="onEditecordClick(dataItem)">
                  <mat-icon [ngStyle]="{'color':'#6BA7FF'}" svgIcon="edit-1"></mat-icon>
                </a>
                <a slb-icon-button routerLink="." title="delete" (click)="ondelete(dataItem)">
                  <mat-icon [ngStyle]="{'color':'#FF6B6B'}" svgIcon="delete"></mat-icon>
                </a>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="Injector Monitoring Tolerance.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Injector Monitoring Tolerance.pdf" [allPages]="true">
              <kendo-grid-column field="well" title="Well"></kendo-grid-column>
              <kendo-grid-column field="startdate" title="Start Date Time"></kendo-grid-column>
              <kendo-grid-column field="targetPolymerConentration" title="Target Polymer Concentration (ppm)"></kendo-grid-column>
              <kendo-grid-column field="conentrationDeviation" title="Concentration Deviation(%)"></kendo-grid-column>
              <kendo-grid-column field="viscocityDeviation" title="Viscocity Deviation(%)"></kendo-grid-column>
            </kendo-grid-pdf>
          </kendo-grid>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #addRecordDialog>
  <mat-dialog-content style="height: auto; width: 500px;" class="update-range-dialog-content">
    <mat-card-title class="headerLabel"> {{ formMode === 'Add' ? 'Add New' : 'Update' }} Record</mat-card-title>
    <slb-modal-close-button mat-dialog-close style="float: right;" buttonType="Icon"></slb-modal-close-button>
    <div class="form-container" style="margin-top: 30px;">
      <form (ngSubmit)='save(formgroup)' [formGroup]='formgroup'>
        <slb-date-picker hint="" [(value)]="startdate" label="Start Date Time" [disabled]="false" [maxValue]="todaysdate" [disabled]="formMode === 'Edit'"></slb-date-picker>
        <br>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Field</mat-label>
          <mat-select placeholder="Select" #input [panelClass]="'dls-panel'" formControlName="field" (selectionChange)="onformfieldChange($event)" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let field of formfieldList" [value]="field.externalId">{{ field.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Well Pad</mat-label>
          <mat-select placeholder="Select" formControlName='wellpad' #input [panelClass]="'dls-panel'" (selectionChange)="onformwellpadChange($event)" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let wellpad of formwellPadList" [value]="wellpad.externalId">{{ wellpad.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Well</mat-label>
          <mat-select placeholder="Select" formControlName='well' #input [panelClass]="'dls-panel'" (selectionChange)="onformwellChange($event)" [hideSingleSelectionIndicator]="true">
            <mat-option *ngFor="let well of formwellList" [value]="well.externalId">{{ well.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Target Polymer Conentration</mat-label>
          <input type="number" matInput formControlName="targetPolymerConentration"><span>ppm</span>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Conentration Deviation</mat-label>
          <input type="number" matInput formControlName="conentrationDeviation"><span>%</span>
        </mat-form-field>
        <div class="space-div"></div>
        <mat-form-field slbFormField class="form-field" appearance="outline" #field>
          <mat-label>Viscocity Deviation</mat-label>
          <input type="number" matInput formControlName="viscocityDeviation"><span>%</span>
        </mat-form-field>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="update-range-dialog-actions">
    <button slb-secondary-button class="dialog-btn-border" matDialogClose="yes">Cancel</button>
    <button slb-button [disabled]="!formgroup.valid" color="primary" (click)="save(formgroup)" matDialogClose="yes">{{ formMode === 'Add' ? 'Create' : 'Update' }}</button>
  </mat-dialog-actions>
</ng-template>
