import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { CdfSpaceView } from 'src/app/common-component/types/cdf-space-view';
import { CognitApiService } from 'src/app/services/cognit-api.service';
import { WellBookService } from '../well-book.service';
import { NodeAndEdgeCollectionResponseV3Response, Document } from '@cognite/sdk/dist/src';
import moment from 'moment';
import { File } from 'src/app/common-component/types/file';

declare interface FileInstance {
  file: string;
  date: string;
  type: string;
}

@Component({
  selector: 'app-dyna-card',
  templateUrl: './dyna-card.component.html',
  styleUrls: ['./dyna-card.component.css'],
})
export class DynaCardComponent implements AfterViewInit {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;

  public loading: boolean = false;
  public gridData: any[] = [];
  public pdfUrl: string | undefined;
  public image: { name: string, url: string } | null = null;
  public showPdfFullScreen: boolean = false;
  public selectedRows: number[] = [];

  private readonly dynaCdfDef: CdfSpaceView | null = null;
  private readonly echoCdfDef: CdfSpaceView | null = null;
  private wellSelected: string | null;

  constructor(private readonly apiService: CognitApiService, private readonly wbService: WellBookService) {
    this.dynaCdfDef = this.wbService.getSpaceDefinition('dynacard');
    this.echoCdfDef = this.wbService.getSpaceDefinition('echometer');
  }

  ngAfterViewInit(): void {
    if (this.hierarchyComponent && this.wbService.getWell())
      this.hierarchyComponent.onFilterWellChange(this.wbService.getWell(), true);
  }

  public togglePdfViewerSize() {
    this.showPdfFullScreen = !this.showPdfFullScreen;
  }

  public handleWellChange(data: any) {
    this.wellSelected = data?.event?.value || null;
    this.wbService.setWell(data?.event ?? null);

    this.loadData();
  }

  public clearData(cleanWellSeletion = true) {
    if (cleanWellSeletion) {
      this.wellSelected = null;
      this.wbService.setWell(null);
    }

    this.clearViewerSection();
  }

  public downloadFile(file: File) {
    return new Promise((res: any) => {
      if (file?.id) {
        this.getDocumentUrls(Number(file?.id), (urls: string[]) => {
          if (urls?.length > 0) {
            for (const url of urls) {
              this.triggerDownloadFile(url, file.name);
            }
          }

          setTimeout(() => res(), 500);
        });
      } else {
        res();
      }
    });
  }

  public previewFile(file: File) {
    this.clearViewerSection();

    if ((file.type === 'PDF' || file.type === 'Image') && file?.id) {
      this.getDocumentUrls(Number(file?.id), (urls: string[]) => {
        if (urls?.length > 0) {
          if (file.type === 'PDF') {
            this.pdfUrl = urls[0];
          } else if (file.type === 'Image') {
            this.image = { name: file.name, url: urls[0] };
          }
        }
      });
    }
  }

  public async downloadAllFiles() {
    if (this.selectedRows.length > 0 && this.gridData.length > 0) {
      for (const row of this.selectedRows) {
        const file = this.gridData.find(e => e.id === row);

        if (file)
          await this.downloadFile(file);
      }
    }
  }

  private clearViewerSection() {
    this.pdfUrl = undefined;
    this.image = null;
  }

  private getDocumentUrls(id: number, handleDocumentUrls: (urls: string[]) => void) {
    this.loading = true;

    this.apiService.getDocumentDownloadUrl(id).subscribe({
      next: (data: any) => {
        this.loading = false;
        let urls: string[] = [];

        if (data?.items?.length > 0) {
          urls = data.items.map((e: any) => e.downloadUrl);

          handleDocumentUrls(urls);
        }
      },
      error: err => {
        console.error(err);
        this.loading = false;
      },
    });
  }

  private async triggerDownloadFile(url: string, name: string) {
    if (url) {
      const outsideRes = await fetch(url);
      const blob = await outsideRes.blob();
      const internalUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = internalUrl;

      if (name)
        link.download = name;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  }

  private loadData() {
    this.clearData(false);

    if (!this.dynaCdfDef || !this.echoCdfDef || !this.wellSelected)
      return;

    this.loading = true;

    const promises = [
      this.fetchData(this.dynaCdfDef, 'dynacardReport', 'dynacardReportFileDate'),
      this.fetchData(this.echoCdfDef, 'echometerReport', 'echometerReportFileDate'),
    ];

    Promise.all(promises).then(data => {
      const dynaCardData = data[0];
      const echometerData = data[1];

      this.loadFiles(dynaCardData.concat(echometerData));
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.loading = false;
    })
  }

  private fetchData(cdfSpace: CdfSpaceView, propertyName: string, propertyDate: string): Promise<any[]> {
    return new Promise(res => {
      const filter: any = this.wbService.getCdfFilter(cdfSpace, 'well', ['node', 'externalId'], [this.wellSelected]);

      this.apiService.getInstancelist(cdfSpace.id, filter, cdfSpace.version, cdfSpace.space).subscribe({
        next: (data: NodeAndEdgeCollectionResponseV3Response) => {
          let dynaCardData: any = [];

          if (data?.items?.length > 0 && cdfSpace) {
            dynaCardData = data.items[0].properties?.[cdfSpace.space]?.[cdfSpace.fullName];
            dynaCardData = this.setFileData(dynaCardData, cdfSpace.id, propertyName, propertyDate);
          }

          res(dynaCardData);
        },
        error: err => {
          console.error(err);
          res([]);
        },
      });
    });
  }

  private setFileData(data: any, type: string, filePropertyName: string, filePropertyDate: string): FileInstance[] {
    const convertedData: FileInstance[] = [];

    if (data && filePropertyName && filePropertyDate) {
      const files = data[filePropertyName];
      const dates = data[filePropertyDate];

      for (const i in files) {
        convertedData.push({ type: type, file: files[i], date: dates[i] });
      }
    }

    return convertedData;
  }

  private loadFiles(fileList: FileInstance[]) {
    this.gridData = [];

    this.clearViewerSection();

    if (fileList.length > 0) {
      this.loading = true;

      const filter: any = this.wbService.getCdfDocsFilter(['externalId'], fileList.map(e => e.file));

      this.apiService.getDocumentList(filter).subscribe({
        next: (data: any) => {
          this.loading = false;
          let docs: any = [];

          if (this.apiService.isListResponse(data)) {
            docs = data.items ?? [];
          } else {
            docs = [data];
          }

          this.processFiles(docs, fileList);
        },
        error: err => {
          console.error(err);
          this.loading = false;
        },
      });
    }
  }

  private processFiles(documents: Document[], fileList: FileInstance[]) {
    if (documents?.length > 0) {
      this.gridData = documents.map(document => {
        const file = fileList.find(e => e.file === document.externalId);
        let fileDate = file?.date;

        if (!file)
          fileDate = moment(document.createdTime).utcOffset(330).format('YYYY-MM-DD HH:mm');

        return {
          id: document.id,
          externalId: document.externalId ?? '',
          name: document.sourceFile?.name ?? '-',
          date: fileDate,
          type: document.type ?? '',
          extension: document.extension ?? '',
          source: file?.type,
        };
      });
    }
  }

}
