import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {

  public loggedIn = false;

  constructor() {

  }

  public loginSuccessEvent(): void {
    this.loggedIn = true;
  }

}
