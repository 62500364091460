import { Component, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import moment, { Moment } from 'moment';
import { CogniteAuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { TabOptionsEnum, ESP, JP } from '../calibrationmodels';
import { HierarchyService } from '../../services/hierarchy.service';
import { Subscription, forkJoin } from 'rxjs';
import { DateRange } from '@slb-dls/angular-material/date-range-picker';
import { CalibrationSumaryModel } from '../calibrationmodels'
import { SlbMessage, SlbSeverity } from '@slb-dls/angular-material/notification';
import { MessageService } from '@slb-dls/angular-material/notification';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { error } from 'console';
import _ from 'lodash';
import { addSortableDates } from 'src/app/utils/sort-dates';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';
import { CalibrationService } from '../calibration-service.service';
import { Convert } from '@slb-psdc/uom_converter';

@Component({
  selector: 'app-calibration-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class CalibrationSummaryComponent {
  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild(DataBindingDirective) directive: any;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public tabOptions = TabOptionsEnum;
  public selectedtab = this.tabOptions.ESP;
  public unitobject: any = {};
  private subs: Subscription[] = [];
  public fieldList: any;
  public wellList: any;
  public wellPadList: any;
  public filterWellList: any;
  public selectedwelllist: any;
  public selectedField: string;
  public selectedWell: string | undefined;
  public selectedWellPad: string;
  public startdate: any;
  public enddate: any;
  public filterStartDate: any;
  public filterEndDate: any;
  public range: DateRange<Moment>;
  public loading: boolean = false;

  public externalId: any = {};
  public dateList: any[] = [];
  public timelist: any = {};
  public gridData: CalibrationSumaryModel[] = [];
  public runDate: string = '';
  public liquidrate: any = {};
  public inputliquidrate: any = {};
  public pip: any = {};
  public inputpip: any = {};
  public pi: any = {};
  public frfactor: any = {};
  public holdfactor: any = {};
  public pumpwfactor: any = {};
  public rpressure: any = {};
  public ks: any = {};
  public kn: any = {};
  public watercut: any = {};
  public gor: any = {};
  public thp: any = {};
  public frequency: any = {};
  public annuluspressure: any = {};
  public pfrate: any = {};
  public pdp: any = {};
  public inputpdp: any = {};
  public calrundate: any = {};
  public finalGridData: any[] = [];

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Well Test Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY HH:mm') },
  ];

  constructor(
    private cogniteAuthService: CogniteAuthService,
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,private calService:CalibrationService) {
    this.selectedtab = this.calService.selectedTab;
  }

  ngOnInit() {
    this.range = ({
      startDate: moment(moment()).add(-1, 'days'),
      endDate: moment()
    });
    this.subs.push(this.hierarchyService.getFieldData().subscribe((data: any) => {
      this.fieldList = data;
    }));

    // this.subs.push(this.hierarchyService.getAllWells().subscribe((data: any) => {
    //   this.selectedwelllist = data;
    // }));
   // this.loadGridData() TODO on load show ALL option and then all wells for same

  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  clear() {
    this.wellPadList = [];
    this.filterWellList = [];
    this.selectedwelllist = [];
    this.gridData = [];
    this.selectedField = '';
    this.selectedWell = '';
    this.selectedWellPad = '';
  }

  onDateSelected(event: any) {

    this.filterStartDate = moment(event.event.startDate);
    if (moment(this.filterStartDate).format("DD-MM-YYYY") != moment(this.filterStartDate).utc().format("DD-MM-YYYY")) {
      this.filterStartDate = moment(this.filterStartDate).add(1, 'days');
    }
    this.filterEndDate = moment(event.event.endDate);
    if (moment(this.filterEndDate).format("DD-MM-YYYY") != moment(this.filterEndDate).utc().format("DD-MM-YYYY")) {
      this.filterEndDate = moment(this.filterEndDate).add(1, 'days');
    }
    if (this.filterStartDate != undefined && this.filterEndDate != undefined &&
       (this.selectedwelllist != undefined && this.selectedwelllist != '')) {
      this.loadGridData();
    }
  }

  onfieldChange(event: any) {
    this.gridData = [];
    this.filterWellList = [];
    this.selectedWell = ""
    this.selectedField = event.event.value;
    this.wellPadList = event.wellPadList;
    this.selectedWell = "";
    this.selectedWellPad = "";
    this.selectedwelllist = event.allFieldwells;
    this.loadGridData();
  }

  onfilterwellPadChange(event: any) {
    this.selectedWellPad = event.event.value;
    this.filterWellList = event.well;
    this.selectedwelllist = event.well; //this.filterWellList.map((x: any) => x.externalId);
    this.selectedWell = '';
    this.gridData = [];
    this.loadGridData();
  }

  onFilterWellChange(event: any) {
    this.wellPadList = event.wellPadList;
    this.filterWellList = event.well;
    this.selectedWell = event.event.value;
    this.selectedField = event.selectedField;
    this.selectedWellPad = event.selectedWellPad;

    if (this.filterWellList == undefined || this.filterWellList.length == 0)
      this.filterWellList = event.allFieldwells;

    this.selectedwelllist = this.filterWellList.filter((x: any) => x.externalId == event.event.value);
    this.selectedWell = this.selectedwelllist[0].externalId;
    if (this.filterStartDate != undefined &&
      this.filterEndDate != undefined && this.selectedWell != undefined)
       { this.loadGridData(); }
  }

  getWellwithPumpType(value: string) {
    let lifttypeTimeserieslist: any[] = [];
    let listofallwell: { externalId: string; Name: string; liftType: string }[] = []
    let lifttypedata: any[] = [];
    this.loading = true;
    this.subs.push(this.hierarchyService.getWelldataWithProperties(value).subscribe((data: any) => {
      let version = environment.cogniteSDMVersion
      let space = environment.cogniteSpace;
      data.forEach((item: any) => {
        let properties = item.properties;
        if (properties !== undefined) {
          let liftType = properties[space]["Well/" + version].liftType;
          listofallwell.push({ externalId: item.externalId, Name: properties[space]["Well/" + version].name.toString(), liftType: String(liftType) })
          if (liftType != undefined)
            lifttypeTimeserieslist.push({ "externalId": String(liftType) })
        }
      });
      this.apiService.getLatestTimeseriesData(lifttypeTimeserieslist).then((timeseriesdatapoints: any) => {
        this.loading = false;
        timeseriesdatapoints.forEach((datapoint: any) => {
          if (datapoint.datapoints.length > 0) {
            datapoint.datapoints.forEach((data: any) => {
              if (data != undefined && String(data.value).toUpperCase() == this.selectedtab) {
                lifttypedata.push(datapoint.externalId)
              }
            })
          }
        })
        this.filterWellList = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
        this.selectedwelllist = listofallwell.filter((item: any) => lifttypedata.includes(item.liftType));
      })
    }));
  }


  onTabChange($event: any) {
    this.selectedtab = $event.value;
    this.calService.selectedTab = this.selectedtab;
    this.clear();
    this.hierarchyComponent.clear();
  }

  getFilter(viewId: string, wellid: string[],version = environment.cogniteSDMVersion) {
    let filter = {
      "nested": {
        "scope": [environment.cogniteSpace, viewId + "/" + version, "well"],
        "filter": {
          "and": [{
            "in": {
              "property": ["node", "externalId"],
              values: wellid
            }
          },
          ]
        }
      }
    }

    return filter;
  }

  loadGridData() {
    if (this.selectedwelllist != undefined && this.selectedwelllist != "") {
    this.dateList = [];
    this.gridData = [];
    this.runDate = '';
    this.externalId = {};
    this.unitobject = {};
    this.timelist = {};
    this.liquidrate = {};
    this.inputliquidrate = {};
    this.pip = {};
    this.inputpip = {};
    this.pi = {};
    this.frfactor = {};
    this.holdfactor = {};
    this.pumpwfactor = {};
    this.rpressure = {};
    this.ks = {};
    this.kn = {};
    this.watercut = {};
    this.gor = {};
    this.thp = {};
    this.frequency = {};
    this.annuluspressure = {};
    this.pfrate = {};
    this.pdp = {};
    this.inputpdp = {};


    let version = environment.cogniteSDMVersion;

    let caloutputviewid = 'WellCalibrationOutputs'
    let operationalviewid = 'WellOperationalInputsHf'
    let theoreticalviewid = 'WellTheoreticalData' 
    let espinputviewid = 'ESPInputs' 
    let jpinputviewid = 'JPInputs' 
    let wellcalibrationinputviewid = "WellCalibrationInputsUsed"

    let well = this.selectedwelllist.map((x: any) => x.externalId);
    let externalidlist: any[] = [];
    let item: any = {};
    let results: any[] = [];
    this.finalGridData =[];
    let sources = [
      this.apiService.getInstancelistWithcursorData(caloutputviewid, this.getFilter(caloutputviewid, well), version),
      //this.apiService.getInstancelist(theoreticalviewid, this.getFilter(theoreticalviewid, well,"1_2"),  "1_2"),
      //this.apiService.getInstancelist(operationalviewid, this.getFilter(operationalviewid, well), version),
      //this.apiService.getInstancelist(espinputviewid, this.getFilter(espinputviewid, well), version),
      //this.apiService.getInstancelist(jpinputviewid, this.getFilter(jpinputviewid, well), version),
      this.apiService.getInstancelistWithcursorData(wellcalibrationinputviewid, this.getFilter(wellcalibrationinputviewid, well), version)
    ];

    this.loading = true;
    forkJoin(sources).subscribe(async (instancedata: any) => {
      item = instancedata[0]['items'];
      const wellTsDict: Record<string, string> = {};
      item.forEach((element: any) => {
        let data = element['properties'][environment.cogniteSpace][caloutputviewid + "/" + version];
        if (data.property == 'EstimatedLiquidRate' && (data.timeseriesExtId != undefined)) {
          this.runDate = moment(element.createdTime).format("YYYY-MM-DD HH:mm");
          externalidlist.push({ "externalId": data.timeseriesExtId,"targetUnit":"volume_flow_rate:bbl_us-per-day"   });
          wellTsDict[data.well.externalId+'_EstimatedLiquidRate'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedPumpWearFactor' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
          wellTsDict[data.well.externalId+'_EstimatedPumpWearFactor'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedPip' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId , "targetUnit":"pressure:psi" });
          wellTsDict[data.well.externalId+'_EstimatedPip'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedProductivityIndex' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
          wellTsDict[data.well.externalId+'_EstimatedProductivityIndex'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedFrictionFactor' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
          wellTsDict[data.well.externalId+'_EstimatedFrictionFactor'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedHoldupFactor' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
         wellTsDict[data.well.externalId+'_EstimatedHoldupFactor'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedReservoirPressure' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId , "targetUnit":"pressure:psi" });
          wellTsDict[data.well.externalId+'_EstimatedReservoirPressure'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedKs' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
          wellTsDict[data.well.externalId+'_EstimatedKs'] = data.timeseriesExtId;
        }
        if (data.property == 'EstimatedKn' && (data.timeseriesExtId != undefined)) {
          externalidlist.push({ "externalId": data.timeseriesExtId });
          wellTsDict[data.well.externalId+'_EstimatedKn'] = data.timeseriesExtId;
        }
      });

      // if another input data is selected
      // item = {};
      // //externalidlist = [];
      // item = instancedata[1]['items'][0]['properties'][environment.cogniteSpace][wellcalibrationinputviewid + "/" + version];
      // if (item.gor != undefined) {
      //     externalidlist.push({ "externalId": item.gor });
      //     this.externalId['theoreticalGOR'] = item.gor;
      //   }
      //   if (item.waterCut != undefined) {
      //     externalidlist.push({ "externalId": item.waterCut });
      //     this.externalId['theoreticalWaterCut'] = item.waterCut;
      //   }
      //   if (item.liquidRate != undefined) {
      //     externalidlist.push({ "externalId": item.liquidRate });
      //     this.externalId['theoreticalLiquidRate'] = item.liquidRate;
      //   }

     /* let items = instancedata[1].items
       items.forEach((wellitem:any) => {
          let item = wellitem['properties'][environment.cogniteSpace][theoreticalviewid + "/" + "1_2"];
          if (!(item.theoreticalGOR == undefined && item.theoreticalWaterCut == undefined && item.theoreticalLiquidRate == undefined)) {
            if (item.theoreticalGOR != undefined) {
              externalidlist.push({ "externalId": item.theoreticalGOR });
              this.externalId['theoreticalGOR'] = item.theoreticalGOR;
              wellTsDict[item.well.externalId+"_theoreticalGOR"]=item.theoreticalGOR;
            }
            if (item.theoreticalWaterCut != undefined) {
              externalidlist.push({ "externalId": item.theoreticalWaterCut });
              wellTsDict[item.well.externalId+'_theoreticalWaterCut'] = item.theoreticalWaterCut;
            }
            if (item.theoreticalLiquidRate != undefined) {
              externalidlist.push({ "externalId": item.theoreticalLiquidRate });
              wellTsDict[item.well.externalId+'_theoreticalLiquidRate'] = item.theoreticalLiquidRate;
            }
          }
      });

      items = instancedata[2].items
      items.forEach((wellitem:any) => {
        let item = wellitem['properties'][environment.cogniteSpace][operationalviewid + "/" + version];
        if (!(item.tubingHeadPress == undefined)) {
          externalidlist.push({ "externalId": item.tubingHeadPress,  "targetUnit":"pressure:psi" });
          wellTsDict[item.well.externalId+'_tubingHeadPress'] = item.tubingHeadPress;
        }
      })

      items = instancedata[3].items
      items.forEach((wellitem:any) => {
        if (wellitem != undefined)
        {
          item = wellitem['properties'][environment.cogniteSpace][espinputviewid + "/" + version];
          if (!(item.operatingFrequency == undefined && item.pumpIntakePress == undefined && item.pumpDischargePress == undefined)) {

            if (item.operatingFrequency != undefined) {
              externalidlist.push({ "externalId": item.operatingFrequency, "targetUnit":"frequency:hz" });
              wellTsDict[item.well.externalId+'_operatingFrequency'] = item.operatingFrequency;
            }
            if (item.pumpIntakePress != undefined) {
              externalidlist.push({ "externalId": item.pumpIntakePress, "targetUnit":"pressure:psi" });
              wellTsDict[item.well.externalId+'_pumpIntakePress'] = item.pumpIntakePress;
            }
            if (item.pumpDischargePress != undefined) {
              externalidlist.push({ "externalId": item.pumpDischargePress, "targetUnit":"pressure:psi"});
              wellTsDict[item.well.externalId+'_pumpDischargePress'] = item.pumpDischargePress;
            }
          }

        }
      })


      items = instancedata[4].items;
      items.forEach((wellitem:any) => {
        if (wellitem != undefined)
        {
          item = wellitem['properties'][environment.cogniteSpace][jpinputviewid + "/" + version];
          if (!(item.aAnnulusPress == undefined && item.powerFluidRateCorrected == undefined)) {
            if (item.aAnnulusPress != undefined) {
              externalidlist.push({ "externalId": item.aAnnulusPress,"targetUnit":"pressure:psi" });
              wellTsDict[item.well.externalId+'_aAnnulusPress'] = item.aAnnulusPress;
            }
            if (item.powerFluidRateCorrected != undefined) {
              externalidlist.push({ "externalId": item.powerFluidRateCorrected ,"targetUnit":"volume_flow_rate:bbl_us-per-day" });
              wellTsDict[item.well.externalId+'_powerFluidRateCorrected'] = item.powerFluidRateCorrected;
            }
          }
        }
      }) */

      let items = instancedata[1].items
      console.log(items);
      items.forEach((wellitem:any) => {
        let item = wellitem['properties'][environment.cogniteSpace][wellcalibrationinputviewid + "/" + version];
        if ( item.liquidRate != undefined) {
          externalidlist.push({ "externalId": item.liquidRate });
          wellTsDict[item.well.externalId+'_theoreticalLiquidRate'] = item.liquidRate;
        }
        if ( item.waterCut != undefined) {
          externalidlist.push({ "externalId": item.waterCut });
          wellTsDict[item.well.externalId+'_theoreticalWaterCut'] = item.waterCut;
        }
        if ( item.gor != undefined) {
          externalidlist.push({ "externalId": item.gor });
          wellTsDict[item.well.externalId+'_theoreticalGOR'] = item.gor;
        }
        if ( item.tubingHeadPress != undefined) {
          externalidlist.push({ "externalId": item.tubingHeadPress });
          wellTsDict[item.well.externalId+'_tubingHeadPress'] = item.tubingHeadPress;
        }
        if ( item.pumpIntakePress != undefined) {
          externalidlist.push({ "externalId": item.pumpIntakePress });
          wellTsDict[item.well.externalId+'_pumpIntakePress'] = item.pumpIntakePress;
        }
        if ( item.pumpDischargePress != undefined) {
          externalidlist.push({ "externalId": item.pumpDischargePress });
          wellTsDict[item.well.externalId+'_pumpDischargePress'] = item.pumpDischargePress;
        }
        if ( item.frequency != undefined) {
          externalidlist.push({ "externalId": item.frequency });
          wellTsDict[item.well.externalId+'_operatingFrequency'] = item.frequency;
        }
        if ( item.aAnnulusPress != undefined) {
          externalidlist.push({ "externalId": item.aAnnulusPress });
          wellTsDict[item.well.externalId+'_aAnnulusPress'] = item.aAnnulusPress;
        }
        if ( item.pfRate != undefined) {
          externalidlist.push({ "externalId": item.pfRate });
          wellTsDict[item.well.externalId+'_powerFluidRateCorrected'] = item.pfRate;
        }
      })

      
     /* if (instancedata[1]['items'][0] != undefined) {
        
         item = instancedata[4]['items'][0]['properties'][environment.cogniteSpace][jpinputviewid + "/" + version];
         if (!(item.aAnnulusPress == undefined && item.powerFluidRateCorrected == undefined)) {
          if (item.aAnnulusPress != undefined) {
             externalidlist.push({ "externalId": item.aAnnulusPress });
             this.externalId['aAnnulusPress'] = item.aAnnulusPress;
           }
           if (item.powerFluidRateCorrected != undefined) {
             externalidlist.push({ "externalId": item.powerFluidRateCorrected });
             this.externalId['powerFluidRateCorrected'] = item.powerFluidRateCorrected;
           }
         }
       
      } */
      results = await this.get_ts_data_in_parallel(externalidlist)
      well.forEach((w:any)=>{

        this.dateList = [];
        this.gridData = [];
        this.runDate = '';
        this.externalId = {};
        this.unitobject = {};
        this.timelist = {};
        this.liquidrate = {};
        this.inputliquidrate = {};
        this.pip = {};
        this.inputpip = {};
        this.pi = {};
        this.frfactor = {};
        this.holdfactor = {};
        this.pumpwfactor = {};
        this.rpressure = {};
        this.ks = {};
        this.kn = {};
        this.watercut = {};
        this.gor = {};
        this.thp = {};
        this.frequency = {};
        this.annuluspressure = {};
        this.pfrate = {};
        this.pdp = {};
        this.inputpdp = {};

      results.forEach((data: any) => {
        //if (data.externalId.slice(0, w.length) == w)
        {
        if (data.externalId== wellTsDict[w+'_EstimatedLiquidRate']) {
          this.unitobject['liquidrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
            let date = this.convertUtcToLocal(datapoint.timestamp);
            if (!this.dateList.includes(date)) { this.dateList.push(date); }
            this.liquidrate[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedPip']) {
          this.unitobject['pip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
            let date = this.convertUtcToLocal(datapoint.timestamp);
            this.pip[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedPumpWearFactor']) {
          this.unitobject['pumpwfactor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.pumpwfactor[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedProductivityIndex']) {
          this.unitobject['pi'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.pi[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedFrictionFactor']){
          this.unitobject['frfactor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.frfactor[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedHoldupFactor'] ){
          this.unitobject['holdfactor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.holdfactor[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedReservoirPressure'] ){
          this.unitobject['rpressure'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.rpressure[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedKs'] ){
          this.unitobject['ks'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.ks[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_EstimatedKn']) {
          this.unitobject['kn'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.kn[date] = datapoint.average;
          });
        }

        // if (data.externalId.includes( 'CallibrationInputsUsed:gor')) {
        //   this.unitobject['gor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
        //   data.datapoints.forEach((datapoint: any) => {
        //   let date = this.convertUtcToLocal(datapoint.timestamp);
        //   this.gor[date] = datapoint.average;
        //   });
        // }

        // if (data.externalId.includes( 'CallibrationInputsUsed:waterCut')) {
        //   this.unitobject['watercut'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
        //   data.datapoints.forEach((datapoint: any) => {
        //       let date = this.convertUtcToLocal(datapoint.timestamp);
        //     this.watercut[date] = datapoint.average;
        //   });
        // }

        // if (data.externalId.includes( 'CallibrationInputsUsed:liquidRate')) {
        //   this.unitobject['inputliquidrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
        //   data.datapoints.forEach((datapoint: any) => {
        //       let date = this.convertUtcToLocal(datapoint.timestamp);
        //     this.inputliquidrate[date] = datapoint.average;
        //   });
        // }

        if (data.externalId ==wellTsDict[w+'_theoreticalGOR']) {
          this.unitobject['gor'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
            let date = this.convertUtcToLocal(datapoint.timestamp);
            this.gor[date] = datapoint.average;
          });
        }
        if (data.externalId ==wellTsDict[w+'_theoreticalWaterCut']) {
          this.unitobject['watercut'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.watercut[date] = datapoint.average;
          });
        }
        if (data.externalId ==wellTsDict[w+'_theoreticalLiquidRate']) {
          this.unitobject['inputliquidrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.inputliquidrate[date] = datapoint.average;
          });
        }

        if (data.externalId== wellTsDict[w+'_tubingHeadPress']) {
          this.unitobject['thp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.thp[date] = datapoint.average;
          });
        }

        if (data.externalId== wellTsDict[w+'_operatingFrequency']) {
          this.unitobject['frequency'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.frequency[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_pumpIntakePress']) {
          this.unitobject['inputpip'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.inputpip[date] = datapoint.average;
          });
        }
        if (data.externalId== wellTsDict[w+'_pumpDischargePress']) {
          this.unitobject['inputpdp'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.inputpdp[date] = datapoint.average;
          });
        }

        if (data.externalId== wellTsDict[w+'_aAnnulusPress']) {
          this.unitobject['annuluspressure'] = 'psia';
          data.datapoints.forEach((datapoint: any) => {
            let date = this.convertUtcToLocal(datapoint.timestamp);
            this.annuluspressure[date] = Convert('psig','psia',datapoint.average);
          });
        }
        if (data.externalId== wellTsDict[w+'_powerFluidRateCorrected']) {
          this.unitobject['pfrate'] = (data.unit != undefined && data.unit != null) ? data.unit : '';
          data.datapoints.forEach((datapoint: any) => {
              let date = this.convertUtcToLocal(datapoint.timestamp);
            this.pfrate[date] = datapoint.average;
          });
        }

      }

    }

      )
      this.getGriddata(w);

    });
      this.loadFinalData();

    });
  }}

  parseDate(dateStr: string): Date {
    return moment(dateStr, 'DD-MM-YYYY HH:mm').toDate();
  }
  sortByDate(array: any[], dateField: string): any[] {
    return _.orderBy(array, [item => this.parseDate(item[dateField])], ['desc']);
  }
  AssignIds(array: any[]): any[] {
    // Assign sequential IDs starting from 1
    return array.map((item, index) => ({
      ...item,
      id: index + 1
    }));
  }


  loadFinalData(){
    this.directive.skip = 0;
    this.gridData = (addSortableDates(this.finalGridData, this.kendoGrid) as any);
    this.loading = false;
  }

  convertUtcToLocal(utcTime: string): string {
    return moment.utc(utcTime).local().format('DD-MM-YYYY HH:mm');
  }

  async get_ts_data_in_parallel(externalidlist:any){
    let max_ts_size=99
    let results: any[] = [];
    let promises = [];
    for (let index = 0; index < externalidlist.length; index += max_ts_size) {
      const batch = externalidlist.slice(index, index + max_ts_size);

      // Store promises for parallel execution
      promises.push(
        this.apiService.getTimeseriesDataAvgRange1(
          batch,
         this.filterStartDate._d,
         this.filterEndDate._d,
        ).catch(error => {
          console.error("Error in getting data:", error);
          return []; // Return an empty array if there's an error to continue processing the rest
        })
      );
    }
    try {
      // Wait for all promises to resolve
      const batchResults = await Promise.all(promises);

      // Flatten the results array
      results = batchResults.flat();
      return results;
    } catch (error) {
      console.error("Error in processing batches:", error);
    }
    return [];
  }

  getGriddata(well: string) {
    this.loading = true;
    let tempitem: CalibrationSumaryModel = {};
    let tempgrid: any[] = [];
    this.gridData = [];
    let id = 0;

    // this.dateList = this.dateList
    //   .map(date => moment(date))
    //   .sort((a, b) => b.valueOf() - a.valueOf())
    //   .map(date => date.format("DD-MM-YYYY HH:mm"));
    // console.log(this.dateList);
    this.dateList.forEach((date) => {
      tempitem = {};
      tempitem.id = id;
      tempitem.well = well;
      tempitem.testdate = date; //+ ' ' + (this.timelist[date] != undefined ? this.timelist[date] : '00:00');
      tempitem.calrundate = this.runDate;
      tempitem.liquidrate = this.liquidrate[date] != undefined ? this.liquidrate[date].toFixed(2) : '-';
      tempitem.inputliquidrate = this.inputliquidrate[date] != undefined ? this.inputliquidrate[date].toFixed(2) : '-';
      tempitem.watercut = this.watercut[date] != undefined ? this.watercut[date].toFixed(2) : '-';
      tempitem.gor = this.gor[date] != undefined ? this.gor[date].toFixed(2) : '-';
      tempitem.fthp = this.thp[date] != undefined ? this.thp[date].toFixed(2) : '-';
      tempitem.pip = this.pip[date] != undefined ? this.pip[date].toFixed(2) : '-';
      tempitem.inputpip = this.inputpip[date] != undefined ? this.inputpip[date].toFixed(2) : '-';
      tempitem.pi = this.pi[date] != undefined ? this.pi[date].toFixed(2) : '-';
      tempitem.pdp = this.pdp[date] != undefined ? this.pdp[date].toFixed(2) : '-';
      tempitem.inputpdp = this.inputpdp[date] != undefined ? this.inputpdp[date].toFixed(2) : '-';
      tempitem.frequency = this.frequency[date] != undefined ? this.frequency[date].toFixed(2) : '-';
      tempitem.frfactor = this.frfactor[date] != undefined ? this.frfactor[date].toFixed(2) : '-';
      tempitem.holdfactor = this.holdfactor[date] != undefined ? this.holdfactor[date].toFixed(2) : '-';
      tempitem.pumpwfactor = this.pumpwfactor[date] != undefined ? this.pumpwfactor[date].toFixed(2) : '-';
      tempitem.rpressure = this.rpressure[date] != undefined ? this.rpressure[date].toFixed(2) : '-';
      tempitem.annuluspressure = this.annuluspressure[date] != undefined ? this.annuluspressure[date].toFixed(2) : '-';
      tempitem.pfrate = this.pfrate[date] != undefined ? this.pfrate[date].toFixed(2) : '-';
      tempitem.ks = this.ks[date] != undefined ? this.ks[date].toFixed(2) : '-';
      tempitem.kn = this.kn[date] != undefined ? this.kn[date].toFixed(2) : '-';
      tempgrid.push(tempitem);
      this.finalGridData.push(tempitem)
      id += 1;
    });
    // this.directive.skip = 0;
    // this.gridData = tempgrid;
    // this.loading = false;
    //this.finalGridData.push(tempgrid)
    return tempgrid;
  }

}

