import { addSortableDates } from 'src/app/utils/sort-dates';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CognitApiService } from '../../services/cognit-api.service'
import { CognitDataFormatter } from '../../services/cognit-data-formatter'
import moment from 'moment';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { LoaderService } from '../../services/loader.service'
import { wellModel } from '../model';
import { HierarchyService } from '../../services/hierarchy.service';
import { HierarchyComponent } from 'src/app/common-component/hierarchy/hierarchy.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExportColumnRule } from 'src/app/directives/export-column-rule';


@Component({
  selector: 'app-hfalarm',
  templateUrl: './hfalarm.component.html',
  styleUrls: ['./hfalarm.component.css']
})
export class HfalarmComponent {

  @ViewChild('hierarchyComponent') hierarchyComponent: HierarchyComponent;
  @ViewChild('hierarchyComponent') HfalarmComponent: HfalarmComponent;
  @ViewChild(GridComponent) public kendoGrid: GridComponent;

  public date1: any;
  public date2: any;
  public showDateRange: boolean = false;
  public showClearButton: boolean = false;
  public loading: boolean = false;
  public wellPadList: any;
  public filterWellList: any;
  public selectedField: string = "";
  public selectedwell: string = "";
  public selectedwellPad: string = "";
  private subs: Subscription[] = [];
  public alarmgridData: wellModel[] = [];
  public rawgridData: wellModel[] = [];
  public WellListToloadGrid: any[] = [];
  public iframeurl: SafeResourceUrl;

  public exportColumnRules: ExportColumnRule[] = [
    { field: 'Start Date', format: (value: any) => moment(value).utcOffset(330).format('DD-MM-YYYY') },
  ];

  constructor(
    private apiService: CognitApiService,
    private hierarchyService: HierarchyService,
    private sanitizer: DomSanitizer,
    private hostElement: ElementRef,) {
    this.showDateRange = true;
    this.showClearButton = true;
  }

  ngOnInit() {
    let well = "Bhag-013-15-203"// "Mang-015-09-203"
    let startdate = moment(this.date1, "YYYY-MM-DD");
    //console.log(startdate);
    let url = "https://app.powerbi.com/reportEmbed?reportId=31a43049-b53b-4828-b85c-71e2c49bd2dd&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&$filter=FlatHierarchy/wellName eq '" + well + "' and CALENDAR/Date ge 2024-05-01 and CALENDAR/Date le 2024-05-20"
    this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // const iframe = this.hostElement.nativeElement.querySelector('iframe');
    // iframe.src = url;
  }

  ngOnChanges() {
    let startdate = moment(this.date1, "YYYY:MM:DD");
  }

  clear() {
    this.alarmgridData = [];
    this.rawgridData = [];
    this.selectedField = "";
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.WellListToloadGrid = [];
    this.hierarchyComponent.selectedField = "";
    this.hierarchyComponent.selectedwellPad = "";
    this.hierarchyComponent.selectedwell = "";
    this.hierarchyComponent.wellPadList = [];
    this.hierarchyComponent.filterWellList = [];
    this.HfalarmComponent.clear();
  }


  onDateSelected(event: any) {
    this.date1 = moment(event.event.startDate).toDate();
    this.date2 = moment(event.event.endDate).add(1, 'days').toDate();
    if (event.event.startDate != null && event.event.endDate != null) {
      this.loadIframe();
      this.loadGridData();
    }
  }
  refresh() {
    //this.loadGridData();
  }
  onfieldChange(event: any) {
    this.selectedField = event.event.value;
    this.selectedwell = "";
    this.selectedwellPad = "";
    this.getWelldata([event.event.value]);
    this.wellPadList = event.wellPadList;
  }

  onfilterwellPadChange(event: any) {
    this.selectedwellPad = event.event.value;
    this.filterWellList = event.well;
    this.WellListToloadGrid = event.well;
    this.loadGridData();
  }
  loadIframe() {
    let startdate = moment(this.date1).format("YYYY-MM-DD");
    let enddate = moment(this.date2).format("YYYY-MM-DD");
    let url = "https://app.powerbi.com/reportEmbed?reportId=31a43049-b53b-4828-b85c-71e2c49bd2dd&autoAuth=true&ctid=4273e6e9-aed1-40ab-83a3-85e0d43de705&$filter=FlatHierarchy/wellName eq '" + this.selectedwell + "' and CALENDAR/Date ge " + startdate + " and CALENDAR/Date le " + enddate + ""
    this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    let doc = document.getElementById('powerbiframe');
  }
  onfilterwellChange(event: any) {
    this.selectedwell = event.event.value;
    this.loadIframe();
    //doc?.conte.location.reload();
    // const iframe = this.hostElement.nativeElement.querySelector('iframe');
    // iframe.src = url;

    if (this.filterWellList == undefined)
      this.WellListToloadGrid = event.allFieldwells;
    else
      this.WellListToloadGrid = this.filterWellList.filter((item: any) => item.externalId == event.event.value);
    this.loadGridData();
  }

  public getWelldata(field: any[], isFilter = false) {
    let wellpadfilter = {
      "nested": {
        "scope": [environment.cogniteSpace, 'WellPad/1_0', "field"],
        "filter": {
          "in": {
            "property": ["node", "externalId"],
            "values": field
          }
        }
      }
    }
    this.loading = true;
    this.apiService.getInstancelist("WellPad", wellpadfilter).subscribe((wellpad: any) => {
      let wellpadid = wellpad.items.map((X: any) => X.externalId)
      let wellfilter = {
        "nested": {
          "scope": [environment.cogniteSpace, 'Well/1_0', "wellPad"],
          "filter": {
            "in": {
              "property": ["node", "externalId"],
              "values": wellpadid
            }
          }
        }
      }
      this.apiService.getInstancelist("Well", wellfilter).subscribe((well: any) => {
        this.loading = false;
        let version = environment.cogniteSDMVersion
        let space = environment.cogniteSpace;
        let nodeProperties: { externalId: string; Name: string; Field: string, Wellpad: string }[] = [];
        well.items.forEach((item: any) => {
          let properties = item.properties;
          let propertydata = properties[space]["Well" + "/" + version];
          if (properties !== undefined) {
            let wellpadname = this.wellPadList.filter((wellpad: any) => wellpad.externalId == propertydata.wellPad.externalId)[0].Name;
            nodeProperties.push({ externalId: item.externalId, Name: propertydata.name.toString(), Field: this.selectedField, Wellpad: wellpadname })
          }
        });
        let listwell = nodeProperties;
        this.WellListToloadGrid = listwell;
        this.loadGridData();
      });

    }
    )
  }

  loadGridData() {
    this.alarmgridData = [];
    this.rawgridData = [];
    let version = "1_2";
    let viewid = this.getalarmviewID();
    let space = environment.cogniteSpace;
    let filter: any = undefined;
    if ((this.WellListToloadGrid != undefined && this.WellListToloadGrid.length > 0) && this.date1 != undefined && this.date1 != null && this.date2 != undefined && this.date2 != null) {
      let selectedwell = this.WellListToloadGrid.map((a: any) => a.externalId);
      filter = {
        and: [{
          "nested": {
            "scope": [environment.cogniteSpace, 'AlsWellAlarms/1_2', "well"],
            "filter": {
              "in": {
                "property": ["node", "externalId"],
                "values": selectedwell
              }
            }
          }
        }, {
          and: [
            {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "startDateTime"],
                gt: moment(this.date1).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }, {
              range: {
                property: [environment.cogniteSpace, 'AlsWellAlarms/1_2', "endDateTime"],
                lte: moment(this.date2).utc().format("YYYY-MM-DDTHH:mm:ss"),
              }
            }
          ]
        }
        ]
      }
      this.loading = true;
      this.apiService.getInstancelist(viewid, filter, version).subscribe(data => {
        let rawData = data['items'];
        if (rawData.length > 0) {
          rawData.forEach((item: any) => {
            let property = item.properties;
            let record = property[space][viewid + "/" + version];
            if (record['well'] != undefined) {
              let starttime = moment(record['startDateTime']).format("DD-MM-YYYY HH:mm");
              let endtime = moment(record['endDateTime']).format("DD-MM-YYYY HH:mm");
              let dataRow = {
                well: record['well']['externalId'],
                workflowName: record['workflowName'],
                startDateTime: starttime,
                endDateTime: endtime,
                alarmName: record['alarmName'],
                alarmFlags: record['alarmFlags'] == 'null' ? '' : record['alarmFlags'],
                alarmMessage: record['alarmMessage'] == 'null' ? '' : record['alarmMessage'],
                alarmType: record['alarmType'],
                alarmLevel: record['alarmLevel'],
              };
              this.rawgridData.push(dataRow);
            }
          });
          this.alarmgridData = addSortableDates(this.rawgridData, this.kendoGrid, null, 'DD-MM-YYYY');
          this.loading = false;
        } else { this.loading = false; }
      });
    } else {
      this.alarmgridData = [];
      this.loading = false;
    }
  }

  getalarmviewID(): string {
    let id = "AlsWellAlarms";
    return id;
  }

}
