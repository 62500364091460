export const environment = {
  production: true,
  //Vendanta
  clientId: "6e96091c-64da-472d-928b-a6c503761c92",
  tenantId: "4273e6e9-aed1-40ab-83a3-85e0d43de705",
  authority: `https://login.microsoftonline.com/4273e6e9-aed1-40ab-83a3-85e0d43de705`,
  Scopes: ['api://6e96091c-64da-472d-928b-a6c503761c92/Read'],
  cluster: "az-pnq-gp-001",
  baseUrl: `https://az-pnq-gp-001.cognitedata.com/`,
  scopes_baseurl: [`https://az-pnq-gp-001.cognitedata.com/DATA.VIEW`],
  returnUrl: "https://drishti.cairn-vedanta.di.evt.slb.com/",
  project: "cairn-test",
  cogniteSpace: "workflow-sdm-spc",
  cogniteInformationSDMSpace: "cognite-development",
  cogniteSDMVersion: "1_0",
  webApiUrl: "https://app-psdc-dashboard.azurewebsites.net/api",
  pythonApiUrl: "https://cdf-api-evt-avath5ameedgb3e8.centralindia-01.azurewebsites.net",
  view: {
    espParameter: 'EspParameters',
    espParameter_2: 'EspParameters_2',
    pcpParameter: 'PcpParameters',
    jpParameter: 'JpParameters',
    hrpParameter: 'HrpParameters',
    injectorWellproperty: 'InjectorWellProperties',
    nf: 'VizWellOperationalInputsHf',
    ProducerWellProperties: 'ProducerWellProperties',
    FieldPfProperties: 'FieldPfProperties',
    AlsWellAlarms: 'AlsWellAlarms',
    AlarmDefinition: 'AlarmDefinition'
  },
  version: {
    espParameter: '1_0',
    espParameter_2: '1_0',
    pcpParameter: '1_0',
    jpParameter: '1_0',
    hrpParameter: '1_0',
    injectorWellproperty: '1_0',
    nf: '1_0',
    ProducerWellProperties: '1_0',
    FieldPfProperties: '1_0',
    injectionwellVersion: '11',
    AlsWellAlarms: '1_2',
    AlarmDefinition: '1.0'
  },
  spaces: {
    informationModel: {
      id: 'cognite-development',
      views: {
        well: {
          id: 'Well',
          version: '1.0',
        },
        wellPad: {
          id: 'WellPad',
          version: '1.0',
        },
        pcp: {
          id: 'ProgressingCavityPump',
          version: '1.0',
        },
        esp: {
          id: 'ElectricalSubmergiblePump',
          version: '1.0',
        },
        jetPump: {
          id: 'JetPump',
          version: '1.0',
        },
        hydraulicRodPump: {
          id: 'HydraulicRodPump',
          version: '1.0',
        },
        WellAllocationdata: {
          id: 'WellAllocationData',
          version: '1.0',
        },
      },
    },
    workflow: {
      id: 'workflow-sdm-spc',
      views: {
        well: {
          id: 'Well',
          version: '1_0',
        },
        Welltheoreticaldata: {
          id: 'WellTheoreticalData',
          version: '1_2',
        },
        VizWellTheoreticalData: {
          id: 'VizWellTheoreticalData',
          version: '1_0',
        },
      },
    },
    wellRanking: {
      id: 'workflow-sdm-spc',
      views: {
        tolerance: {
          id: 'WRFMTolerances',
          version: '1_0',
        },
        JPOpportunityList: {
          id: 'JpOpportunityList',
          version: '1_0',
        },
        ESPOpportunityList: {
          id: 'EspOpportunitiesList',
          version: '1_0',
        },
        productionHistory: {
          id: 'WellTheoreticalData',
          version: '1_2',
        },
        espParameters: {
          id: 'EspParameters',
          version: '1_0',
        },
        jpParameters: {
          id: 'JpParameters',
          version: '1_0',
        }, alerts: {
          id: 'WrfmWorkflowAlarms',
          version: '1_0',
        },
      },
    },
    wellbook: {
      id: 'wellbook-sdm-spc',
      views: {
        wellbook: {
          id: 'Wellbook',
          version: '1.0',
        },
        logs: {
          id: 'Logs',
          version: '1.0',
        },
        dynacard: {
          id: 'Dynacard',
          version: '1.0',
        },
        echometer: {
          id: 'Echometer',
          version: '1.0',
        },
        wellEvents: {
          id: 'WellEvents',
          version: '1.0',
        },
        completionSchematic: {
          id: 'CompletionSchematic',
          version: '1.0',
        },
        deviation: {
          id: 'Deviation',
          version: '1.0',
        },
        ofm: {
          id: 'OFM',
          version: '1.0',
        },
        maps: {
          id: 'Maps',
          version: '1.0',
        },
        tags: {
          id: 'GeTags',
          version: '1.0',
        },
      },
    }, wrfm: {
      id: 'workflow-sdm-spc',
      views: {
        JP: {
          id: 'JpOpportunityList',
          version: '1_0',
        },
        ESP: {
          id: 'EspOpportunitiesList',
          version: '1_0',
        },
      }
    },
    automatedDashboard: {
      id: 'workflow-sdm-spc',
      views: {
        well: {
          id: 'Well',
          version: '1_0',
        },
        Welltheoreticaldata: {
          id: 'WellTheoreticalData',
          version: '1_2',
        },
        WellAllocatedData: {
          id: 'WellAllocatedData',
          version: '1_0',
        },
        InjectorWellProperties: {
          id: 'InjectorWellProperties',
          version: '1_0',
        },
        CurrentLift: {
          id: 'CurrentLift',
          version: '1_0',
        },
        CurrentWellType: {
          id: 'CurrentWellType',
          version: '1_0',
        },
        FieldRollUpTheoretical: {
          id: 'FieldRollUpTheoretical',
          version: '1_0',
        }
      },
    },
    callibrationModule: {
      id: 'workflow-sdm-spc',
      views: {
        CalibrationProcessingData: {
          id: 'CalibrationProcessingData',
          version: '1_1',
        }, 
        CalibrationOutputData: {
          id: 'CalibrationOutputData',
          version: '1_1',
        },

      },
    },

  },
  WTVFunctionName: "ds_well_test_validation-master",
  WelltesteventsFunctionName: "ds_create_well_test_events-master",
  DepthInterpolationFunctionName: "ds_depth_interpolation-master",
  allowedUserIds: ['tkamankar@slb.com', 'ayadav15@slb.com', 'ppitla@slb.com', 'dpande@slb.com', 'aa14@slb.com', 'skumar268@slb.com', 'kgowher@slb.com'
  ],
}

