<div #expandableDiv>
  <div *ngIf="loading" class="spinner__loading loading-container">
    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
  </div>
  <div class="series-selector">
    <mat-label>Available Curves:</mat-label>
    <mat-form-field class="form-field" appearance="outline">
      <mat-select [(value)]="selectedSeries" multiple [panelClass]="'full-height-panel'" (selectionChange)="onSeriesChange()">
        <mat-option *ngFor="let s of series" [value]="s">{{ s.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="chart">
    <button #test type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="resetZoom()" *ngIf="isZoomed">Reset zoom</button>
    <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" [toggleFullscreen]="expandableDiv" (onFullscreenChanged)="toggleFullscreen($event)">
      <mat-icon *ngIf="!isFullscreen" svgIcon="expand" aria-hidden="false" aria-label="Expand" title="View full screen"></mat-icon>
      <mat-icon *ngIf="isFullscreen" svgIcon="close" aria-hidden="false" aria-label="Close" title="Close full screen"></mat-icon>
    </button>
  </div>
  <div id="chart-container" class='las-viewer-chart' #chartContainer>
    <highcharts-chart *ngIf="!loading && selectedSeries.length > 0" [Highcharts]="Highcharts" [constructorType]="'chart'" [options]="chartOpts" (chartInstance)="setChartInstance($event)"></highcharts-chart>
  </div>
  <div style="margin-top: 20px;">
    <kendo-grid appGridAutoTooltip [kendoGridBinding]="curveInfo" [rowClass]="rowClass" appGridTransform>
      <ng-template kendoGridToolbarTemplate>
        <div style="width: 100%; display: flex; justify-content: flex-end; flex-direction: row; flex-wrap: nowrap; align-items: center;">
          <button type="button" class="k-button k-grid-excel k-button-md k-rounded-md k-button-solid-base k-button-solid" (click)="toggleCurveInfoRowsVisibility()">
            <mat-icon *ngIf="showCurveInfoRows" svgIcon="hide" aria-hidden="false" aria-label="Hide" title="Hide rows"></mat-icon>
            <mat-icon *ngIf="!showCurveInfoRows" svgIcon="show" aria-hidden="false" aria-label="Show" title="Show rows"></mat-icon>
          </button>
        </div>
      </ng-template>
      <kendo-grid-column field="name" title="Curve"></kendo-grid-column>
      <kendo-grid-column field="unit" title="Unit of Measurement"></kendo-grid-column>
      <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="no-data" *ngIf="!loading && selectedSeries.length === 0">
    <p>No data to display</p>
  </div>
</div>
