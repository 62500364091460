import { Component, OnDestroy, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnDestroy {
   
  public loggedIn = false;    
  private dialogRef: any;
  @ViewChild('sessionDialog', { static: true }) sessionDialog: TemplateRef<any>;

  constructor( matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,){
   // matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-symbols.svg'));
   // matIconRegistry.addSvgIcon(domSanitizer.bypassSecurityTrustResourceUrl("assets/vedantalogo.svg"));
  }
   
  ngOnDestroy(): void {
       
    }
  
  loginSuccessEvent(event:any) {
    this.loggedIn = true;
  }
}
